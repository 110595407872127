import englishMessages from 'ra-language-english';
//import React from "react";

export default {
  ...englishMessages,
  'undefined' : '',
  main: {
    title: 'Untangl Administration'
  },
  action: {
    detail: 'Detail'
  },
  pos: {
    search: 'Search',
    configuration: 'Configuration',
    profile: 'Profile',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
      white: 'White',
      plain: 'Plain',
      untangl: 'Untangl'
    },
    dashboard: {
      new_users: 'New Users',
      new_untanglements: 'New Untanglements',
      untanglements_per_user: 'Untanglements per user last week',
      daily_untanglements: "Daily untanglements",
      monthly_revenue: 'Monthly Revenue',
      new_orders: 'New Orders',
      pending_reviews: 'Pending Reviews',
      new_customers: 'New Customers',
      pending_orders: 'Pending Orders',
      order: {
        items:
          'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
      },
      welcome: {
        title: 'Welcome to Untangl Admin',
        subtitle:
          "This is Untangl Admin.",
        aor_button: 'react-admin site',
        demo_button: 'Source',
      },
    },
  },
  notification: {
    permission: {
      created: 'Permission created'
    },
    updated: 'Element updated |||| %{smart_count} elements updated',
    updated_one: 'Element updated',
    fields_updated: 'Fields updated',
    fields_not_updated: 'Error: Fields not updated!',
    fields_not_created: 'Error: Fields not created!',
    field_updated: 'Template field changes saved',
    created: 'Element created',
    deleted: 'Element deleted |||| %{smart_count} elements deleted',
    bad_item: 'Incorrect element',
    item_doesnt_exist: 'Element does not exist',
    http_error: 'Server communication error',
    data_provider_error:
      'dataProvider error. Check the console for details.',
    i18n_error:
      'Cannot load the translations for the specified language',
    canceled: 'Action cancelled',
    logged_out: 'Your session has ended, please sign in.',
    onboarded: 'Client onboarded with application %{name}',
    unauthenticated: "Unable to sign in",
    no_dictionaries: "No dictionaries are configured",
    no_data: "No data found in sheet",
    no_header_row: "No header row found",
    no_data_row: "No data rows found under header",
    untangler_decode_error: "Unable to convert this file",
    untangler_template_configuration: 'Template configuration error'
  },
  navigation: {
    back: 'Back',
    create: 'Create',
    edit: 'Edit',
    delete: 'Delete'
  },
  resources: {
    users: {
      name: 'User |||| Users',
      name_one: 'User',
      name_many: 'Users',
      fields: {
        name: 'Full Name',
        rep_uname: 'Rep Uname',
        user_name: 'User Name',
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'Email Address',
        role: 'Role',
        verifyDate: 'Verified',
        jurisdictions: 'Jurisdictions',
        id: 'ID'
      }
    },
    permissions: {
      name: 'Permission |||| Permissions',
      name_many: 'Permissions'
    },
    password: {
      name: 'Password |||| Passwords',
      name_many: 'Passwords'
    },
    employers: {
      name: 'Employer |||| Employers',
      name_one: 'Employer',
      name_many: 'Employers',
      fields: {
        id: 'ID'
      }
    },
    contracts: {
      name: 'Contract |||| Contracts',
      name_one: 'Contract',
      name_many: 'Contracts',
      fields: {
        benefit_uname: 'Benefit Identifier',
        benefit_name: 'Benefit Name',
        benefit_version: 'Benefit Version',
        rates_version: 'Rates Version',
        policy_number: 'Policy Number',
        start_date: 'Start Date',
        end_date: 'End Date',
        creation_date: 'Creation Date',
        name: 'Name',
        value: 'Value',
        type: 'Type'
      }
    },
    evaluations: {
      name: 'Evaluation |||| Evaluations',
      name_one: 'Evaluation',
      name_many: 'Evaluations'
    },
    employees: {
      name: 'Employee |||| Employees',
      name_one: 'Employee',
      name_many: 'Employees',
      fields: {
        'employee.first_name': 'First Name',
        'employee.last_name': 'Last Name',
        'employee.gender': 'Gender',
        'employee.street1': 'Street 1',
        'employee.street2': 'Street 2',
        'employee.city': 'City',
        'employee.postcode': 'Postcode',
        'employee.dob': 'Date of Birth',
        start_date: 'Join Date',
        end_date: 'Leave Date',
        'employer.name': 'Employer'
      }
    },
    persons: {
      name: 'Person |||| People',
      name_one: 'Person',
      name_many: 'People',
      fields: {
        first_name: 'First Name',
        last_name: 'Last Name',
        dob: 'Date of Birth',
        street1: 'Street 1',
        street2: 'Street 2',
        'address.country_name': 'Country'
      }
    },
    benefits: {
      name: 'Benefit |||| Benefits',
      name_one: 'Benefit',
      name_many: 'Benefits',
      benefitName: 'Benefit Name',
      fields: {
        field: 'Field',
        fieldName: 'Field Name',
        fieldValue: 'Field Value',
        name: 'Name',
        value: 'Value',
        label: 'Label',
        formula: 'Formula',
        section: 'Section',
        sectionName: 'Section Name',
        optionName: 'Option Name',
        optionValue: 'Option Value',
        groupName: 'Group Name',
        groupValue: 'Group Value',
        group: "Group",
        constraint: "Constraint",
        constraints: "Constraints"
      }
    },
    representatives: {
      name: 'Application |||| Applications',
      name_one: 'Application',
      name_many: 'Applications',
      fields: {
        uname: 'Unique Name',
        tel: 'Telephone'
      }
    },
    applications: {
      name: 'Application |||| Applications',
      name_one: 'Application',
      name_many: 'Applications',
      fields: {
        name: 'Name',
        tel: 'Telephone'
      }
    },
    sites: {
      name: 'Site |||| Sites',
      name_one: 'Site',
      name_many: 'Sites'
    },
    datadictionaries: {
      name: 'Dictionary Entry |||| Dictionary Entries',
      fields: {
        id: 'ID'
      }
    },
    dictionaries: {
      name: 'Dictionary |||| Dictionaries',
      name_one: 'Dictionary',
      name_many: 'Dictionaries',
      fields: {
        id: 'ID',
        _id: 'ID',
        description: 'Description'
      }
    },
    dictionaryentries: {
      name: 'Dictionary Entry |||| Dictionary Entries',
      name_one: 'Entry',
      name_many: 'Entries',
      fields: {
        id: 'ID',
        _id: 'ID',
        name: 'Name',
        value: 'Value',
        alternates: 'Alternates',
        alias: 'Alias',
        weight: 'Weight',
        dictName: 'Dictionary',
        dictId: 'Dictionary ID'
      }
    },
    dictionaryaliases: {
      name: 'Dictionary Alias |||| Dictionary Aliases',
      name_one: 'Dictionary Alias',
      name_many: 'Dictionary Aliases',
      fields: {
        id: 'ID',
        _id: 'ID',
        alias: 'Alias',
        name: 'Name',
        weight: 'Weight',
        dictName: 'Dictionary',
        dictId: 'Dictionary ID'
      }
    },
    templates: {
      name: 'Template |||| Templates',
      name_one: 'Template',
      name_many: 'Templates',
      fields: {
        id: 'ID',
        _id: 'ID',
        name: 'Name',
        value: 'Value'
      }
    },
    templatefields: {
      name: 'Template Field |||| Template Fields',
      name_one: 'Field',
      name_many: 'Fields',
      name_reorder: 'Template Fields Ordering',
      fields: {
        id: 'ID',
        _id: 'ID',
        title: 'Title',
        name: 'Name',
        source: 'Source',
        value: 'Value',
        expression: 'Formula',
        code: {name: 'Code'},
        constant: {value: 'Constant'},
        function: {name: 'Function'},
        format: 'Format',
        necessity: 'Necessity'
      }
    },
    templatefieldsorder: {
      name: 'Template Field |||| Template Fields (Ordering)',
      name_reorder: 'Reorder Template Fields',
      name_one: 'Field',
      name_many: 'Fields (Ordering)',
      fields: {
        id: 'ID',
        _id: 'ID',
        title: 'Title',
        name: 'Name',
        source: 'Source',
        value: 'Formula',
        code: {name: 'Code'},
        constant: {value: 'Constant'},
        function: {name: 'Function'},
        format: 'Format',
        necessity: 'Necessity'
      }
    },
    untanglements: {
      name: 'Conversion |||| Conversions',
      name_one: 'Conversion',
      name_many: 'Conversions',
      fields: {
        elapsed_time: 'Duration (s)',
        data_size: 'Size (KB)',
        in_range: 'Range'
      }
    },
    conversions: {
      name: 'Conversion |||| Conversions',
      fields: {
        elapsed_time: 'Duration (s)',
        data_size: 'Size (KB)',
        in_range: 'Range'
      }
    },
    setups: {
      name: 'Onboard Client |||| Onboard Client',
      name_one: 'Onboard Client',
      name_many: 'Onboard Client',
      fields: {
        application_name: 'Application Name',
        email: "Email Address"
      }
    }
  },
  validation: {
    user: {
      password: 'Password must be at least 8 characters long'
    }
  },
  entities: {
    US: {
      name: 'User'
    },
    RP: {
      name: 'Application'
    },
    ER: {
      name: 'Employer'
    }

  }
};
