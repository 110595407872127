import React from 'react';
import {
  EditView,
  SimpleForm,
  TextInput,
  SelectInput,
  TopToolbar,
  Loading,
  useGetList,
  EditContextProvider,
  useEditController
} from 'react-admin';
import {UnEditToolbar, DetailTitle} from "../components/InputFields";
import {Breadcrumbs} from "../breadcrumbs";
import {UnShowButtonWithName} from "../button/UnShowButtonWithFilter";

/**
 * <UnShowButtonWithName/> is a shortcut for:
 * <UnShowButtonWithFilter filter={{name: data.name}}/>
 * @returns {null|*}
 * @constructor
 */
const EmployerEditActions = () => {
  return (
    <TopToolbar>
      <UnShowButtonWithName />
    </TopToolbar>
  );
};

export const EmployerEdit = () => {
  const editContext = useEditController({redirect: 'show'});
  const {isLoading: isRepresentativesLoading, data: representatives} = useGetList(
    'representatives',
    {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}}
  );
  if (isRepresentativesLoading) return null;
  if (editContext.isLoading) return <Loading/>;
  return (
    <EditContextProvider value={editContext}>
      <Breadcrumbs/>
      <EditView
        title={<DetailTitle/>}
        actions={<EmployerEditActions/>}
        >
        <SimpleForm toolbar={<UnEditToolbar/>}>
          <TextInput source="name" fullWidth/>
          <TextInput source="street" fullWidth/>
          <TextInput source="city" fullWidth/>
          <TextInput source="postcode"/>
          <SelectInput label="Representative" source="representative_id" choices={representatives} fullWidth/>
          <SelectInput source="status" choices={[
            { id: ' ', name: 'Live' },
            { id: 'test', name: 'Test' }
          ]}/>
        </SimpleForm>
      </EditView>
    </EditContextProvider>
  );
}

export default EmployerEdit;