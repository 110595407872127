import React from 'react';
import {
  Create,
  TabbedForm, FormTab,
  SelectArrayInput, SelectInput,
  TextInput,
  required, minLength,
  TopToolbar,
  useGetList,
  Loading, Error,
  usePermissions,
  useRecordContext
} from 'react-admin';
// import Typography from '@mui/material/Typography';
// import Accordion from '@mui/material/Accordion';
import {BreadcrumbsCreate} from "../breadcrumbs";
import {Activity, isAuthorized} from "../auth/authorization";
import log from "loglevel";
import {CreateTitle} from "../components/InputFields";
import UnHelpButton from "../button/UnHelpButton";
//import TemplateDropPanel from "./TemplateDropPanel";

// const Aside = () => (
//   <TemplateDropPanel />
// );

const transform = (data) => {
  log.debug(`{TemplateCreate.transform} data:`, data);
  data.strategy = data.strategy ? data.strategy : 'untangler';  //Set default strategy
  return data;
};

//let globalRecord;

const TemplateActions = () => {
  const record = useRecordContext();
  console.log("{TemplateActions} record:", record);
  // globalRecord = record;
  // console.log("{TemplateActions} globalRecord:", globalRecord);
  return (
  <TopToolbar>
    <UnHelpButton helpPage={'/Configuration/template'}/>
  </TopToolbar>
  );
}

/**
 * The Error: Rendered more hooks than during the previous render appears if
 * isAuthorized(permissions, Activity.TEMPLATE) is used before useQueryWithStore
 * // const {loaded: loadedDictionaries, error: errorDictionaries, data: dictionaries} =
 //   isAuthorized(permissions, Activity.TEMPLATE) ?
 //     useQueryWithStore({
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

const TemplateCreate = () => {
  const {permissions} = usePermissions();
  const record = useRecordContext();
  //const {defaultValues} = useCreateContext();
  // const resource = useResourceContext();
  // const translate = useTranslate();
  // const {loaded: loadedDictionaries, error: errorDictionaries, data: dictionaries} =
  //   isAuthorized(permissions, Activity.TEMPLATE) ?
  //     useQueryWithStore({
  //       type: 'getList',
  //       resource: 'dictionaries',
  //       payload: {
  //         pagination: {page: 1},
  //         sort: {field: 'name', order: 'ASC'}
  //       }
  //     }) :
  //     {loadedDictionaries: true, data: []};
  const {
    isLoading: isDictionariesLoading,
    error: errorDictionaries,
    data: dictionaries
  } = useGetList('dictionaries', {
    pagination: {page: 1},
    sort: {field: 'name', order: 'ASC'}
  });

  const {
    isLoading: isLocalesLoading,
    error: errorLocales,
    data: localesAll
  } = useGetList('locales', {
    pagination: {page: 1},
    sort: {field: 'name', order: 'ASC'}
  });
  if (!permissions) return null;
  if (isLocalesLoading || isDictionariesLoading) { return <Loading />; }
  if (errorLocales || errorDictionaries) {return <Error />;}
  const locales = localesAll.map(r => ({id: r._id, name: r.name}));
  const displayDictionaries = dictionaries.map(d => {
    const section = d.section ? `${d.section}/` : '';
    const locale = d.locale ? `${d.locale}/` : '';
    return {id: `${d._id}`, name: `${section}${locale}${d.name}`};
  });
  //console.log("{TemplateCreate} defaultValues:", defaultValues);
  log.debug(`{TemplateCreate} displayDictionaries:`, displayDictionaries);
  console.log("{TemplateCreate} For clone - record:", record);
  //console.log("{TemplateCreate} globalRecord:", globalRecord);  // Hack
  // const selDictionaryIds = (globalRecord && globalRecord.dictionaries) ?
  //   globalRecord.dictionaries.map(d => ({_id: d._id, name: d.name})) : [];
  // console.log("{TemplateCreate} For clone - selDictionaryIds:", selDictionaryIds);
  // const initialValues = {
  //   dictionaries: selDictionaryIds
  // }

  return (
    <>
      <BreadcrumbsCreate/>
      <Create
        title={<CreateTitle/>}
        actions={<TemplateActions/>}
        transform={transform}
      >
        <TabbedForm>
          <FormTab label="Template">
            <TextInput source="name" validate={[required(), minLength(2)]}/>
            <TextInput source={'description'}
                       label={'Description'}
                       multiline={true}
                       rows={5}
                       fullWidth
            />
            <SelectArrayInput
              label="Dictionaries"
              source="dictionaries"
              choices={displayDictionaries}
              // initialValue={selDictionaryIds}
              fullWidth />
            {/*<ReferenceInput label="Locale" source="locale" reference="locales" allowEmpty fullWidth>*/}
            {/*  <SelectInput optionText="name" optionValue="id" fullWidth />*/}
            {/*</ReferenceInput>*/}
            <SelectInput label="Locale" source="locale" choices={locales} fullWidth/>
            {isAuthorized(permissions, Activity.TEMPLATE_PERMISSIONS) ?
              <TextInput source="strategy" defaultValue={"untangler"}/> : ''
            }
          </FormTab>
          <FormTab label="YAML">
            <TextInput source="yaml" multiline={true} rows={16} fullWidth/>
          </FormTab>
        </TabbedForm>
      </Create>
    </>
  )
};

export default TemplateCreate;