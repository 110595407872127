import React from 'react';
import {
  ShowView,
  ShowContextProvider,
  TabbedShowLayout, Tab,
  TextField,
  TopToolbar,
  useShowController,
  Loading,
  //usePermissions,
  //useResourceContext, useRecordContext
} from 'react-admin';
import log from 'loglevel';
import {DetailTitle} from "../components/InputFields";
import {Breadcrumbs} from "../breadcrumbs";
import {UnEditButtonWithName} from '../button/UnEditButtonWithFilter';
import {getTabName} from "../util/paramUtil";
import {isValidTabName} from "../components/EditActions";
import {useLocation} from "react-router-dom";
import {VariantTree} from "./VariantTree";
import {AdditionalOptionsTree} from "./AdditionalOptions";
//import {useLocation} from "react-router-dom";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const BenefitActions = ({tabName}) => {
  // const resource = useResourceContext();
  // const data = useRecordContext();
  log.debug("{BenefitActions} tabName:", tabName);
  const adjTabName = isValidTabName(tabName) ? tabName : '';
  log.debug("{BenefitActions} adjTabName:", adjTabName);
  return (
    <TopToolbar>
      <UnEditButtonWithName tabName={adjTabName} />
    </TopToolbar>
  );
}

export const NonInput = React.memo(function NonInput({ children }) {
  return children;
});


/**
 * When this form is first invoked, the URL contains the query arguments such as dictId and dictName.
 * However, these disappear when clicking on tabs and are therefore stored in local state using useState().
 * Each tab has a name set by path.
 *
 * @returns {null|*}
 * @constructor
 */
export const BenefitShow = () => {
  const location = useLocation();
  const showContext = useShowController();
  log.debug("{BenefitShow} showContext:", showContext);
  const {record, isLoading} = showContext;
  if (isLoading) return <Loading/>;
  log.info(`{BenefitShow} id: ${record.id}, name: ${record.name}, isLoading: ${isLoading}`);
  return (
    <ShowContextProvider value={showContext}>
      <Breadcrumbs/>
      <ShowView title={<DetailTitle />}
                actions={<BenefitActions tabName={getTabName(location)} />}
                >
        <TabbedShowLayout>

          <Tab label="Required Selections">
            <VariantTree/>
          </Tab>

          <Tab label="Optional Selections" path={"optional"}>
            <AdditionalOptionsTree/>
          </Tab>

          <Tab label="general" path={"general"}>
            {/*<TextField source='name' label='Benefit name'/>*/}
            <TextField source="summary" fullWidth/>
            {/*<TextField source='description' multiline={true} rows={3} fullWidth/>*/}
            {/*<TextField source="supplier" fullWidth/>*/}
          </Tab>

          <Tab label="yaml" path={"yaml"}>
            <TextField source="yaml" component="pre" fullWidth
              sx={{fontFamily: 'courier'}}
            />
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    </ShowContextProvider>
  )
};

export default BenefitShow;