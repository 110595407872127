import React, {
  useEffect,
  useState,
} from 'react';
import NotInterested from "@mui/icons-material/NotInterested";
import {makeStyles} from '@mui/styles';
import {
  Datagrid, TextField,
  FunctionField,
  TopToolbar, useTranslate,
  useDataProvider, useListController, ListContextProvider, ListView,
  Loading, useNotify, useRedirect, useRefresh, usePermissions, useResourceContext,
  useRecordContext, BulkDeleteWithConfirmButton
} from 'react-admin';
import log from 'loglevel';
import qs from "query-string";
import UnCreateButton from '../button/UnCreateButton';
import {ShowFieldsButton, HideFieldsButton} from './FieldsVisibilityButton';
import {Breadcrumbs} from "../breadcrumbs";
import {defaultPerPage, queryParamFilter, queryParamString, UnPagination} from "../util/paramUtil";
import {Activity, isAuthorized} from "../auth/authorization";
import {fetchDictionariesFromTemplate} from "../templates/TemplateData";
import DropPanel from "../components/DropPanel";
import {NecessityField} from './Necessity';
import {encode_col} from '../util/sheetUtil';
import {MultiSourceField} from './MultiSourceField';
import UnIconButton from "../button/UnIconButton";
import UnListButton from '../button/UnListButton';
import {useLocation} from "react-router-dom";
import {ListTitle} from "../components/InputFields";
import TemplateFields from '.'

const HIDDEN = 'h';

const useStyles = makeStyles(
  () => ({
    expression: {
      fontFamily: 'courier',
      // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
      '& svg': {color: 'orange'}
    },
    source: {
      fontStyle: 'italic'
      //color: theme.palette.error.main
    }
  })
);

/**
 * Throws away unwanted properties that cause errors like:
 * Warning: React does not recognize the basePath prop on a DOM element.
 * @type {React.NamedExoticComponent<{readonly children?: *}>}
 */
export const NonInput = React.memo(function NonInput({children}) {
  return children;
});

const TemplateDropPanel = (props) => {
  return <DropPanel {...props}/>
}

const TemplateFieldBulkActionButtons = (props) => {
  log.debug(`{TemplateFieldBulkActionButtons} props:`, props);
  const {mutationOptions} = props;
  return (
    <>
      <ShowFieldsButton {...props} />
      <HideFieldsButton mutationOptions={mutationOptions} />
      {/*<UnBulkDeleteWithConfirmButton {...props} />*/}
      <BulkDeleteWithConfirmButton mutationOptions={mutationOptions}/>
    </>
  );
}

/**
 * Actions in top toolbar, i.e. buttons in top right
 * @param props
 * @returns {null|*}
 * @constructor
 */
const TemplateFieldActions = (props) => {
  const {permissions} = usePermissions();
  const record = useRecordContext();
  const {filter} = props;
  log.debug(`{TemplateFieldActions} record:`, record);
  //if (!record) return null;
  return (
    <TopToolbar>
      {isAuthorized(permissions, Activity.TEMPLATE) ?
        <UnListButton
          label='resources.templatefieldsorder.name_many'
          pathname='/templatefieldsorder'
          filter={filter}
          record={record}
          icon={<TemplateFields.ICON />}
        /> : null}

      {isAuthorized(permissions, Activity.TEMPLATE) ?
      <UnCreateButton label="Create"
                      filter={filter}
                      idProp={'templateId'}
      />
      : null}
    </TopToolbar>
  );
};

/**
 * The bulk delete is carried out using Datagrid bulkActionButtons using the mutationOptions property
 * to pass the parent templateId. See also deleteMany in dataProviderRest.js.
 * Show and Hide bulk buttons result in updates.
 * Note the TemplateDropPanel has an onload function that is called with the reader argument from the
 * drop panel and dictionaryIds from here.
 *  parse(): input -> record
 *  format(): record -> input
 *  https://marmelab.com/react-admin/Inputs.html#transforming-input-value-tofrom-record
 * @returns {*}
 * @constructor
 */
const TemplateFieldList = () => {
  const {permissions} = usePermissions();
  const location = useLocation();
  const resource = useResourceContext();
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [dictionaries, setDictionaries] = useState();
  const [dictionariesLoading, setDictionariesLoading] = useState(true);
  const args = queryParamFilter(location);
  log.debug(`{TemplateFieldList} args:`, args);
  const {templateId, templateName} = args;
  const untanglefields = useUntanglefields({templateId, templateName});
  const listContext = useListController({pagination: <UnPagination/>, perPage: defaultPerPage});
  const {isLoading, data, page, perPage} = listContext;
  log.debug(`{TemplateFieldList} page: ${page}, data:`, data);
  log.debug(`{TemplateFieldList} templateId:`, templateId);
  //log.debug(`{TemplateFieldList} listContext:`, listContext);
  let row = -1;
  const isAuth = isAuthorized(permissions, Activity.TEMPLATE_READ);
  useEffect( () => {
    (async () => {
      let fetchedDictionaries = await fetchDictionariesFromTemplate({dataProvider, templateId})
        .catch(err => {
          log.error(`fetchDictionariesFromTemplate returned err:`, err);
          fetchedDictionaries = [];
        });
      log.info(`{TemplateFieldList.useEffect} fetchedDictionaries:`, fetchedDictionaries);
      // if (fetchedDictionaries) {  // This condition fixes blank page on update problem
      //   log.debug(`{TemplateFieldList} Setting dictionaries`);
      //   setDictionaries(fetchedDictionaries);
      // }
      // setDictionaries(fetchedDictionaries ? fetchedDictionaries : dictionaries);
      //setDictionaries(d => {d = fetchedDictionaries; return d});
      if (fetchedDictionaries) {
        setDictionaries(fetchedDictionaries);
        setDictionariesLoading(false);
      }
    })();
  }, [permissions, dataProvider, templateId, isAuth]);  // dictionaries?

  if (dictionariesLoading || isLoading) return <Loading/>;
  const parent = 'templates';
  const breadcrumbs = [
    {url: `/${parent}`, title: translate(`resources.${parent}.name_many`), resource: parent},
    {url: `/${parent}/${templateId}/show?${queryParamString({templateName, name: templateName})}`, title: templateName, resource: parent},
    {url: `/${resource}`, title: translate(`resources.${resource}.name_many`), resource}
  ];
  if (!dictionaries) return null;
  const dictNames = dictionaries.map(d => d.name);
  const dictionaryIds = dictionaries.map(d => d._id);
  log.debug(`{TemplateFieldList} dictNames after map:`, dictNames);
  log.debug(`{TemplateFieldList} templateId: ${templateId}, templateName: ${templateName}`);
  return (
    <ListContextProvider value={listContext}>
      <Breadcrumbs breadcrumbs={breadcrumbs}/>
      <ListView
        title={<ListTitle/>}
        actions={<TemplateFieldActions filter={{templateId, templateName}}/>}
        perPage={defaultPerPage}
        pagination={<UnPagination/>}
        sort={{field: '_id', order: 'ASC'}}
      >
        <Datagrid
          bulkActionButtons={isAuthorized(permissions, Activity.TEMPLATE) ?
            <TemplateFieldBulkActionButtons mutationOptions={{meta: {templateId}}}/>
            : false}>
          <FunctionField label="Column" render={(record) => {
              return (record.state !== HIDDEN) ?  encode_col((page - 1) * perPage + ++row): <NotInterested style={{color: "#eeeeee"}}/>;
            }
          } />
          <TextField source="title" sortable={false}/>

          {/*<EntryLink source="name" label={"Entry/Calculation"} sortable={false} dictNames={dictNames}/>*/}
          {/*<TextField source="value" label="Expression/Constant" sortable={false} />*/}

          <MultiSourceField label="Formula" classes={classes} dictNames={dictNames}/>

          {/*<ExpressionEntryLink source="value" label="Expression/Constant" sortable={false} dictNames={dictNames} />*/}

          {/*<FunctionField label="Source" render={record => record.source ? sourceName[record.source] : ''} />*/}

          <TextField source="format" sortable={false}/>
          {/*<TextField source="necessity" sortable={false}/>*/}
          <NecessityField source="necessity"/>

          <UnIconButton label={'Detail'} filter={{templateId, templateName}}/>
          {/*<DetailButton label={'Detail'}/>*/}
        </Datagrid>
      </ListView>
      {(data.length < 1)
      ? <TemplateDropPanel
           // templateId={templateId} templateName={templateName}
           title={'Drag & drop file for fields'}
           onload={(props) => untanglefields(props, dictionaryIds)}
           // dictionaryIds={dictionaryIds}
           resource={resource}/>
      :  null}
    </ListContextProvider>
  );
};

/**
 * Hook that sets up a function to be used when a file is loaded (drag & dropped) into drop panel.
 * This triggers the Untangler untanglefields process that creates new fields in a template.
 * Error definition from module:
 *   ret.status - ('success' if so, ) 'fail' for API errors, 'error' for server errors
 *   ret.name/code - more specific code detailing the error (name is the code that is looked up via i18n)
 *   ret.message - human readable description of the error
 *   ret.data - (optional) data supplied with the error e.g. field validation errors
 * @param templateId
 * @param templateName
 * @returns {function(*=, *=): *}
 */
const useUntanglefields = ({templateId, templateName}) => {
  //log.debug(`{useUntanglefields} templateId:`, templateId);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const resource = 'untanglefields';  // !!!

  return (reader, dictionaryIds) => {
    //log.debug(`{untanglefields} reader:`, reader);
    //log.debug(`{untanglefields} dictionaryIds:`, dictionaryIds);
    // Process file contents
    if (!reader) {
      log.warn(`{untanglefields} Null reader!`);
    }
    const contentsStr = reader.result;
    //log.debug(`{untanglefields} Drop - contentsStr:`, contentsStr);
    const query = {templateId};
    return dataProvider.create(`untanglefields?${qs.stringify(query)}`, {
      data: {
        name: "datasets test api 1",
        ohandle: "US:734743437",  // TODO: Remove in swagger?
        data: _arrayBufferToBase64(contentsStr),
        dictionaryIds
      }
    }).then(({data}) => {
      log.debug(`{untanglefields} resource: ${resource}, /untanglefields returned data:`, data);
      if (data.status === 'fail') { // Error message
        const msgCode = data.name || 'fields_not_created';
        log.debug(`{untanglefields} error message: ${data.message}, msgCode: ${msgCode}`);
        const displayMsg = msgCode === 'untangler_decode_error' ?
          'Unable to convert this file to template fields' :
          `notification.${msgCode}`
        notify(displayMsg, 'warning');
      }
      const filter = {
        templateId,
        templateName
      };
      if (resource === 'untanglefields') {
        log.debug(`{untanglefields} About to refresh. resource: ${resource}`);
        refresh();
        //window.location.reload(true);
      } else {
        log.debug(`{untanglefields} About to redirect. resource: ${resource}`);
        redirect(`/templatefields?${queryParamString(filter)}`);
      }
      return {data};
    });
  }
}

function _arrayBufferToBase64(buffer) {
  let binary = '';
  let bytes = new Uint8Array( buffer );
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode( bytes[ i ] );
  }
  return window.btoa(binary);
}

export default TemplateFieldList;