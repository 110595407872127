import exportEmployeesNormal from './exportNormal';
import exportEmployeesCanadaLife from './exportCanadaLife';
import exportEmployeesWestfield from "./exportWestfield";

const customExport = (view) => {
  return {
    normal: exportEmployeesNormal,
    leavers: exportEmployeesCanadaLife,
    westfield: exportEmployeesWestfield
  }[view];
}

export default customExport;