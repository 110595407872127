/**
 *
 * Per Input Validation: Built-in Field Validators
 * https://marmelab.com/react-admin/CreateEdit.html#validation
 * @type {*[]}
 */

import {
  required,
  minLength,
  // maxLength,
  // minValue,
  // maxValue,
  // number,
  // regex,
  email,
 //choices
} from 'react-admin';

const validateFirstName = [required(), minLength(1)];
const validateLastName = [required(), minLength(2)];
const validateEmail = [required(), email()];
const validatePassword = [required(), minLength(8, 'validation.user.password')];

export {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePassword
}