import React from "react";
import {makeStyles} from '@mui/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import dictionaries from '../dictionaries';
import dictionaryentries from '../dictionaryentries';
import dictionaryaliases from '../dictionaryaliases';
import templates from '../templates';
import templatefields from '../templatefields';
import users from '../users';
import permissions from '../permissions';
import representatives from '../representatives';
import sites from '../sites';
import employers from '../employers';
import employees from '../employees';
import contracts from '../contracts';
import evaluations from '../evaluations';
import untanglements from '../untanglements';
import {useTranslate} from "react-admin";
import {queryParamString} from "../util/paramUtil";

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  }
}));

/**
 * If level is greater than the number of breadcrumbs by one, add a new breadcrumb.
 * Otherwise, a breadcrumb has been clicked on (level < number of breadcrumbs)
 * and we discard all breadcrumbs after it.
 * Store modified breadcrumbs in redux state.
 * If level is equal to the number of breadcrumbs, do nothing except return breadcrumbs.
 * @param breadcrumb  New breadcrumb, if needed
 * @param resource    RA resource/entity, e.g. dictionaries
 * @param level       Position level in breadcrumbs. First one is 1.
 * @returns {*[]}
 */


const breadcrumbData = () => {
  console.log(`{breadcrumbData} Dummy`);
  return [];
}

/**
 * If no resource provided, return null
 * TODO: How can we generate JSX element names dynamically (from resource)?
 * E.g. const BreadcrumbIcon = ({resource}) => [resource].icon;
 * @param resource
 * @returns {*}
 * @constructor
 */
const BreadcrumbIcon = ({resource, className}) => {
  if (!resource) {
    //console.log(`{BreadcrumbIcon} No resource so no icon!`);
    return null;
  }
  const resourceIcon = {
    dictionaries: <dictionaries.ICON className={className}/>,
    dictionaryentries: <dictionaryentries.ICON className={className}/>,
    dictionaryaliases: <dictionaryaliases.ICON className={className}/>,
    templates: <templates.ICON className={className}/>,
    templatefields: <templatefields.ICON className={className}/>,
    templatefieldsorder: <templatefields.ICON className={className}/>,
    users: <users.ICON className={className}/>,
    permissions: <permissions.ICON className={className}/>,
    representatives: <representatives.ICON className={className}/>,
    sites: <sites.ICON className={className}/>,
    employers: <employers.ICON className={className}/>,
    employees: <employees.ICON className={className}/>,
    contracts: <contracts.ICON className={className}/>,
    evaluations: <evaluations.ICON className={className}/>,
    untanglements: <untanglements.ICON className={className}/>
  };
  return resourceIcon[resource] ? resourceIcon[resource] : <dictionaries.ICON />;
  //return <ResourceIcon resource={resource} className={className} />
  // const IconName = `${resource}.ICON`;
  // const IconName = `employees.ICON`;
  // return <IconName className={className} />;
}

/**
 * Render breadcrumb elements based on array of breadcrumbs.
 * The last breadcrumb (leaf node) is current and therefore has no link.
 * Create visual breadcrumb elements from data dynamically.
 * Properties title or caption can be sued to specify a breadcrumb.
 * caption expects a resource name which is translated here.
 * @returns {*}
 */
const BreadcrumbElements = (breadcrumbs) => {
  //console.log(`{BreadcrumbElements} breadcrumbs:`, breadcrumbs);
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {breadcrumbs.map((breadcrumb, i) => {
          if (i < breadcrumbs.length - 1)
            return (<Link key={i} color="inherit" className={classes.link}
                          to={{ pathname: `/#${breadcrumb.url}` }}
                          href={`/#${breadcrumb.url}`}>
              <BreadcrumbIcon resource={breadcrumb.resource} className={classes.icon}/>
              {breadcrumb.title ? breadcrumb.title : (breadcrumb.label ?
                translate(`resources.${breadcrumb.label}.name_many`) : '')}
            </Link>)
          else
            return (<Typography key={i} color="textPrimary" className={classes.link}>
                <BreadcrumbIcon resource={breadcrumb.resource} className={classes.icon}/>
                {breadcrumb.title ? breadcrumb.title : (breadcrumb.label ?
                  translate(`resources.${breadcrumb.label}.name_many`) : '')}
              </Typography>
            )
        }
      )
      }
    </Breadcrumbs>
  );
}

// Was breadcrumb, resource, level, dispatch, extraBreadcrumbs
const renderBreadcrumbs = (breadcrumbs) => {
  // return BreadcrumbElements(breadcrumbData(breadcrumb, resource, level, dispatch, extraBreadcrumbs));
  return BreadcrumbElements(breadcrumbs);
}

// parent, resource, filter, dispatch, level = 1
const useBreadcrumbs = ({displayMode, resource, id, name, parent, filter, level, dispatch, extraBreadcrumbs}) => {
  const translate = useTranslate();
  let breadcrumb;
  console.log(`{useBreadcrumbs} displayMode: ${displayMode}, resource: ${resource}`);
  if (displayMode === 'list') {
    breadcrumb = {
      url: !parent ? `/${resource}` : `/${resource}?${queryParamString(filter)}`,
      title: translate(`resources.${resource}.name_many`),
      resource
    };
  }
  if (displayMode === 'show') {
    breadcrumb = {
      url: `/${resource}/${id}/show?${queryParamString({name})}`,
      title: name,
      resource
    };
  }
  console.log(`{useBreadcrumbs} breadcrumb:`, breadcrumb);
  // If level is zero, bypass the redux store and explicitly pass in the breadcrumbs here
  // if (level === 0) {
  //   return BreadcrumbElements([breadcrumb]);
  // }
  //return renderBreadcrumbs(breadcrumb, resource, level, dispatch);
  return breadcrumbData(breadcrumb, resource, level, dispatch, extraBreadcrumbs)
}

export {
  breadcrumbData,
  BreadcrumbIcon,
  BreadcrumbElements,
  renderBreadcrumbs,
  useBreadcrumbs
}