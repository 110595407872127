import ContractIcon from '@mui/icons-material/FormatListBulleted'; // Was  FilterNone
import ContractList from './ContractList';
import ContractCreate from './ContractCreate';
import ContractShow from './ContractShow';
import ContractEdit from './ContractEdit';

export default {
  list: ContractList,
  create: ContractCreate,
  show: ContractShow,
  edit: ContractEdit,
  ICON: ContractIcon
};