import React from 'react';
import {
  Loading,
  useListController
} from 'react-admin';
import uniq from 'lodash/uniq';
import log from 'loglevel';
import {queryParamString, defaultPerPage, UnPagination} from "../util/paramUtil";
import {Navigate} from "react-router";  // ra v4

/**
 * Return unique list of dictionary names. E.g. ['person', 'employment'].
 * Used to see if we have more than one definition of a dictionary entry (code).
 * @param records
 * @returns {Array}
 */
const getUsedDictionaryNames = records => uniq(records.map(r => r.dictionary));

/**
 * Given a dictionary entry code and a list of dictionary names (from a template),
 * run a query similar to that in /dictionaryaliases in order to retrieve an entry's ID.
 * Then redirect to the entry detail page.
 * If the entry appears in more than one dictionary, redirect to /dictionaryaliases to allow the
 * user to select which one is of interest.
 *
 * dictNames contains dictionary names that can be used by un_admin.
 * Note that data can contain more than results from last query. Use ids to filter it.
 *
 * filters - React element to display filter form
 * filter - the permanent filter used in the REST request, e.g. filter={{is_published: true}}
 * @param props
 * @returns {null|*}
 * @constructor
 */
export const DictionaryRedirect = (props) => {
  const listContext = useListController({pagination: <UnPagination/>, perPage: defaultPerPage, ...props});
  const {data, isLoading} = listContext;
  log.debug(`{DictionaryRedirect} isLoading: ${isLoading}`);
  if (isLoading) return <Loading />;
  log.debug(`{DictionaryRedirect} data:`, data);
  const usedDictionaryNames = getUsedDictionaryNames(data);
  log.debug(`{DictionaryRedirect} usedDictionaryNames:`, usedDictionaryNames);
  if (data && data.length < 1) {
    const urlMany = `/dictionaryaliases`;
    log.warn(`{DictionaryRedirect} No entries available/visible - dictionary permissions?`, urlMany);
    return (<Navigate to={urlMany}/>);
  }
  // isEntryCode
  const {entryId, name, code, dictId, dictionary} = data[0]; // !!!
  log.debug(`{DictionaryRedirect} entryId: ${entryId}, dictId: ${dictId}, code: ${code}, name: ${name}`);
  if (usedDictionaryNames.length === 1) {
    const url = `/dictionaryentries/${entryId}/show?${queryParamString({dictId, dictName: dictionary})}`;
    //log.debug(`{DictionaryRedirect} url:`, url);
    return (<Navigate to={url}/>);
  }
  else {
    const urlMany = `/dictionaryaliases?${queryParamString({code, dicts: usedDictionaryNames})}`;
    //log.debug(`{DictionaryRedirect} urlMany:`, urlMany);
    return (<Navigate to={urlMany}/>);
  }
}

export default DictionaryRedirect;