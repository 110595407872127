/**
 * Activities are things this application can do.
 * If it ends with '_READ', it is read-only access.
 */

export const Role = {
  SUPERADMIN: 'superadmin',
  UN_ADMIN: 'un_admin',
  UN_SUPPORT: 'un_support',
  ER_ADMIN: 'er_admin',
  RP_ADMIN: 'rp_admin'
};

export const Activity = {
  USER: 'user', // WRITE permissions
  USER_READ: 'user_read',
  UNTANGLER: 'untangler',
  EMPLOYER: 'employer',
  EMPLOYER_READ: 'employer_read',
  ORGANISATION: 'organisation', // Porter
  ORGANISATION_READ: 'organisation_read',
  SITE: 'site',
  SITE_READ: 'site_read',
  APPLICATION: 'application',
  APPLICATION_READ: 'application_read',
  DICTIONARY: 'dictionary',
  DICTIONARY_READ: 'dictionary_read',
  DICTIONARY_PERMISSIONS: 'dictionary_permissions',
  TEMPLATE: 'template',
  TEMPLATE_READ: 'template_read',
  TEMPLATE_PERMISSIONS: 'template_permissions',
  BENEFIT: 'benefit',
  BENEFIT_READ: 'benefit_read',
  ONBOARDING: 'onboarding'
};

// let activityRoles = {};
// activityRoles[Activity.USER] = ['superadmin', 'un_admin'];

/**
 * Maps an activity to roles that can perform it.
 */
const activityRoles = {
  [Activity.USER]: [Role.SUPERADMIN],
  [Activity.USER_READ]: [Role.SUPERADMIN, Role.UN_ADMIN],
  [Activity.UNTANGLER]: [Role.SUPERADMIN, Role.UN_ADMIN, Role.UN_SUPPORT],
  [Activity.DICTIONARY]: [Role.SUPERADMIN, Role.UN_ADMIN],
  [Activity.DICTIONARY_READ]: [Role.SUPERADMIN, Role.UN_ADMIN, Role.UN_SUPPORT],
  [Activity.DICTIONARY_PERMISSIONS]: [Role.SUPERADMIN],
  [Activity.TEMPLATE]: [Role.SUPERADMIN, Role.UN_ADMIN],
  [Activity.TEMPLATE_READ]: [Role.SUPERADMIN, Role.UN_ADMIN, Role.UN_SUPPORT],
  [Activity.TEMPLATE_PERMISSIONS]: [Role.SUPERADMIN],
  [Activity.ORGANISATION]: [Role.SUPERADMIN, Role.ER_ADMIN], // Role.ER_ADMIN when admin server applies security filter
  [Activity.ORGANISATION_READ]: [Role.SUPERADMIN, Role.ER_ADMIN],
  [Activity.EMPLOYER]: [Role.SUPERADMIN],
  [Activity.EMPLOYER_READ]: [Role.SUPERADMIN, Role.ER_ADMIN],
  [Activity.APPLICATION]: [Role.SUPERADMIN],
  [Activity.APPLICATION_READ]: [Role.SUPERADMIN, Role.UN_ADMIN, Role.ER_ADMIN],
  [Activity.SITE]: [Role.SUPERADMIN],
  [Activity.SITE_READ]: [Role.SUPERADMIN],
  [Activity.BENEFIT]: [Role.SUPERADMIN],
  [Activity.BENEFIT_READ]: [Role.SUPERADMIN],
  [Activity.ONBOARDING]: [Role.SUPERADMIN]
};

/**
 * Return whether specified activity is authorized for user's roles.
 * Roles are passed in from JWT via permissions in authProvider.
 * @param roles
 * @param activity
 * @returns {*|boolean}
 */
export const isAuthorized = (roles, activity) => {
  //console.log(`{isAuthorized} activity: ${activity}, roles:`, roles);
  //if (!Array.isArray(roles)) console.log(`{isAuthorized} roles should be an array!`);

  if (!roles) {
    console.log(`{isAuthorized} Bug? Has permissions property been passed correctly?`);
    console.trace();
    return false;
  }

  const isAuth = activityRoles[activity].reduce((accum, supportedRole) =>
      roles && roles.includes(supportedRole) ?
        true :
        accum,
    false);
  //console.log(`{isAuthorized} roles: ${roles}, activity: ${activity}, isAuth: ${isAuth}`);

  return isAuth;
};

export const hasRole = (roles, requiredRole = 'superadmin') => {
  return roles && roles.includes(requiredRole);
}

/**
 * Return list of roles from jurisdictions object.
 * @param jurisdictionsObj
 * @returns {string[]|*[]}
 */
export const getRoles = jurisdictionsObj => jurisdictionsObj ? Object.keys(jurisdictionsObj).map(role => role) : [];

