import qs from "query-string";  // {stringify, parse}
import {Pagination} from "react-admin";
import React from "react";
import isUndefined from 'lodash/isUndefined';
import log from 'loglevel';
//import {useLocation} from "react-router-dom";

/**
 * Deprecated. Old name for queryParamsToFilterString.
 * @param filterObj
 * @returns {string}
 */
const queryParamString = (filterObj) => queryParamsToFilterString(filterObj);

/**
 * Convert an object to a URL query parameter string, with a filter property.
 * E.g. Object {dictId} is converted to a string for a filter parameter, resulting in:
 *    filter=%7B"dictId"%3A"5ea85f66a0eecb3393ab9827"%7D
 * Use for creating a new URL with (serialised/encoded) query parameters.
 * @param filterObj
 */
const queryParamsToFilterString = (filterObj) => qs.stringify({filter: JSON.stringify(filterObj)});

/**
 * Deprecated. No filter - DO NOT USE. Use queryParamsToFilterString instead.
 * Unencoded url for use in client-side, e.g. in redirection after Save in Edit page.
 * E.g. `${resource}/${id}/show?${queryParamsToString({dictId})}`
 * dictionaryentries/63973248619e27d3e6fb9573/show?dictId=63973248619e27d3e6fb9521&dictName=person_cl_test
 * @param filterObj
 * @returns {string}
 */
//const queryParamsToString = (filterObj) => qs.stringify(filterObj);

/**
 * Deprecated. Use queryParamsToObject instead.
 * Converts a URL query parameter string to a filter object
 * If no filter object found under search, return contents directly under search
 * (e.g. if employerId under search)
 */
const queryParamObject = (location) => {
  console.log(`{queryParamObject} location:`, location);
  const search = location.search ? qs.parse(location.search) : '';
  //console.log(`{queryParamObject} search:`, search);
  //const filter = (search && search.filter) ? JSON.parse(search.filter) : {};
  //log.debug(`{queryParamObject} filter:`, filter);
  return search; // Was filter;
}

/**
 * Deprecated (renamed). Use queryParamsToObject instead.
 * @param location
 * @returns {*}
 */
const queryParamFilter = (location) => queryParamsToObject(location);

// New
/**
 * Extracts filter from a location query string (the parameters in the URL)
 * and returns them as properties in an object (without the filter property).
 * @param location
 * @returns {any}
 */
const queryParamsToObject = (location) => {
  console.log(`{queryParamsToObject} location:`, location);
  const search = location.search ? qs.parse(location.search) : '';
  console.log(`{queryParamsToObject} search:`, search);
  const filter = (search && search.filter) ? JSON.parse(search.filter) : {};
  log.debug(`{queryParamsToObject} filter:`, filter);
  return filter;
}

/**
 *
 * @param args            The query URL parameters obtained from queryParamObject()
 * @param stateFunctions  The array returned from the useState hook
 * @returns {*}
 */
const stateArgs = (args, stateFunctions) => {
  const [object, setObject] = stateFunctions;
  if (args.name && !object.name) {
    setObject(args);
    log.debug("{stateArgs} setObject - args:", args);
  }
  if (!args.name && object.name) {
    args = object;
  }
  return args;
}

/**
 * Replace the default pagination.
 * Pass an empty array to rowsPerPageOptions to disable the rows per page selection.
 * Set perPage={100} in List
 * @param props {page, perPage, total, setPage}
 * @returns {*}
 * @constructor
 */
const UnPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]} {...props} />

const defaultPerPage = 100;

/**
 * Check if:
 * (1) state property is present, and
 * (2) filter value is missing or same as state, or
 * (3) both filter and state values are undefined
 * If all properties satisfy this condition, the redux state should not be updated.
 * @param property
 * @param filter
 * @param state dictionaryState
 */
const isPropertySufficient = (property, filter, state) => {
  const isSufficient = ((!isUndefined(state[property]) &&
    (isUndefined(filter[property]) || state[property] === filter[property])) ||
    (isUndefined(filter[property]) && isUndefined(state[property]))
  );
  if (isSufficient) {
    log.debug(`{isPropertySufficient} ${property}: (1) state property is present and (2) filter value is missing or same as state`);
  }
  //log.debug(`{isPropertySufficient} ${property} sufficient? ${isSufficient}, state:`, state);
  return isSufficient;
}

/**
 * Check that all properties in filter and state are equal
 * @param properties  Array of property names. E.g. ['dictId', 'dictName', 'name']
 * @param filter
 * @param state
 */
const isFilterEqualState = (properties, filter, state) => {
  return properties.reduce((isEqual, property) => {
    const e = (state[property] === filter[property]);
    //log.debug(`{isFilterEqualState} ${property} e: ${e}`);
    return isEqual && e;
  }, true);
}

// Pass name to 2nd breadcrumb in show/detail page
const redirectShow = (basePath, id, {name}) => `${basePath}/${id}/show?${queryParamString({name})}`;

const checkObjectIdHexRegExp = new RegExp("^[0-9a-fA-F]{24}$");
const isValidTabName = tabName => !checkObjectIdHexRegExp.test(tabName);

// const getTabName = location => location.pathname.split('/').pop();

const getTabName = location => {
  if (!location || !location.pathname) {
    log.warn(`{getTabName} location undefined`);
    return '';
  }
  const tabName = location.pathname.split('/').pop();
  return isValidTabName(tabName) ? tabName : '';
}


// const TabName = () => {
//   const location = useLocation();
//   const tabName = location.pathname.split('/').pop();
//   console.log(`{getTabName} tabName: ${tabName}, location:`, location);
//   return isValidTabName(tabName) ? tabName : '';
// }

// const getInfo = props => {
//   log.debug(`{getInfo} props:`, props);
//   const {location} = props;
//   let filter = queryParamObject(location);
//   if (!filter) log.debug(`{getInfo} filter undefined`);
//   const info = getArgs(filter);
//   log.debug(`{getInfo} info:`, info);
//   return info;
// }

export {
  queryParamString,
  queryParamsToFilterString,
  //queryParamsToString,
  stateArgs,
  queryParamObject,
  queryParamsToObject,
  queryParamFilter,
  UnPagination,
  defaultPerPage,
  isPropertySufficient,
  isFilterEqualState,
  redirectShow,
  getTabName,
  //TabName
}