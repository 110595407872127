import React from "react";
import {
  TopToolbar,
  CloneButton
} from 'react-admin';
import {UnShowButtonWithName} from "../button/UnShowButtonWithFilter";
import log from "loglevel";
import {getTabName, queryParamString} from "../util/paramUtil";
import UnHelpButton from "../button/UnHelpButton";

//const checkObjectIdHexRegExp = new RegExp("^[0-9a-fA-F]{24}$");
// const isValidTabName = tabName => !checkObjectIdHexRegExp.test(tabName.trim());

const isValidTabName = tabName => {
  if (!tabName) return false;
  console.log(`{isValidTabName} tabName: ${tabName}`);
  return tabName ? tabName.trim().length > 1 : false;
}

/**
 * Displays detail (show) page after saving in an edit page, maintaining the same tab.
 *
 * const redirect = (resource, id, data) => redirectWithTab(location, resource, id, data);
 * const listContext = useEditController({redirect});
 * E.g. RepresentativeEdit.js.
 * @param location
 * @param resource
 * @param id
 * @returns {string}
 */
const redirectWithTab = (location, resource, id) => {
  const locationTabName = getTabName(location);
  return '' + `${resource}/${id}/show` + (isValidTabName(locationTabName) ? `/${locationTabName}` : '');
}

/**
 * Create client url for redirect (after save in edit form and for show detail button).
 * Adds a tab name if not on the first tab. E.g.
 *   /employees/6242b46f6624e7074e10106a/show/employment
 *
 * @param basePath    Standard prop - contains base path of client url, e.g. "/employees"
 * @param location    Standard prop - contains client url
 * @param id          Unique ID of item to be edited
 * @param queryObj    E.g. {employer_id: "6242b46e6624e7074e100e82", employer_name: "Boehm"}
 * @returns {string}  Modified client url
 */
const urlForRedirectWithTab = ({basePath, location, id, queryObj}) => {
  const locationTabName = getTabName(location);
  return isValidTabName(locationTabName) ?
    `${basePath}/${id}/show/${locationTabName}?${queryParamString(queryObj)}` :
    `${basePath}/${id}/show?${queryParamString(queryObj)}`;
}

const UnEditActions = ({tabName, helpPath}) => {
  const adjTabName = isValidTabName(tabName) ? tabName : '';
  log.debug(`{UnEditActions} tabName: ${tabName}, adjTabName: ${adjTabName}`);
  return (
    <TopToolbar>
      <UnShowButtonWithName tabName={adjTabName}/>
      {/*<ShowButton basePath={basePath} record={data} />*/}
      <CloneButton />
      {helpPath ? <UnHelpButton helpPage={helpPath}/> : null}
    </TopToolbar>
  );
}

export {
  UnEditActions,
  isValidTabName,
  redirectWithTab,
  urlForRedirectWithTab
};