import React from 'react';
import {useLocation} from "react-router-dom";
import {
  Create,
  SimpleForm,
  DateInput,
  useResourceContext,
  useTranslate,
  useNotify, useRedirect
} from 'react-admin';
import Typography from '@mui/material/Typography';
import {Breadcrumbs} from "../breadcrumbs";
import {queryParamsToFilterString, queryParamsToObject} from "../util/paramUtil";
import log from "loglevel";
import {CreateTitle} from "../components/InputFields";

// location
// const EvaluationCreateTitle = ({employer_name}) => {
//   //console.log("{EvaluationCreateTitle} employer_name:", employer_name);
//   //return <span>Create Evaluation</span>;
//   //return <span>Create Evaluation {location.state ? ' for ' + location.state.employer_id : ''}</span>;
//   return <span>Create Evaluation {employer_name ? ' for ' + employer_name : ''}</span>;
// };

//const isStringEmpty = str => !str || (str.length === 0);
// const BENEFIT_VERSION_SEPARATOR = '.';
// const RATES_VERSION_SEPARATOR = '$';

/**
 * From contracts, return an array of complete benefit identiers.
 * These include benefit and rate versions, e.g.:
 *   canada_life.20170101$20170101
 *   (benefit_uname.benefit_version$rates_version)
 * @param contracts
 */
// const getBenefitIdentifiers = (contracts) => {
//   contracts.forEach(contract => console.log("{EvaluationCreateTitle.getBenefitIdentifiers} contract:", contract));
//   return  contracts.map(contract => {
//     let bv = !isStringEmpty(contract.benefit_version) ? (BENEFIT_VERSION_SEPARATOR + contract.benefit_version) : '';
//     let rv = !isStringEmpty(contract.rates_version) ? (RATES_VERSION_SEPARATOR + contract.rates_version) : '';
//     // If there is a rates table name (is there ever?), use it. Otherwise use rates version.
//     let rvn = !isStringEmpty(contract.rates_name) ? (RATES_VERSION_SEPARATOR + contract.rates_name) : rv;
//     //console.log("[EvaluationCreateTitle.getBenefitIdentifiers} contract.benefit_uname + b + r:", contract.benefit_uname + b + r);
//     return contract.benefit_uname + bv + rvn;
//   });
// };

  //  E.g. axa_ppp,canada_life,westfield_health_foresight2,untangl_var_fee
const Aside = () => (
  <div style={{ width: 300, margin: '1em' }}>
    {/*<Typography variant="h3">Evaluation details</Typography>*/}
    <Typography variant="body1">
      Calculate benefit premiums for all employees and add summaries to employer.
    </Typography>
  </div>
);

// const redirectToDetail = (resource, id, {employer_id, employer_name}) =>
//   `${resource}/${id}/show?${queryParamsToFilterString({employer_id, employer_name})}`


// const redirectToDetail = (props) => {
//   log.debug(`{EvaluationCreate} props:`, props);
//   const {resource, id, ...rest} = props;
//   const {employer_id, employer_name} = rest;
//   const x = `${resource}/${id}/show?${queryParamsToFilterString({employer_id, employer_name})}`
//   log.debug(`{EvaluationCreate} x:`, x);
//   return x;
// }

//let employer_id;
// <NumberInput source="percent" format={v => v*100} parse={v => v/100} label="Formatted number" />
// employer_id and employer_name are in location.state
const EvaluationCreate = () => {
  const resource = useResourceContext();
  const location = useLocation();
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const args = queryParamsToObject(location);
  const {employer_id, employer_name} = args;
  log.debug(`{EvaluationCreate} employer_id: ${employer_id}, employer_name: ${employer_name}`);

  // const redirect = (props) => { // basePath, id, data
  //   log.debug("{EvaluationCreate.redirect} employer_id:", employer_id);
  //   log.debug("{redirect} props:", props);
  //   //return `/employers/${employer_id}/contracts?`; //filter=${JSON.stringify(filterObj)}`;
  //   //return `/evaluations?filter=${queryParamString({employer_id, employer_name})}`;
  //   return `${resource}/${employer_id}/show?${queryParamsToFilterString({employer_id, employer_name})}`
  // };

  // http://127.0.0.1:3000/#/evaluations?filter=%7B%22employer_id%22%3A%2263e9fdefc5f0542281752109%22%2C%22employer_name%22%3A%22Abbott%20Inc%22%7D

  // http://127.0.0.1:3000/#/evaluations?filter=%7B%22employer_id%22%3A%2263e9fdefc5f0542281752109%22%2C%22employer_name%22%3A%22Abbott%20Inc%22%7D&page=1
  const onSuccess = (data) => {
    notify(`Changes saved`);
    log.debug("{onSuccess} data:", data);
    const url = `/evaluations?${queryParamsToFilterString({employer_id, employer_name})}`
    log.debug("{onSuccess} url:", url);
    redirect(url);
  };

  //const redirect = () => `/evaluations/list?employer_id=${employer_id}`;

  // const transform = data => ({
  //   ...data,
  //   employer_id: employer_id
  // });

  const transform = data => {
    log.debug("{EvaluationCreate.transform} data:", data);
    return {
      ...data,
      employer_id
    }
  }
  const parent = 'employers';
  const breadcrumbs = [
    {url: `/${parent}`, label: parent, resource: parent},
    {url: `/${parent}/${employer_id}/show?${queryParamsToFilterString({name: employer_name})}`,
      title: employer_name, resource: parent},
    {url: `/${resource}?${queryParamsToFilterString({employer_id, employer_name})}`,
      label: resource, resource},
    {url: ``, title: translate('navigation.create'), resource}
  ];
  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Create title={<CreateTitle/>}
        aside={<Aside/>}
        transform={transform}
        mutationOptions={{onSuccess}}
      >
      <SimpleForm >
        <DateInput source="as_of_date"/>
        {/*<DateInput source="start_date"/>*/}
        {/*<TextInput source="employer_id" label='Employer ID' disabled />*/}
        {/*<FunctionField label={"Employer Name"} render={record => employer_name} />*/}
      </SimpleForm>
    </Create>
    </>
  )
};

export default EvaluationCreate;