import React from 'react';
import {
  useShowController,
  ShowView,
  TabbedShowLayout, Tab,
  TextField, FunctionField,
  ReferenceField, ReferenceArrayField,
  SingleFieldList, ChipField,
  Loading,
  useTranslate, TopToolbar, Link, ShowContextProvider,
  //useResourceContext,
  usePermissions
} from 'react-admin';
import Chip from '@mui/material/Chip';
import {makeStyles} from '@mui/styles';
import log from 'loglevel';
import {dateFromId} from '../util/dateUtil';
import {DetailTitle} from "../components/InputFields";
import {getTabName, queryParamString} from "../util/paramUtil";
import {Breadcrumbs} from "../breadcrumbs";
import {UnEditButtonWithName} from '../button/UnEditButtonWithFilter';
import {Activity, isAuthorized} from '../auth/authorization';
import {useLocation} from "react-router-dom";
import UnHelpButton from "../button/UnHelpButton";

const stopPropagation = e => e.stopPropagation();

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const RepresentativeActions = (props) => {
  const {permissions} = usePermissions();
  //const resource = useResourceContext();
  const {record, tabName} = props;
  log.debug(`{RepresentativeActions} props:`, props);
  if (!record) return null;
  log.debug(`{RepresentativeActions} permissions: ${permissions}, auth? ${isAuthorized(permissions, Activity.DICTIONARY_PERMISSIONS)}`);
  log.debug(`{RepresentativeActions} tabName: ${tabName}`);
  return (
    <TopToolbar>
      {isAuthorized(permissions, Activity.DICTIONARY_PERMISSIONS) ?
        <UnEditButtonWithName tabName={tabName} /> : ''
      }
      <UnHelpButton helpPage={'/Configuration/application'}/>
    </TopToolbar>
  );
};

const TemplateChip = ({template, classes}) => {
  // log.debug(`{TemplateChip} template:`, template);
  return (
    <Link className={classes.link}
          key={template.templateId}
          to={`/templates/${template.templateId}/show?${queryParamString({name: template.name})}`}
          onClick={stopPropagation}>
      <Chip label={template.name} clickable={true}/>
    </Link>
  );
}

/**
 * AKA Application
 * An element in the list of templates in a record contains a templateId and name.
 * The template names are used to display without joining via another API call.
 * @returns {JSX.Element|null}
 * @constructor
 */
export const RepresentativeShow = () => {
  const translate = useTranslate();
  const {permissions} = usePermissions();
  const location = useLocation();
  const listContext = useShowController();
  const classes = useStyles();
  if (!permissions) return null;
  log.debug(`{RepresentativeShow} permissions:`, permissions);
  const {record, isLoading} = listContext;
  if (isLoading) return <Loading/>;
  log.debug(`{RepresentativeShow} record`, record);
  log.debug(`{RepresentativeShow} tab name:`, getTabName(location));
  // link={(record, reference) => `/sites/${record.id}?name=${reference.name}`}
  return (
    <ShowContextProvider value={listContext}>
      <Breadcrumbs/>
      <ShowView
          title={<DetailTitle prefix={translate(`resources.representatives.name`, {smart_count: 1})} />}
          actions={<RepresentativeActions tabName={getTabName(location)} {...listContext}/>}
          {...listContext}>
        <TabbedShowLayout>
          <Tab label="detail">
            <TextField source="name"/>
            <TextField source="uname"/>
            <TextField source="tel"/>
            <TextField source="email"/>
            <TextField label="Templates"/>
            <div className={classes.root}>
            {record.templates ? record.templates.map((template, i) => {
                return template.templateId ?  // Old applications may not have a templateId
                <TemplateChip key={i} template={template} classes={classes} /> :
                <Chip label={template.name} clickable={false}/>
              }
            ) :
            ''}
            </div>
            {isAuthorized(permissions, Activity.SITE_READ) ?
            <ReferenceField label="Site" source="site" reference="sites"
                            link={(record, reference) => `/sites/${record.site}/show?${queryParamString({name: reference})}`}>
              <TextField source="name"/>
            </ReferenceField> :
              null}
            <FunctionField label="Created" render={record => `${dateFromId(record._id, 'LONG')}`}/>
          </Tab>
          {isAuthorized(permissions, Activity.SITE) ?
          <Tab label="social media" path={"socialmedia"}>
            <TextField source="linkedin"/>
            <TextField source="facebook"/>
            <TextField source="twitter"/>
            <FunctionField source="benefits"
              render={record => record.benefits ? record.benefits.join() : '' }
            />
          </Tab> : null}
          {isAuthorized(permissions, Activity.APPLICATION) ?
            <Tab label="nested applications" path={"nested"}>
              <ReferenceArrayField label="Nested Applications" source="children"
                                   reference="representatives" >
                <SingleFieldList linkType={'show'}>
                  <ChipField source="name" />
                </SingleFieldList>
              </ReferenceArrayField>
            </Tab> : null}
        </TabbedShowLayout>
      </ShowView>
    </ShowContextProvider>
  );
};

export default RepresentativeShow;