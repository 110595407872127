import React from 'react';  // {useState, useRef}
import {useParams, useLocation} from 'react-router-dom';
import {
  ShowView,
  SimpleShowLayout,
  TextField,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
  TopToolbar,
  useShowController, ReferenceField,
  ShowContextProvider, ListContextProvider,
  Loading, usePermissions,
} from 'react-admin';
import log from 'loglevel';
import {DetailTitle} from '../components/InputFields';
import UnListButton from '../button/UnListButton';
import UnExportButton from '../export/UnExportButton';
import DictionaryEntries from '../dictionaryentries';
import {Breadcrumbs} from '../breadcrumbs';
import {queryParamObject} from '../util/paramUtil';
import {UnEditButtonWithName} from '../button/UnEditButtonWithFilter';
import {ownerApplicationIds2} from '../representatives/OwnerUtil';
import {Activity, isAuthorized} from "../auth/authorization";
import yamlExporter from "../export/yamlExporter";
import UnHelpButton from "../button/UnHelpButton";
//import {OwnerChips, OwnerChipsNoLink} from '../components/Chips';
// https://blog.logrocket.com/guide-to-react-useeffect-hook/

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     justifyContent: 'left',
//     flexWrap: 'wrap',
//     '& > *': {
//       margin: theme.spacing(0.5),
//     },
//   }
// }));

/**
 * This top toolbar contains the Dictionary Entries button which has a filter passed to it,
 * containing the dictionary name (data.name).
 * @param props
 * @returns {*}
 * @constructor
 */
const DictionaryActions = (props) => {
  //log.debug(`{DictionaryActions} props:`, props);
  const {record, resource, permissions} = props;
  if (!record) log.debug(`{DictionaryActions} no data`);
  if (!record) return null;
  return (
    <TopToolbar>
      <UnListButton
        label='resources.dictionaryentries.name_many'
        pathname='/dictionaryentries'
        filter={{dictId: record.id, dictName: record.name}}
        record={record}
        icon={<DictionaryEntries.ICON/>}
      />
      {isAuthorized(permissions, Activity.DICTIONARY) ?
        <UnEditButtonWithName record={record} resource={resource}/> :
        <></>
      }
      {isAuthorized(permissions, Activity.DICTIONARY_PERMISSIONS) ?
        <UnExportButton record={record} resource={resource}
                        label={'Export YAML'} exporter={yamlExporter}/> :
        <></>
      }
      <UnHelpButton helpPage={'/Configuration/dictionaries'}/>
    </TopToolbar>
  );
};

// const useGetOwnerApplications = (owners) => {
//   log.debug(`{useGetOwnerApplications} owners:`, owners);
//   const {loaded: isApplicationsLoaded, data: applications} = useGetList(
//     'representatives',
//     {
//       pagination: {page: 1},
//       filter: {ids: owners},
//       //sort: {field: 'name', order: 'ASC'}
//     }
//   );
//   //log.debug(`{useGetOwnerApplications} isApplicationsLoaded: ${isApplicationsLoaded}, applications:`, applications);
//   return {isApplicationsLoaded, applications};
// }

/**
 * We do not pass resource to UnBreadcrumbs.Show so that icon is not displayed in breadcrumb.
 *
 * Adding [] in 2nd arg of useEffect causes it to run once only and depending on any values
 * from props or state - in this case, the main dictionary record.
 * (https://linguinecode.com/post/getting-started-with-react-useeffect)
 *
 * v4 Notes:
 * Get resource (e.g. dictionaries) from useResourceContext()
 * id for this Show page is in useParams()
 * URL query params string is in location.search from useLocation(). Pass to queryParamObject() to get object.
 * E.g. location: {
 *   pathname: '/dictionaries/63637a5d006a4a677d54df00/show',
 *   search: '?name=MyDict',
 *   hash: '',
 *   state: null,
 *   key: 'default'}
 * (useSearchParams() does not seem to contain anything.)
 *
 * @returns {*}
 * @constructor
 */
export const DictionaryShow = () => {
  const {permissions} = usePermissions();
  //const translate = useTranslate();
  //const classes = useStyles();
  // const resourceDefinitions = useResourceDefinition();
  // log.debug(`{DictionaryShow} resourceDefinitions:`, resourceDefinitions);
  //const resource = useResourceContext();
  const params = useParams();
  console.log(`{DictionaryShow} params:`, params);  // {*: '', id: '63973248619e27d3e6fb946e'}
  const {id} = params;
  const location = useLocation();
  const showContext = useShowController();
  const filter = queryParamObject(location);
  log.debug(`{DictionaryShow} showContext:`, showContext);
  log.debug(`{DictionaryShow} location:`, location);
  log.debug(`{DictionaryShow} params:`, params);
  log.debug(`{DictionaryShow} filter:`, filter);
  const {isLoading, record} = showContext;
  if (!record || isLoading) {return <Loading />;}
  const {name} = record;
  log.info(`{DictionaryShow} id: ${id}, name: ${name}, isLoading: ${isLoading}, record:`, record);
  const applicationIds = ownerApplicationIds2(record ? record.owners : []);
  log.debug(`{DictionaryShow} record owners applicationIds:`, applicationIds);
  // const breadcrumbs = [
  //   {url: `/${resource}`, title: translate(`resources.${resource}.name_many`), resource},
  //   {url: `/${resource}/${id}/show`, title: name, resource}
  // ];
  return (
    <ShowContextProvider value={showContext}>
      {/*<UnBreadcrumbs.All breadcrumbs={breadcrumbs} />*/}
      <Breadcrumbs/>
      <ShowView
        title={<DetailTitle prefix={"Dictionary"}/>}
        actions={
          <ListContextProvider value={showContext}>
            <DictionaryActions permissions={permissions} {...showContext}/>
          </ListContextProvider>}
      >
        <SimpleShowLayout>
          <TextField source="name"/>
          <TextField source="section"/>
          {/*<TextField source="locale"/>*/}
          <ReferenceField label="Locale" source="locale" reference="locales" link="">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="description"/>
          {/*<TextField label="Owner applications"/>*/}

          {isAuthorized(permissions, Activity.DICTIONARY) ?
          <ReferenceArrayField label="Owner applications" reference="representatives" source="ownerIds">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField> :
            <></>
          }
          <TextField label=""/>
        </SimpleShowLayout>
      </ShowView>
      </ShowContextProvider>
  );
}

export default DictionaryShow;