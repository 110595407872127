import log from "loglevel";
import isUndefined from "lodash/isUndefined";

/**
 * return valid entity handles from owners list.
 * E.g.  In ["1234567890xxxxxx", "RP:5f43d779271ad42bd3e8a87a"],
 * remove first element that has no entity code (e.g. RP).
 * @param owners
 * @returns {undefined|*}
 */
const validOwnerEntityHandles = (owners) => {
  if (!owners) return [];
  //const validOwners = owners ? owners.map(owner => owner.split(':')[1]) : [];
  //log.debug(`{validOwnerEntityHandles} validOwners:`, validOwners);
  const filteredOwners = owners.filter(owner => {
    const [code] = owner.split(':');  // id is in 2nd element
    return !isUndefined(owner) && !isUndefined(code) && code.length < 4;
  });
  log.debug(`{validOwnerEntityHandles} filteredOwners:`, filteredOwners);
  return filteredOwners;
}

/**
 * Extract list of application (rep) IDs from [RP:<application_id>]
 * These are found in the owners property.
 * @param props
 * @returns {undefined|*}
 */
const ownerApplicationIds = (props) => {
  if (!props.record) return undefined;
  const {record} = props;
  //log.debug(`{ownerApplicationIds} record:`, record);
  // const filteredOwners = owners.filter(owner => !_.isUndefined(owner));
  const filteredOwners = validOwnerEntityHandles(record.owners);
  log.debug(`{ownerApplicationIds} filteredOwners:`, filteredOwners);
  return filteredOwners.map(fo => fo.split(':')[1]);
}

const ownerApplicationIds2 = (owners) => {
  const filteredOwners = validOwnerEntityHandles(owners);
  log.debug(`{ownerApplicationIds2} filteredOwners:`, filteredOwners);
  return filteredOwners.map(fo => fo.split(':')[1]);
}

export {
  validOwnerEntityHandles,
  ownerApplicationIds,
  ownerApplicationIds2
}