import React  from 'react';
import {
  SimpleForm,
  TextInput,
  SaveButton, CloneButton,
  DeleteWithConfirmButton,
  Toolbar,
  TopToolbar,
  useGetList,
  SelectArrayInput, SelectInput, usePermissions,
  useEditController,
  EditContextProvider,
  EditView,
  useRecordContext
} from 'react-admin';
import log from 'loglevel';
import {queryParamObject} from "../util/paramUtil";
import {UnShowButtonWithName} from "../button/UnShowButtonWithFilter";
import {DetailTitle} from '../components/InputFields';
import {Activity, isAuthorized} from "../auth/authorization";
import {useLocation, useParams} from "react-router-dom";
import {Breadcrumbs} from '../breadcrumbs';
import UnHelpButton from "../button/UnHelpButton";

/**
 *
 * const MyEdit = (props) => {
 *   const controllerProps = useEditController(props);
 *   return (
 *     <EditContextProvider value={controllerProps}>
 *       <MyEditView>
 *     </EditContextProvider>
 *    );
 * };

 */

const DictionaryEditActions = () => {
  const record = useRecordContext();
  console.log("{DictionaryEditActions} record:", record);
  if (!record) return null;
  return (
    <TopToolbar>
      <UnShowButtonWithName />
      <CloneButton />
      <UnHelpButton helpPage={'/Configuration/dictionaries'}/>
    </TopToolbar>
  );
};

/**
 * Display toolbar at bottom of form
 * Note styling on SaveButton to add right margin
 * TODO: Check MUI Confirm: https://marmelab.com/react-admin/Confirm.html#sx-css-api
 * @returns {*}
 * @constructor
 */
const DictionaryEditToolbar = () => {
  const record = useRecordContext();
  console.log("{DictionaryEditToolbar} record:", record);
  const {name} = record;
  return (
    <Toolbar>
      <SaveButton sx={{marginRight: '2em'}} />
      <DeleteWithConfirmButton confirmTitle={`Delete dictionary ${name}?`}
                               confirmContent={'Confirm to delete this dictionary'} />
      {/*<UnDeleteButton undoable={false}/>*/}
    </Toolbar>
  )
};

// Pass name to 2nd breadcrumb in show/detail page
//const redirect = (basePath, id, {name}) => `${basePath}/${id}/show?${queryParamString({name})}`;

export const DictionaryEdit = () => {
  const {permissions} = usePermissions();
  if (!isAuthorized(permissions, Activity.DICTIONARY)) {
    return (<div/>);
  }
  if (!isAuthorized(permissions, Activity.DICTIONARY_PERMISSIONS)) {  // un_admin
    return DictionaryEditAdmin();
  }
  else {
    return DictionaryEditSuperAdmin();
  }
};

const DictionaryEditSuperAdmin = () => {
  const location = useLocation();
  const params = useParams();
  console.log(`{DictionaryEdit} params:`, params);  // {*: '', id: '63973248619e27d3e6fb94ea'}
  const {id} = useParams();
  const {name} = queryParamObject(location);
  log.debug(`{DictionaryEdit} id: ${id}, name: ${name}`);
  const editContext = useEditController({redirect: "show"});

  const {isLoading: isLocalesLoading, data: locales} = useGetList(
    'locales',
    {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}
  });
  const {isLoading: isRepresentativesLoading, data: representatives} = useGetList(
    'representatives',
    {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}
  });
  if (isRepresentativesLoading || isLocalesLoading) return null;
  const ownerEntityHandles = representatives.map(r => ({id: `RP:${r.id}`, name: r.name}));
  const listLocales = locales.map(r => ({id: r.id, name: r.name}));

  if (editContext.isLoading) return null;
  return (
    <EditContextProvider value={editContext}>
      <Breadcrumbs/>
      <EditView
        title={<DetailTitle/>}
        actions={<DictionaryEditActions/>}
      >
        <SimpleForm toolbar={<DictionaryEditToolbar/>}>
          <TextInput source="name" fullWidth/>
          <TextInput source="section" fullWidth/>
          <SelectInput label="Locale" source="locale" choices={listLocales} fullWidth />
          <SelectArrayInput label="Owners" source="owners" choices={ownerEntityHandles} fullWidth />
          <TextInput source={'description'} multiline={true} rows={7} fullWidth />
        </SimpleForm>
      </EditView>
      {/*</div>*/}
    </EditContextProvider>
  );
}

/**
 * TODO: Refactor this to merge with superadmin version above
 * @returns {JSX.Element|null}
 * @constructor
 */
const DictionaryEditAdmin = () => {
  const location = useLocation();
  //const {id} = useParams();
  const {name} = queryParamObject(location);
  const editContext = useEditController({redirect: "show"});

  const {isLoading: isLocalesLoading, data: locales} = useGetList(
    'locales',
    {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}
    });
  const {isLoading: isRepresentativesLoading, data: representatives} = useGetList(
    'representatives',
    {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}
    });
  if (isRepresentativesLoading || isLocalesLoading) return null;
  const ownerEntityHandles = representatives.map(r => ({id: `RP:${r.id}`, name: r.name}));
  const listLocales = locales.map(r => ({id: r.id, name: r.name}));

  if (editContext.isLoading) return null;
  return (
    <EditContextProvider value={editContext}>
      <Breadcrumbs/>
      <EditView
        title={<DetailTitle />}
        actions={<DictionaryEditActions/>}
      >
        <SimpleForm
          toolbar={<DictionaryEditToolbar name={name}/>}>
          <TextInput source="name" fullWidth/>
          <TextInput source="section" fullWidth/>
          <SelectInput label="Locale" source="locale" choices={listLocales} fullWidth allowEmpty/>
          <SelectArrayInput label="Owners" source="owners" choices={ownerEntityHandles} fullWidth/>
          <TextInput source={'description'} multiline={true} rows={7} fullWidth/>
        </SimpleForm>
      </EditView>
    </EditContextProvider>
  );
};

export default DictionaryEdit;