import UntanglementIcon from '@mui/icons-material/Gesture';  // Was Input
import UntanglementList from './UntanglementList';
import UntanglementShow from './UntanglementShow';
import UntanglementCreate from './UntanglementCreate';

export default {
  list: UntanglementList,
  show: UntanglementShow,
  create: UntanglementCreate,
  // edit: UntanglementEdit,
  ICON: UntanglementIcon
};