import {BreadcrumbElements} from './UnBreadcrumbsUtil';

/**
 * Render a breadcrumb trail based on array of breadcrumbs.
 * The last breadcrumb (leaf node) is current and therefore has no link.
 * Create visual breadcrumb elements from data dynamically.
 * Properties title or label can be sued to specify a breadcrumb.
 * label expects a resource name which is translated here.
 * E.g.  {url: `/${parent}`, title: translate(`resources.${parent}.name_many`), resource: parent},
 *
 *  Generates a breadcrumb trail for a list page.
 *  If parent is undefined, it generates for a top level (level = 1).
 *  If parent is defined, it generates for level 3, e.g for dictionaryentries or templatefields.
 *  The parent parameters are passed in via filter, e.g. filter={{dictId, dictName}}.
 *  The breadcrumb (nested or not) specified here for this page is added to the redux state
 *  in renderBreadcrumbs.
 *  The accumulated trail of breadcrumbs would look like this:
 *   {url: `/${parent}`, title: translate(`resources.${parent}.name_many`), resource: parent},
 *   {url: `/${parent}/${id}/show?${queryParamString({name})}`, title: name},
 *   {url: `/${current}?${queryParamString({[parentIdName]: id})}`,
 *   TODO: For handling parent ID and name, just pass filter through.
 * @param props
 * @returns {*}
 * @constructor
 */
const UnBreadcrumbsAll = (props) => {
  //console.log(`{UnBreadcrumbs} props:`, props);
  // parent, resource, filter, dispatch, level = 1
  const {breadcrumbs} = props;
  return BreadcrumbElements(breadcrumbs);
}

export default UnBreadcrumbsAll;


// import React from 'react';
// import {makeStyles} from '@mui/styles';
// import Typography from '@mui/material/Typography';
// import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Link from '@mui/material/Link';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import Template from '../templates';
// import TemplateField from '../templatefields';
// import {queryParamString} from '../util/paramUtil';
// import {UnBreadcrumbs} from '../breadcrumbs';
//
// const breadcrumbsList = (id, name, parent, current, translate) => {
//   return [
//     {url: `/${current}`, title: translate(`resources.${current}.name_many`)}
//   ];
// }
//
// const breadcrumbsDetail = (id, name, parent, current, translate) => {
//   const filterObj = {name};
//   const filterStr = queryParamString(filterObj);
//   console.log(`{breadcrumbsDetail} filterStr: ${filterStr}`);
//   return [
//     {url: `/${current}`, title: translate(`resources.${current}.name_many`)},
//     {url: `/${parent}/${id}/show?${filterStr}`, title: name}
//   ];
// }
//
// /**
//  * /dictionaryentries/<id>?dictId=<dictId>
//  * @param id
//  * @param name
//  * @param parent
//  * @param current
//  * @param translate
//  * @returns {[{title: *, url: string}, {title: *, url: string}, {title: *, url: string}]}
//  */
// const breadcrumbsNestedList = (id, name, parent, current, translate) => {
//   const filterObj = {name};
//   const filterStr = queryParamString(filterObj);
//   console.log(`{breadcrumbsNestedList} filterStr: ${filterStr}`);
//   return [
//     {url: `/${parent}`, title: translate(`resources.${parent}.name_many`)},
//     {url: `/${parent}/${id}/show?${filterStr}`, title: name},
//     {url: `/${current}`, title: translate(`resources.${current}.name_many`)}
//   ];
// }
//
// const useStyles = makeStyles((theme) => ({
//   link: {
//     display: 'flex',
//   },
//   icon: {
//     marginRight: theme.spacing(0.5),
//     width: 20,
//     height: 20,
//   },
// }));
//
// const UnBreadcrumbsList = (props) => {
//   const {classes, breadcrumbs} = props;
//   console.log(`{UnBreadcrumbsList} breadcrumbs:`, breadcrumbs);
//   return (
//     <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
//       <Link color="inherit" className={classes.link}
//             to={{pathname: `/#${breadcrumbs[0].url}`}}
//             href={`/#${breadcrumbs[0].url}`}>
//         <Template.icon className={classes.icon}/>
//         {breadcrumbs[0].title}
//       </Link>
//     </Breadcrumbs>
//   );
// }
//
// const UnBreadcrumbsDetail = (props) => {
//   const {type, classes, breadcrumbs} = props;
//   console.log(`{UnBreadcrumbsDetail} breadcrumbs:`, breadcrumbs);
//   return (
//     <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
//       <Link color="inherit" className={classes.link}
//             to={{pathname: `/#${breadcrumbs[0].url}`}}
//             href={`/#${breadcrumbs[0].url}`}>
//         <Template.icon className={classes.icon}/>
//         {breadcrumbs[0].title}
//       </Link>
//       <Link color="inherit" className={classes.link}
//             to={{pathname: `/#${breadcrumbs[1].url}`}}
//             href={`/#${breadcrumbs[1].url}`}>
//         {breadcrumbs[1].title}
//       </Link>
//       {/*<Typography color="textPrimary" className={classes.link}>*/}
//       {/*  <TemplateField.icon className={classes.icon}/>*/}
//       {/*  {breadcrumbs[2].title}*/}
//       {/*</Typography>*/}
//     </Breadcrumbs>
//   );
// }
//
// const UnBreadcrumbsNestedList = (props) => {
//   const {type, classes, breadcrumbs} = props;
//   console.log(`{UnBreadcrumbsNestedList} breadcrumbs:`, breadcrumbs);
//   return (
//     <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
//       <Link color="inherit" className={classes.link}
//             to={{pathname: `/#${breadcrumbs[0].url}`}}
//             href={`/#${breadcrumbs[0].url}`}>
//         <Template.icon className={classes.icon}/>
//         {breadcrumbs[0].title}
//       </Link>
//       <Link color="inherit" className={classes.link}
//             to={{pathname: `/#${breadcrumbs[1].url}`}}
//             href={`/#${breadcrumbs[1].url}`}>
//         {breadcrumbs[1].title}
//       </Link>
//       <Typography color="textPrimary" className={classes.link}>
//         <TemplateField.icon className={classes.icon}/>
//         {breadcrumbs[2].title}
//       </Typography>
//     </Breadcrumbs>
//   );
// }
//
// /**
//  * <UnBreadcrumbs type='show' id={id} name={name} parent='dictionaries'
//  resource={resource} translate={translate}/>
//  * @param props
//  * @returns {*}
//  * @constructor
//  */
// export default function DisplayBreadcrumbs(props) {
//   console.log(`{UnBreadcrumbs} props:`, props);
//   const classes = useStyles();
//   const {type, id, name, parent, resource, translate} = props;
//   console.log(`{UnBreadcrumbs} type:`, type);
//   if (type === 'list') {
//     // const breadcrumbs = breadcrumbsList(id, name ? name : 'unknown', parent, resource, translate);
//     //const breadcrumbs = list.breadcrumbsData(id, name ? name : 'unknown', parent, resource, translate);
//     console.log(`{UnBreadcrumbs} breadcrumbs (list):`, breadcrumbs);
//     return (<UnBreadcrumbs.List classes={classes}
//                                id={id}
//                                name={name ? name : 'unknown'}
//                                resource={resource}
//                                translate={translate} />)
//   }
//   if (type === 'show') {
//     const breadcrumbs = breadcrumbsDetail(id, name ? name : 'unknown', parent, resource, translate);
//     console.log(`{UnBreadcrumbs} breadcrumbs (show):`, breadcrumbs);
//     return (<UnBreadcrumbsDetail classes ={classes} breadcrumbs={breadcrumbs}/>)
//   }
//   if (type === 'nestedlist') {
//     const breadcrumbs = breadcrumbsNestedList(id, name ? name : 'unknown', parent, resource, translate);
//     console.log(`{UnBreadcrumbs} breadcrumbs (nestedlist):`, breadcrumbs);
//     return (
//       <UnBreadcrumbsNestedList classes={classes} breadcrumbs={breadcrumbs}/>
//     );
//   }
// }