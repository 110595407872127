import {defaultTheme} from "react-admin";
//import {deepmerge} from "@mui/utils";
import {untanglColors} from './themeColors';
import {grey} from "@mui/material/colors";

export const plainTheme = {
  ...defaultTheme,
  name: "plain",
  palette: {
    primary: {
      //main: purple[500]
      main: untanglColors.tealblue
    },
    secondary: {
      //main: green[500]
      main: grey['A700']
    },
    headerText: {
      //main: indigo["50"]
      main: "white"
    },
    headerBackground: {
      main: untanglColors.tealblue
    }
  },
  // typography: {
  //   h6: {
  //     fontWeight: 400,
  //   },
  // },
  sidebar: {
    width: 200,
  },
  components: {
    ...defaultTheme.components,
    // MuiTypography: {
    //   styleOverrides: {
    //     root: {
    //       color: untanglColors.orange
    //     }
    //   }
    // },
    RaDatagrid: {
      styleOverrides: {
        root: {
          //backgroundColor: "Lavender",
          "& .RaDatagrid-headerCell": {
            // color: "white",
            // backgroundColor: untanglColors.tealblue,
            fontWeight: "bold"
          },
        }
      }
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: '3px solid #fff',
          '&.RaMenuItemLink-active': {
            borderLeft: '2px solid #4f3cc9',
            //color: untanglColors.tealblue,
            fontWeight: "bold"
          },
        },
      },
    },
    RaLayout: {
      styleOverrides: {
        root: {
          '&.RaLayout-content': {
            paddingTop: "200px"
          }
        }
      }
    }
  }
}