import {downloadCSV} from "react-admin";
import {unparse as convertToCSV} from "papaparse/papaparse.min";
import {dateFromId} from "../util/dateUtil";
import log from 'loglevel';

const exportUntanglements = (untanglements) => {
  // const fileName = 'DictionaryAliases';
  // const entriesForExport = entries.map(e => {
  //   let eForExport = {};
  //   eForExport['Alias'] = e.alias; // add a field
  //   eForExport['Code'] = e.code;
  //   eForExport['Type'] = e.type;
  //   eForExport['Weight'] = e.weight;
  //   eForExport['Dictionary'] = e.dict;
  //   eForExport['Constraints'] = constraintsStr(e);
  //   return eForExport;
  // });
  // const csv = convertToCSV({
  //   data: entriesForExport,
  //   // Order fields in the export
  //   fields: ['Alias', 'Code', 'Type', 'Weight', 'Dictionary', 'Constraints']
  // });
  // downloadCSV(csv, fileName);

  //log.debug("{exporter} untanglements:", untanglements);
  const fields = ['Name', 'User Name', 'Class',  'Elapsed Time', 'Size', 'Range', 'Template', 'Created'];
  const fieldSource = (un, i) => {
    const sources = [
      un.name,
      un.user_name,
      un.class,
      un.elapsed_time ? un.elapsed_time.toFixed(2) : '',
      un.data_size ? un.data_size.toFixed(2) : '',
      un.in_range,
      un.template,
      dateFromId(un._id, 'SHORT')
    ];
    return sources[i];
  };
  // let fileName = translate(`resources.untanglements.name`);
  let fileName = 'Untangler';
  fileName = fileName + '_' + new Date().toLocaleDateString();
  log.debug("{exporter} fileName:", fileName);
  const unsForExport = untanglements.map(un => {
    let unForExport = {};
    //log.debug("{exporter} un:", un);
    fields.forEach((field, i) => {
      unForExport[field] = fieldSource(un, i);
    });
    return unForExport;
  });
  const csv = convertToCSV({
    data: unsForExport,
    // Order fields in the export
    //fields: ['Name', 'User Name', 'Class', 'Template', 'Elapsed Time', 'Size', 'Range', 'Created']
    fields
  });
  downloadCSV(csv, fileName); // download as 'uns.csv` file
};

export default exportUntanglements;