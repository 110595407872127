import React from 'react';
import qs from 'query-string';
import {Link} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import EmployeesIcon from '@mui/icons-material/PeopleOutline';
import {Button} from 'react-admin';
import log from 'loglevel';

// theme
const useStyles = makeStyles(() => ({
  button: {
    marginTop: '0em'
  }
}));

/**
 * Calls a list page with a filter object argument.
 * E.g <UnListButton label='My list' pathname='/mylist' filter={{dicts: ['employer']}}
            icon={<FormatListBulletedIcon />}
       />
 * @param props
 * @returns {*}
 * @constructor
 */

const UnListButton = (props) => {
  const {filter, label, pathname, style, icon = <EmployeesIcon />} = props;
  const classes = useStyles();
  //log.debug(`{UnListButton} label: ${label}, pathname: ${pathname}, filter:`, filter);
  const filterStr = JSON.stringify(filter);
  const queryParam = qs.stringify({
    page: 1,
    filter: filter ? filterStr : ''
    // sort: 'id',
    // order: 'DESC',
  });
  log.debug(`{UnListButton} List path will be: ${pathname}?${queryParam}`);
  return <Button
    className={classes.button}
    component={Link}
    to={{
      pathname,
      search: queryParam,
    }}
    style={style}
    //style={{minWidth: '40px', padding: '2px 2px 2px 1px'}}
    label={label}
    title={label}
  >
    {icon}
  </Button>
};

export default UnListButton