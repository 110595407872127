import * as React from "react";
import PropTypes from 'prop-types';
import {Link} from "react-admin";
import {queryParamString} from "../util/paramUtil";
import {isEntryCode} from '../dictionaryentries/DictionaryEntryUtil';
import log from 'loglevel';
// For link to popup dialog, see:
// * https://v4.mui.com/components/dialogs/#dialog

// Useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();

const keyWords = [
  'INPUT(', 'ENTRY(', 'ISMISSING(', 'ISFOUND(',
]

/**
 * Return indexes of tokens that contain a key word, e.g. 'ENTRY('
 * @param tokens
 */
const getKeyWordsIndexes = (tokens) => tokens.reduce((accum, token, i) => {
  //console.log(`{getKeyWordsIndexes} i: ${i}, includes? ${keyWords.includes(token)}, token:`, token);
  keyWords.map(keyWord => {
    if (token.includes(keyWord)) {
      accum.push(i);
    }
  })
  return accum;
}, []);

/**
 * ExpressionEntryLink
 * Render an expression with links in any entry codes to the entry detail page.
 * @param className
 * @param source
 * @param dictNames
 * @param record
 * @returns {JSX.Element}
 * @constructor
 */

const ExpressionEntryLink = ({className, source, dictNames, record = {}}) => {
  //log.debug(`{ExpressionEntryLink} source: ${source}, dictNames: ${dictNames}, record:`, record);
  //const id = record._id;
  if (!record || !record.source) log.warn('{ExpressionEntryLink} No record or source!')
  if (record.source !== 'expr') {
    return (<span>{record[source]}</span>);
  }
  const expression = record[source];
  //console.log(`{ExpressionEntryLink} ****** expression:`, expression);
  const tokens = expression ?
    expression.split('"').map((exp, i) => i === 0 ? exp : '"' + exp) :
    [];  // Tokenize on quotes
  //console.log(`{ExpressionEntryLink} tokens:`, tokens);
  const indexKeyWords = getKeyWordsIndexes(tokens); // Array of token indexes where key words (e.g. ENTRY) are
  //console.log(`{ExpressionEntryLink} indexKeyWords:`, indexKeyWords);
  if (indexKeyWords.length < 1) {
    return (<span className={className}>{record[source]}</span>);
  }
  const codeKeyWords = indexKeyWords.map(i => i + 1); // Entry code/name is token after key word
  // Move quote from start of code to end of key word
  const tokensAdj = tokens
    .map((token, i) => (indexKeyWords.includes(i)) ? token + '"' : token)
    .map((token, i) => (codeKeyWords.includes(i)) ? token.substr(1) : token);

  return (
    <div>
      {tokensAdj.map((token, i) => {
        if (codeKeyWords.includes(i)) { // If entry code or name
          const filter = {
            dicts: dictNames  // e.g. ['person', 'employment_cl']
          };
          if (isEntryCode(token))
            filter.code = `^${token}`;
          else // Entry name (not code)
            filter.name = `${token}`;
          const url = `/dictionaryredirect?${queryParamString(filter)}`;
          return (
            <Link key={i} to={url} onClick={stopPropagation}>
              <span className={className}>{token}</span>
            </Link>
          )
        }
        else return (<span key={i} className={className}>{token}</span>)
      })
      }
    </div>
  );

}

ExpressionEntryLink.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ExpressionEntryLink;