// handleSubmit, handleSubmitWithRedirect
import {SaveButton,
  //useCreate, useNotify, useRedirect, useSaveContext
} from "react-admin";

import React from "react";
//import log from "loglevel";

/**
 * onClick doesn’t replace the default submission handler, since a default SaveButton is a submit button,
 * but is instead called before it.
 *
 * useSaveContext grabs the save callback prepared either by useEditController or useCreateController.
 * It is used by <SaveButton> to submit the form. (https://marmelab.com/react-admin/useSaveContext.html)
 *
 * useSaveContext is necessary because the <SaveButton> doesn’t know if it is used in a <Create> or an <Edit> page.
 *
 * https://marmelab.com/react-admin/SaveButton.html
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

// const permissionDataFromForm = (data) => {
//   log.debug("{permissionDataFromForm} data:", data);
//   if (data.us_event) {
//     data.event = data.us_event;
//     delete data.us_event;
//     data.entity_id = data.user_id;
//   }
//   else if (data.rp_event && data.rp_entity_id) {
//     data.event = data.rp_event;
//     delete data.rp_event;
//     data.entity_id = data.rp_entity_id;
//     delete data.rp_entity_id;
//   }
//   else if (data.er_event && data.er_entity_id) {
//       data.event = data.er_event;
//       delete data.er_event;
//       data.entity_id = data.er_entity_id;
//       delete data.er_entity_id;
//       data.key = data.er_event; // Each child in an array or iterator should have a unique "key" prop.
//     }
//     else if (data.sa_event) {
//         data.event = 'setsuperadmin';
//         delete data.sa_event;
//         data.entity_id = data.user_id;
//       }
//       else {
//         log.debug("{savePermission} No permission data!:", data);
//       }
//   return data;
// };

const SavePermissionButton = (props) => {
  // const notify = useNotify();
  //
  // const {
  //   save, // the create or update callback, which receives the form data and calls the dataProvider
  //   saving, //  boolean that becomes true when the dataProvider is called
  //   mutationMode, // the current mutation mode, either 'undoable', 'optimistic', or 'pessimistic'
  // } = useSaveContext();

  const {label} = props;
  console.log(`{SavePermissionButton} label: ${label}`);
  // const [create] = useCreate('permissions');
  // const redirect = useRedirect();

  return (
    // <SaveButton
    //   label="post.action.save_and_show"
    //   type="button"
    //   variant="text"
    //   mutationOptions={{
    //     onSuccess: data => {
    //       notify('ra.notification.created', {
    //         type: 'info',
    //         messageArgs: { smart_count: 1 },
    //       });
    //       redirect('show', 'posts', data.id);
    //     },
    //   }}
    // />

    <SaveButton
      label="Save"
      type="button"
      variant="text"
      //onClick={() => alert(`Saving ${label}`)}
      // mutationOptions={{
      //   onSuccess: data => {
      //     //notify('Permission created');
      //     console.log('{SavePermissionButton} Permission created');
      //     //redirect('show', 'users');
      //   }
      // }}
    />
  );
}


// const SavePermissionButtonOld = (props) => {
//   const [create] = useCreate('permissions');
//   const redirect = useRedirect();
//   const notify = useNotify();
//   const {basePath} = props;
//   //const formState = useFormState();
//
//   const handleSave = useCallback((values, redirect) => {
//     //form.change('average_note', 10);
//     const data = permissionDataFromForm(values);
//     log.debug("{handleSave} data before create:", data);
//     log.debug("{handleSave} redirect:", redirect);
//     //return crudCreate('permissions', { ...values }, basePath, redirectTo);
//     //log.debug("{handleSave} create fn:", create);
//
//     create(
//       {payload: {data}},
//       {
//         onSuccess: ({data: newRecord}) => {
//           log.debug("{handleSave} onSuccess - newRecord:", newRecord);
//           notify('notification.permission.created', 'info', {
//             smart_count: 1,
//           });
//           redirectTo(redirect, basePath, newRecord.id, newRecord);
//         }
//       }
//     );
//
//   }, [
//     create,
//     notify,
//     redirectTo,
//     basePath
//   ]);
//
//   return (
//     <SaveButton
//       {...props}
//       onSave={handleSave}
//     />
//   );
// };

export default SavePermissionButton;