import React from 'react';
import {
  List,
  TextField, ChipField, FunctionField, usePermissions, TopToolbar, CreateButton,
  Datagrid
} from 'react-admin';
// import {UnDatagrid as Datagrid} from "../styled/Datagrid";
import {Breadcrumbs} from "../breadcrumbs";
import {defaultPerPage, UnPagination} from "../util/paramUtil";
import UnIconButton from "../button/UnIconButton";
import {ListTitle} from "../components/InputFields";
import {Activity, isAuthorized} from "../auth/authorization";

const SiteActions = () => {
  const {permissions} = usePermissions();
  return (
    <TopToolbar>
      {isAuthorized(permissions, Activity.SITE) ?
        <CreateButton />
        : null}
    </TopToolbar>
  );
}

const SiteList = () => {
  return (
    <>
      <Breadcrumbs/>
      <List sort={{field: 'name', order: 'ASC'}}
            perPage={defaultPerPage}
            pagination={<UnPagination/>}
            title={<ListTitle/>}
            actions={<SiteActions/>}
      >
        <Datagrid bulkActionButtons={false}>
          {/*<TextField source="uname" label={"Unique Name"}/>*/}
          <TextField source="name"/>
          <FunctionField label={"Type"} render={
            record => record && record.type ?
              <ChipField source="type"/>:
              ''
          }/>
          <TextField source="tagline"/>
          {/*<UnShowButtonWithName record={record} resource={resource}/>*/}
          <UnIconButton label={'Detail'} />
        </Datagrid>
      </List>
    </>
  )
};

export default SiteList;