import DictionaryIcon from '@mui/icons-material/LibraryBooks';
import DictionaryList from './DictionaryList';
import DictionaryShow from './DictionaryShow';
import DictionaryEdit from './DictionaryEdit';
import DictionaryCreate from './DictionaryCreate';

export default {
  list: DictionaryList,
  show: DictionaryShow,
  edit: DictionaryEdit,
  create: DictionaryCreate,
  ICON: DictionaryIcon
};