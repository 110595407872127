// import TemplateIcon from '@mui/icons-material/Web';
// import TemplateList from './TemplateList';
// import TemplateShow from './TemplateShow';
// import TemplateCreate from './TemplateCreate';
// import TemplateEdit from './TemplateEdit';

export default {
  // list: TemplateList,
  // show: TemplateShow,
  // create: TemplateCreate,
  // edit: TemplateEdit,
  // icon: TemplateIcon
};