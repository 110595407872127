import {
  useRecordContext,
  useResourceContext,
  useTranslate
} from 'react-admin';
import {BreadcrumbElements} from './UnBreadcrumbsUtil';
import log from "loglevel";

/**
 * Display breadcrumbs for a detail (show) page. E.g. Dictionaries > MyDictionary.
 * For a nested resource (i.e. at level 4), use UnBreadcrumbsAll instead.
 *
 * The URL won't work for a nested resource (e.g. dictionaryentries) but
 * it's not needed since it is a leaf node that is never an active link.
 * To make it work the filter would need to contain dictId (for dictionaryentries).
 * {url: "/dictionaries", title: "Dictionaries", resource: "dictionaries"}
 * {url: "/dictionaries/5ea85f66a0eecb3393ab97ca/show?filter=%7B%22name%22%3A%22person%22%7D", title: "person", resource: "dictionaries"}
 * {url: "/dictionaryentries?filter=%7B%22dictId%22%3A%225ea85f66a0eecb3393ab97ca%22%7D", title: "Entries", resource: "dictionaryentries"}
 *
 * If full is true, the whole trail of breadcrumbs are fabricated rather than reading earlier ones
 * out of the redux state. This is to support dictionaryaliases, which jumps from level 1 to level 4 on Show.
 * The breadcrumb is the leaf node and extraBreadcrumbs are those earlier in the trail.
 * Prop parent is only needed in full mode and contains the parent id, name and resource.
 * @param props
 * @returns {*}
 * @constructor
 */
const UnBreadcrumbsShow = (props) => {
  const translate = useTranslate();
  const resource = useResourceContext();
  const record = useRecordContext();
  const {propName, breadcrumbs} = props;
  if (!record) log.warn(`{UnBreadcrumbsShow} Undefined record - still isLoading or not in ContextProvider?`)
  if (breadcrumbs) {
    return BreadcrumbElements(breadcrumbs);
  }
  const fieldName = propName ? propName : 'name';
  log.debug(`{UnBreadcrumbsShow} fieldName: ${fieldName}`);
  const fieldValue = record[fieldName] ? record[fieldName] : '';

  const showBreadcrumbs = [
    {url: `/${resource}`, title: translate(`resources.${resource}.name_many`), resource},
    {url: ``, title: fieldValue, resource}
  ];
  return BreadcrumbElements(showBreadcrumbs);
}

export default UnBreadcrumbsShow;