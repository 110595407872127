// Custom display fields (for List and Show pages)

import React from "react";
import {
  Labeled,
  //FunctionField
} from 'react-admin';
import Typography from '@mui/material/Typography';
//import pure from 'recompose/pure';
import { dateFromId } from "../util/dateUtil";

/**
 * For List
 * @param record
 * @returns {*}
 * @constructor
 */
const CreateDateFieldForList = ({record = {}}) => <span>{dateFromId(record._id, 'SHORT')}</span>;
CreateDateFieldForList.defaultProps = { label: 'Created' };

/**
 * For Show
 * @param props
 * @returns {*}
 * @constructor
 */
const CreateDateFieldBasic = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {className, basePath, record = {}, ...rest} = props;
  return (
    <Labeled {...rest}>
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...rest}
      >
        {dateFromId(record._id, 'SHORT')}
      </Typography>
    </Labeled>
  )
};

// CreateDateFieldBasic.displayName = 'Created';
// const CreateDateField = pure(CreateDateFieldBasic);

const CreateDateField = CreateDateFieldBasic;

// CreateDateField.defaultProps = {
//   addLabel: true,
// };

//CreateDateField.defaultProps = { label: 'Created' };

// const XUnMoneyField = ({label, source}) =>
//   <FunctionField label={label} render={record => {
//     let value = record[source];
//     return isNaN(value) ? '0.00' : (value/100).toFixed(2);
//   }} />;

const UnMoneyField = props => {
  const {className, source, record = {}, ...rest} = props;
  let value = record[source];
  console.log("{UnMoneyField} value", value);
  return (
    <Labeled {...rest}>
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...rest}
      > {isNaN(value) ? '0.00' : (value/100).toFixed(2)}
      </Typography>
    </Labeled>
  )
};


export {
  CreateDateFieldForList,
  CreateDateField,
  UnMoneyField
}