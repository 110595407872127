import DictionaryEntryIcon from '@mui/icons-material/DescriptionOutlined';
import DictionaryEntryList from './DictionaryEntryList';
import DictionaryEntryShow from './DictionaryEntryShow';
import DictionaryEntryEdit from './DictionaryEntryEdit';
import DictionaryEntryCreate from './DictionaryEntryCreate';

export default {
  list: DictionaryEntryList,
  show: DictionaryEntryShow,
  edit: DictionaryEntryEdit,
  create: DictionaryEntryCreate,
  ICON: DictionaryEntryIcon
};