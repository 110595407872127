import React from 'react';
import {
  ShowView,
  ShowContextProvider,
  SimpleShowLayout,
  TopToolbar,
  TextField, DateField,
  FunctionField,
  EditButton,
  //Loading, Error,
  usePermissions,
  //useResourceContext,
  useRecordContext, useShowController, Loading
} from 'react-admin';
import {makeStyles} from '@mui/styles';
import log from 'loglevel';
import {CreateDateField} from "../components/Fields.js";
import UnListButton from '../button/UnListButton';
import {Activity, isAuthorized} from "../auth/authorization";
import Permissions from '../permissions';
import {Breadcrumbs} from "../breadcrumbs";
import {queryParamFilter} from "../util/paramUtil";
import UnEditButtonWithFilter, {UnEditButtonWithName} from "../button/UnEditButtonWithFilter";
import {RoleChips, roleChoices, roleToName, roleToShortName} from './UserUtil';
import {useLocation, useParams} from "react-router-dom";
import {DetailTitle} from "../components/InputFields";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const UserActions = () => {
  const record = useRecordContext();
  const {permissions} = usePermissions();
  //const resource = useResourceContext();
  log.debug("{UserActions} record:", record);
  log.debug("{UserActions} permissions:", permissions);
  if (!record) return null;

  if (isAuthorized(permissions, Activity.USER)) {
    return (
      <TopToolbar>
        <UnEditButtonWithName resource={'password'} caption={'Change Password'}/>
        <UnListButton
          label='resources.permissions.name_many'
          pathname='/permissions'
          filter={{user_id: record.id, user_name: record.name}}
          record={record}
          icon={<Permissions.ICON/>}
        />
        {/*<UnListButton label='Permissions' pathname='/permissions' basePath={basePath} record={data}*/}
        {/*              upperResource='user' icon=<Permissions.ICON/> />*/}
        {isAuthorized(permissions, Activity.USER) ?
          <UnEditButtonWithName />
          : null}
      </TopToolbar>
    )}
  else {
    return <TopToolbar></TopToolbar>
  }
};

//const UserTitle = ({record}) => <span>User: {record ? `${record.name}` : ''}</span>;

/**
 *
 * @param permissions   E.g. verified,superadmin
 * @param props
 * @returns {*}
 * @constructor
 */

export const UserShow = () => {
  const classes = useStyles();
  //const permissions = usePermissions();
  const location = useLocation();
  const {id} = useParams();
  //const resource = useResourceContext();

  // TODO: This is returning all employers
  // const {data: employers, loaded: employersLoaded, error: employersError} = useQuery({
  //   type: 'getList',
  //   resource: 'employers',
  //   payload: {
  //     pagination: {page: 1},
  //     sort: {field: 'name', order: 'ASC'},
  //     filter: {view: 'summary'}
  //   }
  // });
  const employers = [];

  const {name} = queryParamFilter(location);
  log.debug(`{UserShow} id: ${id}, name: ${name}`);
  const roleToNameMap = roleToName(roleChoices);
  const listContext = useShowController();
  const {isLoading} = listContext;
  if (isLoading) return <Loading/>;
  return (
    <ShowContextProvider value={listContext}>
      <Breadcrumbs/>
      <ShowView
        title={<DetailTitle />}
        actions={<UserActions />}
        >
        <SimpleShowLayout>
          <TextField source="name"/>
          <TextField source="email"/>
          <TextField source="user_name"/>
          {/*<FunctionField label="Password" render={() => (*/}
          {/*  "**********"*/}
          {/*)*/}
          {/*}/>*/}

          <FunctionField label="Roles" render={record =>
            <RoleChips record={record} classes={classes}
                       employers={employers}
                       roleToName={roleToNameMap} roleToShortName={roleToShortName}/>
          }/>
          <DateField source="verifyDate"/>
          <TextField source="expiration"/>
          <TextField source="rep_uname" label={"Application (rep_uname) - deprecated"}/>
          <CreateDateField label='Created'/>
          {/*<TextField source="id"/>*/}
        </SimpleShowLayout>
      </ShowView>
    </ShowContextProvider>
  );
};

export default UserShow;