import React from 'react';
import {
  TabbedForm, FormTab,
  TextInput,
  SaveButton, Toolbar, TopToolbar, DeleteWithConfirmButton,
  Loading,
  EditView,
  useEditController,
  EditContextProvider,
  CloneButton,
  // useTranslate,
  // usePermissions,
  useRecordContext,
  //FunctionField
} from 'react-admin';
import {makeStyles} from '@mui/styles';
import log from 'loglevel';
import {DetailTitle} from "../components/InputFields";
import {Breadcrumbs} from "../breadcrumbs";
import {queryParamObject, queryParamString, getTabName} from "../util/paramUtil";
import {UnShowButtonWithFilter} from "../button/UnShowButtonWithFilter";
import {isValidTabName} from "../components/EditActions";
import EmployeeOptionsForm from "./EmployeeOptionsForm";
import {useLocation, useParams} from "react-router-dom";
//import {AdditionalOptionsTree} from "./AdditionalOptions";
import {SelectionsEdit} from "./Selections";
//import Typography from "@mui/material/Typography";
//import {Activity, isAuthorized} from "../auth/authorization";

/**
 * react-data-grid
 * Example:
 * https://gitlab.com/ABOS-Software/abos-client/blob/6631e0078c17fad5eb3fd0f3a01c628e37b71fe7/src/resources/Products/ProductsGrid.js
 */

const useStyles = makeStyles((theme) => ({
  form: { padding: 0 },
  wideField: {width: '100%'},
  narrowField: {width: '8m', marginRight: '0.5em'},
  formGroup: {display: 'inline-block', marginRight: '0.5em'},
  formGroupUnder: {marginRight: '0.5em'},

  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const BenefitEditActions = ({filter, tabName}) => {
  // const record = useRecordContext();
  // if (!record) return null;
  const adjTabName = isValidTabName(tabName) ? tabName : '';
  log.debug(`{BenefitEditActions} filter:`, filter);
  return (
    <TopToolbar>
      <UnShowButtonWithFilter
        filter={filter}
        tabName={adjTabName}
      />
      <CloneButton />
    </TopToolbar>
  );
};

// React does not recognize the `dictId` prop on a DOM element.
/**
 * In UnDeleteButton, queryParam is ID used to delete. filter used for redirected query.
 * @param props
 * @returns {*}
 * @constructor
 */
const BenefitEditToolbar = props => {
  const {filter, ...rest} = props;
  log.debug(`{BenefitEditToolbar} filter:`, filter);
  const record = useRecordContext();
  //const {dictId, dictName} = filter;

  // const redirect = (basePath) =>
  //   `${basePath}?${queryParamString({dictId, dictName})}`;

  log.debug(`{BenefitEditToolbar} record:`, record);
  return (
    <Toolbar {...rest}>
      <SaveButton/>
      <DeleteWithConfirmButton confirmTitle={`Delete ${record.name}?`}
                               confirmContent={'Confirm to delete this benefit'}
                               //redirect={redirect}
      />
    </Toolbar>
  )
};

// const OptionsField = () => {
//   const selOptionGroups = useWatch({name: 'optionGroups'});
//   log.debug(`{OptionsField} selOptionGroups:`, selOptionGroups);
//     return (
//     <Typography>
//       selOptionGroups
//     </Typography>
//     )
// }

/**
 * Benefit edit page with tabs: main entry and aliases (constraints to come later).
 * Uses UnEdit with custom controller to pass extra dictId to query.
 * Uses custom delete button to handle extra dictId parameter.
 *
 * Mnemonic for the two functions:
 * parse(): display -> record
 * format(): record -> display
 *
 * Note: We cannot allow dictionary to be changed until we add a backend function that deletes the entry from
 * one dictionary and adds it to another, since it is a subdocument.
 */
export const BenefitEdit = () => {
  //const {permissions} = usePermissions();
  const location = useLocation();
  //const translate = useTranslate();
  const {id} = useParams();
  const classes = useStyles();
  //const dataProvider = useDataProvider();
  const args = queryParamObject(location);
  if (args.id) delete args.id;
  const editContext = useEditController({args});
  log.debug(`{BenefitEdit} editContext:`, editContext);

  /**
   * After saving, the tab's name is used to set the equivalent tab in the Show page, e.g:
   *   /dictionaryentries/60c84f8a0688ec1f58553643/show/constraints?....
   * The first tab ("entry") has no tab name and must not be included in the URL.
   * @param basePath
   * @returns {`${string}/${string}/show/${*}?${string}`}
   */
  const redirect = (basePath) => {
    const restArgs = args; // queryObj
    const locationTabName = getTabName(location);
    log.debug(`{BenefitEdit.redirect} locationTabName: ${locationTabName}`);
    return isValidTabName(locationTabName) ?
      `${basePath}/${id}/show/${locationTabName}?${queryParamString(restArgs)}` :
      `${basePath}/${id}/show?${queryParamString(restArgs)}`;
    //return urlForRedirectWithTab({queryObj, ...props});
  }

  // if (!isAuthorized(permissions, Activity.DICTIONARY)) {
  //   return (<div/>);
  // }
  const {isLoading} = editContext;
  if (isLoading) return <Loading/>;
  return (
    <EditContextProvider value={editContext}>
      <Breadcrumbs/>
        <EditView
          title={<DetailTitle />}
          actions={<BenefitEditActions tabName={getTabName(location)} />}
        >
        <TabbedForm
          redirect={redirect}
          toolbar={<BenefitEditToolbar filter={{...args}}/>}
        >

          <FormTab label="Required Selections">
            <EmployeeOptionsForm classes={classes}  />
          </FormTab>

          <FormTab label="Optional Selections" path={"optional"}>
          </FormTab>

          <FormTab label="General" path={"general"}>
            <TextInput source='name' label='Benefit name' fullWidth/>
            <TextInput source="summary" fullWidth/>
            <TextInput source='description' multiline={true} rows={3} fullWidth/>
            <TextInput source="supplier" fullWidth/>
          </FormTab>

          <FormTab label="Selection Names" path={"names"}>
            <SelectionsEdit/>
              {/*<ArrayInput source="optionGroups" label=''>*/}
              {/*  <SimpleFormIterator>*/}
              {/*    <TextInput source='name' fullWidth />*/}
              {/*    <TextInput source='value' fullWidth />*/}
              {/*  </SimpleFormIterator>*/}
              {/*</ArrayInput>*/}
          </FormTab>

          <FormTab label="yaml" path={"yaml"}>
            {/*<TextInput source="yaml" component="pre" fullWidth*/}
            {/*           sx={{fontFamily: 'courier'}}*/}
            {/*/>*/}
            <TextInput source="yaml" multiline={true} rows={40} fullWidth/>
          </FormTab>

        </TabbedForm>
        </EditView>
      </EditContextProvider>
 )
};

export default BenefitEdit;