// import  {useEffect} from 'react';
// import {useSelector, useDispatch} from "react-redux";
import without from 'lodash/without';
import {
  //Identifier,
} from 'react-admin';

//import {parse} from "query-string";

// import {UntanglState, DictionaryState} from '../types/types';
// import {setDictionary} from '../reduxActions/dictionaryActions';
//import {queryParamObject} from '../util/paramUtil';
//import {Props} from "react";

/**
 * Convert constraints properties to comma-separated string for display
 * Old format: object
 * @param r
 * @returns {*}
 */
// const constraintsStrFromObject = (r) => {
//   const constraintsObj = r.constraints ? r.constraints : {};
//   const keys = constraintsObj ? Object.keys(constraintsObj) : [];
//   return keys.reduce((accum, key) => {
//     const separator = accum ? ', ' : '';
//     return `${accum}${separator}${key}=${constraintsObj[key]}`;
//   }, '');
// };

export interface NameValue {
  name: string;
  value?: string | number | Date;
}

export interface ConstraintsProps {
   constraints?: [NameValue];
}

/**
 * Convert constraints properties to comma-separated string for display.
 * A null constraint is removed with without(), in case an incomplete constraint is stored in db.
 * @param r   Entry record
 * @returns {*}
 */
const constraintsStr = (r: ConstraintsProps) : string => {
  const constraints = r.constraints ? without(r.constraints, null) : [];
  //console.log(`{constraintsStr} constraints:`, constraints);
  return constraints.reduce((accum, constraint) => {
    const separator = accum ? ', ' : '';
    //console.log(`{constraintsStr} constraint:`, constraint);
    return `${accum}${separator}${constraint.name}=${constraint.value}`;
  }, '');
};

const hasWhiteSpace = s => s.indexOf(' ') >= 0;

const isLowerCase = s => s === s.toLowerCase();

/**
 * Returns true if key is the correct format of an entry code and false if an entry name.
 * E.g code: "last_name", name: "Last Name"
 * Note the entry code may not actually exist - it must be in entryNameColRefMap.
 */
const isEntryCode = (key) => !hasWhiteSpace(key) && isLowerCase(key);


/**
 * Check if:
 * (1) state property is present, and
 * (2) filter value is missing or same as state, or
 * (3) both filter and state values are undefined
 * If all properties satisfy this condition, the redux state should not be updated.
 * @param property
 * @param filter
 * @param dictionaryState
 */
// const isPropertySufficient = (property, filter, state) => {
//   const isSufficient = (!isUndefined(state[property]) &&
//     (isUndefined(filter[property]) || state[property] == filter[property]) ||
//     (isUndefined(filter[property]) && isUndefined(state[property]))
//   );
//   if (isSufficient) {
//     console.log(`{isPropertySufficient} ${property}: (1) state property is present and (2) filter value is missing or same as state`);
//   }
//   return isSufficient;
// }

/**
 * Check that all properties in filter and state are equal
 * @param properties
 * @param filter
 * @param state
 */
// const isFilterEqualState = (properties, filter, state) => {
//   return properties.reduce((isEqual, property) => {
//     const e = (state[property] === filter[property]);
//     console.log(`{isFilterEqualState} ${property} e: ${e}`);
//     return isEqual && e;
//   }, true);
// }

// interface IDictionaryFilter {
//   dictId: Identifier,
//   dictName: string,
//   //name: string
// }

/**
 * Assume filter contains the newest parameters and each property present is stored.
 * For each filter property that is missing, it is retrieved from the store (if available).
 *
 * TODO: when filter is missing dictName in DictionaryEntryList button:
 *    <UnShowButtonWithFilter filter={{dictId, dictName}} {...props} />
 * this goes into infinite render.
 * @param filter
 */
// const useDictionaryArgs = (filter: IDictionaryFilter, dispatch): object => {
//   const dispatch2 = useDispatch();
//   console.log(`{getDictionaryArgs} filter:`, filter);
//
//   const dictionaryState: DictionaryState = useSelector((state: UntanglState) => state.untangl ? state.untangl.dictionary : {});
//   console.log(`{getDictionaryArgs} dictionaryState after useSelector:`, dictionaryState);
//   if (isUndefined(filter)) {
//     console.log(`{getDictionaryArgs} filter is undefined - returning state`);
//     return dictionaryState;
//   }
//
//   const state = dictionaryState;
//   const properties = ['dictId', 'dictName'];  // 'name'
//   // If filter is same as state, return
//   if (isFilterEqualState(properties, filter, state)) {
//   // if (dictionaryState.dictId === filter.dictId
//   //   && dictionaryState.dictName == filter.dictName
//   //   && dictionaryState.name == filter.name) {
//     console.log(`{getDictionaryArgs} filter is same as state - returning`);
//     return state;
//   }
//
//   // If dictionaryState is more complete than filter then return here
//   // Conditions to exit...
//   // If (1) state property is present, (2) filter value is missing or same as state
//   // const isDictIdPresent = isPropertySufficient('dictId', filter, dictionaryState);
//   // const isDictNamePresent = isPropertySufficient('dictName', filter, dictionaryState);
//   // const isNamePresent = isPropertySufficient('name', filter, dictionaryState);
//   // if (isDictIdPresent && isDictNamePresent && isNamePresent) {
//   //   console.log(`{getDictionaryArgs} returning as dictId, dictName && name sufficient`);
//   //   return dictionaryState;
//   // }
//
//   const b = properties.reduce((isSufficient, property) => {
//     return isSufficient && isPropertySufficient(property, filter, state);
//   }, true);
//   if (b) {
//     console.log(`{getDictionaryArgs} returning as dictId, dictName && name sufficient`);
//     return state;
//   }
//
//   const bestFilter = {
//     dictId: get(filter, 'dictId', get(state, 'dictId', undefined)),
//     dictName: get(filter, 'dictName', get(state, 'dictName', undefined)),
//     //name: get(filter, 'name', get(state, 'name', undefined))
//   };
//   console.log(`{getDictionaryArgs} storing bestFilter:`, bestFilter);
//   //dispatch2(setDictionary(bestFilter));
//   useEffect(() => {
//     dispatch2(setDictionary(bestFilter));  // Store bestFilter in redux store
//   }, [bestFilter]);
//   return bestFilter;
// };

/**
 * Read the redux dictionary state using the useSelector hook.
 * If the dictionary state is empty it should mean it is the first time and we can use the dictId
 * query string from the URL to set the state using dispatch(setDictionary()).
 * @param filter
 */
// const getDictionaryArgsOld = (filter: IDictionaryFilter): object => {
//   console.log(`{getDictionaryArgs} filter:`, filter);
//   let dictId, dictName, name;
//   const dispatch = useDispatch();
//   const dictionaryState: DictionaryState = useSelector((state: UntanglState) => state.untangl ? state.untangl.dictionary : {});
//   console.log(`{getDictionaryArgs} dictionaryState after useSelector:`, dictionaryState);
//   const haveDictIdAndNoState = filter && filter.dictId && isEmpty(dictionaryState);
//   const isDictIdDiffFromFilter = filter && filter.dictId && dictionaryState.dictId !== filter.dictId;
//   console.log(`{getDictionaryArgs} haveDictIdAndNoState: ${haveDictIdAndNoState}`);
//   console.log(`{getDictionaryArgs} isDictIdDiffFromFilter: ${isDictIdDiffFromFilter},
//     dictionaryState.dictId: ${dictionaryState.dictId}`);
//   if (filter && filter.dictId && isEmpty(dictionaryState)  ||
//     (filter && filter.dictId && dictionaryState.dictId !== filter.dictId)
//   ) {
//     console.log(`{getDictionaryArgs} dictionaryState empty. filter:`, filter);
//     dispatch(setDictionary({
//       dictId: filter.dictId,
//       dictName: filter.dictName,  // Testing only. We don't know the name until useGetOneWithArgs is complete
//       name: filter.name
//     }));
//     console.log(`{getDictionaryArgs} setting dictionary with dictId: ${filter ? filter.dictId : 'filter undefined'}`);
//     dictId = filter && filter.dictId ? filter.dictId : undefined;
//     dictName = filter && filter.dictName ? filter.dictName : undefined;
//     name = filter && filter.name ? filter.name : undefined;
//     console.log(`{getDictionaryArgs} dictId: ${dictId}, dictName: ${dictName}, name: ${name}`);
//   }
//   else {
//     dictId = dictionaryState.dictId ? dictionaryState.dictId : undefined;
//     dictName = dictionaryState.dictName ? dictionaryState.dictName : undefined;
//     name = dictionaryState.name ? dictionaryState.name : undefined;
//     name = name ? name : (filter ? filter.name: undefined); // Hack to use filter name if state name missing
//     console.log(`{getDictionaryArgs} set from state - dictId: ${dictId}, dictName: ${dictName}, name: ${name}`);
//   }
//   return {dictId, dictName, name};
// };

/**
 * Return template ID and name (if available).
 * If found in filter via location.search, use it. If not get redux state using getTemplateArgs().
 * @param props
 */
// const getDictionaryInfo = (props, dispatch) => {
//   console.log(`{getDictionaryInfo} props:`, props);
//   //let {filterValues} = props;
//   let filter;
//   const search = get(props, 'location.search');
//   console.log(`{getDictionaryInfo} search:`, search);
//   //console.log(`{getTemplateInfo} filterValues:`, filterValues);
//
//   if (search) {
//     const argObj = parse(search);
//     console.log("{getDictionaryInfo} argObj after search parse:", argObj);
//     if (argObj.filter) {
//       filter = JSON.parse(argObj.filter);
//     }
//   }
//   if (!filter) console.log(`{getTemplateInfo} filter undefined`);
//   const info = useDictionaryArgs(filter, dispatch);
//   console.log(`{getDictionaryInfo} info:`, info);
//   return info;
// }

// Component - NOT USED
// const DictionaryInfo = props => {
//   const dispatch = useDispatch();
//   console.log(`{DictionaryInfo} props:`, props);
//   const info  = getDictionaryInfo(props, dispatch);
//   console.log(`{DictionaryInfo} info:`, info);
//   return info;
// }

// Hook version
// const getDictionaryArgsHook = (filter: IDictionaryFilter, dispatch, dictionaryState): object => {
//   console.log(`{getDictionaryArgsHook} filter:`, filter);
//   // const dictionaryState: DictionaryState = useSelector((state: UntanglState) => state.untangl ? state.untangl.dictionary : {});
//   console.log(`{getDictionaryArgsHook} dictionaryState after useSelector:`, dictionaryState);
//   if (isUndefined(filter)) {
//     console.log(`{getDictionaryArgsHook} filter is undefined - returning state`);
//     return dictionaryState;
//   }
//   const state = dictionaryState;
//   const properties = ['dictId', 'dictName'];  // 'name'
//   // If filter is same as state, return
//   if (isFilterEqualState(properties, filter, state)) {
//     console.log(`{getDictionaryArgsHook} filter is same as state - returning`);
//     //console.log(`{getDictionaryArgsHook} filter is same as state - NOT returning`);
//     return state;
//   }
//   const b = properties.reduce((isSufficient, property) => {
//     return isSufficient && isPropertySufficient(property, filter, state);
//   }, true);
//   if (b) {  // Will loop for ever without this test
//     console.log(`{getDictionaryArgsHook} returning as dictId and dictName sufficient`);
//     return state;
//   }
//   const bestFilter = {
//     dictId: get(filter, 'dictId', get(state, 'dictId', undefined)),
//     dictName: get(filter, 'dictName', get(state, 'dictName', undefined))
//   };
//   console.log(`{getDictionaryArgsHook} storing bestFilter:`, bestFilter);
//   //dispatch2(setDictionary(bestFilter));
//   useEffect(() => {
//     dispatch(setDictionary(bestFilter));  // Store bestFilter in redux store
//   }, [bestFilter]);
//   return bestFilter;
// };

// const useDictionaryInfoOrig = (props) => {
//   // const dispatch = useDispatch();
//   const {dispatch} = props;
//   // const dictionaryState: DictionaryState = useSelector((state: UntanglState) => state.untangl ? state.untangl.dictionary : {});
//   console.log(`{useDictionaryInfo} props:`, props);
//   let filter = queryParamObject(props.location);
//   // const search = get(props, 'location.search');
//   // console.log(`{useDictionaryInfo} search:`, search);
//   // if (search) {
//   //   const argObj = parse(search);
//   //   console.log("{useDictionaryInfo} argObj after search parse:", argObj);
//   //   if (argObj.filter) {
//   //     filter = JSON.parse(argObj.filter);
//   //   }
//   // }
//   if (!filter) console.log(`{useDictionaryInfo} filter undefined`);
//   //const info = getDictionaryArgsHook(filter, dispatch, dictionaryState);
//   console.log(`{useDictionaryInfo} filter:`, filter);
//   // const dictionaryState: DictionaryState = useSelector((state: UntanglState) => state.untangl ? state.untangl.dictionary : {});
//   console.log(`{useDictionaryInfo} dictionaryState after useSelector:`, dictionaryState);
//   if (isUndefined(filter)) {
//     console.log(`{useDictionaryInfo} filter is undefined - returning state`);
//     return dictionaryState;
//   }
//   const state = dictionaryState;
//   const properties = ['dictId', 'dictName'];  // 'name'
//   // If filter is same as state, return
//   if (isFilterEqualState(properties, filter, state)) {
//     console.log(`{useDictionaryInfo} filter is same as state - returning`);
//     //console.log(`{useDictionaryInfo} filter is same as state - NOT returning`);
//     return state;
//   }
//   const b = properties.reduce((isSufficient, property) => {
//     return isSufficient && isPropertySufficient(property, filter, state);
//   }, true);
//   if (b) {  // Will loop for ever without this test
//     console.log(`{useDictionaryInfo} returning as dictId and dictName sufficient`);
//     return state;
//   }
//   const bestFilter = {
//     dictId: get(filter, 'dictId', get(state, 'dictId', undefined)),
//     dictName: get(filter, 'dictName', get(state, 'dictName', undefined))
//   };
//   console.log(`{useDictionaryInfo} storing bestFilter:`, bestFilter);
//
//   // useEffect(() => {
//   //   dispatch(setDictionary(bestFilter));  // Store bestFilter in redux store
//   // }, [bestFilter]);
//   console.log(`{useDictionaryInfo} bestFilter:`, bestFilter);
//   return bestFilter;
// }

// const useDictionaryInfo = (props: Props) : any => {
//   console.log(`{useDictionaryInfo} props:`, props);
//   const filter = queryParamObject(props.location);
//   if (!filter) console.log(`{useDictionaryInfo} filter undefined`);
//   console.log(`{useDictionaryInfo} filter:`, filter);
//   if (filter === undefined) {
//     console.log(`{useDictionaryInfo} filter is undefined - returning state`);
//     return {};
//   }
//   return filter;
// }

/**
 * Converts a filter object to a URL query parameter,
 * E.g. Object {dictId} is converted to a string for a filter parameter, resulting in:
 *    filter=%7B"dictId"%3A"5ea85f66a0eecb3393ab9827"%7D
 * @param filterObj
 */
//const queryParamString = (filterObj) => stringify({filter: JSON.stringify(filterObj)});

export {
  constraintsStr,
  isEntryCode
  //useDictionaryArgs,
  // getDictionaryInfo,
  // DictionaryInfo,
  //useDictionaryInfo
  //queryParamString
}