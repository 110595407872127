import Typography from "@mui/material/Typography";
//import Link from "@mui/material/Link";
import React from "react";
import UnHelpButton from "../button/UnHelpButton";

const Aside = () => ( // {classes, record}
  <div style={{width: 330, margin: '1em'}}>
    {/*<Typography variant="body2" color="inherit" align="left">*/}
    {/*  <i>{record.templateName}</i>*/}
    {/*</Typography>*/}
    <Typography variant="h6" color="inherit" align="left">
      Formula examples
    </Typography>
    <UnHelpButton
      helpPage={'/Configuration/function-reference'}
      label={'Functions'}
    />

    {/*<Link href={"http://untangl.uk/book/Configuration/field-formats"} target="_blank">*/}
    {/*  Help on template fields*/}
    {/*</Link>*/}
    <Typography variant="body2">
      <br/>
      <b>MS Excel functions</b><br/>
      <code>
        ROW()<br/>
        IF(ROW()=4,SUM(E2:E4),0)<br/>
      </code>

      <br/><b>Untangler functions</b><br/>
      <code>
        U.INPUT("email")<br/>
        U.INPUT("last_name")<br/>
      </code>
      <br/><code>
        U.FIELD("Date of Birth")<br/>
      </code>
      <br/><code>
        U.ATTRIBUTE("Age Type")<br/>
      </code>
      <br/><code>
        U.INCREMENT() + 2<br/>
      </code>
      <br/><code>
        U.IFMISSING(U.INPUT("dob"),'',
        &nbsp;&nbsp;U.INPUT("dob"))
      </code>
      <br/><br/><code>
        U.ISMISSING("dob", "Missing")
      </code>
      <br/><br/><code>
      U.IFERROR(<br/>
      &nbsp;&nbsp;U.FIELD("Age") &lt; U.ATTRIBUTE("MaxAge"),<br/>
      &nbsp;&nbsp;"OK",<br/>
      &nbsp;&nbsp;"CHECK",<br/>
      &nbsp;&nbsp;"Max Age Check failed")
      </code>

      <br/><br/><b>Mixing Excel and Untangler functions</b><br/>
      <code>IF(U.INPUT("salary") &lt; 100000,<br/>
        &nbsp;&nbsp;U.FIELD("Last Name"), <br/>
        &nbsp;&nbsp;DATEVALUE(U.ATTRIBUTE("Start Date"))
       )</code>
    </Typography>
  </div>
);

export default Aside;
