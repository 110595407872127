import React from 'react';
import {
  EditView,
  SimpleForm,
  TextField,
  TextInput,
  SelectInput,
  required,
  PasswordInput,
  //useNotify, useRefresh, useRedirect,
  Loading,
  useGetList,
  EditContextProvider,
  useEditController
} from 'react-admin';
import log from 'loglevel';
import {queryParamObject} from "../util/paramUtil";
import {Breadcrumbs} from "../breadcrumbs";
import {UnEditActions} from "../components/EditActions";
import {UnEditToolbar, DetailTitle} from "../components/InputFields";

import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePassword
} from "./UserValidate";
import {useLocation, useParams} from "react-router-dom";

/**
 * TODO: toLowerCase of email not working
 * parse - prepare input value for output
 * format - prepare value read before display - format={v => v.toLowerCase()}
 * Associated reference no longer appears to be available.
 */

//const CommentField = ({value}) => value;

// const UserEditToolbar = props => {
//   return (
//     <Toolbar {...props}>
//       <SaveButton/>
//     </Toolbar>
//   )
// };
// const UserEditActions = ({data, resource}) => {
//   if (!data) return null;
//   return (
//     <TopToolbar>
//       <UnShowButtonWithName record={data} resource={resource}/>
//     </TopToolbar>
//   );
// };


//const UserTitle = ({record}) => <span>User: {record ? `${record.name}` : ''}</span>;

// Pass name to 2nd breadcrumb in show/detail page
//const redirect = (basePath, id, {name}) => `${basePath}/${id}/show?${queryParamString({name})}`;

// Time formats to milliseconds: https://github.com/vercel/ms
const expirations = [
  {id: '1m', name: '1 minute'},
  {id: '2m', name: '2 minutes'},  // Testing 2 * 60000
  {id: '1h', name: '1 hour'},
  {id: '1d', name: '1 day'},
  {id: '7d', name: '7 days'},
  {id: '30d', name: '30 days'},
  {id: '120d', name: '120 days'},
  {id: '180d', name: '180 days'},
]

export const UserEdit = () => {
  const location = useLocation();
  const {id} = useParams();
  // const notify = useNotify();
  // const refresh = useRefresh();
  // const redirect = useRedirect();
  const {name, type} = queryParamObject(location);
  log.debug(`{UserEdit} id: ${id}, name: ${name}, type: ${type}`);

  // Note undoable must be set to false in Edit for data arg to be populated
  // const onSuccess = ({data}) => {
  //   log.debug(`{UserEdit.onSuccess} data:`, data);
  //   const {user} = data;
  //   const name = `${user.first_name} ${user.last_name}`;
  //   // https://marmelab.com/react-admin/Actions.html#handling-side-effects-in-usedataprovider
  //   notify(`notification.updated_one`, {type: 'info'});
  //   // redirect to the result of a function
  //   // redirect((redirectTo, basePath, is, data) => ...)
  //   const redirectUrl = `/users/${user._id}/show?${queryParamString({name})}`;
  //   log.debug(`{UserEdit.onSuccess} redirectUrl:`, redirectUrl);
  //   redirect(redirectUrl);
  //   refresh();
  // };

  // transform the form data before calling dataProvider.update()
  const transform = data => {
    log.debug(`{UserEdit.transform} data:`, data);
    const {hashedPassword, ...rest} = data;
    log.debug(`{UserEdit.transform} transformed data:`, rest);
    return rest;
  };

  const editContext = useEditController({redirect: 'show', transform});

  const {
    isLoading: isRepresentativesLoading,
    data: representatives
  } = useGetList('representatives', {
    pagination: {page: 1},
    sort: {field: 'name', order: 'ASC'}
  });
  if (editContext.isLoading) return <Loading />;
  if (isRepresentativesLoading) return <Loading />;
  const representativesList = representatives.map(r => ({id: r.uname, name: r.name}));
  log.debug(`{UserEdit} representativesList:`, representativesList);
  return (
    <EditContextProvider value={editContext}>
      <Breadcrumbs/>
      <EditView
        title={<DetailTitle />}
        actions={<UnEditActions />}
        // transform={transform}
        undoable={false}  // Needed for onSuccess to receive data
      >
        <SimpleForm  toolbar={<UnEditToolbar/>}>
          <TextInput source="first_name" validate={validateFirstName} fullWidth />
          <TextInput source="last_name" validate={validateLastName} fullWidth />
          <TextInput source="email" parse={v => v.toLowerCase()} validate={validateEmail} fullWidth />
          <TextField source="user_name" label='User Name' />

          {/*<PasswordInput source="password" validate={validatePassword} fullWidth />*/}

          {/*<ReferenceInput label="Application" source="rep_uname" reference="representatives"*/}
          {/*                sort={{ field: 'name', order: 'ASC' }} allowEmpty>*/}
          {/*  <SelectInput optionText="name" optionValue="uname"/>*/}
          {/*</ReferenceInput>*/}
          {/*<SelectInput label="Application (rep_uname) - deprecated" source="rep_uname" choices={representativesList} fullWidth allowEmpty/>*/}

          <SelectInput source='expiration' choices={expirations} validate={required()}/>
          <TextField source="rep_uname" />
          <TextField label="ID" source="id" />
          {/*<CommentField value='Ignore message: Associated reference no longer appears to be available.' />*/}
        </SimpleForm>
      </EditView>
    </EditContextProvider>
  );
}




export default UserEdit;