import {defaultTheme} from "react-admin";
import {untanglColors} from './themeColors';

export const darkTheme = {
  ...defaultTheme,
  name: "dark",
  palette: {
    mode: 'dark', // Switching the dark mode on is a single property value change.
    primary: {
      main: untanglColors.orange
    },
    secondary: {
      main: untanglColors.tealblue
    },
    headerText: {
      //main: indigo["50"]
      main: "white"
    },
    headerBackground: {
      main: untanglColors.orange
    }
  },
  sidebar: {
    width: 200,
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: '3px solid #000',
          '&.RaMenuItemLink-active': {
            borderLeft: '3px solid #90caf9',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: '#ffffffb3',
          backgroundColor: '#616161e6',
        },
      },
    },
  },

  overrides: {
    MuiToolbar: {
      dense: {
        minHeight: '64px' // Was 48
      }
    },
    MuiTableRow: {//untanglColors.tealblue,
      head: {
        backgroundColor: 'blue',
        "& > th ": {
          color: 'white',
          backgroundColor: 'blue'
          //fontWeight: 'bold',
        }
      },
    },
    // https://stackoverflow.com/questions/56682287/how-to-custom-color-text-and-icon-in-tablesorttext-component-of-material-ui
    MuiTableSortLabel: {
      root: {
        "&$active": {//untanglColors.tealblue,
          backgroundColor: 'blue',
          color: 'white',
          // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
          "&& $icon": {
            color: 'white'
          }
        }
      }
    }
  }
};