import {queryParamString} from "../util/paramUtil";
import {ChipField, Link} from "react-admin";
import React from "react";
import Chip from "@mui/material/Chip";

const stopPropagation = e => e.stopPropagation();

/**
 * Renders chips for each application ID isted in applicationIds.
 * Uses applications as a lookup to find name.
 * Note applications should contain the same number of items as applicationIds, but may
 * contain more if not completely filtered (e.g. as superadmin role).
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const OwnerChips = props => {
  const {classes, applicationIds, applications} = props;
  //log.debug(`{OwnerChips} applicationIds: ${applicationIds}, applications:`, applications)
  return (
    <div className={classes.root}>
      {!applications ? '' : applications.map(a => {
        //log.debug(`{OwnerChips} map a:`, a);
        const url = `/representatives/${a.id}/show?${queryParamString({name: a.name})}`;
        return applicationIds.includes(a.id) ? (
          <Link className={classes.link} key={a.id} to={url} onClick={stopPropagation}>
            <ChipField record={a} source='name' key={a.id} clickable={true}/>
          </Link>)
        : null
      })}
    </div>
  );
}

export const OwnerChipsNoLink = props => {
  const {classes, applicationIds, applications} = props;
  //log.debug(`{OwnerChipsNoLink} applicationIds: ${applicationIds}, applications:`, applications)
  return (
    <div className={classes.root}>
      {!applications ? '' : applications.map(a => {
        //log.debug(`{OwnerChipsNoLink} map a:`, a);
        return applicationIds.includes(a.id) ?
              <ChipField record={a} source='name' key={a.id} clickable={false}/>
          : null
      })}
    </div>
  );
}

export const DictionaryChips = ({classes, dictionaries}) => (
  <div className={classes.root}>
    {!dictionaries ? '' : dictionaries.map(d => {
      const url = `/dictionaries/${d.id}/show?${queryParamString({name: d.name})}`;
      return (
        <Link className={classes.link} key={d.id} to={url} onClick={stopPropagation}>
          <ChipField record={d} source='name' key={d.id} clickable={true}/>
        </Link>);
    })}
  </div>
);

export const ApplicationChip = ({application}) => {
  if (!application) return '';
  console.log(`{ApplicationChip} application:`, application);
  const url = `/applications/${application._id}/show?${queryParamString({name: application.name})}`;
  console.log(`{ApplicationChip} url:`, url);
  return (
      <Link key={application.id} to={url} onClick={stopPropagation}>
        <ChipField record={application} source='name' key={application.id} clickable={true}/>
      </Link>
  );
}

// className={classes.root}
export const TemplatesChip = ({templates}) => {
  if (!templates) return '';
  //console.log(`{TemplatesChip} templates:`, templates);
  return (
  <div >
    {!templates ? '' : templates.map(template => {
      const url = `/templates/${template.templateId}/show?${queryParamString({name: template.name})}`;
      console.log(`{TemplatesChip} url:`, url);
      return (
        <Link key={template._id} to={url} onClick={stopPropagation}>
          <ChipField record={template} source='name' key={template.templateId} clickable={true}/>
        </Link>
      );
    })}
  </div>
  )
}

export const TemplateChip = ({templateId, templateName}) => {
  //console.log(`{TemplateChip} templateId: ${templateId}, templateName: ${templateName}`);
  if (templateId) {
    return (
      <Link
        // key={templateId}
        to={`/templates/${templateId}/show?${queryParamString({name: templateName})}`}
        onClick={stopPropagation}>
        <Chip label={templateName} clickable={true}/>
      </Link>
    );
  }
  else return (
      <Chip label={templateName} clickable={false}/>
  );
}

// Copied from RepresentativeShow - TODO merge with version above
// const TemplateChipNew = ({template, classes, key}) => {
//   // log.debug(`{TemplateChip} template:`, template);
//   return (
//     <Link className={classes.link}
//           key={template.templateId}
//           to={`/templates/${template.templateId}/show?${queryParamString({name: template.name})}`}
//           onClick={stopPropagation}>
//       <Chip label={template.name} key={key} clickable={true}/>
//     </Link>
//   );
// }
