import * as React from 'react';
import {
  required,
  TextInput,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin';
//import { InputAdornment, Grid, Box } from '@mui/material';
// <Grid container columnSpacing={2}>
export const SelectionsEdit = () => (
  <ArrayInput source="optionGroups" label=''>
    <SimpleFormIterator inline>
      <TextInput source="name" validate={req} sx={{width: 300}}/>
      <TextInput source="value" validate={req} sx={{width: 400}}/>
    </SimpleFormIterator>
  </ArrayInput>
);

// export const SelectionsEdit = () => (
//   <ArrayInput source="optionGroups" label=''>
//     <SimpleFormIterator inline>
//       <Grid container columnSpacing={2}>
//         <Grid item xs={12} sm={6}>
//           <NumberInput
//             source="price"
//             validate={req}
//             fullWidth
//             sx={{width: '700', display: 'inline-block', marginRight: '0.5em'}}
//           />
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <NumberInput source="stock" validate={req} fullWidth />
//         </Grid>
//       </Grid>
//     </SimpleFormIterator>
//   </ArrayInput>
// );

const req = [required()];

