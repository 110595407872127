import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput, SelectInput,
  TextField
} from 'react-admin';
import log from 'loglevel';
import {queryParamString} from "../util/paramUtil";

/**
 * Note that in database:
 *   persons in the UI are stored as employees
 *   employees in the UI are stored as employments
 * The UI person detail page contains a mixture of person and person data.
 */

const PersonTitle = ({ record }) => {
  return <span>Person{(record && record.last_name) ? `: ${record.last_name}` : ''}</span>;
};

// Buttons at top right
// const PersonEditActions = ({ basePath, data, resource }) => (
//   <TopToolbar>
//     <ShowButton basePath={basePath} record={data} />
//   </TopToolbar>
// );

/**
 * The employments property may either contain an array of employments or of employment indexes.
 * @param data
 * @returns {*}
 */
//const employmentId = data => data.employments[0].id ? data.employments[0].id : data.employments[0];

/**
 * The person form would normally default to displaying the persons list after an update,
 * but it does not exist. It should return to the employee show/detail page.
 * @param basePath
 * @param id
 * @param data
 * @returns {string}
 */
// const redirectX = (basePath, id, data) => {
//   log.debug("{PersonEdit.redirect} data:", data);
//   //Employer ID
//   // const filterObj = {
//   //   filter: JSON.stringify({employer_id: data.employments[0].employer})
//   // };
//   //const url = `/employees/${data.employments[0].id}/show?employer_id=${JSON.stringify(filterObj)}`;
//   //const url = `/employees/${data.employments[0].id}/show`;
//   const url = `/persons/${data.id}/show`;
//   log.debug(`{PersonEdit.redirect} redirecting to: ${url}`);
//   return url;
//   //return `/employees/${data._id}/show?${stringify(filterObj)}`;
// };

const redirect = (basePath, id, data) => {
  //const {employer_id, employer_name} = data;
  log.debug("{PersonEdit.redirect} data:", data);
  const employer_id = data.employments[0].employer;
  log.debug(`{PersonEdit.redirect} employer_id: ${employer_id}`);
  // return `/employees/${data._id}/show?${queryParamString({employer_id})}`;
  return `/employees?${queryParamString({employer_id})}`;
}

// const redirect2 = (basePath, id, data) => {
//   const filterObj = {
//     employer_id: data.employer._id
//   };
//   return `/employees/${data._id}/show?employer_id=${JSON.stringify(filterObj)}`;
// };


export const PersonEdit = (props) => {
  log.debug("{PersonEdit} props:", props);
  return (
    <Edit
      title={<PersonTitle/>}
      //actions={<PersonEditActions/>}
      {...props}>
      <SimpleForm
        redirect={redirect}
        //toolbar={<UnEditToolbar/>}
        >
        <TextInput source="first_name"/>
        <TextInput source="last_name"/>
        <SelectInput source="gender" choices={[
          { id: 'male', name: 'Male' },
          { id: 'female', name: 'Female' }
        ]} />
        <DateInput source="dob" />
        {/*<TextInput source="address.street1"/>*/}
        {/*<TextInput source="address.street2"/>*/}
        {/*<TextInput source="address.street3"/>*/}
        <TextInput source="street1"/>
        <TextInput source="street2"/>
        <TextInput source="city"/>
        <TextInput source="postcode"/>
        <TextInput source="address.country_name"/>
        <TextField source="id" label={"Person ID"}/>
        {/*<TextField source="employments[0].id" label={"Employee ID"}/>*/}
      </SimpleForm>
    </Edit>
  );

  // return (
  //   <UnEdit title="Person Edit"
  //         {...props}>
  //     <SimpleForm>
  //       <TextInput source="first_name"/>
  //       <FunctionField label={"Test"} render={record => `{PersonEdit} Field in PersonEdit`} />
  //     </SimpleForm>
  //   </UnEdit>
  // );
};

export default PersonEdit;