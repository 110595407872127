/**
 * From xlsx library used in Untangler
 * @param col
 * @returns {string}
 */

const encode_col = (col) => {
  let s = '';
  for (++col; col; col=Math.floor((col-1)/26)) {
    s = String.fromCharCode(((col - 1) % 26) + 65) + s;
  }
  return s;
}

export {
  encode_col
}