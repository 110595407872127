import React, {useCallback} from 'react';
import {
  useNotify,
  useDataProvider,
  useResourceContext,
  Button, useRecordContext
} from 'react-admin';
import IconButton from "@mui/material/IconButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import downloadXlsx from './downloadXlsx';
// import log from 'loglevel';
//import {Tooltip} from "@mui/material";

/**
  * We need our extended getOne() method forcing us to make a new button component.
 * Note that the exporter function is passed in as an attribute (e.g. see DictionaryShow).
 * This is not an export from the current list page - it is just downloading a single (converted) file.
 * @param props
 * @constructor
 */
const UnDownloadButton = props => {
  const record = useRecordContext();
  const resource = useResourceContext(props);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  //console.log(`{UnDownloadButton} props:`, props)
  const {
    onClick,
    //label,
    showLabel,
    //label = 'ra.action.export',
    //icon = defaultIcon,
    //record,
    data,
    //...rest
  } = props;
  const id = record ? record.id : (data ? data._id : 0);
  //log.debug(`{DownloadButton} id: ${id}, props:`, props);
  const handleClick = useCallback(
    event => {
      dataProvider.getOneWithArgs(resource, // e.g. 'untanglements'
        {id, args: {format: 'xlsx', view: 'kitchensink'}}
      )
      .then(
        ({data}) => {
          //log.debug(`{DownloadButton} data:`, data);
          return downloadXlsx(data.data, resource);  // !!!
        }
      )
      .catch(error => {
        console.error(error);
        notify('ra.notification.http_error', 'warning');
      });
      if (typeof onClick === 'function') {
        onClick(event);
      }
    },
    [
      id,
      dataProvider,
      notify,
      onClick,
      resource
    ]
  );

  // <Tooltip title={'Download'}>
  //   {/*<IconButton color="primary" aria-label="Download"*/}
  //   {/*            onClick={handleClick}>*/}
  //   {/*  <CloudDownloadIcon/>*/}
  //   {/*</IconButton>*/}
  //   <Button color="primary" aria-label="DDD"
  //               onClick={handleClick}
  //           icon={<CloudDownloadIcon/>}
  //  />
  // </Tooltip>
  if (showLabel) {
    //console.log(`{UnDownloadButton} Label found: ${label}`);
    return(
    <Button color="primary"
                style={{minWidth: '40px', padding: '2px 2px 2px 10px'}}
                label="Download"
                aria-label="Download"
                onClick={handleClick}>
      <CloudDownloadIcon style={{fontSize: 28}} />
    </Button>
    )
  }
  else return (
    <IconButton color="primary"
                style={{minWidth: '40px', padding: '2px 2px 2px 10px'}}
                aria-label="Download"
               onClick={handleClick}>
      <CloudDownloadIcon style={{fontSize: 28}} />
    </IconButton>
  );

  // return (
  //   <Button color="primary"
  //           //label={label}
  //           onClick={handleClick}
  //           icon={
  //             <CloudDownloadIcon
  //             fontSize="large"
  //             style={{ fontSize: "200px" }}
  //           />
  //           }
  //   />
  // );
};

//const defaultIcon = <CloudDownloadIcon/>  //<DownloadIcon />;

// interface Props {
//   basePath?: string;
//   icon?: JSX.Element;
//   label?: string;
//   maxResults?: number;
//   onClick?: (e: Event) => void;
//   resource?: string;
// }

//export type UnDownloadButtonProps = Props & ButtonProps;

export default UnDownloadButton;

