import React from 'react';
import {
  List, Datagrid,
  TextField, DateField,
  Filter,
  TextInput, SelectInput,
  FunctionField,
  useGetList,
  Loading, Error, useResourceContext,
} from 'react-admin';
import {makeStyles} from '@mui/styles';
import Icon from '@mui/icons-material/Person';
import log from 'loglevel';
import {Breadcrumbs} from '../breadcrumbs';
import {defaultPerPage, UnPagination} from '../util/paramUtil';
import {RoleChips, roleChoices, roleAllChoices, roleToName} from './UserUtil';
import UnIconButton from "../button/UnIconButton";
import {ListTitle} from "../components/InputFields";
//import {useLocation} from "react-router-dom";

export const UserIcon = Icon;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

/**
 * TODO: user service to search jursidictions for selected rep ID
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const UserFilter = (props) => {
  const {applications, ...rest} = props;
  return (
    <Filter {...rest}>
      <TextInput label="Email" source="email" sx={{width: "260px"}}/>
      <SelectInput source="role" label="Role" alwaysOn choices={roleChoices} />
      {/*<ReferenceInput label="Application" source="representative_id" reference="representatives">*/}
      {/*  <SelectInput optionText="name" optionValue= "_id"/>*/}
      {/*</ReferenceInput>*/}
      <SelectInput source="applicationId" label="Applications" choices={applications}/>
    </Filter>
  );
}

const UserListSuperadmin = () => {
  //const location = useLocation();
  const resource = useResourceContext();
  const classes = useStyles();
  //const dataProvider = useDataProvider();

  // Doesn't work with useQueryWithStore (which is not supported in v4 anyway)
  const {
    data: employers,
    isLoading: isEmployersLoading,
    error: employersError} = useGetList(
    'employers',
    {
      pagination: {page: 1},
      sort: {
        field: 'name',
        order: 'ASC'
      },
      filter: {view: 'summary'}
    }
  );

  // Original
  const {
    data: applications,
    isLoading: isApplicationsLoading,
    error: applicationsError} = useGetList(
    'representatives',
    {
      pagination: {page: 1},
      sort: {
        field: 'name',
        order: 'ASC'
      }
    }
  );

  // v4 syntax but not with v 3.19
  // const {data: applications, isLoading: isApplicationsLoading} = useGetList(
  //   'representatives',
  //   {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}}
  // );

  if (isApplicationsLoading || isEmployersLoading) return <Loading />;
  if (applicationsError || employersError) return <Error />;
  log.debug(`{UserListSuperadmin} isApplicationsLoading: ${isApplicationsLoading}, applications:`, applications);
  log.debug(`{UserListSuperadmin} isEmployersLoading: ${isEmployersLoading}, employersError: ${employersError}, employers:`, employers);

  // if (isApplicationsLoading || isEmployersLoading) return <Loading />;
  // log.debug(`{UserListSuperadmin} isApplicationsLoading: ${isApplicationsLoading}, applications:`, applications);
  // log.debug(`{UserListSuperadmin} isEmployersLoading: ${isEmployersLoading}, employers:`, employers);

  const roleToNameMap = roleToName(roleAllChoices);
  log.debug("{UserListSuperadmin} roleToNameMap:", roleToNameMap);
  return (
    <React.Fragment>
      <Breadcrumbs resource={resource}/>
      <List title={<ListTitle/>}
            filters={<UserFilter applications={applications}/>}
            sort={{field: 'last_name', order: 'ASC'}}
            perPage={defaultPerPage}
            pagination={<UnPagination/>}
      >
        <Datagrid bulkActionButtons={false}>
          {/*<TextField source="id" />*/}
          <TextField source="first_name"/>
          <TextField source="last_name"/>
          <TextField source="email"/>
          <FunctionField label="Roles" render={() =>
            <RoleChips classes={classes} roleToName={roleToNameMap}
                       applications={applications} employers={employers}/>
          }/>
          {/*<RoleChips classes={classes} roleToName={roleToNameMap} roleToShortName={roleToShortName}/>*/}
          {/*<TextField source="rep_uname"/>*/}
          <DateField source="verifyDate"/>
          {/*<UnShowButtonWithName record={record} resource={resource}/>*/}
          <UnIconButton label={'Detail'} />
          {/*<EditButton basePath="/users" />*/}
        </Datagrid>
      </List>
    </React.Fragment>
  );
}

export default UserListSuperadmin;