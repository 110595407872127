import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import React from "react";
import {useRecordContext} from 'react-admin';

const exportFileName = ees => {
  let employerName = (ees.length > 0) ? ees[0].employer.name.replace(/\s/g, '_') : '';
  let fileName = (ees.length > 0) ? 'Employees_for_' + employerName : 'Employees';
  return fileName + '_' + new Date().toLocaleDateString();
};

// benefit_sets[0].
const employerMonthlyCost = (e, benefit_uname) => {
  console.log(`[employerMonthlyCost] benefit_uname ${benefit_uname} e:`, e);
  //console.log(`[employerMonthlyCost] e.benefit_set.evaluation_id:`, e.benefit_set.evaluation_id);
  if (e.benefit_set && !isUndefined(e.benefit_set.evaluation_id)) {
    console.log("[employerMonthlyCost] map e.benefit_set:", e.benefit_set.evaluation_id);
    let benefit = find(e.benefit_set.benefits, ben => {
      console.log("[employerMonthlyCost] benefit_uname:", benefit_uname, " ben.benefit_uname:", ben.benefit_uname);
      return benefit_uname === ben.benefit_uname;
    });
    return benefit.er_contribution / 100;
  }
  else return 0;
};

const unadjustedMonthlyCost = (e, benefit_uname) => {
  if (e.benefit_set && !isUndefined(e.benefit_set.evaluation_id)) {
    let benefit = find(e.benefit_set.benefits, ben => {
      return benefit_uname === ben.benefit_uname;
    });
    console.log("[unadjustedMonthlyCost] benefit:", benefit);
    return benefit.er_unadjusted_contribution / 100;
  }
  else return 0;
};

const FullNameField = () => {
  const record = useRecordContext();
  return (
    <span>{record.employee ? record.employee.first_name : ''} {record.employee ? record.employee.last_name : ''}</span>
  )
}
FullNameField.defaultProps = {label: 'Name'};

const fullNameField = (record) =>
  (record && record.employee) ?
    (record.employee.first_name + ' ' + record.employee.last_name) :
    ''

export {
  exportFileName,
  employerMonthlyCost,
  unadjustedMonthlyCost,
  FullNameField,
  fullNameField
}