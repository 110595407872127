import React from 'react';
import {useLocation} from "react-router-dom";
import {Navigate} from "react-router";  // ra v4 Was Redirect
import qs from "query-string";
import {
  Datagrid, Filter,
  TextField, NumberField,
  TextInput,
  SelectArrayInput,
  TopToolbar,
  useGetList,
  useListController, ListView, ListContextProvider,
  //useResourceContext, useRecordContext
} from 'react-admin';
import log from 'loglevel';
import exportDictionaryAliases from './DictionaryAliasExport';
import {Breadcrumbs} from "../breadcrumbs";
import {queryParamString, defaultPerPage, UnPagination} from "../util/paramUtil";
//import UnIconButton from "../button/UnIconButton";
import UnEntryIconButton from '../dictionaryentries/UnEntryIconButton';
import {ListTitle} from "../components/InputFields";

//const defaultIcon = <PageviewIcon  style={{fontSize: 30}}/>;

// Useful to prevent click bubbling in a datagrid with rowClick
//const stopPropagation = e => e.stopPropagation();

// const EntryIconButton = () => {
//   const record = useRecordContext();
//   //log.debug(`{EntryIconButton} record:`, record);
//   return (
//   <UnIconButton label={'Detail'} idName={'entryId'} filter={{dictId: record.dictId, dictName: record.dictionary}}
//                 record={record} myresource={'dictionaryentries'}/>
//   )
// }

const DictionaryFilter = (props) => {
  const {dictionaries, ...rest} = props;
  //log.debug(`{DictionaryFilter} dictionaries:`, dictionaries);
  // Set ID field to dictionary name, to support loose coupling between microservices
  const modDictionaries = dictionaries.map(d => ({id: d._id, name: `${d.section}/${d.name}`}));
  return (
    <Filter {...rest}>
      <TextInput label='Search for alias' source='alias' alwaysOn/>
      <TextInput label='Code' source='code'/>
      {/*<SelectInput source='dicts' label='Dictionary' choices={dictChoices} />*/}
      <SelectArrayInput label="Dictionary" source="dicts" choices={modDictionaries} />
      {/*<ReferenceArrayInput source="name" reference="dictionaries" label="Dictionary">*/}
      {/*  <SelectArrayInput label="Dictionary" optionText="name" />*/}
      {/*</ReferenceArrayInput>*/}
    </Filter>
  );
}

//const DictionaryEntryPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />;

/**
 * Buttons in top toolbar at top right.
 * Note that we can use regular CreateButton component since we do not need to specify extra
 * dictionary ID as it is specified in the form (in the dropdown list).
 * BUT - default???
 *
 * @param resource
 * @param filters
 * @param filterValues
 * @param showFilter
 * @param displayedFilters
 * @returns {*}
 * @constructor
 */
const DictionaryAliasActions = ({resource, filters, filterValues, showFilter, displayedFilters}) => {
  return (
    <TopToolbar>
      {filters && React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    </TopToolbar>
  );
}

/**
 * dictNames contains dictionary names that can be used by un_admin.
 * If the user selects no filter, these dictNames
 *
 * filters - React element to display filter form
 * filter - the permanent filter used in the REST request, e.g. filter={{is_published: true}}
 * @param props
 * @returns {null|*}
 * @constructor
 */
export const DictionaryAliasList = () => {
  //const resource = useResourceContext();
  const location = useLocation();
  // Get list of dictionaries for filter
  const {isLoading, data: dictionaries} = useGetList(
   'dictionaries',
    {
      pagination: {page: 1},
      sort: {field: 'alias', order: 'ASC'}
    }
  );

  const listContext = useListController({pagination: <UnPagination/>, perPage: defaultPerPage});
  log.debug('{DictionaryAliasList} listContext:', listContext);
  const {data} = listContext;
  if (!data || isLoading) return null;

  //data._id = data.entryId;  // Hack to correct inbound IDs
  // const {_id, ...dataRest} = dataIn;
  // listContext.data = {...dataRest, _id: dataIn.entryId};
  // log.debug('{DictionaryAliasList} listContext.data:', listContext.data);
  // const {data} = listContext;

  const queryLocationToObject = (location, propertyName) => {
    const search = location.search ? qs.parse(location.search) : '';
    log.debug(`{queryParamObject} search:`, search);
    if (!search || search === '') return undefined;
    let propertyValue = search[propertyName] ? JSON.parse(search[propertyName]) : undefined;
    log.debug(`{queryParamObject} propertyValue for ${propertyName}:`, propertyValue);
    return propertyValue;
  }
  const redirect = queryLocationToObject(location, 'redirect');
  log.debug('{DictionaryAliasList} redirect:', redirect);
  let isToRedirect = redirect && (redirect === true);  // If present, redirect
  if (data && !isLoading && isToRedirect) {
    const ids = Object.keys(data);
    log.debug(`{DictionaryAliasList} ids:`, ids);
    const firstRow = data[ids[0]];
    log.debug(`{DictionaryAliasList} data[${ids[0]}]:`, data[ids[0]]);
    const {entryId, name, dictId, dictionary} = firstRow;
    log.debug(`{DictionaryAliasList} entryId: ${entryId}, dictId: ${dictId}`);
    const url = `/dictionaryentries/${entryId}/show?${queryParamString({dictId, dictName: dictionary, name})}`;
    log.debug(`{DictionaryAliasList} redirect url:`, url);
    return (
      // <Redirect to={url} />  ra v4
      <Navigate to={url} />
    );
  }
 // TODO: How to delete filterValues in listContext after redirection?
 // https://stackoverflow.com/questions/52926290/how-to-reset-filter-values-in-react-admin-framework

  return (
    <ListContextProvider value={listContext}>
      <Breadcrumbs/>
      <ListView
        title={<ListTitle/>}
            perPage={defaultPerPage}
            sort={{field: 'name', order: 'ASC'}}
            pagination={<UnPagination/>}
            actions={<DictionaryAliasActions/>}
            filters={<DictionaryFilter
              dictionaries={dictionaries}
            />}
            exporter={exportDictionaryAliases}
            >
        <Datagrid bulkActionButtons={false}>
          <TextField source='alias' />
          <TextField source='name' label='Entry name' />
          <TextField source='code' />
          <TextField source='type' />
          <NumberField source='weight' />
          {/*<TextField source='_id' />*/}
          {/*<TextField source='entryId' />*/}
          {/*<TextField source='dictId' />*/}
          <TextField source='dictionary' label='Dictionary'/>
          {/*<FunctionField source='constraints' label='Constraints' sortable={false} render={r => constraintsStr(r)}/>*/}
          {/*<EntryShowButton label={'Detail'}/>*/}
          {/*<UnEntryIconButton label={'Detail'} idName={'entryId'}*/}
          {/*              myresource={'dictionaryentries'}/>*/}
          <UnEntryIconButton label={'Detail'} myresource={'dictionaryentries'}/>
        </Datagrid>
      </ListView>
    </ListContextProvider>
  );
}

export default DictionaryAliasList;