import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import React from "react";
import {useRecordContext} from 'react-admin';

const necessities = [
  {id: 'optional', name: 'Optional'},
  {id: 'expected', name: 'Expected'},
  {id: 'mandatory', name: 'Mandatory'}
];

// const getNecessityIndex = (necessity) => necessities.filter(nec => nec.id === necessity)[0].name;

const getNecessityIndex = (necessity) => {
  const index = necessities.map(nec => nec.id).indexOf(necessity);
  //const x = necessities.filter(nec => nec.id === necessity)[0].name;
  //console.log(`{getNecessityIndex} necessity: ${necessity}, index:`, index)
  return index;
}

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#0072ea',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

/**
 * See: Rating component: https://mui.com/material-ui/react-rating/
 * @param props
 * @returns {*[]}
 * @constructor
 */
const NecessityField = ({necessity}) => {
  const record = useRecordContext();
  //let starsArray = [];
  //console.log(`{NecessityField} record:`, record);
  const nec = necessity ? necessity : (record ? record.necessity : '');
  const numStars = getNecessityIndex(nec);  // 0 - 2
  //console.log(`{NecessityField} necessity: ${nec}, numStars:`, numStars);
  //const colors = ['#0072ea', 'orange', 'red'];
  // for (let i = 0; i <= numStars; i++) {
  //   starsArray.push(<FiberManualRecordIcon
  //     key={i}
  //     fontSize={'inherit'}
  //     style={{fill: colors[numStars]}}
  //   />)
  // }
  // return starsArray;
  return (
    // <StyledRating name="read-only" value={numStars + 1} readOnly />
  <StyledRating
    name="read-only-necessity"
    value={numStars + 1}
    readOnly
    icon={<FiberManualRecordIcon fontSize="inherit" />}
    emptyIcon={<FiberManualRecordOutlinedIcon fontSize="inherit" />}
    max={3}
  />
  )
}

export {
  necessities,
  getNecessityIndex,
  NecessityField
}