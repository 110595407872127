import React from 'react';
import {useLocation, useParams} from "react-router-dom";
import {
  TabbedForm, FormTab,
  TextInput, NumberInput, SelectInput,
  ArrayInput, SimpleFormIterator,
  SaveButton, Toolbar, TopToolbar, DeleteWithConfirmButton,
  useRecordContext,
  useResourceContext,
  usePermissions,
  useEditController,
  Loading,
  required,
  EditView,
  EditContextProvider,
  useGetList
} from 'react-admin';
import {makeStyles} from '@mui/styles';
import filter from 'lodash/filter';
import log from 'loglevel';
import {DetailTitle} from "../components/InputFields";
import {Breadcrumbs} from "../breadcrumbs";
import {
  getTabName,
  queryParamsToObject,
  queryParamsToFilterString
} from "../util/paramUtil";
import {UnShowButtonWithFilter} from "../button/UnShowButtonWithFilter";
import {Activity, isAuthorized} from "../auth/authorization";
import {isValidTabName} from "../components/EditActions";
import UnHelpButton from "../button/UnHelpButton";

/**
 * react-data-grid
 * Example:
 * https://gitlab.com/ABOS-Software/abos-client/blob/6631e0078c17fad5eb3fd0f3a01c628e37b71fe7/src/resources/Products/ProductsGrid.js
 */

const useStyles = makeStyles(() => ({
  form: { padding: 0 },
  wideField: {width: '40em'},
  narrowField: {width: '5em'},
  formGroup: {display: 'inline-block', marginRight: '0.5em'},
}));

/**
 * Show top toolbar containing Detail button to display dictionary entry page.
 * @param filter
 * @param tabName
 * @returns {JSX.Element}
 * @constructor
 */
const EntryEditActions = ({filter, tabName}) => {
  const adjTabName = isValidTabName(tabName) ? tabName : '';
  return (
    <TopToolbar>
      <UnShowButtonWithFilter
        filter={filter}
        tabName={adjTabName}
      />
      <UnHelpButton helpPage={'/Configuration/names-and-aliases'}/>
    </TopToolbar>
  );
};

/**
 * Save and Delete buttons at bottom of dictionary entry form.
 * @param props
 * @returns {*}
 * @constructor
 */
const EntryEditToolbar = ({filter}) => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const {dictId, dictName} = filter;

  // Display entry list page after delete
  const redirect = () => `${resource}?${queryParamsToFilterString({dictId, dictName})}`;

  return (
    <Toolbar>
      <SaveButton/>
      <DeleteWithConfirmButton confirmTitle={`Delete ${filter.name}?`}
                               confirmContent={'Confirm to delete this dictionary entry'}
                               record={record}
                               redirect={redirect}
      />
    </Toolbar>
  )
};

/**
 * Dictionary Entry edit page with tabs: main entry and aliases (constraints to come later).
 * Uses meta property in useEditController to pass extra dictId to server query.
 * Uses custom delete button to handle extra dictId parameter.
 *
 * Mnemonic for the two functions:
 * parse(): display -> record
 * format(): record -> display
 *
 * Note: We cannot allow dictionary to be changed until we add a backend function that deletes the entry from
 * one dictionary and adds it to another, since it is a subdocument.
 */
export const DictionaryEntryEdit = () => {
  const location = useLocation();
  const resource = useResourceContext();
  const {permissions} = usePermissions();
  const {id} = useParams();
  const classes = useStyles();
  const args = queryParamsToObject(location);  // Renamed from queryParamFilter & is good - creates args object 14-Dec-22
  if (args.id) delete args.id;
  const {dictId} = args;
  log.debug(`{DictionaryEntryEdit} args:`, args);

  // Show detail page after save
  const redirectPath = ({filter}) => {
    const locationTabName = getTabName(location);
    return `/${resource}/${id}/show` + (isValidTabName(locationTabName) ?
      `/${locationTabName}?${queryParamsToFilterString(filter)}` :
      `?${queryParamsToFilterString(filter)}`);
  }

  const editContext = useEditController({
    resource,
    id,
    queryOptions: {meta: {dictId}},
    redirect: redirectPath({filter: {dictId}}),
    mutationMode: 'optimistic' // added as default 'undoable' was not updating target detail page with new data
  });
  log.debug(`{DictionaryEntryEdit} editContext:`, editContext);

  const {isLoading: isTypesLoading, data: types} = useGetList(
    'types',
    {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}
  });

  if (!isAuthorized(permissions, Activity.DICTIONARY)) {
    return (<div/>);
  }
  const {record, isLoading} = editContext;
  if (isTypesLoading || isLoading) return <Loading/>;
  const parent = 'dictionaries';
  const {dictName} = args;
  const {name} = record;
  const breadcrumbs = [
    {url: `/${parent}`, label: parent, resource: parent},
    {url: `/${parent}/${dictId}/show?${queryParamsToFilterString({name: dictName})}`, title: dictName, resource: parent},
    {url: `/${resource}?${queryParamsToFilterString({dictId, dictName})}`, label: resource, resource},
    {url: ``, title: name, resource}, // Leaf node for current page has no link
  ];
  record.type = record.type ? record.type.toLowerCase() : '';
  const currentType = filter(types, {name: record.type})[0];
  log.info(`{DictionaryEntryEdit} id: ${id}, name: ${name}, dictId: ${dictId}, dictName: ${dictName}, args:`, args);
  const constraints = currentType ?
    currentType.constraints.map(c => ({id: c, name: c})) :
    [];
  return (
    <EditContextProvider value={editContext}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <EditView
        title={<DetailTitle/>}
        actions={<EntryEditActions filter={{dictId}} tabName={getTabName(location)}/>}>
        <TabbedForm toolbar={<EntryEditToolbar filter={{name, ...args}} />}>
          <FormTab label="entry">
            <TextInput source='name' label='Entry name' fullWidth/>
            <TextInput source='code'/>
            <SelectInput source='type' choices={types ? types: []} validate={[required()]}/>
            {/*<NumberInput source='weight' parse={(value) => value ? value : 100}/>*/}
            <TextInput source={'notes'} label="Description" multiline={true} rows={7} fullWidth/>
          </FormTab>

          <FormTab label="aliases" path={"aliases"}>
            <ArrayInput source="aliases" label=''>
              <SimpleFormIterator>
                <TextInput source='alias' className={classes.wideField} formClassName={classes.formGroup} />
                <NumberInput source='weight'  className={classes.narrowField} formClassName={classes.formGroup} />
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>

          <FormTab label="constraints" path={"constraints"}>
            <ArrayInput source="constraints" label=''>
              <SimpleFormIterator>
                <SelectInput source="name" choices={constraints} className={classes.wideField} formClassName={classes.formGroup} />
                {/*<TextInput source='name' className={classes.alias} formClassName={classes.aliasFormGroup} />*/}
                <TextInput source='value' className={classes.wideField} formClassName={classes.formGroup} />
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>

          <FormTab label="values" path={"values"}>
            <ArrayInput source="values" label=''>
              <SimpleFormIterator>
                <TextInput source='value' className={classes.wideField} formClassName={classes.formGroup} />
                <TextInput source='alternates' className={classes.wideField} formClassName={classes.formGroup} />
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
        </TabbedForm>
      </EditView>
    </EditContextProvider>
 )
};

export default DictionaryEntryEdit;