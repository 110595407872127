import {
  downloadCSV
} from 'react-admin';
import {unparse as convertToCSV} from 'papaparse/papaparse.min';
import {exportFileName} from '../EmployeeUtil';

const exportEmployeesWestfield = ees => {
  console.log("{exportEmployeesNormal} ees:", ees);
  const fileName = exportFileName(ees);
  console.log("{exportEmployeesNormal} fileName:", fileName);
  const eesForExport = ees.map(ee => {
    let eeForExport = {};
    console.log("{exportEmployeesNormal} ee:", ee);
    eeForExport['Forename'] = ee.employee.first_name; // add a field
    eeForExport['Surname'] = ee.employee.last_name;
    eeForExport['Gender'] = ee.employee.gender && ee.employee.gender.substring(0,1).toUpperCase();
  // .map(function truncate(value, entries) {
  //     return value && value.substring(0,1).toUpperCase();
  //   })
    eeForExport['Date of Birth'] = ee.employee.dob ? new Date(ee.employee.dob).toLocaleDateString() : '';
    eeForExport['Home address 1'] = ee.employee.street1;
    eeForExport['Home address 2'] = ee.employee.street2;
    eeForExport['Home address 3'] = ee.employee.city;
    eeForExport['Home postcode'] = ee.employee.postcode;
    eeForExport['Level of Cover'] = 'F2+DL';
    eeForExport['Join Date'] = ee.start_date ? new Date(ee.start_date).toLocaleDateString() : '';
    eeForExport['Leave Date'] = ee.end_date ? new Date(ee.end_date).toLocaleDateString() : '';
    return eeForExport;
  });
  const csv = convertToCSV({
    data: eesForExport,
    // Order fields in the export
    fields: ['Forename', 'Surname', 'Gender', 'Date of Birth',
      'Home address 1', 'Home address 2','Home address 3', 'Home postcode',
      'Level of Cover',
      'Join Date', 'Leave Date']
  });
  downloadCSV(csv, fileName); // download as 'ees.csv` file
};

export default exportEmployeesWestfield;