import React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import {
  useTranslate,
  //useLocale, useSetLocale,
  useTheme, Title,
  useLocaleState
} from 'react-admin';
//import { makeStyles } from '@mui/styles';
import {darkTheme, lightTheme, plainTheme} from '../layout/themes';
//import {ListTitle} from "../components/InputFields";
import {useSystemName} from "../components/InputFields";
//import { changeTheme } from './actions';

// const useStyles = makeStyles({
//   label: { width: '10em', display: 'inline-block' },
//   button: { margin: '1em' },
// });

// TODO: Check use of react context in:
//  https://stackoverflow.com/questions/63961087/how-to-get-theme-from-store-and-switch-the-app-theme-on-react-admin

const Configuration = () => {
  const translate = useTranslate();
  const [locale, setLocale] = useLocaleState();
  const [theme, setTheme] = useTheme();
  const systemName = useSystemName();
  console.log(`{Configuration} systemName:`, systemName);
  //const classes = useStyles();
  //console.log(`{Configuration} translated pos.theme.dark:`, translate('pos.theme.dark'));
  //const theme = useSelector(state => state.theme);  // useSelector won't return anything v4
  console.log(`{Configuration} locale:`, locale);
  console.log(`{Configuration} theme:`, theme);
  return (
    <Card>
      <Title title={`Configuration ${systemName}`} />
      {/*<Title title={translate('pos.configuration')} />*/}
      <CardContent>
        <Box sx={{width: '10em', display: 'inline-block'}}>
          {translate('pos.theme.name')}
        </Box>
        <Button
          variant="contained"
          sx={{margin: '1em'}}
          color={
            theme.name === 'light'
              ? 'primary'
              : 'secondary'
          }
          onClick={() => setTheme(lightTheme)}
        >
          {translate('pos.theme.light')}
        </Button>

        <Button
          variant="contained"
          sx={{margin: '1em'}}
          color={
            theme.name === 'dark'
              ? 'primary'
              : 'secondary'
          }
          onClick={() => setTheme(darkTheme)}
        >
          {translate('pos.theme.dark')}
        </Button>

        <Button
          variant="contained"
          sx={{margin: '1em'}}
          color={
            theme.name === 'plain'
              ? 'primary'
              : 'secondary'
          }
          onClick={() => setTheme(plainTheme)}
        >
          {translate('pos.theme.plain')}
        </Button>
      </CardContent>

      <CardContent>
        <Box sx={{width: '10em', display: 'inline-block'}}>
          {translate('pos.language')}
        </Box>
        <Button
          variant="contained"
          sx={{margin: '1em'}}
          color={
            locale === 'en'
              ? 'primary'
              : 'secondary'
          }
          //color={'primary'}
          // color={locale === 'en' ? 'primary' : 'default'}
          onClick={() => {
            setLocale('en');
            setTheme(theme);  // Hack to try and get title to redraw
          }}
        >
          en
        </Button>
        <Button
          variant="contained"
          sx={{margin: '1em'}}
          // color={locale === 'fr' ? 'primary' : 'default'}
          color={
            locale === 'fr'
              ? 'primary'
              : 'secondary'
          }
          onClick={() => {
            setLocale('fr');
            setTheme(theme);
          }}
        >
          fr
        </Button>
        {/*<Button*/}
        {/*  variant="contained"*/}
        {/*  sx={{margin: '1em'}}*/}
        {/*  color={locale === 'de' ? 'primary' : 'default'}*/}
        {/*  onClick={() => setLocale('de')}*/}
        {/*>*/}
        {/*  de*/}
        {/*</Button>*/}
      </CardContent>
    </Card>
  );
};

export default Configuration;
