import {
  Button, useDelete, useRecordContext,
  useNotify, useResourceContext
} from "react-admin";
import log from "loglevel";
import ActionDelete from "@mui/icons-material/Delete";
import React from "react";

// refresh={refresh} redirect={redirect}
const DeletePermissionButton = (props) => {
  const record = useRecordContext();
  const resource = useResourceContext();
  //const refresh = useRefresh();
  const notify = useNotify();
  const [deletePermission, {isLoading}] = useDelete();
  log.debug("{DeletePermissionButton} props:", props);
  const {
    user_id,
    icon = <ActionDelete/>,
    queryParam,
    label
  } = props;

  // Server API: /admin/users/{user_id}/jurisdictions/{role_name}:
  const handleDelete = () => {
    log.debug(`{handleDelete} user_id: ${user_id}, record:`, record, {});
    log.debug("{handleDelete} resource:", resource);
    deletePermission(
      resource, // "permissions"
      {
        id: record._id,
        meta: {user_id, role_name: record._id}
      },
      // {id: record._id, user_id: userId, query: {user_id: userId, role_name: record._id}}
      {
        onSuccess: () => { // data
          //refresh();
          notify('Permission revoked');
        },
        onError: (error) => {
          notify(`Permission revoke error: ${error.message}`, {type: 'error'});
        },
      }
    );
    log.debug(`{handleDelete} Called deletePermission - queryParam: ${queryParam}`);
    ///permissions?filter={"user_id":"6272b869217edf5f35225c88","user_name":"Hillary%20Anderson"}
    //redirect(`/permissions/list?user_id=${userId}&user_name=${userName}`); // TODO: How to refresh?
    //redirect(`/permissions/list?user_id=${userId}&user_name=${userName}`);

    // setTimeout(() => {  // Hack to prevent user service timing sequence issue
    //   console.log("Delayed for 3s");
    // }, "3000");
    // refresh();
  };

  // const [deletePermission, {isLoading}] = useDelete(
  //   resource, // "permissions"
  //   {id: record._id, user_id: userId, query: {user_id: userId, role_name: record._id}}
  // );
  return <Button label={label} onClick={handleDelete} disabled={isLoading}>
    {icon}
  </Button>
}

export default DeletePermissionButton;

