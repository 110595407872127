import React from 'react';
import {
  TabbedForm, FormTab,
  SimpleFormIterator, ArrayInput,
  TopToolbar,
  TextInput, DateInput,
  EditView,
  FunctionField,
  SelectInput,
  useGetList,
  Loading,
  EditContextProvider,
  useResourceContext,
  useEditController
} from 'react-admin';
import {makeStyles} from "@mui/styles";
//import log from 'loglevel';
import {Breadcrumbs} from "../breadcrumbs";
import {DetailTitle, UnEditToolbar} from "../components/InputFields";
import {getTabName, queryParamsToFilterString, queryParamsToObject} from "../util/paramUtil";
import {UnShowButtonWithFilter} from "../button/UnShowButtonWithFilter";
// import DropPanel from "../components/DropPanel";
// import useRateTable from "./rateTable";
import {useLocation, useParams} from "react-router-dom";
import {isValidTabName} from "../components/EditActions";

const useStyles = makeStyles((theme) => ({
  form: { padding: 0 },
  wideField: {width: '100%'},
  narrowField: {width: '8m', marginRight: '0.5em'},
  normalField: {width: '20m', marginRight: '0.5em'},
  formGroup: {display: 'inline-block', marginRight: '0.5em'},
  formGroupUnder: {marginRight: '0.5em'},

  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const ContractEditActions = ({filter, tabName}) => {
  const adjTabName = isValidTabName(tabName) ? tabName : '';
  return (
    <TopToolbar>
      <UnShowButtonWithFilter filter={filter} tabName={adjTabName}/>
    </TopToolbar>
  );
}

const types = [
  {id: 's', name: 'String'},
  {id: 'n', name: 'Number'},
  {id: 'd', name: 'Date'}
];

// const AttributesTab = ({label, classes}) => (
//   <FormTab label={label} path={label}>
//     <ArrayInput source="attributesBasis" label=''>
//       <SimpleFormIterator>
//         <TextInput source='name'
//                    className={classes.normalField}
//                    formClassName={classes.formGroup}
//         />
//         <TextInput source='value'
//                    className={classes.normalField}
//                    formClassName={classes.formGroup}
//         />
//         <SelectInput source='type'
//                      className={classes.narrowField}
//                      formClassName={classes.formGroup}
//                      choices={types}
//         />
//       </SimpleFormIterator>
//     </ArrayInput>
//   </FormTab>
// )

/**
 * Sets up employer_id to support nested URL to:
 * PUT /employers/{employer_id}/contracts/{id}
 * @param employer_id
 * @param employer_name
 * @returns {string}
 */
// const redirect = (employer_id, employer_name) => { // basePath, id, data
//   log.debug("{ContractEdit.redirect} employer_id:", employer_id);
//   const filterObj = {
//     employer_id: employer_id,
//     employer_name: employer_name
//   };
//   //return `/employers/${employer_id}/contracts?`; //filter=${JSON.stringify(filterObj)}`;
//   return `/contracts?filter=${JSON.stringify(filterObj)}`;
// };

export const ContractEdit = () => {
  const location = useLocation();
  const resource = useResourceContext();
  const {id} = useParams();
  const classes = useStyles();

  const {isLoading: isBenefitsLoading, data: allBenefits} = useGetList(
   'benefits',
    {pagination: {perPage: 100}, sort: {field: 'name', order: 'ASC'}}
  );

  // Signature resource, id, data
  const redirectPath = () => {
    const locationTabName = getTabName(location);
    return isValidTabName(locationTabName) ?
      `${resource}/${id}/show/${locationTabName}?${queryParamsToFilterString({employer_id, employer_name})}` :
      `${resource}/${id}/show?${queryParamsToFilterString({employer_id, employer_name})}`;
  }

  const {employer_id, employer_name} = queryParamsToObject(location);
  console.log(`{ContractEdit} contractId: ${id}`);
  //const rateTable = useRateTable(employer_id, id);
  const editContext = useEditController({
    resource,
    id,
    queryOptions: {meta: {employer_id}},
    mutationMode: "pessimistic",  // Subsequent redirected detail page is stale without this
    redirect: redirectPath  // Was redirect={redirect(employer_id, employer_name)}
  });
  const {record, isLoading} = editContext;
  console.log(`{ContractEdit} employer_id: ${employer_id}, employer_name: ${employer_name}, isLoading: ${isLoading}`);
  if (isLoading || isBenefitsLoading) return <Loading />;
  record.employer_id = employer_id;
  console.log(`{ContractEdit} allBenefits:`, allBenefits);
  const benefits = allBenefits ? allBenefits.map(b => ({id: b._id, name: b.name})) : [];
  console.log(`{ContractEdit} record:`, record);

  const parent = 'employers';
  const breadcrumbs = [
    {url: `/${parent}`, label: parent, resource: parent},
    {url: `/${parent}/${employer_id}/show?${queryParamsToFilterString({name: employer_name})}`, title: employer_name, resource: parent},
    {url: `/${resource}?${queryParamsToFilterString({employer_id, employer_name})}`, label: resource, resource},
    {url: ``, title: record.benefit_uname, resource},
  ];
  return (
    <EditContextProvider value={editContext}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <EditView
        title={<DetailTitle titleField={'benefit_uname'}/>}
        actions={<ContractEditActions filter={{employer_id, employer_name}} tabName={getTabName(location)}/>}
      >
        <TabbedForm toolbar={<UnEditToolbar/>}>
          <FormTab label="Contract">
            <SelectInput source="benefit_id" choices={benefits} fullWidth/>
            <TextInput source="benefit_version" fullWidth/>
            <TextInput source="rates_version" fullWidth/>
            {/*<TextInput source="rates_name"/>*/}
            <TextInput source="policy_number" fullWidth/>
            <DateInput source="start_date"/>
            <DateInput source="end_date"/>
            <DateInput source="creation_date"/>
            <FunctionField label="employer_id" render={() => employer_id}/>
          </FormTab>

          {/*<AttributesTab label="basis" classes={classes}/>*/}
          <FormTab label="basis" path={"basis"}>
            <ArrayInput source="attributesBasis" label=''>
              <SimpleFormIterator>
                <TextInput source='name'
                           className={classes.normalField}
                           formClassName={classes.formGroup}
                />
                <TextInput source='value'
                           className={classes.normalField}
                           formClassName={classes.formGroup}
                />
                <SelectInput source='type'
                             className={classes.narrowField}
                             formClassName={classes.formGroup}
                             choices={types}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>

          <FormTab label="other" path="other">
            <ArrayInput source="attributesRate" label=''>
              <SimpleFormIterator>
                <TextInput source='name'
                           className={classes.normalField}
                           formClassName={classes.formGroup}
                />
                <TextInput source='value'
                           className={classes.normalField}
                           formClassName={classes.formGroup}
                />
                <SelectInput source='type'
                             className={classes.narrowField}
                             formClassName={classes.formGroup}
                             choices={types}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>

          {/*<FormTab label="Rate Table" path="rateTable">*/}
          {/*  <DropPanel*/}
          {/*    // templateId={templateId} templateName={templateName}*/}
          {/*    title={'Drag & drop file for fields'}*/}
          {/*    onload={(props) => rateTable(props)}*/}
          {/*    // dictionaryIds={dictionaryIds}*/}
          {/*    resource={resource}/>*/}
          {/*</FormTab>*/}

          {/*<FormTab label="Legacy Contract" path="legacy contract">*/}
          {/*  <TextInput source="benefit_uname"/>*/}
          {/*  <TextInput source="benefit_version"/>*/}
          {/*  <TextInput source="rates_version"/>*/}
          {/*  /!*<TextInput source="rates_name"/>*!/*/}
          {/*  <TextInput source="policy_number"/>*/}
          {/*  <DateInput source="start_date"/>*/}
          {/*  <DateInput source="end_date"/>*/}
          {/*  <DateInput source="creation_date"/>*/}
          {/*  <FunctionField label="employer_id" render={() => employer_id}/>*/}
          {/*</FormTab>*/}
        </TabbedForm>
      </EditView>
    </EditContextProvider>
  );
};

export default ContractEdit;