import React from 'react';
import {Link} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import GridList from "@mui/material/GridList";
// import GridListTile from '@mui/material/GridListTile';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Dictionaries from "../dictionaries";
import Templates from "../templates";
import Untanglements from "../untanglements";
import {Activity, isAuthorized} from '../auth/authorization';

// import {Responsive} from 'react-admin';
// import DictionaryEntries from "../dictionaryentries";
//import UntanglementsPerUser from './UntanglementsPerUser';
// import {Responsive} from 'react-admin';
// import {BarUsage} from './nivo/UsageCharts';
//import {makeStyles} from "@mui/material";
// import Card from "../layout/Login";  // LineUsage
// import UntanglementsPerUser from './UntanglementsPerUser';

//import DailyUntanglements from './DailyUntanglements';
// import NbNewUntanglements from './NbNewUntanglements';
//import {BarUsage, LineUsage} from './chartUsage';
//import {BarUsage} from './nivo/UsageCharts';
//import Card from "../layout/Login";  // LineUsage
//import CardIcon from "./NbNewUsers";
//import PersonIcon from "@mui/material/SvgIcon/SvgIcon";

// const styles = {
//   flex: {display: 'flex'},
//   flexColumn: {display: 'flex', flexDirection: 'column'},
//   leftCol: {flex: 1, marginRight: '1em', maxWidth: '600px'},
//   rightCol: {flex: 1, marginLeft: '1em'},
//   singleCol: {marginTop: '2em', marginBottom: '2em'}
// };

// const userNames = [
//   'Reid Altenwerth',
//   'Kelton Bahringer',
//   'Madelyn Bailey',
//   'Mia Bayer',
//   'Kiana Beier',
//   'Emanuel Bernhard',
//   // 'Pete Boehm',
//   // 'Reanna Borer',
//   // 'Trevor Doyle',
//   // 'Hazel Fahey',
//   // 'Norma Williamson',
//   // 'Barry Zboncak'
// ];

//https://nivo.rocks/storybook/?path=/story/bar--stacked-horizontal

// Use this when the back end is ready

const useStyles = makeStyles({
  // flex: {display: 'flex'},
  // flexColumn: {display: 'flex', flexDirection: 'column'},
  // leftCol: {flex: 1, marginRight: '1em', maxWidth: '600px'},
  // rightCol: {flex: 1, marginLeft: '1em'},
  // singleCol: {marginTop: '2em', marginBottom: '2em'},

  root: {
    minWidth: 200,
    maxWidth: 275
  },
  // bullet: {
  //   display: 'inline-block',
  //   margin: '0 2px',
  //   transform: 'scale(0.8)',
  // },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function OutlinedCard(props) {
  const {title, detail, url, icon} = props;
  const classes = useStyles();
  //const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        {/*<Typography className={classes.title} color="textSecondary" gutterBottom>*/}
        {/*  Dictionaries*/}
        {/*</Typography>*/}
        <Typography variant="h5" component="h2">
          {icon} {title}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {detail}
        </Typography>
        {/*<Typography variant="body2" component="p">*/}
        {/*  well meaning and kindly.*/}
        {/*</Typography>*/}
      </CardContent>
      <CardActions>
        <Button to={`${url}`} size="small" component={Link} variant="contained" >
          View
        </Button>
        {/*<ListButton*/}
        {/*  label={`resources.${title}.name_many`} color="primary"*/}
        {/*  pathname={`/${title}`}*/}
        {/*/>*/}
      </CardActions>
    </Card>
  );
}


const Dashboard = ({permissions}) => {
  //const classes = useStyles();
  const titles = ['Dictionaries', 'Templates', 'Conversions'];
  const details = [
    'Look up entries in dictionaries',
    'Define fields in templates',
    'Untangler conversions & analyses'
  ];
  const urls = [
    '/dictionaries',
    '/templates',
    '/untanglements'
  ];
  const icons = [
    <Dictionaries.ICON/>,
    <Templates.ICON/>,
    <Untanglements.ICON/>
  ];
  if (!permissions) return (<div></div>);
  if (isAuthorized(permissions, Activity.DICTIONARY_READ)) {
    return (
      <ImageList cols={3}>
        {titles.map((title, i) => {
          return (
            <ImageListItem key={i}>
              <OutlinedCard title={title} detail={details[i]} url={urls[i]} icon={icons[i]}/>
            </ImageListItem>
          )
        })}
      </ImageList>
    )
  }
  else return (<div></div>);
};
  // return (
  //   <Responsive
  //     xsmall={
  //       <div>
  //         <div style={classes.flexColumn}>
  //           {/*<div style={{ marginBottom: '2em' }}>*/}
  //             {/*<LineUsage/>*/}
  //           {/*</div>*/}
  //           <div style={classes.flex}>
  //             {/*<UntanglementsPerUser value={<BarUsage userNames={userNames}/>}/>*/}
  //           </div>
  //         </div>
  //       </div>
  //     }
  //     small={
  //       <div style={classes.flexColumn}>
  //         {/*<div style={styles.singleCol}>*/}
  //           {/*<LineUsage/>*/}
  //         {/*</div>*/}
  //         <div style={classes.flex}>
  //           {/*<UntanglementsPerUser value={<BarUsage userNames={userNames}/>}/>*/}
  //         </div>
  //       </div>
  //     }
  //     medium={
  //       <div style={classes.flex}>
  //         <div style={classes.leftCol}>
  //           OutlinedCard();
  //           {/*<UntanglementsPerUser value={<BarUsage userNames={userNames} />}/>*/}
  //         </div>
  //         {/*<div style={classes.rightCol}>*/}
  //           {/*<DailyUntanglements value={<LineUsage/>} />*/}
  //         {/*</div>*/}
  //       </div>
  //     }
  //   />
  // );
//};

// const useStyles = makeStyles(theme => ({
//   title: {
//     marginTop: '8em',
//     //marginLeft: '6em',
//     display: 'flex',
//     justifyContent: 'center',
//     color: theme.palette.grey[500],
//   }
// }));

// Blank
// const Dashboard = () => {
//   const classes = useStyles();
//   return (
//       <div className={classes.title}>
//         Untangl Administration&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//       </div>
//   );
// };


//   constructor(props) {
//     super(props);
//     console.log("{constructor} ...");
//     dataProviderFactory(
//       'rest'  //process.env.REACT_APP_DATA_PROVIDER
//     )
//     .then((p) => {
//       this.dataProvider = p;
//       console.log("{constructor} this.dataProvider:", this.dataProvider);
//       this.setState({ status: 'provider ready' });
//     });
//   //  this.state = {};
//   }
//
//   componentWillMount() {    // MSH: use constructor instead
//     // this.dataProvider = await dataProviderFactory(
//     //   'rest'  //process.env.REACT_APP_DATA_PROVIDER
//     // );
//     console.log("{componentWillMount} this.dataProvider:", this.dataProvider);
//   }
//
//   componentDidMount() {
//     console.log("{componentDidMount} before fetchData");
//     this.fetchData();
//   }
//
//   componentDidUpdate(prevProps) {
//     console.log("{componentDidUpdate} before fetchData");
//     // if (this.props.version !== prevProps.version) {
//     //   this.fetchData();
//     // }
//     this.fetchData();
//   }
//
//   fetchData() {
//     this.fetchUsers();
//     this.fetchUntanglements();
//   }
//
//   fetchUsers() {
//     //const { dataProvider } = this.props;
//     //console.log("{fetchUsers} dataProvider:", dataProvider);
//
//     if (!this.dataProvider) {
//       console.log("{fetchUsers} this.dataProvider not ready");
//       return null;
//     }
//     if (this.state.isNewUsersRunning) {
//       console.log("{fetchUsers} already running");
//       return null;
//     }
//     this.setState({ isNewUsersRunning: true });
//     console.log("{fetchUsers} about to call api");
//     const aMonthAgo = new Date();
//     aMonthAgo.setDate(aMonthAgo.getDate() - 30);
//
//     return this.dataProvider(GET_LIST, 'users', {
//       filter: { verifyDate: aMonthAgo.toISOString() },
//       sort: { field: 'verifyDate', order: 'DESC' },
//       pagination: { page: 1, perPage: 1000 },
//     })
//     .then(response => response.data)
//     .then(newUsers => {
//       console.log("{fetchUsers} setState newUsers:", newUsers);
//       this.setState({ newUsers });
//       this.setState({
//         nbNewUsers: newUsers.reduce(nb => ++nb, 0),
//       });
//     });
//   }
//
//   fetchUntanglements() {
//     //const { dataProvider } = this.props;
//
//     if (!this.dataProvider) {
//       console.log("{fetchUntanglements} this.dataProvider not ready");
//       return null;
//     }
//     if (this.state.isNewUntanglementsRunning) {
//       console.log("{fetchUntanglements} already running");
//       return null;
//     }
//     this.setState({ isNewUntanglementsRunning: true });
//     const aMonthAgo = new Date();
//     aMonthAgo.setDate(aMonthAgo.getDate() - 30);
//     let fromDate = moment(aMonthAgo).format("YYYY-MM-DD");
//     console.log("{fetchUntanglements} fromDate:", fromDate);
//     return this.dataProvider(GET_LIST, 'untanglements', {
//       filter: { createdatestart: fromDate },
//       sort: { field: 'createdatestart', order: 'DESC' },
//       pagination: { page: 1, perPage: 2000 },
//     })
//     .then(response => response.data)
//     .then(newUntanglements => {
//       console.log("{fetchUntanglements} newUntanglements.length:", newUntanglements.length);
//       this.setState({ newUntanglements });
//       this.setState({
//         nbNewUntanglements: newUntanglements.reduce(nb => ++nb, 0)
//       });
//     });
//   }
//
//   render() {
//   //  return null;
//     //const { dataProvider } = this.state;
//     if (!this.dataProvider) {
//       console.log("{render} this.dataProvider:", this.dataProvider);
//       return (
//         <div className="loader-container">
//           <div className="loader">Loading dashboard...</div>
//         </div>
//       );
//     }
//
//     const {
//       nbNewUsers,
//       nbNewUntanglements
//     } = this.state;
//     return (
//       {/*<Responsive*/}
//         {/*xsmall={*/}
//           {/*<div>*/}
//             {/*<div style={styles.flexColumn}>*/}
//               {/*<div style={{ marginBottom: '2em' }}>*/}
//                 {/*<NbNewUntanglements value={nbNewUntanglements}/>*/}
//               {/*</div>*/}
//               {/*<div style={styles.flex}>*/}
//                 {/*<NbNewUsers value={nbNewUsers}/>*/}
//               {/*</div>*/}
//             {/*</div>*/}
//           {/*</div>*/}
//         {/*}*/}
//         {/*small={*/}
//           {/*<div style={styles.flexColumn}>*/}
//             {/*<div style={styles.singleCol}>*/}
//               {/*<NbNewUntanglements value={nbNewUntanglements}/>*/}
//             {/*</div>*/}
//             {/*<div style={styles.flex}>*/}
//               {/*<NbNewUsers value={nbNewUsers}/>*/}
//             {/*</div>*/}
//           {/*</div>*/}
//         {/*}*/}
//         {/*medium={*/}
//           {/*<div style={styles.flex}>*/}
//               {/*<div style={styles.leftCol}>*/}
//                 {/*<NbNewUntanglements value={nbNewUntanglements}/>*/}
//               {/*</div>*/}
//               {/*<div style={styles.rightCol}>*/}
//                 {/*<NbNewUsers value={nbNewUsers}/>*/}
//               {/*</div>*/}
//           {/*</div>*/}
//         {/*}*/}
//       {/*/>*/}
//     );
//   }
// }

export default Dashboard;