import {
  downloadCSV
} from 'react-admin';
import {unparse as convertToCSV} from 'papaparse/papaparse.min';
import {
  exportFileName,
  employerMonthlyCost,
  unadjustedMonthlyCost
} from '../EmployeeUtil';

const exportEmployeesCanadaLife = ees => {
  console.log("{exportEmployeesCanadaLife} v2 ees:", ees);
  const fileName = exportFileName(ees);
  console.log("{exportEmployeesCanadaLife} fileName:", fileName);
  const benefit_uname = 'canada_life';
  const eesForExport = ees.map(ee => {
    let eeForExport = {};
    console.log("{exportEmployeesCanadaLife} ee:", ee);
    eeForExport['Employee ID'] = ee.id;
    eeForExport['Surname'] = ee.employee.last_name;
    eeForExport['First Name'] = ee.employee.first_name; // add a field
    eeForExport['Gender'] = ee.employee.gender;
    eeForExport['Date of Birth'] = ee.employee.dob ? new Date(ee.employee.dob).toLocaleDateString() : '';
    eeForExport['Select Fixed benefit'] = `100000`;
    eeForExport['Business / Workplace Postcode'] = ee.employer.postcode;
    eeForExport['Join Date'] = ee.start_date ? new Date(ee.start_date).toLocaleDateString() : '';
    eeForExport['Leave Date'] = ee.end_date ? new Date(ee.end_date).toLocaleDateString() : '';
    eeForExport['Employer Monthly Cost'] = employerMonthlyCost(ee, benefit_uname);
    eeForExport['Unadjusted Monthly Cost'] = unadjustedMonthlyCost(ee, benefit_uname);
    return eeForExport;
  });
  const csv = convertToCSV({
    data: eesForExport,
    // Order fields in the export
    fields: ['Employee ID', 'Surname', 'First Name', 'Gender', 'Date of Birth', 'Select Fixed benefit',
      'Business / Workplace Postcode', 'Join Date', 'Leave Date',
      'Employer Monthly Cost', 'Unadjusted Monthly Cost']
  });
  downloadCSV(csv, fileName); // download as 'ees.csv` file
};

export default exportEmployeesCanadaLife;