import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput
} from 'react-admin';
import {BreadcrumbsCreate} from "../breadcrumbs";
import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePassword
} from "./UserValidate";
import {CreateTitle} from "../components/InputFields";

/**
 * Create a new user with default unregistered role.
 * The server side (porter-admin) uses the email address to populate the user name.
 *
 * ReferenceInput: source is reps uname in this user record. Key in reps table assumed to be id, so we specify
 * optionValue in SelectInput to use uname instead.
 *
 * @returns {*}
 * @constructor
 */
const UserCreate = () => {
  // const {loaded: representativesLoaded, data: representatives} = useQueryWithStore({
  //   type: 'getList',
  //   resource: 'representatives',
  //   payload: {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}}
  // });
  // if (!representativesLoaded) return null;
  //const representativesList = representatives.map(r => ({id: r.uname, name: r.name}));
  return (
    <React.Fragment>
      <BreadcrumbsCreate/>
      <Create title={<CreateTitle />}>
        <SimpleForm>
          <TextInput source="first_name" validate={validateFirstName} fullWidth/>
          <TextInput source="last_name" validate={validateLastName} fullWidth/>
          <TextInput source="email" parse={v => v.toLowerCase()} validate={validateEmail} fullWidth/>
          <TextInput source="password" validate={validatePassword} fullWidth />
          {/*<ReferenceInput label="Application" source="rep_uname" reference="representatives"*/}
          {/*                sort={{ field: 'name', order: 'ASC' }} allowEmpty>*/}
          {/*  <SelectInput optionText="name" optionValue="uname"/>*/}
          {/*</ReferenceInput>*/}
        {/*  <SelectInput label="Application" source="rep_uname" choices={representativesList} allowEmpty fullWidth/>*/}
        </SimpleForm>
      </Create>
    </React.Fragment>
  )
};

export default UserCreate;