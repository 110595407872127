/**
 * Convert incoming b64 encoded file to a BLob
 * https://stackoverflow.com/questions/27980612/converting-base64-to-blob-in-javascript/27980815
 *
 * TODO: Each time you call createObjectURL(), a new object URL is created,
 * even if you've already created one for the same object.
 * Each of these must be released by calling URL.revokeObjectURL() when you no longer need them.
 * https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
 *
 * @param b64Data
 * @param contentType
 * @param sliceSize
 * @returns {Blob}
 */
const _b64toBlob = (b64Data, contentType = '') => {
  const byteChars = atob(b64Data);
  const ab = new ArrayBuffer(byteChars.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteChars.length; i++) {
    ia[i] = byteChars.charCodeAt(i);
  }
  return new Blob([ab], {type: contentType});
};


export default (xlsx, filename) => {
  //console.log(`{downloadXlsx} xlsx:`, xlsx);
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  //const blob = new Blob([xlsx], {type: mimeType});
  const blob = _b64toBlob(xlsx, mimeType);

  //console.log(`{downloadXlsx} blob:`, blob);
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Manage IE11+ & Edge
    window.navigator.msSaveOrOpenBlob(blob, `${filename}.xlsx`);
  } else {
    fakeLink.setAttribute('href', URL.createObjectURL(blob));
    fakeLink.setAttribute('download', `${filename}.xlsx`);
    fakeLink.click();

    // TODO: URL.revokeObjectURL(fakeLink.href);
  }
};