import React, {Fragment} from "react";
import {TextField, useRecordContext} from "react-admin";
import EntryLink from "./EntryLink";
import ExpressionEntryLink from "./ExpressionEntryLink";

const MultiSourceField = ({classes, dictNames}) => {
  const record = useRecordContext();
  const {source} = record;
  //console.log(`{MultiSourceField} source:`, source)
  //if (record && record.value) console.log(`{MultiSourceField} expression:`, record.value)
  if (source === "constant") {
    return (
      <Fragment>
        <TextField source={'value'}/>
        <span className={classes.source}>&nbsp;{'(Constant)'}</span>
      </Fragment>
    )
  }
  if (source === "code") {
    return (
      <Fragment>
        <EntryLink source="name" record={record} sortable={false} dictNames={dictNames}/>
        <span className={classes.source}>&nbsp;{'(Entry)'}</span>
      </Fragment>
    )
  }
  if (source === "calc") {
    return (
      <Fragment>
        <TextField source="name" />
        <span className={classes.source}>&nbsp;{'(Calculation)'}</span>
      </Fragment>
    )
  }
  if (source === "function") {
    return (
      <Fragment>
        <TextField source="name"/>
        <span className={classes.source}>&nbsp;{'(Function)'}</span>
      </Fragment>
    )
  }
  else
    return (
      <ExpressionEntryLink source="value"
                           label="Formula"
                           sortable={false}
                           record={record}
                           className={classes.expression}
                           dictNames={dictNames} />
    )
}

export {
  MultiSourceField
}