import React from 'react';
import {
  Datagrid,
  TextField,
  useListController,
  ListContextProvider,
  TopToolbar,
  CreateButton,
  ListView, FunctionField,
  Loading, usePermissions
} from 'react-admin';
import log from 'loglevel';
import {Breadcrumbs} from "../breadcrumbs";
import {defaultPerPage, UnPagination} from "../util/paramUtil";
import {Activity, isAuthorized} from "../auth/authorization";
import UnIconButton from "../button/UnIconButton";
import {TemplatesChip} from "../components/Chips";
import {ListTitle} from "../components/InputFields";

const RepresentativeActions = () => {
  const {permissions} = usePermissions();
  log.debug(`{RepresentativeList} permissions:`, permissions);
  if (isAuthorized(permissions, Activity.APPLICATION)) {  // superadmin
    return (
      <TopToolbar>
        <CreateButton />
      </TopToolbar>
    );
  }
  else return (<TopToolbar/>);
}

/**
 * loading, // boolean that is true on mount, and false once the data was fetched
 * TODO: bulkActionButtons cannot be used as no DELETE /representatives in org service
 * @returns {*}
 * @constructor
 */
const RepresentativeList = () => {
  // const resource = useResourceContext();
  const listContext = useListController({
    pagination: <UnPagination/>, 
    perPage: defaultPerPage,
    filter: {view: 'flatNested'},
    sort: {field: 'name', order: 'ASC'}
  });
  const {isLoading, page, data} = listContext;
  if (isLoading) return <Loading />;
  log.debug(`{RepresentativeList} isLoading: ${isLoading}, page: ${page}, data:`, data);
  return (
    <ListContextProvider value={listContext}>
      <Breadcrumbs/>
      <ListView
        sort={{field: 'name', order: 'ASC'}}
        pagination={<UnPagination/>}
        title={<ListTitle />}
        actions={<RepresentativeActions />}
       >
        <Datagrid bulkActionButtons={false}>
          <TextField source="name"/>
          {/*<TextField source="uname"/>*/}
          <TextField source="tel"/>

          <FunctionField label={"Templates"} render={
            record => record && record.templates && record.templates.length > 0 ?
              <TemplatesChip templates={record && record.templates}/> :
              ''
          }/>

          {/*<EmailField source="email"/>*/}
          {/*<UnShowButtonWithName record={record} resource={resource}/>*/}
          <UnIconButton label={'Detail'} />
        </Datagrid>
      </ListView>
    </ListContextProvider>
  );
};

export default RepresentativeList;