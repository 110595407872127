import * as React from "react";
import PropTypes from 'prop-types';
import {Link} from "react-admin";
import {queryParamString} from "../util/paramUtil";

// Useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();

const EntryLink = ({source, dictNames, record = {}}) => {
  //log.debug(`{EntryLink} source: ${source}, dictNames: ${dictNames}, record:`, record);
  const filter = {
    code: '^' + record.name,
    dicts: dictNames  // e.g. ['person', 'employment_cl']
  };
  //log.debug(`{EntryLink} filter:`, filter);
  const url = `/dictionaryredirect?${queryParamString(filter)}`;
  //log.debug(`{EntryLink} url:`, url);

  const id = record._id;
  if (record.source === 'code') {
    return (
      <Link key={id} to={url} onClick={stopPropagation}>
        <span>{record[source]}</span>
      </Link>
    );
  }
  else return (
      <span>{record[source]}</span>
  );
}

EntryLink.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default EntryLink;