//import { DataGrid } from '@mui/x-data-grid';
//TODO: Use Table instead of large DataDrid. See: https://mui.com/material-ui/react-table/
import {
  ArrayField,
  Datagrid,
  TextField,
  NumberField,
  useRecordContext
} from 'react-admin';
import React from "react";

/**
 * https://v4.mui.com/components/data-grid/#data-grid
 * https://medium.com/nerd-for-tech/how-to-implement-material-ui-data-grid-in-your-project-part-ii-e94d91bfea36
 * https://smartdevpreneur.com/the-ultimate-guide-to-customizing-material-ui-datagrid/
 * https://material-ui.com/components/data-grid/columns/
 * https://material-ui.com/components/data-grid/style/#styling-cells
 * fontFamily: 'courier',
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
// const ElapsedTimesOld = ({classes}) => {
//   const record = useRecordContext();
//   //console.log(`{ElapsedTimes} classes:`, classes);
//   //const columnTitles = ['Module', 'Elapsed Time'];
//   const columns = [
//     {
//       field: 'module',
//       headerName: 'Module',
//       width: 260,
//       headerClassName: classes.headerCell,
//       cellClassName: classes.tableCell
//     },
//     {
//       field: 'elapsedTime',
//       headerName: 'Elapsed (ms)',
//       width: 180,
//       type: 'number',
//       headerClassName: classes.headerCell,
//     },
//     {
//       field: 'numTimes',
//       headerName: '#',
//       width: 90,
//       type: 'number',
//       headerClassName: classes.headerCell,
//     },
//     {
//       field: 'start1',
//       headerName: 'Start (First)',
//       width: 160,
//       type: 'dateTime',
//       headerClassName: classes.headerCell,
//     },
//     {
//       field: 'finish1',
//       headerName: 'Finish (First)',
//       width: 160,
//       type: 'dateTime',
//       headerClassName: classes.headerCell,
//     },
//     {
//       field: 'start2',
//       headerName: 'Start (Last)',
//       width: 160,
//       type: 'dateTime',
//       headerClassName: classes.headerCell,
//     },
//     {
//       field: 'finish2',
//       headerName: 'Finish (Last)',
//       width: 160,
//       type: 'dateTime',
//       headerClassName: classes.headerCell,
//     }
//   ]
//   console.log(`{ElapsedTimes} record:`, record);
//   const elapsedTimes = (record.elapsedTimes && Array.isArray(record.elapsedTimes)) ? record.elapsedTimes : [];
//   console.log(`{ElapsedTimes} elapsedTimes:`, elapsedTimes);
//   // const modules = Object.keys(elapsedTimesObj).sort((a, b) => a.localeCompare(b, 'en', {sensitivity: 'base'}));
//   const modules = (elapsedTimes.length > 0) ?
//     elapsedTimes.sort((a, b) => a.task.localeCompare(b.task, 'en', {sensitivity: 'base'})) :
//     [];
//
//   const formatTime = (dateStr) => {
//     const date = new Date(dateStr);
//     let isoTime;
//     try {
//       isoTime = date.toISOString().split('T')[1];
//     }
//     catch {
//       return '?'
//     }
//     return isoTime.substring(0, isoTime.length - 1);
//   }
//
//   const rows = modules.map((m, i) => ({
//     id: i,
//     module: m.task,
//     numTimes: m.numTimes,
//     elapsedTime: m.elapsed ? m.elapsed.toFixed(0) : '',
//     start1: formatTime(m.start1),
//     finish1: formatTime(m.finish1),
//     start2: m.start2 ? formatTime(m.start2) : '',
//     finish2: m.finish2 ? formatTime(m.finish2) : ''
//   }));
//   // return (
//   // <Table>
//   //   <TableHead>
//   //     <TableRow>
//   //       <TableCell key={1} style={{width: '50%'}}>{columnTitles[0]}</TableCell>
//   //       <TableCell key={2} style={{minWidth: '20%'}}>{columnTitles[1]}</TableCell>
//   //       <TableCell key={3} style={{minWidth: '30%'}}>x</TableCell>
//   //     </TableRow>
//   //   </TableHead>
//   //
//   //   <TableBody>
//   //     {modules.map((module, index) => {
//   //         //console.log(`{render.TableBody} item ${index}:`, item);
//   //         return (
//   //           <TableRow key={index}>
//   //             <TableCell key={1} className={classes.tableCell}>
//   //               {module}
//   //             </TableCell>
//   //             <TableCell key={2}  className={classes.tableCell} align="right" >
//   //               {parseFloat(elapsedTimesObj[module]).toFixed(2)}ms
//   //             </TableCell>
//   //             <TableCell></TableCell>
//   //           </TableRow>
//   //         )
//   //       }
//   //     )}
//   //   </TableBody>
//   // </Table>
//   // );
//
//   return (
//     <div style={{width: '100%'}}>
//       <DataGrid autoHeight
//                 rowHeight={20}
//                 className={classes.root}
//         // pageSize={5}
//         // checkboxSelection
//         // onCellClick={() => null}
//         // onRowClick={handleRowClick}
//                 columns={columns}
//                 rows={rows}
//       />
//     </div>
//   );
// }

const ElapsedTimes = () => {
  const record = useRecordContext();
  return (
    <ArrayField source="analyses" label={''}>
      {/*<TableItemsField items={record.analyses} fieldNames={['message', 'oref', 'tref', 'scope', 'level', 'detail']}/>*/}
      {/*filters={<AnalysesFilter />}*/}
      <Datagrid data={record.elapsedTimes} bulkActionButtons={false}>
        <TextField source="task" label={'Module'} />
        <NumberField source="elapsed" label="Elapsed (ms)" sx={{width: 50}}/>
        <TextField source="numTimes" label="#"/>
        <TextField source="start1" label="Start (First)"/>
        <TextField source="finish1" label="Finish (First)"/>
        <TextField source="start2" label="Start (Last)"/>
        <TextField source="finish2" label="Finish (Last)"/>
      </Datagrid>
    </ArrayField>
  )
}

export default ElapsedTimes;