/**
 Similar to EmployeeShow.

 address: {_id: "5de4c8053f39f277fe6a1779", country_name: "United Kingdom"}
 city: "Lake Jaden"
 dob: "1999-10-12T20:32:08.727Z"
 employments: [{_id: "5de4c8063f39f277fe6a1827", employer: "5de4c8016397d9780a65ecf1",…}]
 first_name: "Camron"
 gender: "female"
 last_name: "Mayer"
 postcode: "FG4 6FE"
 street1: "2 High St"
 _id: "5de4c8053f39f277fe6a1778"
 */

import React from 'react';
import {
  ShowView,
  useShowController,
  TopToolbar,
  SimpleShowLayout,
  TextField, DateField,
  EditButton
} from 'react-admin';
import EmployerButton from '../components/EmployerButton';
import UnEditButton from '../components/UnEditButton';

const FullNameField = ({record = {}}) =>
  <span>{record.first_name} {record.last_name}</span>;
FullNameField.defaultProps = {label: 'Name'};

const EmployeeTitle = ({record}) => {
  return <span>Person: {<FullNameField record={record} />}</span>;
};

// Buttons at top right
const PersonShowActions = ({basePath, data}) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data}  label={'Personal'} />
    <UnEditButton pathname='/employees' basePath={basePath}
                  record={data && data.employments ? data.employments[0] : {}} caption={'Employment'} />
    <EmployerButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const PersonShow = (props) => {
  const controllerProps = useShowController(props);
  const {record} = controllerProps;
  if (!record) return null;
  console.log("{PersonShow} record:", record);
  return (
    <ShowView
      //id2={employerId}
      title={<EmployeeTitle/>}
      actions={<PersonShowActions/>}
      {...controllerProps} {...props}>
      <SimpleShowLayout>
        <TextField source="first_name"/>
        <TextField source="last_name"/>
        <TextField source="gender"/>
        <DateField source="dob" />
        <TextField source="street1"/>
        <TextField source="street2"/>
        <TextField source="city"/>
        <TextField source="postcode"/>
        <TextField source="address.country_name"/>
      </SimpleShowLayout>
    </ShowView>
  );
};

export default PersonShow;

