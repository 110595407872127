import React from 'react';
import {
  ListView,
  ListContextProvider,
  useTranslate,
  TopToolbar,
  useUpdate,
  useRefresh,
  useRecordContext,
  useListController,
  useNotify
} from 'react-admin';
import DragTable from './DragTable';
import UnListButton from "../button/UnListButton";
import {
  queryParamsToObject,
  queryParamsToFilterString
} from '../util/paramUtil';
import {Breadcrumbs} from '../breadcrumbs';
import TemplateFields from "../templatefields";
import {useLocation} from "react-router-dom";

/**
 *
 * This page allows template fields to be dragged to new positions in the list.
 * The new field order is immediately saved when a field is dropped.
 * Instead of using Datagrid, the table is rendered by DragTable, which accepts column values in sources
 * and heading titles in headings. The callback function onDrop() is invoked on each drop.
 *
 * When a field is dropped, the API update is called.
 * GET (list)
 * Client url: /templatefieldsorder/<templateId>
 * Client calls to porter-admin server: GET /admin/templatefields/<templateId>
 * Porter-admin server calls bsfactory with: GET /admin/templatefields/<templateId>
 *
 * PUT (update)
 * Client calls to porter-admin server: PUT /admin/templatefields/<templateId>
 */
const TemplateFieldOrderActions = (props) => {
  const record = useRecordContext();
  const {filter} = props;
  console.log("{TemplateFieldOrderActions} props:", props);
  return (
    <TopToolbar>
      {/*<UnShowButton pathname='/templates'*/}
      {/*              record={{_id: filterValues.templateId, name: filterValues.templateName}} caption='Template'*/}
      {/*              icon=<FormatListBulletedIcon/> />*/}
      <UnListButton
        label='resources.templatefields.name_many'
        pathname='/templatefields'
        filter={filter}
        record={record}
        icon={<TemplateFields.ICON />}
      />
    </TopToolbar>
  );
};

//const TemplateFieldOrderPagination = (rest) => <Pagination rowsPerPageOptions={[500]} {...rest} />

/**
 * Rather than invoke <List>, we call useListController (to get the data) and (to render) ListView separately.
 * DragTable is then used instead of Datagrid to support drag and drop.
 * In the onDrop callback, the useUpdate hook is used to make the update call to /templatefieldsorder/<templateId>.
 * The onDrop function is passed to DragTable to run when an item is dropped.
 *
 * @returns {null|*}
 * @constructor
 */
const TemplateFieldOrderList = () => {
  const translate = useTranslate();
  const location = useLocation();
  const refresh = useRefresh();
  const notify = useNotify();
  const [update] = useUpdate();
  const filter = queryParamsToObject(location);
  const {templateId, templateName} = filter;
  const perPage = 1000;
  const resource = 'templatefieldsorder';
  //console.log(`{TemplateFieldOrderList} filter:`, filter);
  const listContext = useListController({
    perPage,
    filter
  });
  const {data, total, isLoading, error} = listContext;
  console.log(`{TemplateFieldOrderList} total: ${total}, data:`, data);
  if (isLoading) {
    //console.log(`{TemplateFieldOrderList} is loading`);
    return null;
  }
  if (error) {
    console.log(`{TemplateFieldOrderList} error:`, error);
    return null;
  }

  // Hack - some records have name property and some have title after sort
  const records = data.map(record => {
    record.name = record.name || record.title;
    return record;
  })
  const sources = ['title', 'value', 'name', 'format', 'source']; // 'id', 'necessity'
  const headings = sources.map(heading => translate(`resources.templatefieldsorder.fields.${heading}`));
  //console.log(`{TemplateFieldOrderList} headings:`, headings);

  // onDrop is called when an item in DragTable is dropped. updatedIds contains the new sorted list of IDs.
  const onDrop = (props) => {
    const {updatedIds} = props;
    console.log(`{onDrop} updatedIds: ${updatedIds.length}:`, updatedIds);
    update(
      'templatefieldsorder',
      {id: templateId, data: updatedIds}, // {templateId: templateId, fieldOrderData: ids},{
      {
        undoable: false,
        onSuccess: () => {  // data
          refresh();
          notify('Fields reordered');
        },
        onError: (error) => {
          notify(`Field reordering error: ${error.message}`, {type: 'error'});
        },
      });
  }

  const parent = 'templates';
  const breadcrumbs = [
    {url: `/${parent}`, title: translate(`resources.${parent}.name_many`), resource: parent},
    {url: `/${parent}/${templateId}/show?${queryParamsToFilterString({templateName, name: templateName})}`, title: templateName, resource: parent},
    {url: `/${resource}`, title: translate(`resources.${resource}.name_many`), resource}
  ];
  return (
    <ListContextProvider value={listContext}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <ListView title={translate(`resources.templatefieldsorder.name`, 2)}
                actions={<TemplateFieldOrderActions filter={{templateId, templateName}}/>}
                //pagination={<TemplateFieldOrderPagination/>}
      >
        <DragTable sources={sources} headings={headings} onDrop={onDrop} records={records}
                   {...listContext} />
      </ListView>
    </ListContextProvider>
  );
};

export default TemplateFieldOrderList;