import React from 'react';
import {
  List, Datagrid,
  TextField,
  FunctionField, usePermissions, TopToolbar, CreateButton,
  //useResourceContext
} from 'react-admin';
import {Breadcrumbs} from "../breadcrumbs";
import {defaultPerPage, UnPagination} from "../util/paramUtil";
import UnIconButton from "../button/UnIconButton";
import {ListTitle} from "../components/InputFields";
import {Activity, isAuthorized} from "../auth/authorization";

// const OptionGroups = ({record}) => {
//   console.log(`{BenefitList} record:`, record);
//   console.log(`{BenefitList} record.optionGroups:`, record.optionGroups);
//   return record ? record.optionGroups.join(',') : '';
// }

const BenefitActions = () => {
  const {permissions} = usePermissions();
  return (
    <TopToolbar>
      {isAuthorized(permissions, Activity.BENEFIT) ?
        <CreateButton />
        : null}
    </TopToolbar>
  );
}

const BenefitList = () => {
  return (
    <>
      <Breadcrumbs/>
      <List sort={{field: 'name', order: 'ASC'}}
            perPage={defaultPerPage}
            pagination={<UnPagination/>}
            title={<ListTitle />}
            actions={<BenefitActions />}
      >
        <Datagrid bulkActionButtons={true}>
          <TextField source="name"/>
          <TextField source="summary"/>
          <TextField source="category"/>
          <TextField source="supplier"/>
          <FunctionField label="Required Selections" render={
            record => record ? record.optionGroups.map(opt => opt.name).join(', ') : ''
          } />
          <UnIconButton label={'Detail'} />
        </Datagrid>
      </List>
    </>
  )
};

export default BenefitList;