import downloadYaml from './downloadYaml';
import {Exporter} from 'react-admin';

//const yamlExporter = (data, _, __, resource) => null;

// Added async as type expects promise. Gets rid of: Type error: Type 'void' is not assignable to type 'Promise<void>'.  TS2322
const yamlExporter: Exporter = async (data, _, __, resource) => {
  downloadYaml(data, resource); // Function does not return anything
}

export default yamlExporter;
