/**
 * Edit a template
 * A template contains 2 lists of foreign keys:
 * (1) dictionaries, e.g. ["5f620a2616dfb21d9d44cb04", "5f620a2616dfb21d9d44cc52"] and
 * (2) owners applications, e.g. ["RP:5f43d779271ad42bd3e8a87c"]
 * The full lookup lists for both of these are retrieved in useEffect.
 *
 * If a template owner (application) does not own all the referenced dictionaries,
 * only those owned will be displayed in the list. If the user then saves the template
 * the unowned dictionaries will be lost from the template. Only a user with full permissions
 * (e.g. superadmin) can add them back.
 *
 */
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {
  ArrayInput,
  CloneButton,
  DeleteWithConfirmButton, EditContextProvider,
  EditView,
  FormTab,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  Toolbar,
  TopToolbar,
  useDataProvider,
  useEditController,
  useGetList,
  Loading,
  useTranslate, usePermissions,
  useResourceContext,
  useRedirect, useRecordContext
} from 'react-admin';
import log from 'loglevel';
import {DetailTitle} from "../components/InputFields";
import {getTabName} from "../util/paramUtil";
import {Breadcrumbs} from "../breadcrumbs";
import {Activity, isAuthorized} from "../auth/authorization";
import {UnShowButtonWithName} from "../button/UnShowButtonWithFilter";
import {
  validOwnerEntityHandles
} from '../representatives/OwnerUtil';
import {
  fetchDictionaries
} from "./TemplateData";
import TemplateFields from "../templatefields";
import UnListButton from "../button/UnListButton";
import {useLocation, useParams} from "react-router-dom";
import {isValidTabName} from "../components/EditActions";
import UnHelpButton from "../button/UnHelpButton";

const styles = {
  form: {padding: 0},
  medium: {width: '20em'},
  handler: {width: '20em', margin: '0.5em'},
  weight: {width: '5em'},
  aliasFormGroup: {display: 'inline-block'},
  weightFormGroup: {display: 'inline-block', marginLeft: 24},
};
const useStyles = makeStyles(styles);

// const TemplateAside = ({templateId, templateName, dictionaryIds, resource}) => (
//   <TemplateDropPanel templateId={templateId} templateName={templateName}
//                      dictionaryIds={dictionaryIds} resource={resource}/>
// );

//const TABNAMES = ['attributes', 'rowhandlers'];
//const isValidTabName = tabName => TABNAMES.includes(tabName);

// const checkObjectIdHexRegExp = new RegExp("^[0-9a-fA-F]{24}$");
// const isValidTabName = tabName => checkObjectIdHexRegExp.test(tabName);

// {tabName}
const TemplateEditActions = (props) => {
  const record = useRecordContext();
  const {permissions} = usePermissions();
  const {tabName} = props;
  //const adjTabName = isValidTabName(tabName) ? tabName : '';
  // const canAuthPerm =  isAuthorized(permissions, Activity.TEMPLATE_PERMISSIONS);
  // const adjTabName = isAuthorized(permissions, Activity.TEMPLATE_PERMISSIONS) ?
  //   (isValidTabName(tabName) ? tabName : '') :
  //   (isValidTabName(tabName) && (tabName !== 'rowhandlers') ? tabName : '')
  // log.debug(`{TemplateEditActions} tabName: ${tabName}, adjTabName: ${adjTabName}, canAuthPerm: ${canAuthPerm}`);
  if (!record) return null;
  const adjTabName = isValidTabName(tabName) ? tabName : '';
  return (
    <TopToolbar>
      <UnListButton
        label='resources.templatefields.name_many'
        pathname='/templatefields'
        filter={{templateId: record.id, templateName: record.name}}
        //record={data}
        icon={<TemplateFields.ICON/>}
      />
      {/*tabName={adjTabName}*/}
      <UnShowButtonWithName tabName={adjTabName}/>
      {isAuthorized(permissions, Activity.TEMPLATE_PERMISSIONS) ?
          <CloneButton /> :
        null}
      <UnHelpButton helpPage={'/Configuration/template'}/>
    </TopToolbar>
  );
};

/**
 * Note - adding this toolbar exhibits delete button justification bug
 * @param props
 * @returns {*}
 * @constructor
 */
const TemplateEditToolbar = props => {
  const {name} = props;
  return (
    <Toolbar {...props} >
      <SaveButton sx={{marginRight: '2em'}} />
      <DeleteWithConfirmButton confirmTitle={`Delete template ${name}?`}
                               confirmContent={'Confirm to delete this template'}/>
    </Toolbar>
  )
};

const intersection = (arrA, arrB) => arrA.filter(x => arrB.includes(x));

const RowHandlersEditTable = (props) => {
  const {classes, rowHandlerDef} = props;
  return (<ArrayInput source="rowHandlers" label=''>
    <SimpleFormIterator>
      {/*<SelectInput source="name" choices={rowHandlerDefs} formClassName={classes.aliasFormGroup} onChange={handleChange}/>*/}
      <TextInput source="name" label={`Name`} className={classes.handler}
                 formClassName={classes.aliasFormGroup}/>
      {rowHandlerDef['Compare'].entries.map((rh, i) => {
        return (
          <TextInput source={`entries.${i}.name`} label={`Entry Name ${i + 1}`} className={classes.handler}
                     formClassName={classes.aliasFormGroup} key={i}/>
        )
      })}
      <TextInput source={`args.0.name`} label={`Argument Name`} className={classes.handler}
                 formClassName={classes.aliasFormGroup}/>
      <TextInput source={`args.0.value`} label={`Argument Value`} className={classes.handler}
                 formClassName={classes.aliasFormGroup}/>

    </SimpleFormIterator>
  </ArrayInput>)
}

// E.g. ["5f43d779271ad42bd3e8a87c"]
const cleanDictionaryIds = (dictionaryIds, dictionaries) => {
  // Ensure only object IDs are returned
  let cleanedDictionaryIds = dictionaryIds.map(d => d._id ? d._id : d);
  // Check dict ID is valid, i.e. in available list
  const validDictionaryIds = dictionaries.map(d => d.id);
  //log.debug(`{cleanDictionaryIds} validDictionaryIds:`, validDictionaryIds);
  cleanedDictionaryIds = cleanedDictionaryIds.filter(id => validDictionaryIds.includes(id));
  //log.debug(`{cleanDictionaryIds} cleanedDictionaryIds:`, cleanedDictionaryIds);
  return cleanedDictionaryIds;
}

/**
 * un_admin role view
 * On dictionaries list, source="dictionaries" refers to those dictionaries belonging in this template.
 * But choices={dictionaries} displays the full list of available dictionaries to this user/application.
 * TODO: change useState dictionaries to permittedDictionaries?
 * @param editContext
 * @param otherProps
 * @returns {JSX.Element}
 * @constructor
 */
const TemplateEditViewRestricted = (editContext, otherProps) => {
  const {location} = otherProps;
  const {
    name, classes, dictionaries, locales, repEntityHandles
  } = otherProps;
  const allDictionaryIds = dictionaries.map(d => d.id);
  // log.debug(`{TemplateEditViewRestricted} allDictionaryIds:`, allDictionaryIds);
  // log.debug(`{TemplateEditViewRestricted} repEntityHandles:`, repEntityHandles);
  return (
    <EditContextProvider value={editContext}>
      <Breadcrumbs/>
      <EditView
        title={<DetailTitle />}
        actions={<TemplateEditActions tabName={getTabName(location)}/>}
       >
        <TabbedForm
          //redirect={redirectShow}
          toolbar={<TemplateEditToolbar name={name}/>}>
          <FormTab label="template">
            <TextInput source='name'/>
            <TextInput source={'description'}
                       label={'Description'}
                       multiline={true}
                       rows={5}
                       fullWidth
            />
            <SelectArrayInput
              label="Dictionaries"
              source="dictionaries"
              // Inbound. Reading from data source into form
              format={recDictionaries => { // e.g. ["RP:5f43d779271ad42bd3e8a87c"]
                log.debug(`{TemplateEditViewRestricted.format} record dictionaries:`, recDictionaries);
                const recDictionaryIds = recDictionaries.map(d => d._id ? d._id : d);
                // TODO: filter dictionaries for choices
                const filteredDictionaryIds = intersection(allDictionaryIds, recDictionaryIds);
                // const filteredDictionaries = intersection(allDictionaryIds, recDictionaries);
                log.debug(`{TemplateEditViewRestricted.format} filteredDictionaryIds:`, filteredDictionaryIds);
                // const cleanedDictionaries = filteredDictionaries.map(d => {
                //   return d._id ? d._id : d;
                // })
                // const filteredDictionaries = recDictionaries.filter(recDictId => {
                //   console.log(`{TemplateEditViewRestricted.format} recDictId: ${recDictId}, dictionaries:`,
                //     dictionaries);
                //   return recDictId === dictionaries.id;
                // });
                // log.debug(`{TemplateEditViewRestricted.format} cleanedDictionaries:`, cleanedDictionaries);
                // return cleanedDictionaries;
                return filteredDictionaryIds;
              }}
              // Outbound. From form to data source.
              parse={recDictionaries => {
                return recDictionaries.map(d => d._id ? d._id : d);
              }}
              choices={dictionaries}/>
            {/*<ReferenceInput label="Locale" source="locale" reference="locales" allowEmpty fullWidth>*/}
            {/*  <SelectInput optionText="name" optionValue="id" fullWidth />*/}
            {/*</ReferenceInput>*/}
            <SelectInput label="Locale" source="locale" choices={locales} fullWidth/>
            <TextInput source='strategy'/>
            <SelectArrayInput label='Owner applications'
                              source="owners"
                              choices={repEntityHandles} fullWidth/>
          </FormTab>
          <FormTab label="attributes" path={"attributes"}>
            <ArrayInput source="attributes" label=''>
              <SimpleFormIterator>
                <TextInput source='name' className={classes.medium} formClassName={classes.aliasFormGroup}/>
                <TextInput source='value' className={classes.medium} formClassName={classes.aliasFormGroup}/>
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
        </TabbedForm>
      </EditView>
    </EditContextProvider>
  );
}

/**
 * Superadmin role view
 * redirect - https://marmelab.com/react-admin/Edit.html#redirect
 * @param props
 * @param editContext
 * @param otherProps
 * @returns {JSX.Element}
 * @constructor
 */
const TemplateEditViewFull = (editContext, otherProps) => {
  // const {permissions} = usePermissions();
  const {permissions} = editContext;
  const {id, location} = otherProps;
  const {
    name, classes, dictionaries, locales, repEntityHandles
  } = otherProps;
  // // Used in dropdown list for Owner applications
  // const repEntityHandles = applications ? applications.map(r => ({ id: `RP:${r.id}`, name: r.name })) : [];
  // // Filter invalid owners in this template record
  // const validOwners = validOwnerEntityHandles(editContext.record.owners);
  log.debug(`{TemplateEditViewFull} id: ${id}, record:`, editContext.record);
  log.debug(`{TemplateEditViewFull} location:`, location);
  const {_id: templateId, name: templateName, dictionaries: dictionaryIds} = editContext.record;
  //const templateName = editContext.record.name;
  //let dictionaryIds = editContext.record.dictionaries;
  log.debug(`{TemplateEditViewFull} templateId: ${templateId}, templateName: ${templateName}, dictionaryIds:`, dictionaryIds);

  /**
   * After saving, the tab's name is used to set the equivalent tab in the Show page, e.g:
   *   /dictionaryentries/60c84f8a0688ec1f58553643/show/constraints?....
   * The first tab ("entry") has no tab name and must not be included in the URL.
   * @param basePath
   * @returns {`${string}/${string}/show/${*}?${string}`}
   */
  // const args = queryParamFilter(location);
  // if (args.id) delete args.id;
  // const {name, dictId, dictName} = args;

  // const redirect = (basePath) => {
  //   const restArgs = args;
  //   log.debug(`{TemplateEditViewFull.redirect} basePath: ${basePath}, args:`, args);
  //   log.debug(`{TemplateEditViewFull.redirect} location:`, location);
  //   const locationTabName = getTabName(location);
  //   const isValid = isValidTabName(locationTabName);
  //   log.debug(`{TemplateEditViewFull.redirect} locationTabName: ${locationTabName}, isValidTabName: ${isValid}`);
  //   return isValid ?
  //     `${basePath}/${id}/show/${locationTabName}?${queryParamString(restArgs)}` :
  //     `${basePath}/${id}/show?${queryParamString(restArgs)}`;
  // }

  // const rowHandlerDefs = [
  //   {id: 'Compare', name: 'Compare', entries: [{name: 'Leave Date'}, {name: 'Join Date'}], args: [{name: 'op', value: '>'}]},
  //   {id: 'LeftEmploymentStatus', name: 'LeftEmploymentStatus'}
  // ];
  const rowHandlerDef = {
    Compare: {entries: [{name: 'Leave Date'}, {name: 'Join Date'}], args: [{name: 'op', value: '>'}]},
    LeftEmploymentStatus: {entries: [{name:'LeftEmploymentStatus'}]}
  }
  return (
    <EditContextProvider value={editContext}>
      <Breadcrumbs/>
      <EditView
        title={<DetailTitle />}
        actions={<TemplateEditActions tabName={getTabName(location)}/>}
      >
        <TabbedForm
          toolbar={<TemplateEditToolbar name={name}/>}>
          <FormTab label="template">
            <TextInput source='name' fullWidth/>
            <TextInput source={'description'}
                       label={'Description'}
                       multiline={true}
                       rows={5}
                       fullWidth
            />
            {/* NB. SelectArrayInput can use optionText={optionRenderer} for format display*/}
            {/*const optionRenderer = choice => `${choice.name} (${choice.quantity})`;*/}
            <SelectArrayInput
              label="Dictionaries"
              source="dictionaries"
              // Inbound. Reading from data source into form
              format={dictionaryIds => cleanDictionaryIds(dictionaryIds, dictionaries)}
              choices={dictionaries} fullWidth/>
            <SelectInput label="Locale" source="locale" choices={locales} fullWidth/>
            <TextInput source='strategy'/>
            <SelectArrayInput label='Owner applications'
              source="owners"
              // format={owners => ownerApplicationIds(owners)}  // Inbound. Strip entity code.
              // format={owners => { // e.g. ["RP:5f43d779271ad42bd3e8a87c"]
              //   log.debug(`{TemplateEditViewFull.format} owners:`, owners);
              // }}  // Inbound. Strip entity code.

              // parse={owners => owners.map(applicationId => `RP:${applicationId}`)} // Outbound. Add entity code.
               parse={owners => {
                 log.debug(`{TemplateEditViewFull.parse} owners:`, owners);
                 return owners;
               }}
              choices={repEntityHandles} fullWidth/>
          </FormTab>
          <FormTab label="attributes" path={"attributes"}>
            <ArrayInput source="attributes" label=''>
              <SimpleFormIterator>
                <TextInput source='name' className={classes.medium} formClassName={classes.aliasFormGroup}/>
                <TextInput source='value' className={classes.medium} formClassName={classes.aliasFormGroup}/>
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
          {isAuthorized(permissions, Activity.TEMPLATE_PERMISSIONS) ?
            <FormTab label="Row Handlers" path="rowhandlers">
              <RowHandlersEditTable classes={classes} rowHandlerDef={rowHandlerDef}/>
            </FormTab> :
            null}

        </TabbedForm>
      </EditView>
    </EditContextProvider>
  );
}

/**
 * @returns {*}
 * @constructor
 */
export const TemplateEdit = () => {
  const location = useLocation();
  const {permissions} = usePermissions();
  const resource = useResourceContext();
  const redirect = useRedirect();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  //const [applications, setApplications] = useState();
  const [dictionaries, setDictionaries] = useState();
  const [handlerName, setHandlerName] = useState();
  const {id} = useParams();
  // Signature resource, id, data
  const redirectPath = (resource, id) => `${resource}/${id}/show/${getTabName(location)}`;

  const editContext = useEditController({redirect: redirectPath});
  log.debug(`{TemplateEdit} editContext:`, editContext);
  //const isAuthTP = isAuthorized(permissions, Activity.TEMPLATE_PERMISSIONS);
  // useEffect( () => {
  //   let isMounted = true; // Prevents error: Can't perform a React state update on an unmounted component.
  //   (async () => {
  //     const apps = await fetchAllApplications({dataProvider});
  //     if (isMounted && apps) { // mounted
  //       setApplications(apps);
  //     }
  //     return () => isMounted = false; // Cleanup function
  //   })();
  // }, [editContext.record, permissions, dataProvider, isAuthTP]);

  const {isLoading: isApplicationsLoading, data: applications} = useGetList(
    'representatives',
    {sort: {field: 'name', order: 'ASC'}
    });

  const isAuthT = isAuthorized(permissions, Activity.TEMPLATE);
  useEffect( () => {
    let isMounted = true;
    (async () => {
      const fetchedDictionaries = await fetchDictionaries({dataProvider});
      log.debug(`{TemplateEdit.useEffect} fetchedDictionaries:`, fetchedDictionaries);
      if (isMounted && fetchedDictionaries) {
        setDictionaries(fetchedDictionaries);
      }
      return () => isMounted = false;
    })();
  }, [editContext.record, permissions, dataProvider, isAuthT]);

  const {isLoading: isLocalesLoading, data: localesAll} = useGetList(
    'locales',
    {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}}
  );

  if (editContext.isLoading) return <Loading />;
  if (!permissions || !dictionaries || isApplicationsLoading || isLocalesLoading) return null;
  const {record, owners} = editContext;
  log.debug(`{TemplateEdit} record:`, record);
  const {name} = record;
  const locales = localesAll.map(r => ({id: r._id, name: r.name}));
  log.info(`{TemplateEdit} id: ${id}, name: ${name}, permissions:`, permissions);

  // Used in dropdown list for Owner applications
  const repEntityHandles = applications ? applications.map(r => ({ id: `RP:${r.id}`, name: r.name })) : [];
  log.debug(`{TemplateEdit} repEntityHandles:`, repEntityHandles);
  // Filter invalid owners in this template record
  const validOwners = validOwnerEntityHandles(owners);

  const breadcrumbs = [
    {url: `/${resource}`, title: translate(`resources.${resource}.name_many`), resource},
    {url: ``, title: name, resource}
  ];
  const otherProps = {id, name, breadcrumbs, classes, dictionaries, applications, locales,
    handlerName, setHandlerName, location,
    repEntityHandles, validOwners
  };
  if (!isAuthorized(permissions, Activity.TEMPLATE_PERMISSIONS)) {
    return TemplateEditViewRestricted({redirect, permissions, ...editContext}, otherProps);
  }
  else {
    return TemplateEditViewFull({permissions, ...editContext}, otherProps);
  }
};

export default TemplateEdit;