import * as React from 'react';
import get from 'lodash/get';
import {
  AppBar,
  Logout,
  UserMenu,
  useTranslate,
} from 'react-admin';
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import SettingsIcon from '@mui/icons-material/Settings';

const useStyles = makeStyles(theme => ({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  appbarImage: {
    marginRight: '40px'
  },
  appBar: {
    color: theme.palette.headerText.main,
    backgroundColor: theme.palette.headerBackground.main
  }
}));

const ConfigurationMenu = React.forwardRef((props, ref) => {
  console.log(`{ConfigurationMenu} props:`, props);
  //const resourcesDefinitions = useResourceDefinitions();
  const translate = useTranslate();
  return (
    <MenuItem
      component={Link}
      ref={ref}
      {...props}
      to="/configuration"
    >
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText>{translate('pos.configuration')}</ListItemText>
    </MenuItem>
  );
    // return (
    //     <MenuItem
    //         component={Link}
    //         ref={ref}
    //         {...props}
    //         to="/configuration"
    //     >
    //         <ListItemIcon>
    //             <SettingsIcon />
    //         </ListItemIcon>
    //         <ListItemText>{translate('pos.configuration')}</ListItemText>
    //     </MenuItem>
    // );
});

const ProfileMenu = React.forwardRef((props, ref) => {
  console.log(`{ProfileMenu} props:`, props);
  const translate = useTranslate();
  return (
    <MenuItem
      component={Link}
      ref={ref}
      {...props}
      to="/profile"
    >
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText>{translate('pos.profile')}</ListItemText>
    </MenuItem>
  );
});

/**
 * Used in UnAppBar
 * @returns {JSX.Element}
 * @constructor
 */
const CustomUserMenu = () => (
    <UserMenu>
        <ConfigurationMenu />
        <ProfileMenu/>
        <Logout />
    </UserMenu>
);

// The app title is defined in DetailTitle (components/InputFields.js)
// By default, the react-admin <AppBar> component displays the page title.
// You can override this default by passing children to <AppBar> -
// they will replace the default title. And if you still want to include the page title,
// make sure you include an element with id react-admin-title in the top bar
// (this uses React Portals).
// https://marmelab.com/react-admin/Theming.html#customizing-the-appbar-content

// const UnAppBar = (props) => {
//     const isLargeEnough = useMediaQuery(theme =>
//         theme.breakpoints.up('sm')
//     );
//     const classes = useStyles();
//     return (
//         <AppBar
//             {...props}
//             color="secondary"
//             elevation={1}
//             userMenu={<CustomUserMenu />}
//             sx={{
//               minHeight: 60,
//             }}
//         >
//             <img src='/untangl_small_white46@2x.png' alt="logo" className={classes.appbarImage} />
//             <Typography
//                 variant="h6"
//                 color="inherit"
//                 sx={{
//                     flex: 1,
//                     textOverflow: 'ellipsis',
//                     whiteSpace: 'nowrap',
//                     overflow: 'hidden',
//                 }}
//                 id="react-admin-title"
//             />
//           {/*<Typography*/}
//           {/*  variant="h6"*/}
//           {/*  color="inherit"*/}
//           {/*  sx={{*/}
//           {/*    flex: 1,*/}
//           {/*    textOverflow: 'ellipsis',*/}
//           {/*    whiteSpace: 'nowrap',*/}
//           {/*    overflow: 'hidden',*/}
//           {/*  }}*/}
//           {/*  id="react-admin-title2"*/}
//           {/*>*/}
//           {/*  QA*/}
//           {/*</Typography>*/}
//             {/*{isLargeEnough && <Logo />}*/}
//             {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
//         </AppBar>
//     );
// };

/**
 * theme.palette.headerText.main is custom color attribute for text colour in app bar.
 * Defaults to white if not in theme.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const UnAppBar = (props) => {
  const isLargeEnough = useMediaQuery(theme =>
      theme.breakpoints.up('sm')
  );
  const classes = useStyles();
  const theme = useTheme();
  console.log(`{UnAppBar} theme:`, theme);
  const headerTextColor = get(theme, "palette.headerText.main", "white");
  const appBarImg = get(theme, "custom.appBarImg", "untangl_small_white46@2x.png");
  return (
    <AppBar
      {...props}
      //color={theme.palette.headerBackground.main}
      className={classes.appBar}
      elevation={1}
      userMenu={<CustomUserMenu />}
    >
      {/*<img src='/untangl_small_white46@2x.png' alt="logo" sx={{*/}
      {/*    marginRight: '40px'*/}
      {/*}}/>*/}
      <Box
        component="img"
        sx={{
          height: 54,
          // width: 350,
          // maxHeight: { xs: 233, md: 167 },
          // maxWidth: { xs: 350, md: 250 },
          marginRight: '40px',
          paddingTop: '4px',
          paddingBottom: '4px'
        }}
        alt="Logo"
        src={`${appBarImg}`}
        //src={`http://admin.untangl.uk/${appBarImg}`}
        // src="http://admin.untangl.uk/untangl_small_white46@2x.png"
      />
      <Typography
        variant="h6"
        color="inherit"
        sx={{
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          color: headerTextColor
        }}
        id="react-admin-title"
      />
      {/*{isLargeEnough && <Logo />}*/}
      {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
    </AppBar>
  );
};

export default UnAppBar;
