/**
 * This module is a library providing various utility functions.
 * Note it is NOT a real Vue mixin
 */

/**
 * _extractDomain - extracts a base domain from a full domain for cookie setting
 *
 * @param domainIn - e.g. one.untangl.co.uk
 * @return {*|string|*} - e.g. untangl.co.uk
 * @private
 */
// const extractDomain = function(domainIn) {
//   if (domainIn.search(/localhost/) !== -1 || domainIn.search(/127.0.0.1/) !== -1  ){
//     // vm.$log.debug("{_extractDomain} returning localhost");
//     return 'localhost';
//   }
//   //cannot do atomic groups with JS so the first alternate group gives us a match i.e. length = 3
//   const domainRX = /^(untangler1|untangler|one|admin)\.(\w+(?:\.\w+)*)$/;
//   console.log(`{extractDomain} domainRX:`, domainRX);
//   let r = domainIn.match(domainRX);
//   console.log("{_extractDomain} match gives",r,{});
//   if (r == null) {
//     return domainIn; //cannot detect any subdomain
//   }
//   else {
//     if (r.length === 3) {
//       return r[2]; //core domain
//     }
//     else {
//       return domainIn; //don't know what it is so play safe
//     }
//   }
// };


/**
 * _getSite - evaulate whether this is a live site and https or not
 *
 * @returns {{isLive: boolean, isHttps: boolean}}
 * @private
 */
// const getSite = function() {
//   const domain = extractDomain(document.location.host);
//   console.log(`{getSite} domain:`, domain);
//   // Variables to say if we are 1) live and 2) https (Live and Beta are now https)
//   const isLive =  (domain !== 'localhost') &&
//     (domain !== '127.0.0.1') &&
//     (domain !== 'untangl.uk') &&
//     (domain !== 'untangl.info');
//   const isHttps =  (domain !== 'localhost') &&
//     (domain !== '127.0.0.1') &&
//     (domain !== 'untangl.uk');
//   return {isLive, isHttps};
// }

const mainDomain = 'untangl';

/**
 * Determine system (prod, beta, qa or dev) from URL
 * @returns {string}
 */
const getSystem = () => {
  //const domain = extractDomain(document.location.host);
  const domain = document.location.host;
  let system = 'dev';
  if (domain.includes(`${mainDomain}.co.uk`)) system = 'prod';
  if (domain.includes(`${mainDomain}.info`)) system = 'beta';
  if (domain.includes(`${mainDomain}.uk`)) system = 'qa';
  console.log(`{getSystem} system: ${system}, domain:`, domain);
  return system;
}

// Use named exports so we can individually deconstruct them on import e.g.
// import {AXIOS} from '../../mixins/axios'
export {
  //getSite,  //Execute function to obtain information
  //extractDomain,
  getSystem,

}