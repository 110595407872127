import React from 'react';
import {makeStyles} from '@mui/styles';
import {
  List, Datagrid,
  TopToolbar,
  TextField,
  CreateButton, Filter, TextInput,
  usePermissions,
  useRecordContext
} from 'react-admin';
import {defaultPerPage, queryParamString, UnPagination} from '../util/paramUtil';
import {Breadcrumbs} from '../breadcrumbs';
import {Activity, isAuthorized} from "../auth/authorization";
import log from 'loglevel';
import UnIconButton from "../button/UnIconButton";
import DictionaryEntries from "../dictionaryentries";
import {Link} from "react-router-dom";
import {ListTitle} from "../components/InputFields";

const useStyles = makeStyles(theme => {
  console.log(`{UnEntriesButton.useStyles} theme:`, theme);
  return ({
    root: {
      //backgroundColor: 'red'
      color: theme.palette.primary.main
    }
  })
});

const UnEntriesButton = (props) => {
  //log.debug(`{UnEntriesButton} props:`, props);
  const record = useRecordContext(props);
  const classes = useStyles();
  //log.debug(`{UnEntriesButton} record:`, record);
  const queryParam = queryParamString({dictId: record.id, dictName: record.name});
  return (
    <Link to={`/dictionaryentries?${queryParam}`} className={classes.root}>
      <DictionaryEntries.ICON style={{fontSize: 26}}/>
    </Link>
    // <UnListButton
    //   label='' //'resources.templatefields.name_many'
    //   pathname='/dictionaryentries'
    //   filter={{dictId: record.id, dictName: record.name}}
    //   //record={record}
    //   size={'large'}
    //   icon={<DictionaryEntries.ICON style={{fontSize: 26}}/>}
    //   style={{minWidth: '40px', padding: '2px 2px 2px 1px'}}
    // />
  );
}

const DictionaryActions = ({permissions}) => {
  return (
    <TopToolbar>
      {isAuthorized(permissions, Activity.DICTIONARY) ?
      <CreateButton />
      : null}
    </TopToolbar>
  );
}

const DictionaryFilter = (props) => {
  log.debug(`{DictionaryFilter} props:`, props);
  return (
    <Filter {...props}>
      <TextInput label='Section' source='section' alwaysOn/>
    </Filter>
  );
}

/**
 * Datagrid rowClick="show" to allow click on entire row.
 * Only superadmin can bulk delete.
 * @returns {JSX.Element}
 * @constructor
 */
export const DictionaryList = () => {
  const {permissions} = usePermissions();
  const isBulkActionButtons = isAuthorized(permissions, Activity.DICTIONARY_PERMISSIONS);
  return (
    <>
      <Breadcrumbs/>
      <List
          perPage={defaultPerPage}
          pagination={<UnPagination/>}
          actions={<DictionaryActions
          permissions={permissions}/>}
          title={<ListTitle />}
          filters={<DictionaryFilter/>}
      >
        <Datagrid bulkActionButtons={isBulkActionButtons}>
          <TextField source='name' />
          <TextField source='section' />
          <TextField source='locale' />
          <TextField source='description' sortable={false}/>
          <UnIconButton label={'Detail'} />
          <UnEntriesButton label={'Entries'}/>
        </Datagrid>
      </List>
    </>
  );
};

export default DictionaryList;