import EvaluationIcon from '@mui/icons-material/Functions';
import EvaluationList from './EvaluationList';
import EvaluationCreate from './EvaluationCreate';
//import EvaluationShow from './EvaluationShow';
// import EvaluationEdit from './EvaluationEdit';

export default {
  list: EvaluationList,
  create: EvaluationCreate,
  //show: EvaluationShow,
  // edit: EvaluationEdit,
  ICON: EvaluationIcon
};