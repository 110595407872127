import React from 'react';
import {
  //ArrayInput,
  Create, FormTab,
  //SelectInput,
  //SimpleFormIterator,
  TabbedForm,
  TextInput,
  //useResourceContext
} from 'react-admin';
import {BreadcrumbsCreate} from "../breadcrumbs";
import {CreateTitle} from "../components/InputFields";
import EmployeeOptionsForm from "./EmployeeOptionsForm";
import {makeStyles} from "@mui/styles";
import {SelectionsEdit} from "./Selections";
//import {useLocation, useParams} from "react-router-dom";
//import {makeStyles} from "@mui/styles";
//import EmployeeOptionsForm from "./EmployeeOptionsForm";

// const useStyles = makeStyles(() => ({
//   form: { padding: 0 },
//   wide: {width: '24em'},
//   narrow: {width: '8m'},
//   formGroup: {display: 'inline-block', marginRight: '0.5em'},
// }));

// const optionGroups  = [
//   {name: 'Level', id: 'level'},
//   {name: 'Dependants', id: 'dependants'}
// ];

// const BenefitTitle = () => { // {record}
//   //console.log("{BenefitTitle} record:", record);
//   return <span>New Benefit</span>;
// }

// const GroupInputList = props => {
//   const groups = useWatch({name: 'groups'});
//   console.log("{GroupInputList} groups:", groups);
// }

const useStyles = makeStyles((theme) => ({
  form: { padding: 0 },
  wideField: {width: '100%'},
  narrowField: {width: '8m', marginRight: '0.5em'},
  formGroup: {display: 'inline-block', marginRight: '0.5em'},
  formGroupUnder: {marginRight: '0.5em'},

  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const BenefitCreate = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <BreadcrumbsCreate/>
      <Create title={<CreateTitle />} >
        <TabbedForm
          //redirect={redirect}
          //toolbar={<BenefitEditToolbar filter={{name, ...args}} record={record}/>}
        >
          <FormTab label="Required Selections">
            <EmployeeOptionsForm classes={classes}  />
          </FormTab>

          <FormTab label="Optional Selections" path={"optional"}>
          </FormTab>

          <FormTab label="General" path={"general"}>
            <TextInput source='name' label='Benefit name' fullWidth/>
            <TextInput source="summary" fullWidth/>
            <TextInput source='description' multiline={true} rows={3} fullWidth/>
            <TextInput source="supplier" fullWidth/>
          </FormTab>

          <FormTab label="Selection Names" path={"names"}>
            <SelectionsEdit/>
          </FormTab>

          <FormTab label="yaml" path={"yaml"}>
            {/*<TextInput source="yaml" component="pre" fullWidth*/}
            {/*           sx={{fontFamily: 'courier'}}*/}
            {/*/>*/}
            <TextInput source="yaml" multiline={true} rows={40} fullWidth/>
          </FormTab>

          {/*<FormTab label="Groups" path={"groups"}>*/}
          {/*  <ArrayInput source="groups" label=''>*/}
          {/*    <SimpleFormIterator>*/}
          {/*      <TextInput source='groupName' className={classes.narrow} formClassName={classes.formGroup} />*/}
          {/*    </SimpleFormIterator>*/}
          {/*  </ArrayInput>*/}
          {/*</FormTab>*/}

          {/*<FormTab label="Employee Options" path={"options"}>*/}
          {/*  <TextField label="Level"/>*/}
          {/*  <EmployeeOptionsForm classes={classes} {...props} />*/}
          {/*</FormTab>*/}

        </TabbedForm>
      </Create>
    </React.Fragment>
  );
}


export default BenefitCreate;