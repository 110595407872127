import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import {
  ArrayField,
  ShowView,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  ReferenceField,
  useShowController,
  ShowContextProvider,
  Loading, Error, usePermissions, useResourceContext, useRecordContext
} from 'react-admin';
import log from 'loglevel';
import UnExportButton from '../export/UnExportButton';
import {DetailTitle} from '../components/InputFields';
import UnListButton from '../button/UnListButton';
import {OwnerChips, DictionaryChips, OwnerChipsNoLink} from '../components/Chips';
import {getTabName} from '../util/paramUtil';
import TemplateFields from '../templatefields';
import Untanglements from '../untanglements';
import {Breadcrumbs} from "../breadcrumbs";
import {UnEditButtonWithName} from "../button/UnEditButtonWithFilter";
import {Activity, Role, isAuthorized, hasRole} from "../auth/authorization";
import {ownerApplicationIds} from '../representatives/OwnerUtil';
import yamlExporter from "../export/yamlExporter";
import {
  useGetOwnerApplications,
  useGetDictionaries,
} from './TemplateData';
import RowHandlersTable from './RowHandlersTable';
import TableItemsField from "../components/TableItemsField";
import RowHandlerDef from './RowHandlerDef';
import {isValidTabName} from "../components/EditActions";
import {useLocation, useParams} from "react-router-dom";
import UnHelpButton from "../button/UnHelpButton";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const dateNumDaysAgo = (numDays) => {
  const d = new Date();
  d.setDate(d.getDate() - numDays);  // Number of days ago
  log.debug(`{dateNumDaysAgo} d:`, d);
  const isoDate = d.toISOString().split('T')[0]; //`${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`;
  log.debug(`{dateNumDaysAgo} iso date: `, isoDate);
  return isoDate;
}

const TemplateActions = (props) => {
  const {permissions} = usePermissions();
  const resource = useResourceContext();
  const record = useRecordContext();
  const {tabName} = props;
  log.debug('{TemplateActions} props:', props);
  if (!record) return null;
  const canAuthPerm =  isAuthorized(permissions, Activity.TEMPLATE_PERMISSIONS);
  const adjTabName = isAuthorized(permissions, Activity.TEMPLATE_PERMISSIONS) ?
    (isValidTabName(tabName) ? tabName : '') :
    (isValidTabName(tabName) && (tabName !== 'rowhandlers') ? tabName : '')
  log.debug(`{TemplateActions} tabName: ${tabName}, adjTabName: ${adjTabName}, canAuthPerm: ${canAuthPerm}`);
  return (
    <TopToolbar>
      <UnListButton
        label='resources.templatefields.name_many'
        pathname='/templatefields'
        filter={{templateId: record.id, templateName: record.name}}
        record={record}
        icon={<TemplateFields.ICON/>}
      />
      {isAuthorized(permissions, Activity.TEMPLATE) ?
        <UnEditButtonWithName record={record} resource={resource} tabName={adjTabName}/>
        : null }
      <UnListButton
        label='resources.untanglements.name_many'
        pathname='/untanglements'
        filter={{templateId: record.id, createdatestart: dateNumDaysAgo(7)}}  //templateName: record.name
        record={record}
        icon={<Untanglements.ICON/>}
      />
      {isAuthorized(permissions, Activity.TEMPLATE_PERMISSIONS) ?
        <UnExportButton record={record} resource={resource}
                        label={'Export YAML'} exporter={yamlExporter}/> :
        <></>
      }
      <UnHelpButton helpPage={'/Configuration/template'}/>
    </TopToolbar>
  );
};

/**
 * The template is rendered by TemplateShowAdmin for all 3 main roles ATM.
 * @returns {JSX.Element|null}
 * @constructor
 */
export const TemplateShow = () => {
  const {permissions} = usePermissions();
  log.debug(`{TemplateShow} permissions:`, permissions);
  if (!permissions) return <Loading/>;
  if (hasRole(permissions, Role.SUPERADMIN)) {
    return TemplateShowAdmin(permissions);
  }
  else if (hasRole(permissions, Role.UN_ADMIN)) {
    return TemplateShowAdmin(permissions);
  }
  else if (hasRole(permissions, Role.UN_SUPPORT)) {
    return TemplateShowAdmin(permissions);
  }
  else return <Error/>;
}

/**
 * Display template information with lookup calls to applications and dictionaries (in useEffect).
 * Roles superadmin adn un_admin both use this.
 * un_admin does not display the strategy or the Export YAML button.
 * TODO: un_support view does not display locale name
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export const TemplateShowAdmin = (permissions) => {
  const location = useLocation();
  const {id} = useParams();
  const showContext = useShowController();
  const classes = useStyles();
  const [applications, setApplications] = useState(); // Hook lets us store component state
  const [dictionaries, setDictionaries] = useState();
  const applicationIds = ownerApplicationIds(showContext);
  const {isLoading, record} = showContext;
  log.debug(`{TemplateShowAdmin} isLoading: ${isLoading}, applicationIds: ${applicationIds}, record:`, record);
  const dictionaryIds = record ? record.dictionaries : undefined;
  useGetDictionaries({dictionaryIds, setDictionaries}); // Looks up dictionaries and updates state
  useGetOwnerApplications({applicationIds, applications, setApplications}); // Updates applications state

  if (isLoading) return <Loading/>;
  const {name} = record;
  log.info(`{TemplateShowAdmin} id: ${id}, name: ${name}, applicationIds: ${applicationIds}, applications:`, applications, ', dictionaries:', dictionaries);
  return (
    <ShowContextProvider value={showContext}>
      <Breadcrumbs/>
      <ShowView
        title={<DetailTitle />}
        permissions={permissions}
        actions={
          <TemplateActions tabName={getTabName(location)}/>
          // <ListContextProvider value={showContext}>
          //  <TemplateActions tabName={getTabName(location)} {...props} {...showContext}/>
          // </ListContextProvider>
        }
      >
        <TabbedShowLayout>
          <Tab label="Template">
            <TextField source="name"/>
            <TextField source="description"/>
            <TextField label="Dictionaries"/>
            <DictionaryChips classes={classes} dictionaries={dictionaries}/>
            <ReferenceField label="Locale" source="locale" reference="locales" link={""}>
              <TextField source="name" />
            </ReferenceField>
            <TextField label="Owner applications"/>

            {/*<OwnerChips classes={classes} applicationIds={applicationIds} applications={applications}/>*/}

            {isAuthorized(permissions, Activity.TEMPLATE) ?
              <OwnerChips classes={classes} applicationIds={applicationIds} applications={applications}/> :
              <OwnerChipsNoLink classes={classes} applicationIds={applicationIds} applications={applications}/>
            }
              {/*<OwnerChips classes={classes} applicationIds={applicationIds} applications={applications}/> */}
            {/*<TextField source='owners' label='Owner application IDs'/>)*/}
            {/*{isAuthorized(permissions, Activity.TEMPLATE_PERMISSIONS) ? <TextField source="strategy"/> : ''}*/}
            <TextField source="strategy"/>
          </Tab>

          <Tab label="attributes" path={"attributes"}>
            <ArrayField source="attributes" label=''>
              <TableItemsField items={record.attributes} fieldNames={['name', 'value']}/>
            </ArrayField>
          </Tab>

          <Tab label="row handlers" path={"rowhandlers"}>
            <RowHandlersTable classes={classes} rowHandlerDef={RowHandlerDef} items={record.rowHandlers}/>
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    </ShowContextProvider>
  )
};

export default TemplateShow;