import EmployeeIcon from '@mui/icons-material/PeopleOutline';
import EmployeeList from './EmployeeList';
import EmployeeShow from './EmployeeShow';
import EmployeeEdit from './EmployeeEdit';
import EmployeeCreate from './EmployeeCreate';

export default {
  list: EmployeeList,
  show: EmployeeShow,
  edit: EmployeeEdit,
  create: EmployeeCreate,
  ICON: EmployeeIcon
};