/**
 * By default, the <Create> view starts with an empty record.
 * However, if the location object (injected by react-router-dom) contains a record in its state,
 * the <Create> view uses that record instead of the empty object.
 * That’s how the <CloneButton> works behind the hood.
 *
 * https://v4.mui.com/components/material-icons/#material-icons
 */
import React, {useState} from 'react';
import {
  TextInput, SelectInput,
  TabbedForm, FormTab, Create, useTranslate, RadioButtonGroupInput, useResourceContext, TopToolbar,
  //AutocompleteInput
} from 'react-admin';
import log from 'loglevel';
import {Breadcrumbs} from "../breadcrumbs";
import {CreateTitle} from "../components/InputFields";
import {
  queryParamsToFilterString,
  queryParamString,
  queryParamsToObject
} from "../util/paramUtil";
import Aside from "./TemplateFieldPanel";
import {sources, getSourceName, necessities} from './TemplateFieldConstants';
import {useLocation} from "react-router-dom";
import {formats} from "./TemplateFieldUtil"
import FormatInput from "./FormatInput"
import UnHelpButton from "../button/UnHelpButton";

// TODO: Merge with Edit page

// const sources = [
//   { id: 'expr', name: 'Expression' },
//   { id: 'code', name: 'Dictionary' },
//   { id: 'constant', name: 'Constant' },
//   { id: 'function', name: 'Function' },
//   { id: 'calc', name: 'Calculation' }
// ];
// const getSourceName = (abbr) => sources.filter(s => s.id === abbr)[0].name;
//

const FieldCreateActions = () => {
  return (
    <TopToolbar>
      <UnHelpButton helpPage={'/Configuration/field-formats'}/>
    </TopToolbar>
  );
};
const redirect = (resource, id, {templateId, templateName}) =>
  `${resource}?${queryParamString({templateId, templateName})}`;

/**
 *
 * If templateId is missing from POST data, add defaultValues={{templateId}} for TabbedForm
 * @returns {JSX.Element}
 * @constructor
 */
export const TemplateFieldCreate = () => {
  const location = useLocation();
  const resource = useResourceContext();
  const translate = useTranslate();
  const [source, setSource] = useState('expr');

  const onSourceChange = (props) => {
    const {target} = props;
    console.log(`{onSourceChange} value: ${target.value}, source: ${source}`);
    // 'constant' condition to be removed
    setSource(target.value);
  }

  const args = queryParamsToObject(location);
  log.debug("{TemplateFieldCreate} args:", args);
  const {templateId, templateName} = args;
  const parent = 'templates';
  //const optionRenderer = nec => <Fragment>{nec.name}&nbsp;<NecessityField necessity={nec.id} {...props}/></Fragment>;
  const necessityRenderer = nec => nec.name;
  const breadcrumbs = [
    {url: `/${parent}`, title: translate(`resources.${parent}.name_many`), resource: parent},
    {url: `/${parent}/${templateId}/show?${queryParamsToFilterString({name: templateName})}`,
      title: templateName, resource: parent},
    {url: `/${resource}?${queryParamsToFilterString({templateId, templateName})}`,
      title: translate(`resources.${resource}.name_many`), resource},
    {url: ``, title: translate(`navigation.create`), resource},
  ];
  log.debug("{TemplateFieldCreate} showing");
  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Create
        title={<CreateTitle />}
        aside={<Aside/>}
        actions={<FieldCreateActions/>}
        redirect={redirect}
      >
        <TabbedForm
          // defaultValues={{templateId}}
        >
          <FormTab label="Field">
          <TextInput source="title" fullWidth/>
          {/*<TextInput source="format"/>*/}

          <FormatInput source="format" name="format" label="Format" fullWidth
                       formats={formats}
                       // onChange={onFormatsChange}
          />
          {/*<AutocompleteInput source="format"*/}
          {/*                   choices={formats.map(f => ({id: f, name: f}))}*/}
          {/*                   onCreate={() => {'New'}}*/}
          {/*/>*/}


            {source === "expr" ?
              <TextInput source={'value'} label={getSourceName('expr')}
                         InputProps={{style: {fontFamily: 'courier'}}}
                         multiline={true}
                         rows={15}
                         fullWidth
              /> : null}
            {source === "constant" ?
              <TextInput source={'value'} label={`Constant value`} fullWidth /> :
              null}
            {source === "code"?
              <TextInput source="name" label={'Dictionary Entry'} fullWidth
              /> : null}
            {source === "calc"?
              <TextInput source="name" label={`Calculation name`} fullWidth
              /> : null}
            {source === "function"?
              <TextInput source="name" label={`Function name`} fullWidth
              /> : null}

            <TextInput source={'notes'} multiline={true} rows={3} fullWidth />
          </FormTab>
          <FormTab label='Options'>
            <SelectInput source="source"
                         choices={sources}
                         fullWidth
                         onChange={onSourceChange}
                         defaultValue={"expr"}
            />
            <RadioButtonGroupInput source="necessity"
                                   choices={necessities}
                                   optionText={necessityRenderer}
                                   translateChoice={false}
                                   row={false}
                                   defaultValue={"expected"}
            />

            <RadioButtonGroupInput source="state" label="Visibility" defaultValue={'s'} choices={[
              { id: 's', name: 'Visible' },
              { id: 'h', name: 'Hidden' },
            ]} />
          </FormTab>
        </TabbedForm>
      </Create>
    </>
  )
};

export default TemplateFieldCreate;
