import React, {useState} from 'react';
import {
  ShowContextProvider,
  useShowController,
  ShowView,
  TabbedShowLayout, Tab,
  Loading,
  TextField, SelectField,
  TopToolbar, useTranslate, FunctionField, usePermissions, useResourceContext, useRecordContext
} from 'react-admin';
import NotInterested from "@mui/icons-material/NotInterested";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import {makeStyles} from '@mui/styles';
import log from 'loglevel';
import {Breadcrumbs} from "../breadcrumbs";
import {NecessityField} from './Necessity';
import UnEditButtonWithFilter from "../button/UnEditButtonWithFilter";
import {getTabName, queryParamFilter, queryParamString} from "../util/paramUtil";
import {Activity, isAuthorized} from "../auth/authorization";
import {useGetDictionaries} from "../templates/TemplateData";
import {MultiSourceField} from "./MultiSourceField";
import {sources} from './TemplateFieldConstants';
import {useLocation, useParams} from "react-router-dom";
import {DetailTitle} from "../components/InputFields";
import UnHelpButton from "../button/UnHelpButton";

const useStyles = makeStyles(() => ({
  expression: {
    paddingBottom: '100px',
    fontFamily: 'courier',
    // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
    '& svg': { color: 'orange' }
  },
  notlast: { width: '16em', display: 'inline', float: 'left' },
}));

const TemplateFieldActions = (props) => {
  const {permissions} = usePermissions();
  const record = useRecordContext();
  const {tabName} = props;
  return (
    <TopToolbar>
      {isAuthorized(permissions, Activity.TEMPLATE) ?
      <UnEditButtonWithFilter filter={{
          //id: record.id,
          //name: record.title,
          templateId: record.templateId,
          templateName: record.templateName
        }}
        record={record}
        tabName={tabName}
      />
      : null}
      <UnHelpButton helpPage={'/Configuration/field-formats'}/>
    </TopToolbar>
  );
}

export const TemplateFieldShow = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const location = useLocation();
  const resource = useResourceContext();
  const {id} = useParams();
  const [dictionaries, setDictionaries] = useState();
  let args = queryParamFilter(location);
  const {templateId} = args;
  log.debug(`{TemplateFieldShow} templateId: ${templateId}`); // Get templateName from record
  const showContext = useShowController({
    resource,
    id,
    queryOptions: {meta: {templateId}}
  });
  const {record, isLoading} = showContext;
  const dictionaryIds = record ? record.dictionaries : undefined;
  useGetDictionaries({dictionaryIds, setDictionaries}); // Updates dictionaries state
  if (isLoading) return <Loading />;
  log.debug("{TemplateFieldShow} record:", record);
  const {templateName} = record;
  const parent = 'templates';
  const dictNames = dictionaries ? dictionaries.map(d => d.name) : [];
  log.debug(`{TemplateFieldShow} dictNames after map:`, dictNames);
  log.debug(`{TemplateFieldShow} templateId: ${templateId}, templateName: ${templateName}`);
  log.info(`{TemplateFieldShow} field id: ${record.id}, title: ${record.title}, name: ${record.name}`);
  const breadcrumbs = [
    {url: `/${parent}`, title: translate(`resources.${parent}.name_many`), resource: parent},
    {url: `/${parent}/${templateId}/show?${queryParamString({name: templateName})}`, title: templateName, resource: parent},
    {url: `/${resource}?${queryParamString({templateId, templateName})}`, title: translate(`resources.${resource}.name_many`), resource},
    {url: `/${resource}/${record.id}/show?${queryParamString({templateId, templateName, name: record.name})}`, title: record.title, resource},
  ];
  return (
    <ShowContextProvider value={showContext}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <ShowView title={<DetailTitle prefix={"Template Field"} titleField={'title'}/>}
                actions={<TemplateFieldActions tabName={getTabName(location)}/>}
      >
        <TabbedShowLayout>
          <Tab label="field">
            <TextField source="title" />
            <TextField source="format" />
            {/*<TextField label={'Formula'} />*/}
            <MultiSourceField label="Formula" classes={classes} dictNames={dictNames}/>
            {/*<TextField label={'  '} />*/}
            <TextField source="notes" />
          </Tab>

          <Tab label="options" path="options">
            <SelectField source="source" choices={sources}/>
            <TextField source="necessity"/> <NecessityField label = '' source="necessity"
                                                            sx={{
                                                              height: 50,
                                                              width: 200,
                                                              display: 'flex',
            }}/>
            <FunctionField label="Visible" render={(record) => {
              return record.state === 'h' ?
                <NotInterested style={{color: "gray"}}/> :
                <CheckOutlinedIcon style={{color: "green"}}/>
            }
            }/>
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    </ShowContextProvider>
  )
};

export default TemplateFieldShow;