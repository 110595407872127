import React, {
  //useEffect, useState
} from 'react';
import {
  EditView, TabbedForm, FormTab,
  //TextField,
  TextInput,
  SelectInput,
  SelectArrayInput,
  useTranslate,
  //Link,
  useGetList,
  Loading,
  useEditController, EditContextProvider, usePermissions,
  //useDataProvider
} from 'react-admin';
import log from 'loglevel';
import {DetailTitle, UnEditToolbar } from "../components/InputFields";
import {UnEditActions, redirectWithTab} from "../components/EditActions";
import {getTabName} from "../util/paramUtil";
import {Breadcrumbs} from "../breadcrumbs";
import {Activity, isAuthorized} from "../auth/authorization";
import {useLocation, useParams} from "react-router-dom";

//const stopPropagation = e => e.stopPropagation();

// const useStyles = makeStyles((theme) => ({
//   inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
//   root: {
//     display: 'flex',
//     justifyContent: 'left',
//     flexWrap: 'wrap',
//     '& > *': {
//       margin: theme.spacing(0.5),
//     }
//   }
// }));

// const RepresentativeEditActions = ({ basePath, data, resource }) => (
//   <TopToolbar>
//     <ShowButton basePath={basePath} record={data} />
//     <CloneButton basePath={basePath} record={data} />
//   </TopToolbar>
// );

// const TemplateChipField = ({classes, record}) => (
//   <Link className={classes.link} key={1} to={'/templates'} onClick={stopPropagation}>
//     <Chip label={record.template} key={1} clickable={true}/>
//   </Link>
// );

//const redirect = (basePath, id, {name}) => `${basePath}/${id}/show?${queryParamString({name})}`;

/**
 * Mnemonic for the two functions:
 * parse(): display -> record
 * format(): record -> display
 */
export const RepresentativeEdit = () => {
  const {permissions} = usePermissions();
  if (isAuthorized(permissions, Activity.DICTIONARY_PERMISSIONS)) {  // superadmin
    return RepresentativeEditSuperAdmin();
  }
  else return null;  //RepresentativeEditUntanglerAdmin(props);
}

/**
 * The endpoint (PUT /representatives) takes properties template and templateId for the name and ID
 * in this app/rep.
 * 1st version just takes template from a text box, containing the name.
 * In 2nd version, user views all available templates in a select list. The associated templateId
 * is automatically sent with the request. We need to manually set the template name too.
 * @returns {JSX.Element|null}
 * @constructor
 */
const RepresentativeEditSuperAdmin = () => {
  const redirect = (resource, id) => redirectWithTab(location, resource, id);

  const transform = (data) => {
    if (data.template) delete data.template;
    return data;
  };

  const translate = useTranslate();
  const location = useLocation();
  const {id} = useParams();
  const listContext = useEditController({redirect});
  log.debug(`{RepresentativeEditSuperAdmin} listContext:`, listContext);
  const {isLoading: isSitesLoading, data: sites} = useGetList(
      'sites',
    {sort: {field: 'name', order: 'ASC'}}
  // {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}}
    );
  const {isLoading: isTemplatesLoading, data: templates} = useGetList(
    'templates',
    {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}}
  );
  const {isLoading: isApplicationsLoading, data: applications} = useGetList(
    'applications', // 'representatives' clashes with main record
    {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}}
  );

  //let applications;
  // useEffect(() => {
  //   if (!listContext.record) {  // This should not happen
  //     log.debug(`{RepresentativeEditSuperAdmin.useEffect} Representative record not yet loaded`);
  //     return;
  //   }
  //   //else log.debug(`{RepresentativeEditSuperAdmin.useEffect} Representative record loaded`);
  //   (async () => {
  //     const res = await dataProvider.getList('representatives', {filter: {}})
  //       .catch(error => log.error(`{RepresentativeEditSuperAdmin} useEffect - applications error:`, error));
  //     log.info(`{RepresentativeEditSuperAdmin.useEffect} applications res:`, res);
  //     setApplications(res.data ? res.data: undefined);
  //   })();
  // }, [listContext.record]);

  const {record, isLoading} = listContext;
  if (isLoading) return <Loading />;
  if (isSitesLoading || isTemplatesLoading || isApplicationsLoading) return <Loading/>;
  log.debug(`{RepresentativeEditSuperAdmin} id: ${id}, name: ${record.name}`);
  const selectTemplates = templates.map((t) => ({
    id: t._id,
    templateId: t._id,
    name: t.name}));

  return (
    <EditContextProvider value={listContext}>
      <Breadcrumbs/>
      <EditView
        title={<DetailTitle prefix={translate(`resources.representatives.name`, {smart_count: 1})}/>}
        actions={<UnEditActions tabName={getTabName(location)} helpPath={'/Configuration/application'}/>}
        transform={transform}
      >
        <TabbedForm toolbar={<UnEditToolbar/>}>
          <FormTab label="main">
            <TextInput source="name" fullWidth/>
            <TextInput source="uname" fullWidth/>
            <TextInput source="email" fullWidth/>
            <TextInput source="tel"/>
            {/*<TextInput source="template"/>*/}
            <SelectArrayInput label="Template" source="templateIds" choices={selectTemplates}
                              fullWidth
                              // optionValue="templateIds"
                              //onChange={onChange}
            />
            <SelectInput label="Site" source="site" choices={sites} defaultValue="" fullWidth/>
            <TextInput label="Id" source="id" disabled/>
          </FormTab>
          <FormTab label="social media" path={"socialmedia"}>
            <TextInput source="linkedin" fullWidth/>
            <TextInput source="facebook" fullWidth/>
            <TextInput source="twitter" fullWidth/>
            {/*Stored as array. Viewed as comma-separated string.*/}
            <TextInput source="benefits" fullWidth
                       format={bens => bens ? bens.join() : ''}
                       parse={benStr => benStr.split(',')}
            />
          </FormTab>
          <FormTab label="Nested Applications" path={"nested"}>
            <SelectArrayInput label="Applications" source="children" choices={applications}
                              fullWidth
            />
          </FormTab>
        </TabbedForm>
      </EditView>
    </EditContextProvider>
  )
}

/**
 * Not currently used as un_admin not given permissions to change applications (reps)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
// const RepresentativeEditUntanglerAdmin = (props) => {
//   const translate = useTranslate();
//   const classes = useStyles();
//   const {id, location, resource} = props;
//   const {name} = queryParamObject(location);
//   log.debug(`{RepresentativeEditUntanglerAdmin} id: ${id}, name: ${name}, props:`, props);
//   return (
//     <React.Fragment>
//       <UnBreadcrumbs.Edit id={id} name={name} resource={resource}/>
//       <Edit
//         title={<DetailTitle prefix={translate(`resources.representatives.name`, {smart_count: 1})}/>}
//         actions={<UnEditActions/>}
//         {...props}>
//         <TabbedForm redirect={redirect} toolbar={<UnEditToolbar/>}>
//           <FormTab label="main">
//             <TextField source="name" />
//             <TextField source="uname" />
//             <TextInput source="email" />
//             <TextInput source="tel"/>
//             <TextField label="Templates"/>
//             <TemplateChipField classes={classes}/>
//           </FormTab>
//         </TabbedForm>
//       </Edit>
//     </React.Fragment>
//   );
// }

export default RepresentativeEdit;