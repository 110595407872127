import React from 'react';
//import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


//import withStyles from '@mui/styles/withStyles';

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',
//     marginTop: theme.spacing(3),
//     overflowX: 'auto',
//   },
//   table: {
//     minWidth: 650,
//   },
// }));

// const styles = {
//   root: {
//     width: '100%',
//       //marginTop: theme.spacing(3),
//     overflowX: 'auto'
//   },
//   table: {
//     minWidth: 650
//   }
// };

// data[controllerProps.ids[0]].benefit_unames
// headings, rows
export function UnTable(props) {
  //const classes = useStyles();
  console.log("{UnTable} props:", props);
  let {headings, fields, rows} = props;
  console.log("{UnTable} headings:", headings);
  // let {ids, data, rows} = props;
  // console.log("{UnTable} ids:", ids);
  // console.log("{UnTable} ids[0]:", ids[0]);
  // let benefit_unames = (ids && ids.length > 0) ? data[ids[0]].benefit_unames : null;
  if (rows) {
    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              {headings.map(heading => (
                <TableCell key={heading} sx={{fontWeight: 700}}>{heading}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.id}>
                {fields.map(field => (
                  <TableCell component="th" scope="row" key={field}>
                    {row[field]}
                  </TableCell>
                ))}
                {/*<TableCell align="right">{row.total}</TableCell>*/}
                {/*<TableCell align="right">{row.canada_life}</TableCell>*/}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
  else {
    return null;
  }
}

//export default withStyles(styles)(UnTable);
export default UnTable;