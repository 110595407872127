import {Link} from "react-router-dom";
// import {stringify} from "query-string";
import React from "react";
import ContentAdd from '@mui/icons-material/Add';
import {
  useResourceContext,
  //useRecordContext,
  Button
} from 'react-admin';
import log from 'loglevel';
import {queryParamsToFilterString} from "../util/paramUtil";
//import ContentCreate from '@mui/icons-material/Create';  // Used for Edit not Create

/**
 * Component to render a Create button for a resource with filter arguments.
 * Useful for nested resources like template field or dictionary entry that need a parent ID passed.
 * The object in filter (e.g. {templateId}) is appended to the client URL.
 * Also, the form is initialised with the ID and name in the filter.
 * Serialisation also done in queryParamsToFilterString().
 *
 * <UnCreateButton label="Create" filter={filter}
 *                 idProp={'templateId'} nameProp={'templateName'} icon={<ContentAdd />}/>
 *
 * state={{record: filter}} initialises the record to be subsequently saved.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const UnCreateButton = (props) => {
  const resource = useResourceContext();
  log.debug("{UnCreateButton} props:", props);
  //const classes = useStyles();
  //let {filter, idProp, nameProp = '', label = 'Create', icon = <ContentAdd />} = props;
  let {filter, label = 'Create', icon = <ContentAdd />} = props;
  log.debug("{UnCreateButton} filter:", filter);
  const filterStr = queryParamsToFilterString(filter);
  log.debug("{UnCreateButton} filterStr:", filterStr);
  return <Button
    //className={classes.button}
    component={Link}
    to= {`/${resource}/create?${filterStr}`}
    state={{record: filter}}
    //to={{
      // pathname: `/${resource}/create`,
      // search: stringify({
      //   filter: JSON.stringify(filter)  // {templateId}
      // })
      // state: {
      //   [nameProp]: filter[nameProp],
      //   [idProp]: filter[idProp], // e.g. filter.templateId
      //   record: filter  // Sets default value in create form - e.g. {templateId}
      // }

    label={label}
    title={label}
  >
    {icon}
  </Button>
};

export default UnCreateButton;