import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
// import FormatListBulletedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import TemplateFieldList from './TemplateFieldList';
import TemplateFieldShow from './TemplateFieldShow';
import TemplateFieldEdit from './TemplateFieldEdit';
import TemplateFieldCreate from './TemplateFieldCreate';

export default {
  list: TemplateFieldList,
  show: TemplateFieldShow,
  create: TemplateFieldCreate,
  edit: TemplateFieldEdit,
  ICON: FeaturedPlayListOutlinedIcon
};