import React, {
  useState,
  ErrorInfo,
  ReactNode,
  ComponentType,
  HtmlHTMLAttributes,
} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import clsx from 'clsx';
import {styled, SxProps} from '@mui/material/styles';
// import { CoreLayoutProps } from 'ra-core';
import {
  AppBarProps,
  CoreLayoutProps
} from 'react-admin';
import UnAppBar  from './UnAppBar';
import { Sidebar as DefaultSidebar, MenuProps } from 'react-admin';
// import { Menu as DefaultMenu, MenuProps } from './Menu';
import Menu from './Menu';
import { Error, ErrorProps } from 'react-admin';
import { SkipNavigationButton } from 'react-admin';
import { useSidebarState } from 'react-admin';
import { Inspector } from 'react-admin';

const DefaultMenu = Menu;
const DefaultAppBar = UnAppBar;

/**
 * paddingTop is added to content to align breadcrumbs with top of side menu.
 *   return <Layout {...props}
 *                  appBar={UnAppBar}
 *                  sidebar={UnSidebar}
 *                  menu={Menu}
 *   />;
 * @param props
 * @constructor
 */
export const Layout = (props: LayoutProps) => {
  const {
    appBar: AppBar = DefaultAppBar,
    children,
    className,
    dashboard,
    error: errorComponent,
    menu: Menu = DefaultMenu,
    sidebar: Sidebar = DefaultSidebar,
    title,
    ...rest
  } = props;

  const [open] = useSidebarState();
  const [errorInfo, setErrorInfo] = useState<ErrorInfo>(null);

  const handleError = (error: Error, info: ErrorInfo) => {
    setErrorInfo(info);
  };

  return (
    <StyledLayout className={clsx('layout', className)} {...rest}>
      <SkipNavigationButton />
      <div className={LayoutClasses.appFrame}>
        <AppBar open={open} title={title} />
        <main className={LayoutClasses.contentWithSidebar}>
          <Sidebar>
            <Menu hasDashboard={!!dashboard} />
          </Sidebar>
          <div id="main-content" className={LayoutClasses.content}>
            <ErrorBoundary
              onError={handleError}
              fallbackRender={({ error, resetErrorBoundary }) => (
                <Error
                  error={error}
                  errorComponent={errorComponent}
                  errorInfo={errorInfo}
                  resetErrorBoundary={resetErrorBoundary}
                  title={title}
                />
              )}
            >
              {children}
            </ErrorBoundary>
          </div>
        </main>
        <Inspector />
      </div>
    </StyledLayout>
  );
};

export interface LayoutProps
  extends CoreLayoutProps,
    Omit<HtmlHTMLAttributes<HTMLDivElement>, 'title'> {
  appBar?: ComponentType<AppBarProps>;
  className?: string;
  error?: ComponentType<ErrorProps>;
  menu?: ComponentType<MenuProps>;
  sidebar?: ComponentType<{ children: ReactNode }>;
  sx?: SxProps;
}

export interface LayoutState {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

const PREFIX = 'RaLayout';
export const LayoutClasses = {
  appFrame: `${PREFIX}-appFrame`,
  contentWithSidebar: `${PREFIX}-contentWithSidebar`,
  content: `${PREFIX}-content`,
};

const StyledLayout = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1,
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  position: 'relative',
  minWidth: 'fit-content',
  width: '100%',
  color: theme.palette.getContrastText(theme.palette.background.default),

  [`& .${LayoutClasses.appFrame}`]: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(7),
    },
  },
  [`& .${LayoutClasses.contentWithSidebar}`]: {
    display: 'flex',
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [`& .${LayoutClasses.content}`]: {
    backgroundColor: theme.palette.background.default,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 0,
    padding: 0,
    paddingTop: "15px",
    [theme.breakpoints.up('xs')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(1),
    },
  },
}));