import React from 'react';
import Card from '@mui/material/Card';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

const typeObj = {
  s: 'String',
  n: 'Number',
  d: 'Date'
}

export default function AttributeTable(props) {
  const {attributes} = props;
  return (
    <Card>
      <Table sx={{padding: 2}} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attributes.map(attr => (
            <TableRow key={attr.id}>
              <TableCell>{attr.name}</TableCell>
              <TableCell>{attr.value}</TableCell>
              <TableCell>{(attr.type && typeObj[attr.type])? typeObj[attr.type] : ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>);
}