import React from 'react';
import {
  ShowView,
  TopToolbar,
  TabbedShowLayout,
  //Title,
  Loading,
  ShowContextProvider,
  useShowController,
  useResourceContext,
  usePermissions,
  useRecordContext,
  TextField, DateField, Tab, ArrayField,
  Datagrid,
  FunctionField
} from 'react-admin';
import {useLocation, useParams} from "react-router-dom";
import {queryParamFilter, queryParamsToFilterString, getTabName} from "../util/paramUtil";
import {Breadcrumbs} from "../breadcrumbs";
import {DetailTitle} from "../components/InputFields";
import UnEditButtonWithFilter from "../button/UnEditButtonWithFilter";
import {isValidTabName} from "../components/EditActions";
import {Activity, isAuthorized} from "../auth/authorization";
import {FullNameField, fullNameField} from './EmployeeUtil';
// import {
//   PersonalTab,
//   EmploymentTab,
//   BenefitSetTab,
//   BenefitSetHistoryTab
// } from './EmployeeShowTabs';

// As the Show page has more tabs than the Edit page, only select the tab if in this list
const TABNAMES = ['employment'];

// Buttons at top right
const EmployeeShowActions = ({tabName}) => {
  const record = useRecordContext();
  console.log("{EmployeeShowActions} record:", record);
  const {permissions} = usePermissions();
  const adjTabName = isValidTabName(tabName) && TABNAMES.includes(tabName) ? tabName : '';
  //console.log("{EmployeeShowActions} adjTabName:", adjTabName);
  return (
  <TopToolbar>
    {isAuthorized(permissions, Activity.USER_READ) ?
    <UnEditButtonWithFilter
      filter={{
        employer_id: record.employer._id,
        employer_name: record.employer.name
      }}
      tabName={adjTabName}
       /> :
      null
    }
  </TopToolbar>
)};

const TotalField = ({data}) => {
  const benefits = data.benefits;
  const total = benefits.reduce((accum, b) =>  accum + b.er_contribution, 0);
  return (
    <ul>
      {total}
      {/*{record.tags.map(item => (*/}
        {/*<li key={item.name}>{item.name}</li>*/}
      {/*))}*/}
    </ul>
  );
};
TotalField.defaultProps = {addLabel: true};

export const EmployeeShow = () => {
  const permissions = usePermissions();
  const location = useLocation();
  const {id} = useParams();
  const resource = useResourceContext();
  const {employer_id} = queryParamFilter(location);
  const showContext = useShowController({
    id,
    queryOptions: {meta: {employer_id}}
  });
  const {record, isLoading} = showContext;
  if (isLoading) return <Loading/>;
  console.log(`{EmployeeShow} record:`, record);
  const employer_name = record.employer.name;
  if (!record.employee) return null;

  const parent = 'employers';
  const breadcrumbs = [
    {url: `/${parent}`, label: parent, resource: parent},
    {url: `/${parent}/${employer_id}/show?${queryParamsToFilterString({name: employer_name})}`, title: employer_name, resource: parent},
    {url: `/${resource}?${queryParamsToFilterString({employer_id, employer_name})}`, label: resource, resource},
    {url: ``, title: <FullNameField record={record} />, resource},
  ];
  return (
    <ShowContextProvider value={showContext}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

        <ShowView
          title={<DetailTitle value={fullNameField(record)}/>}
          actions={
            <EmployeeShowActions
              employerId={employer_id}
              tabName={getTabName(location)}
              permissions={permissions}
            />}
        >
            <TabbedShowLayout>

              <Tab label="personal">
                <TextField source="employee.first_name"/>
                <TextField source="employee.last_name"/>
                <TextField source="employee.gender"/>
                <DateField source="employee.dob"/>
                <TextField source="employee.street1"/>
                <TextField source="employee.street2"/>
                <TextField source="employee.city"/>
                <TextField source="employee.postcode"/>
                {/*<CreateDateField/>*/}
              </Tab>

              <Tab label="employment" path="employment">
                <TextField source="employer.name"/>
                <DateField source="start_date"/>
                <DateField source="end_date"/>
                <TextField source="work_email"/>
                <TextField source="work_phone"/>
                <TextField source='grade' />
                <TextField source='notes' />
              </Tab>

              <Tab label="benefits" path="benefits">
                <ArrayField source="benefit_set.benefits" >
                  <Datagrid>
                    <TextField source="display_name" label='Display Name' />
                    <TextField source="benefit_identifier" />
                    <FunctionField label='Cost'
                                   render={data => {
                                     let value = data.er_contribution;
                                     return isNaN(value) ? '0.00' : (value/100).toFixed(2)}
                                   }
                    />
                    <TextField source="supplier" />
                  </Datagrid>
                </ArrayField>
              </Tab>

              <Tab label="Benefit Set History">
                <ArrayField source="benefit_sets" >
                  <Datagrid>
                    <DateField source="evaluation_date" />
                    {/*<TotalField />*/}
                    <FunctionField label='Total' render={data => {
                      const total = data.benefits.reduce((accum, b) =>  accum + b.er_contribution, 0);
                      return isNaN(total) ? '0.00' : (total/100).toFixed(2);
                    }} />
                    <ArrayField source="benefits" >
                      <Datagrid>
                        <TextField source='benefit_identifier' />
                        {/*<UnMoneyField source="er_contribution" label='Cost' />*/}
                        <FunctionField label='Cost'
                                       render={data => {
                                         let value = data.er_contribution;
                                         return isNaN(value) ? '0.00' : (value/100).toFixed(2)}
                                       }
                        />
                        <TextField source="supplier" />
                      </Datagrid>
                    </ArrayField>
                  </Datagrid>
                </ArrayField>
              </Tab>
            </TabbedShowLayout>
          </ShowView>
        </ShowContextProvider>
  );
};

export default EmployeeShow;
