import {makeStyles} from '@mui/styles';
import React from "react";
import {
  TextInput,
  Toolbar, SaveButton,
  useRecordContext,
  useTranslate, useResourceContext
} from 'react-admin';
import {getSystem} from './system.js'
import log from 'loglevel';

// This doesn't change TextInput width when className is set in UnTextInput
const useTextInputStyles = makeStyles({
    root: {
      width: '100%'
      //height: '100px' // Adjusts separation of fields vertically
    }
  },
  { name: 'UnTextInput'}
);

/**
 * When setting the className prop on a TextInput component, the underlying material-ui <TextField> receives it,
 * and renders with custom styles. You can also set the underlying component variant and margin that way.
 * Material-UI's class names follow a simple pattern in development mode:
 *  Mui[component name]-[style rule name]-[UUID].
 * https://material-ui.com/api/text-field/
 * https://material-ui.com/customization/components/#overriding-styles-with-classes
 *
 * The classes attribute on TextInput causes the following error but still works:
 * Material-UI: The key `root` provided to the classes prop is not implemented in undefined.
 * You can only override one of the following:
 * clearIcon,visibleClearIcon,clearButton,selectAdornment,inputAdornedEnd.
 *
 */
const UnTextInput = ({...props}) => {
  const styles = useTextInputStyles();
  return (
    // Does not adjust width
    <TextInput
      fullWidth={true}
      className={
        styles.root
        //root: styles.root   // This sets .UnTextInput-root-279 {width: 603px;}
      }
      {...props}
    />
  );
};

const useMultiTextInputStyles = makeStyles({
    // root: {
    //   width: '100%'
    // },
  },
  {name: 'UnMultiTextInput'}
);

/**
 * Additional props are passed down to the underlying component (usually a material-ui component).
 * For instance, when setting the className prop on a TextInput component, the underlying material-ui
 * <TextField> receives it, and renders with custom styles.
 * You can also set the underlying component variant and margin that way.
 * https://marmelab.com/react-admin/Inputs.html
 * https://material-ui.com/api/text-field/
 * TODO: translate does not use our 18n lookup in ResettableTextField so label is incorrect
 *
 * Additional props are passed down to the underlying component (usually a material-ui component).
 * For instance, when setting the `fullWidth` prop on a `TextInput` component, the underlying
 * material-ui `<TextField>` receives it, and goes full width.
 * https://github.com/marmelab/react-admin/commit/3881a3dc2dcfe0024c723ee1ca352268994a7c82
 *
 * @param classes
 * @param props
 * @returns {*}
 * @constructor
 */
const UnMultiTextInput = ({rows = 3, ...props}) => {
  const styles = useMultiTextInputStyles();
  return (
    <TextInput
      multiline={true}
      rows={rows}
      fullWidth
      // options={{
      //   root: styles.root
      // }}
      classes={{
        root: styles.root   // This sets .UnMultiTextInput-root-279 {width: 700;}
      }}
      {...props}
    />
  );
};

//const nameCapitalized = (name) => name.charAt(0).toUpperCase() + name.slice(1);

// Removes Delete button from bottom of form
const UnEditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

const useSystem = () => getSystem();

const displaySystem = {
  dev: 'Development',
  qa: 'QA',
  beta: 'Beta',
  prod: ''
}

const useSystemName = () => {
  const system = getSystem();
  return system && displaySystem[system] ? ` (${displaySystem[system]})` : ''
}

const SimpleTitle = ({prefix}) => {
  const system = useSystem(); // dev, QA, beta or production
  console.log(`{SimpleTitle} prefix: ${prefix}`);
  return <span>Test {prefix}
    {system && displaySystem[system] ? ` (${displaySystem[system]})` : ''}</span>;
}

/**
 * Display title in application bar to right of Untangl logo.
 * Assume record field is "name", unless specified in titleField.
 * @param prefix  Optional. Overrides default resource if specified.
 * titleField   Name of property which contains name to display, if not 'name'.
 * value        Actual value to be displayed for this resource.
 * @returns {JSX.Element}
 * @constructor
 */
const DetailTitle = ({prefix, titleField, value}) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const resource = useResourceContext();
  const system = useSystem(); // dev, QA, beta or production
  const defaultTitleField = 'name'; // Default name of record property is 'name'
  const selTitle = titleField ? titleField : defaultTitleField;
  const defaultPrefix = resource ? translate(`resources.${resource}.name`, {smart_count: 1}) : '';
  const selPrefix = prefix ? prefix : defaultPrefix;
  console.log(`{DetailTitle} selPrefix: ${selPrefix}, value: ${value}`);
  if (value) {
    //console.log(`{DetailTitle} value:`, value);
    return <span>{selPrefix}: {value}
      {system && displaySystem[system] ? ` (${displaySystem[system]})` : ''}</span>;
  }
  else return <span>{selPrefix} {record ? `: ${record[selTitle]}` : ''}
    {system && displaySystem[system] ? ` (${displaySystem[system]})` : ''}</span>;
};

/**
 * Displays title in app bar for a Create page.
 * TODO: i18n New
 * @param prefix
 * @returns {JSX.Element}
 * @constructor
 */
const CreateTitle = ({prefix}) => {
  const translate = useTranslate();
  const resource = useResourceContext();
  const system = useSystem(); // dev, QA, beta or production
  const defaultPrefix = translate(`resources.${resource}.name`, {smart_count: 1});
  const selPrefix = prefix ? prefix : defaultPrefix;
  return <span>New {selPrefix}
    {system && displaySystem[system] ? ` (${displaySystem[system]})` : ''}</span>;
};

/**
 * Display a title (in Appbar) in a list page.
 * Display plural of resource with no fields after in application bar.
 * No single name value available from record as there are multiple records in a list,
 * so use nameValue passed in explicitly from caller.
 * @param prefix
 * @param nameValue Value of name to be displayed
 * @returns {JSX.Element}
 * @constructor
 */
const ListTitle = ({prefix, nameValue}) => {
  const translate = useTranslate();
  const resource = useResourceContext();
  const system = useSystem(); // dev, QA, beta or production
  if (!nameValue) log.warn(`{ListTitle} No nameValue specified!`);
  //const defaultTitleField = 'name';
  //const selTitle = titleField ? titleField : defaultTitleField;
  const defaultPrefix = translate(`resources.${resource}.name`, {smart_count: 2});
  const selPrefix = prefix ? prefix : defaultPrefix;
  return <span>{selPrefix} {nameValue ? ': ' + nameValue : ''}
    {system && displaySystem[system] ? ` (${displaySystem[system]})` : ''}</span>;
};

export {
  UnTextInput,
  UnMultiTextInput,
  UnEditToolbar,
  DetailTitle,
  CreateTitle,
  ListTitle,
  SimpleTitle,
  useSystemName
}