import React, {useState} from 'react';
import {
  Create, SaveButton, SelectInput,
  SimpleForm,
  TextInput, Toolbar,
  useNotify, useRedirect,
  Loading,
  useGetList, useResourceContext
} from 'react-admin';
import {Breadcrumbs} from "../breadcrumbs";
import log from 'loglevel';
import SetupDropPanel from "./SetupDropPanel";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import {CreateTitle} from "../components/InputFields";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  cells: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '50ch'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

/**
 * Create toolbar at bottom of form containing Save button.
 * The <SaveButton> specifies a callback to transform the record before it is saved to the API (POST /setups).
 * After the save (after the API call returns) onSuccess in mutationOptions is called, passing
 * in the response data, which is used to redirect the user to the applications (representatives) page.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SetupToolbar = (props) => {
  // The transform data arg is the form's data before being saved (so no IDs yet, etc)
  const transform = data => {
    return ({...data, dataFiles});  // Add dataFiles to record for saving to API
  };
  const notify = useNotify();
  const redirect = useRedirect();
  const {dataFiles, ...rest} = props;
  return (
    <Toolbar {...rest} >
      <SaveButton type="button"
                  transform={transform}
                  mutationOptions={{
                    onSuccess: (data) => {
                      log.debug(`{onSuccess} applicationName:`, data.applicationName);
                      // Note notify args have changed (Mar 23).
                      // See https://marmelab.com/react-admin/Upgrade.html#usenotify-now-takes-an-options-object
                      notify('notification.onboarded', {
                          type: 'info',
                          name: data.applicationName
                        });
                      redirect(`/representatives/${data.id}/show?name=${data.applicationName}`);
                    }
                  }}
      />
    </Toolbar>
  )
};

const Aside = () => (
  <div style={{ width: 300, margin: '1em' }}>
    <Typography variant="h5" color="textSecondary" gutterBottom={true}>
      Client Onboarding
    </Typography>
    <Typography variant="body2" gutterBottom={true}>
      This is a quick way of getting an Untangler client up and running, by setting up the following:
    </Typography>
    <Typography variant="body2" gutterBottom={true}>
      <li>an application (for permissions)</li>
      <li>a user (for sign-in) with first & last name, email address and support role</li>
      <li>a set of cloned dictionaries, defined by the industry choice</li>
      <li>an output template, defined by dropping a sample MS Excel worksheet into the box with dashed lines</li>
    </Typography>
    <Typography variant="body2" gutterBottom={true}>
      Note that the application name will be appended to the name of a dictionary. E.g. <i>person</i> is cloned
      to become <i>person_abc</i> for application <i>ABC</i>.
    </Typography>
    <Typography variant="body2" gutterBottom={true}>
      <b>Industry to dictionary assignments</b>
    </Typography>
    <Typography variant="body2" gutterBottom={true}>
      <li>Human Resources: Person, Employer, Employment</li>
      <li>Insurance: Person, Employer, Employment, Insurance</li>
    </Typography>
  </div>
);

/**
 * dataFiles is populated after SetupDropPanel receives uploaded data file.
 * It is then added to the posted data (POST /setups) in the SaveButton transform call back.
 * Note that POST response contains id set to application ID (see dataProviderRest.create)
 * @returns {JSX.Element|null}
 * @constructor
 */
const SetupCreate = () => {
  const classes = useStyles();
  const [dataFiles, setDataFiles] = useState([]);
  const resource = useResourceContext();
  const {
    isLoading: isIndustriesLoading,
    data: industries} = useGetList(
    'industries',
    {} // pagination: {page: 1} sort: {field: 'uname', order: 'ASC'}
  );
  log.debug(`{SetupCreate} isIndustriesLoading: ${isIndustriesLoading}, industries:`, industries);
  if (isIndustriesLoading) return <Loading />;
  const industries2 = industries.map((ind) => ({id: ind._id, ...ind}));

  // onSuccess moved to SaveButton above
  // const onSuccess = (props) => {
  //   const {data} = props;
  //   log.debug(`{onSuccess} data:`, data);
  //   log.debug(`{onSuccess} applicationName:`, data.applicationName);
  //   notify('notification.onboarded', 'info', {name: data.applicationName});
  //   //redirect('show', `/representatives?name=${data.applicationName}`, data.id, data);
  //   redirect(`/representatives/${data.id}/show?name=${data.applicationName}`);
  //   refresh();
  // };
  const breadcrumbs = [{url: ``, title: 'Onboard Client', resource}];
  return (
    <React.Fragment>
      <Breadcrumbs breadcrumbs={breadcrumbs}/>
      <Create
        title={<CreateTitle/>}
        aside={<Aside classes={classes} />}
      >
        <SimpleForm
          toolbar={<SetupToolbar dataFiles={dataFiles}/>}
        >
          <TextInput source="applicationName"/>
          <TextInput source="firstName" fullWidth/>
          <TextInput source="lastName" fullWidth/>
          <TextInput source="emailAddress" fullWidth/>
          <SelectInput source="industry" choices={industries2} fullWidth />
          <SetupDropPanel setDataFiles={setDataFiles} classes={classes} dataFiles={dataFiles}/>
        </SimpleForm>
      </Create>
    </React.Fragment>
  );
}

export default SetupCreate;