import React, {useCallback} from 'react';
import DownloadIcon from '@mui/icons-material/GetApp';
import {
  Button,
  //CRUD_GET_ONE,
  useNotify,
  //useVersion,
  useDataProvider,
  useListContext,
  fetchRelatedRecords,
  // DataProvider,
  // ExporterContext,
  // Exporter,
  // FilterPayload,
  useResourceContext,
} from 'react-admin';
import log from 'loglevel';
//import PropTypes from 'prop-types';
//import YamlExporterContext from "./YamlExporterContext";
//const UnExportButton = (props) => null;

// TODO: use ExportButtonProps from RA instead of UnExportButtonProps?

/**
 * Original ExportButton exports a list using dataProvider.getList().
 * We need our extended getOne() method forcing us to make a new button component.
 * Note that the exporter function is passed in as an attribute (e.g. see DictionaryShow).
 * @param props
 * @constructor
 */
const UnExportButton = props => {
  const {
    //maxResults = 1000,
    onClick,
    label = 'ra.action.export',
    icon = defaultIcon,
    exporter: customExporter,
    record,
    //sort, // deprecated, to be removed in v4
    ...rest
  } = props;
  const {
    //filterValues,
    //currentSort,
    exporter: exporterFromContext,
    total,
  } = useListContext(props);
  const resource = useResourceContext(props);
  const exporter = customExporter || exporterFromContext;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const id = record ? record.id : 0;
  log.debug(`{UnExportButton} id: ${id}, props:`, props);
  const handleClick = useCallback(
    event => {
      // dataProvider
      //   .getList(resource, {
      //     sort: currentSort || sort,
      //     filter: filterValues,
      //     pagination: { page: 1, perPage: maxResults },
      //   })
      dataProvider.getOneWithArgs(resource, // was 'dictionaries'
        {id, args: {format: 'yaml'}}
      )
        .then(
           ({data}) => {
           log.debug(`{UnExportButton} data from getOneWithArgs:`, data);
           const exp = exporter &&
            exporter(
              data,
              fetchRelatedRecords(dataProvider),
              dataProvider,
              resource
            )
            log.debug(`{UnExportButton} exported:`, exp);
            return exp;
          }
        )
        .catch(error => {
          console.error(error);
          notify('ra.notification.http_error', 'warning');
        });
      if (typeof onClick === 'function') {
        onClick(event);
      }
    },
    [
      id,
      //currentSort,
      dataProvider,
      exporter,
      //filterValues,
      //maxResults,
      notify,
      onClick,
      resource,
      //sort,
    ]
  );

  return (
    <Button
      onClick={handleClick}
      label={label}
      disabled={total === 0}
      {...sanitizeRestProps(rest)}
    >
      {icon}
    </Button>
  );
};

const defaultIcon = <DownloadIcon />;

const sanitizeRestProps = ({
                             // basePath,
                             // filterValues,
                             // resource,
                             ...rest
                           }) =>
  rest;

// interface Props {
//   basePath?: string;
//   exporter?: Exporter;
//   filterValues?: FilterPayload;
//   icon?: JSX.Element;
//   label?: string;
//   maxResults?: number;
//   onClick?: (e: Event) => void;
//   resource?: string;
//   sort?: SortPayload;
// }

//export type UnExportButtonProps = Props & ButtonProps;

// Disabled to remove ugly lint error MSH
// UnExportButton.propTypes = {
//   basePath: PropTypes.string,
//   exporter: PropTypes.func,
//   filterValues: PropTypes.object,
//   label: PropTypes.string,
//   maxResults: PropTypes.number,
//   resource: PropTypes.string,
//   sort: PropTypes.exact({
//     field: PropTypes.string,
//     order: PropTypes.string,
//   }),
//   icon: PropTypes.element,
// };

export default UnExportButton;



// const UnExportButton: FunctionComponent<UnExportButtonProps> = (props) => {
//   const {
//     sort,
//     filter = defaultFilter,
//     maxResults = 1000,
//     resource,
//     record,
//     onClick,
//     label = 'ra.action.export',
//     icon = defaultIcon,
//     ...rest
//   } = props;
//   const exporter = useContext(YamlExporterContext);
//   const dataProvider = useDataProvider();
//   const notify = useNotify();
//   const id = record.id;
//   log.debug(`{UnExportButton}id: ${id}, props:`, props);
//   //const dp = dataProvider(resource);
//   const handleClick = useCallback(
//     event => {
//       // dataProvider
//       //   .getList(resource, {
//       //     sort,
//       //     filter,
//       //     pagination: { page: 1, perPage: maxResults },
//       //   })
//       dataProvider.getOneWithArgs(resource, // was 'dictionaries'
//         {id, args: {format: 'yaml'}}
//       )
//       .then(data => {
//         console.log(`{UnExportButton} data:`, data);
//         exporter && exporter(
//           data,
//           fetchRelatedRecords(dataProvider),
//           dataProvider,
//           resource
//         );
//       })
//       .catch(error => {
//         console.error(error);
//         notify('ra.notification.http_error', 'warning');
//       });
//
//
//       if (typeof onClick === 'function') {
//         onClick(event);
//       }
//     },
//     [
//       dataProvider,
//       exporter,
//       filter,
//       maxResults,
//       notify,
//       onClick,
//       resource,
//       sort,
//     ]
//   );
//
//   return (
//     <Button
//       onClick={handleClick}
//       label={label}
//       {...sanitizeRestProps(rest)}
//     >
//       {icon}
//     </Button>
//   );
// };
//
// const defaultIcon = <DownloadIcon />;
// const defaultFilter = {};
//
// const sanitizeRestProps = ({
//                              basePath,
//                              exporter,
//                              ...rest
//                            }: Omit<
//   UnExportButtonProps,
//   'sort' | 'filter' | 'maxResults' | 'resource' | 'label'
//   >) => rest;
//
// interface Props {
//   basePath?: string;
//   exporter?: (
//     data: any,
//     fetchRelatedRecords: (
//       data: any,
//       field: string,
//       resource: string
//     ) => Promise<any>,
//     dataProvider: DataProvider
//   ) => Promise<void>;
//   filter?: any;
//   icon?: JSX.Element;
//   label?: string;
//   maxResults?: number;
//   onClick?: (e: Event) => void;
//   resource?: string;
//   sort?: SortPayload;
// }
//
// export type UnExportButtonProps = Props & ButtonProps;
//
// UnExportButton.propTypes = {
//   basePath: PropTypes.string,
//   exporter: PropTypes.func,
//   filter: PropTypes.object,
//   label: PropTypes.string,
//   maxResults: PropTypes.number,
//   resource: PropTypes.string.isRequired,
//   sort: PropTypes.exact({
//     field: PropTypes.string,
//     order: PropTypes.string,
//   }),
//   icon: PropTypes.element,
// };
//
// export default UnExportButton;
