//import Typography from "@mui/material/Typography";
import React, {useMemo, useState}  from "react";
import {useDropzone} from 'react-dropzone';
import log from 'loglevel';
import {makeStyles} from "@mui/styles";

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'  // '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',  // Extends box vertically to bottom
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1.0),
    },
  }
}));

/**
 *
 * @param props   onload is a function to run on loading the file (e.g. untanglefields in TemplateFieldList)
 * @returns {JSX.Element}
 * @constructor
 */
function DropPanel(props) {
  const {onload, title} = props;
  //log.debug(`{DropPanel} resource: ${resource}, templateId: ${templateId}, templateName: ${templateName}, dictionaryIds:`, dictionaryIds);
  const [, setFiles] = useState([]);
  const classes = useStyles();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
      //accept: 'application/*',
      onDrop: acceptedFiles => {
        acceptedFiles.map(file => {
          log.debug(`{StyledDropzone} Drop - file name:`, file.name);
          //log.debug(`Drop - file:`, file);
          const reader = new FileReader();
          reader.onloadstart = () => console.log('File reader started');
          reader.onloadend = () => console.log('File reader ended');
          reader.onabort = () => console.log('File reader aborted');
          reader.onerror = () => console.log('File reader failed');
          reader.onload = () => onload(reader); // onload provided by caller
          reader.readAsArrayBuffer(file);
          return null;  //To prevent warning array-callback-return
        });
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
      }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);
//  <div className={classes.root}>
//  <div className="container">
  return (
    <div className={classes.root}>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>{title}</p>
      </div>
    </div>
  );
}

// const TemplateDropPanel = (props) => {
//   return <StyledDropzone {...props}/>
// }

// const TemplateDropPanel = () => {
//   const onDrop = useCallback(acceptedFiles => {
//     // Do something with the files
//   }, [])
//   const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
//   return (
//     <div {...getRootProps()}>
//       <input {...getInputProps()} />
//       {
//         isDragActive ?
//           <p>Drop the files here ...</p> :
//           <p>Drag 'n' drop some files here, or click to select files</p>
//       }
//     </div>
//   )
// }

export default DropPanel;