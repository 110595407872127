const formats = [
  'string',
  'integer',
  'number',
  'date',
  'date_iso',
  'genderMF',
  'money',
  'money_currency',
  'money_with_symbol',
  '#0',
  '#0.0',
  '000'
];

//const ucfirst = name => name.charAt(0).toUpperCase() + name.slice(1);

export {
  formats
}