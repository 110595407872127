import React from 'react';
import {useLocation, useParams} from "react-router-dom";
import {
  TabbedForm,
  FormTab,
  TopToolbar,
  TextInput,
  DateInput,
  SelectInput,
  TextField,
  EditView,
  Loading,
  EditContextProvider,
  useResourceContext,
  useEditController
} from 'react-admin';
import {DetailTitle, UnEditToolbar} from "../components/InputFields";
import {queryParamsToObject, queryParamsToFilterString, getTabName} from "../util/paramUtil";
import log from "loglevel";
import {FullNameField, fullNameField} from './EmployeeUtil';
import {UnShowButtonWithFilter} from "../button/UnShowButtonWithFilter";
import {Breadcrumbs} from "../breadcrumbs";
import {
  isValidTabName,
  //urlForRedirectWithTab
} from "../components/EditActions";

/**
 * Note that in database:
 *   persons in the UI are stored as employees
 *   employees in the UI are stored as employments
 * The UI employee detail page contains a mixture of person and employee data.
 * Setting path on a FormTab selects tab by name. (See tabName in UnShowButtonWithFilter.)
 */

// const EmployeeTitle = ({ record }) => {
//   return <span>Employee{(record && record.employee && record.employee.last_name) ?
//     `: ${record.employee.first_name} ${record.employee.last_name}` :
//     ''}</span>;
// };
//const EmployeeTitle = ({record}) => <span>Employee: {<FullNameField record={record} />}</span>;

/**
 * Return whether specified tab name is in tabs 2 onward.
 * @param tabName
 * @returns {boolean}
 */
const TABNAMES = ['employment'];
// const isValidTabName = tabName => TABNAMES.includes(tabName);

/**
 * To create the DETAIL button url, the isValidTabName() is used to check for a tab name as
 * the first tab does not have one. E.g.
 *   /employees/6242b46f6624e7074e10106a/show/employment
 * @param filter
 * @param tabName
 * @returns {JSX.Element|null}
 * @constructor
 */

const EmployeeEditActions = ({filter, tabName}) => {
  const adjTabName = isValidTabName(tabName) && TABNAMES.includes(tabName) ? tabName : '';
  console.log(`{EmployeeEditActions} adjTabName: ${adjTabName}, filter: `, filter);
  return (
    <TopToolbar>
      <UnShowButtonWithFilter filter={filter} tabName={adjTabName}/>
    </TopToolbar>
  );
}

export const EmployeeEdit = () => {

  const redirectPath = () => {
    const locationTabName = getTabName(location);
    log.debug(`{EmployeeEdit.redirectPath} locationTabName: ${locationTabName}, employer_id: ${employer_id}`);
    return isValidTabName(locationTabName) ?
      `${resource}/${id}/show/${locationTabName}?${queryParamsToFilterString({employer_id})}` :
      `${resource}/${id}/show?${queryParamsToFilterString({employer_id})}`;
  }

  const location = useLocation();
  const resource = useResourceContext();
  const {id} = useParams();
  const {employer_id} = queryParamsToObject(location);
  const editContext = useEditController({
    resource,
    id,
    queryOptions: {meta: {employer_id}},
    mutationMode: "pessimistic",  // Subsequent redirected detail page is stale without this
    redirect: redirectPath
  });
  const {record, isLoading} = editContext;
  if (isLoading) return <Loading/>;
  console.log(`{EmployeeEdit} employer_id: ${employer_id}, isLoading: ${isLoading}`);
  const employer_name = record.employer.name;
  const parent = 'employers';
  const breadcrumbs = [
    {url: `/${parent}`, label: parent, resource: parent},
    {url: `/${parent}/${employer_id}/show?${queryParamsToFilterString({name: employer_name})}`,
      title: employer_name, resource: parent},
    {url: `/${resource}?${queryParamsToFilterString({employer_id, employer_name})}`, label: resource, resource},
    {url: ``, title: <FullNameField record={record} />, resource},
  ];
  return (
    <EditContextProvider value={editContext}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <EditView
        title={<DetailTitle value={fullNameField(record)}/>}
        actions={<EmployeeEditActions filter={{employer_id, employer_name}}
                                      tabName={getTabName(location)} />}
      >
        <TabbedForm
          redirect={redirectPath}
          toolbar={<UnEditToolbar/>}
        >
          <FormTab label="Personal">
            <TextInput source="employee.first_name" fullWidth/>
            <TextInput source="employee.last_name" fullWidth/>
            <SelectInput source="employee.gender" choices={[
              { id: 'male', name: 'Male' },
              { id: 'female', name: 'Female' }
            ]} />
            <DateInput source="employee.dob" />
            <TextInput source="employee.street1" fullWidth />
            <TextInput source="employee.street2" fullWidth />
            <TextInput source="employee.city" fullWidth />
            <TextInput source="employee.postcode" />
            <TextInput source="employee.address.country_name" fullWidth />
            {/*<TextField source="id" label={"Person ID"} />*/}
          </FormTab>
          <FormTab label="employment" path="employment">
            <TextField source="employer.name" />
            <DateInput source="start_date" label={"Join Date"} />
            <DateInput source="end_date" label={"Leave Date"} />
            <TextInput source='grade' fullWidth />
            <TextInput source='work_email' fullWidth />
            <TextInput source='work_phone' fullWidth />
            <TextInput source='notes' fullWidth />
            {/*<TextField source="id" label={"Employee ID"}/>*/}
          </FormTab>
        </TabbedForm>
      </EditView>
    </EditContextProvider>
  );
}

export default EmployeeEdit;