/**
 * For changes in v3 and onSave see: https://github.com/marmelab/react-admin/blob/master/UPGRADE.md
 */

import React from "react";
import {useLocation} from "react-router-dom";
import {
  Create, SimpleForm,
  SelectInput, TextInput,
  Toolbar, useNotify, useRedirect,
  useGetList, Loading, useTranslate,
  useResourceContext
} from 'react-admin';
import log from 'loglevel';
import {
  queryParamObject, queryParamsToFilterString
} from "../util/paramUtil";
import SavePermissionButton from './SavePermissionButton';
import {Breadcrumbs} from "../breadcrumbs";
// pashl3y28

// {userId, userName}
// const PermissionsCreateActions = () => {
//   return (
//     <TopToolbar>
//       {/*<UnShowButton pathname='/users' record={{_id: userId, name: userName}} caption='Show User'*/}
//       {/*  icon=<LockIcon/> />*/}
//     </TopToolbar>
//   );
// };

/**
 * Toolbar goes below form
 * @param props
 * @returns {*}
 * @constructor
 */
const PermissionCreateToolbar = props => {
  const {redirectPath, ...rest} = props;
  //console.log(`{PermissionCreateToolbar} redirectPath:`, redirectPath);
  return (
    <Toolbar {...rest}>
      <SavePermissionButton
        label="user permission"
        redirect={redirectPath}
      />
    </Toolbar>
  );
};

//const RootTag = (props) => { return props.children; };

// const redirectx = (employerId, employerName) => { // basePath, id, data
//   log.debug("{ContractCreate.redirect} employerId:", employerId);
//   const filterObj = {
//     employer_id: employerId,
//     employer_name: employerName
//   };
//   //return `/employers/${employerId}/contracts?`; //filter=${JSON.stringify(filterObj)}`;
//   return `/contracts?filter=${JSON.stringify(filterObj)}`;
// };

const PermissionCreate = () => {
  const location = useLocation();
  const notify = useNotify();
  const redirect = useRedirect();
  const resource = useResourceContext();
  const translate = useTranslate();
  const {user_id, user_name} = queryParamObject(location);
  console.log(`{PermissionCreate} user_id: ${user_id}, user_name: ${user_name}`);

  const transform = data => {
    log.debug(`{PermissionCreate.transform} data:`, data);
    return {
      ...data,
      user_id
    }
  };

  const onSuccess = (data) => {
    notify(`Permissions saved`);
    log.debug("{onSuccess} data:", data);
    const url = `/permissions?${queryParamsToFilterString({user_id, user_name})}`
    log.debug("{onSuccess} url:", url);
    redirect(url);
  };


  const {
    isLoading: isApplicationsLoading,
    data: applications} = useGetList(
      'representatives',
      {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}}
  );
  const {
    isLoading: isEmployersLoading,
    data: employers} = useGetList(
      'employers',
      {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}}
  );

  const parent = 'users';
  const breadcrumbs = [
    {url: `/${parent}`, title: translate(`resources.${parent}.name_many`), resource: parent},
    {url: `/${parent}/${user_id}/show?${queryParamsToFilterString({user_id, name: user_name})}`, title: user_name, resource: parent},
    {url: `/${resource}?${queryParamsToFilterString({user_id, user_name})}`, title: 'Permissions', resource},
    {url: ``, title: 'Create', resource}
  ];

  if (isApplicationsLoading || isEmployersLoading) return <Loading/>;
  log.debug(`{PermissionCreate.transform} applications:`, applications);
  /* <TextInput source="security_code" defaultValue={"pashl3y15" />*/
  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Create
        transform={transform}
        mutationOptions={{onSuccess}}
      >
        <SimpleForm
          toolbar={<PermissionCreateToolbar />}
          defaultValues={{user_id, user_name}}
        >
          <SelectInput source="event" label="User role" choices={[
            {id: 'register_quiet', name: 'registered'},
            {id: 'verify_quiet', name: 'verified'},
            {id: 'unverify', name: 'unverify'}
          ]}/>
          <TextInput source="user_name" disabled />
          <TextInput source="security_code" />
        </SimpleForm>

        <SimpleForm toolbar={<PermissionCreateToolbar/>}>
          <SelectInput source="event" label="Application role" choices={[
            {id: 'setuntangler', name: 'untangler'},
            {id: 'setunsupport', name: 'un_support'},
            {id: 'setunadmin', name: 'un_admin'},
            {id: 'setrepadmin', name: 'rp_admin'}
          ]}/>
          <SelectInput label="Application" source="entity_id" choices={applications} />
          <TextInput source="security_code"/>
        </SimpleForm>

        <SimpleForm toolbar={<PermissionCreateToolbar/>}>
          <SelectInput source="event" label="Employer role" choices={[
            { id: 'apply', name: 'er_admin' }
          ]}/>
          <SelectInput label="Employer" source="entity_id" choices={employers} />
          <TextInput source="security_code" />
        </SimpleForm>

        <SimpleForm toolbar={<PermissionCreateToolbar />}>
          {/* event is setsuperadmin */}
          <TextInput source='event' label={'Admin role'}/>
          <TextInput source="security_code" />
        </SimpleForm>

      </Create>
    </>
 );
}


// const PermissionCreateOld = () => {
//   const location = useLocation();
//   const resource = useResourceContext();
//   const translate = useTranslate();
//   const {user_id, user_name} = queryParamObject(location);
//   const userId = user_id;
//   const userName = user_name;
//
//   // const redirect = (filterObj) => `/permissions/list?user_id=${user_id}&user_name=${user_name}`;
//   // const redirect = (filterObj) => `/permissions?filter=${JSON.stringify(filterObj)}`;
//
//   const redirect = (filterObj) => {
//     //console.log(`{PermissionCreate.create} filterObj:`, filterObj);
//     return `/permissions?filter=${JSON.stringify(filterObj)}`
//   }
//
//   log.debug(`{PermissionCreate} userId: ${userId}, userName: ${userName}`);
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   //let {hasShow, hasEdit, hasList, hasCreate, ...rest} = props;
//   const parent = 'users';
//   const breadcrumbs = [
//     {url: `/${parent}`, title: translate(`resources.${parent}.name_many`), resource: parent},
//     {url: `/${parent}/${user_id}/show?${queryParamString({user_id, name: user_name})}`, title: user_name, resource: parent},
//     {url: `/${resource}?${queryParamString({user_id, user_name})}`, title: 'Permissions', resource},
//     {url: ``, title: 'Create', resource}
//   ];
//   const {
//     isLoading: isRepresentativesLoading,
//     data: applications} = useGetList(
//       'applications',
//       {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}}
//   );
//   const {
//     isLoading: isEmployersLoading,
//     data: employers} = useGetList(
//       'employers',
//       {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}}
//   );
//   if (isRepresentativesLoading || isEmployersLoading) <Loading />;
//   return (
//     <React.Fragment>
//       <UnBreadcrumbs.All breadcrumbs={breadcrumbs} />
//       <Create title={`Create Permission for ${userName}`}>
//       <RootTag>
//         <PermissionsCreateActions userId={userId} userName={userName}/>
//         <SimpleForm
//           toolbar={<PermissionCreateToolbar redirectPath={redirect({user_id, user_name})} />}
//           //save={onSave}
//           //redirect={redirect}
//           defaultValues={{ user_id: userId, user_name: userName }}>
//           <SelectInput source="us_event" label="User role" choices={[
//             { id: 'register_quiet', name: 'registered' },
//             { id: 'verify_quiet', name: 'verified' },
//             { id: 'unverify', name: 'unverify' }
//           ]}/>
//           <TextInput source="user_name" disabled />
//           <TextInput source="security_code" />
//         </SimpleForm>
//
//         <SimpleForm
//           toolbar={<PermissionCreateToolbar redirectPath={redirect({user_id, user_name})} />}
//           //redirect={this.redirect}
//           defaultValues={{ user_id: userId, user_name: userName }}>
//           <SelectInput source="rp_event" label="Application role" choices={[
//             { id: 'setuntangler', name: 'untangler' },
//             { id: 'setunsupport', name: 'un_support' },
//             { id: 'setunadmin', name: 'un_admin' },
//             { id: 'setrepadmin', name: 'rp_admin' }
//           ]}/>
//           {/*<ReferenceInput label="Application" source="rp_entity_id" reference="applications">*/}
//           {/*  <SelectInput optionText="name"/>*/}
//           {/*</ReferenceInput>*/}
//           <SelectInput label="Application" source="rp_entity_id" choices={applications} />
//           <TextInput source="security_code"/>
//         </SimpleForm>
//
//         <SimpleForm
//           toolbar={<PermissionCreateToolbar redirectPath={redirect({user_id, user_name})} />}
//           //redirect={redirect}
//           defaultValues={{ user_id: userId, user_name: userName }}>
//           <SelectInput source="er_event" label="Employer role" choices={[
//             { id: 'apply', name: 'er_admin' }
//           ]}/>
//           {/*<ReferenceInput label="Employer" source="er_entity_id" reference="employers">*/}
//           {/*  <SelectInput optionText="name"/>*/}
//           {/*</ReferenceInput>*/}
//           <SelectInput label="Employer" source="er_entity_id" choices={employers} />
//           <TextInput source="security_code" />
//         </SimpleForm>
//
//         <SimpleForm
//           toolbar={<PermissionCreateToolbar redirectPath={redirect({user_id, user_name})} />}
//           //redirect={redirect}
//           defaultValues={{user_id: userId, user_name: userName}}>
//           <TextInput source='sa_event' label={'Admin role'}/>
//           <TextInput source="security_code" />
//         </SimpleForm>
//
//       </RootTag>
//     </Create>
//     </React.Fragment>
//   )
// };


export default PermissionCreate;