import {downloadCSV} from "react-admin";
import {unparse as convertToCSV} from "papaparse/papaparse.min";
import {constraintsStr} from '../dictionaryentries/DictionaryEntryUtil';

const exportDictionaryAliases = entries => {
  const fileName = 'DictionaryAliases';
  const entriesForExport = entries.map(e => {
    let eForExport = {};
    eForExport['Alias'] = e.alias; // add a field
    eForExport['Code'] = e.code;
    eForExport['Type'] = e.type;
    eForExport['Weight'] = e.weight;
    eForExport['Dictionary'] = e.dict;
    eForExport['Constraints'] = constraintsStr(e);
    return eForExport;
  });
  const csv = convertToCSV({
    data: entriesForExport,
    // Order fields in the export
    fields: ['Alias', 'Code', 'Type', 'Weight', 'Dictionary', 'Constraints']
  });
  downloadCSV(csv, fileName);
};

export default exportDictionaryAliases;