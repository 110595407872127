import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {
  useInput
} from 'react-admin';

const formats = [
  'string',
  'integer',
  'number',
  'money',
  'money_with_symbol',
  'money_currency',
  'date',
  'date_iso',
  'genderMF',
  'year_as_date',
  '#0.00'
];

// const formats = [
//   {key: 'string', name: 'string'},
//   {key: 'integer', name: 'integer'},
//   {key: 'number', name: 'number'},
//   {key: 'money', name: 'money'},
//   {key: 'money_with_symbol', name: 'money_with_symbol'},
//   {key: 'money_currency', name: 'money_currency'},
//   {key: 'date', name: 'date'},
//   {key: 'date_iso', name: 'date_iso'},
//   {key: 'genderMF', name: 'genderMF'},
//   {key: 'year_as_date', name: 'year_as_date'},
//   {key: '#0.00', name: '#0.00'}
// ];

/**
 * Combobox for field format
 * Allows user to either pick from a defined list of formats or enter a custom one by hand.
 *
 * With respect to the input field, MUI Autocomplete is an abstract input field.
 * We need to specify what input component needs to be rendered and in most cases,
 * it would be TextField. Therefore ‘renderInput’ component is mandatory.
 *
 * Recommended settings?
 *   - selectOnFocus to helps the user clear the selected value.
 *   - clearOnBlur to helps the user to enter a new value.
 *   - handleHomeEndKeys to move focus inside the popup with the Home and End keys.
 *   - a last option, for instance Add "YOUR SEARCH".
 *
 *   freeSolo seems to disable the handy dropdown (v) and delete (x) icons at the the far right.
 *   Since these icons are handy to keep, instead set getOptionSelected() to always return true.
 * https://v4.mui.com/components/autocomplete/#free-solo
 *
 * useInput lets you build custom inputs for react-admin.
 * It’s a wrapper around react-hook-form’s useController
 * https://marmelab.com/react-admin/useInput.html
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
// const FormatInput = (props) => {
//   const {source} = props;
//   console.log(`{FormatInput} source: ${source}, props:`, props);
//
//   // const onMyChange = (event, newValue) => {
//   //   console.log(`{onMyChange} newValue:`, newValue);
//   // }
//
//   const {
//     id, field, fieldState
//     //input, // rest: checked, onBlur, onFocus, value: "string"
//     //meta: {touched, error}
//   } = useInput({
//     source,
//     //onChange
//   });
//   //const {onChange, value, ...rest} = input; // name
//   console.log(`{FormatInput} id: ${id}, field:`, field);
//   console.log(`{FormatInput} fieldState:`, fieldState);
//   //console.log(`{FormatInput} value:`, value);
//
//   // Note different signatures for onChange
//   //const onMyChange = (event, newValue) => onChange(newValue);
//
//   return (
//     // <Autocomplete
//     //   {...rest}
//     //   options={formats}
//     //   value={value}
//     //   //freeSolo
//     //   onChange={onMyChange}
//     //   getOptionSelected={() => true}  // getOptionSelected={(option, value)
//     //   // getOptionLabel={(option) => {
//     //   //   console.log(`{getOptionLabel} option:`, option);
//     //   //   return option || "";  // "string"
//     //   // }}
//     //   renderInput={(params) => (
//     //     <TextField {...params}
//     //                label="Format"
//     //                onChange={({target}) => onChange(target.value)}
//     //                variant="filled" />
//     //   )}
//     // />
//     <Autocomplete
//       id={id}
//       freeSolo
//       options={formats}
//       // getOptionLabel={(option) => {
//       //   console.log(`{getOptionLabel} option:`, option);
//       //   return option || "";  // "string"
//       // }}
//       // onChange={onChange}
//       sx={{ width: 300 }}
//       renderInput={(params) => <TextField {...params} label="Format" />}
//       {...field}
//     />
//   );
// };

const FormatInput = (props) => {
  const {source, ...rest} = props;
  console.log(`{FormatInput} source: ${source}, props:`, props);
  const propsInput = useInput(props);
  //const {
    //input, // rest: checked, onBlur, onFocus, value: "string"
    //meta: {touched, error}
  //} = propsInput;
  console.log(`{FormatInput} propsInput:`, propsInput);
  const {field} = propsInput;
  const {name, value, onChange} = field;
  //const {onChange, value, ...rest} = input; // name
  console.log(`{FormatInput} name: ${name}`);
  //console.log(`{FormatInput} value:`, value);
  // Note different signatures for onChange
  const onMyChange = (event, newValue) => onChange(newValue);

  return (
    <Autocomplete
      {...rest}
      options={formats}
      value={value}
      freeSolo
      onChange={onMyChange}
      //getOptionSelected={() => true}  // getOptionSelected={(option, value)
      isOptionEqualToValue={() => true}
      // getOptionLabel={(option) => {
      //   console.log(`{getOptionLabel} option:`, option);
      //   return option || "";  // "string"
      // }}
      renderInput={(params) => (
        <TextField {...params}
                   label="Format"
                   onChange={({target}) => onChange(target.value)}
                   variant="filled" />
      )}
    />
  );
};

export default FormatInput;