import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import React from "react";

/**
 *  Row handler element example:
 *    name: 'Compare', args: [{op: '>'}], entries: [{name: 'Leave Date'}, {name: 'Join Date'}]
 *
 *  See also: https://github.com/mui-org/material-ui-x/tree/master/packages/grid/x-grid
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const RowHandlersTable = (props) => {
  const {items} = props;  // items are rows
  const titles = ['Name', 'Entry Name 1', 'Entry Name 2', 'Argument Name', 'Argument Value'];
  //const properties = ['name', 'entries[0].name', 'entries[1].name'];
  return (
    <div>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow key={0}>
            {titles.map(title => (
              <TableCell key={title}><b>{title}</b></TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items ? items.map((r, i) => {
            return r ? (
            <TableRow key={r._id}>
              <TableCell key={r.name}>{r.name}</TableCell>
              <TableCell key={i*1000 + 1}>{r.entries && r.entries[0] ? r.entries[0].name : ''}</TableCell>
              <TableCell key={i*1000 + 2}>{r.entries && r.entries[1] ? r.entries[1].name : ''}</TableCell>
              <TableCell key={i*1000 + 3}>{r.args && r.args[0] ? r.args[0].name: ''}</TableCell>
              <TableCell key={i*1000 + 4}>{r.args && r.args[0] ? r.args[0].value: ''}</TableCell>
            </TableRow>
            ) : null
          }) : null}
        </TableBody>
      </Table>
    </div>
  );
}

export default RowHandlersTable;