/*
Note:
Change Menu background color on React-Admin:
https://stackoverflow.com/questions/53165311/change-menu-background-color-on-react-admin

For exact property on menu link, see:
https://reactrouter.com/web/api/NavLink/exact-bool
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {
  MenuItemLink,
  //Responsive, // ra v4
  useSidebarState,
  usePermissions,
  useTranslate
} from 'react-admin';
import {Activity, isAuthorized} from '../auth/authorization';
import users from '../users'; // Entities here are imported for their icons
import sites from '../sites';
import representatives from '../representatives';
import employers from '../employers';
import dictionaries from '../dictionaries';
import dictionaryaliases from '../dictionaryaliases';
import templates from '../templates';
import untanglements from '../untanglements';
import setups from '../setups';



// const useMenuStyles = makeStyles(theme => {
//   //console.log(`{useMenuStyles} theme:`, theme);
//   const menuTheme = {
//     root: {
//       //backgroundColor: 'yellow'
//       //color: 'red'
//       color: theme.palette.secondary.main  //untanglColors.tealblue
//     }, // Style applied to the MenuItem from material-ui
//     active: {fontWeight: 'bold'}, // Style applied when the menu item is the active one
//     icon: {}, // Style applied to the icon
//     // menu: {
//     //   // backgroundColor: untanglColors.tealblue,
//     //   // color: 'white',
//     //   //backgroundColor: 'yellow',
//     //   color: untanglColors.tealblue,
//     //   //fontWeight: 'bold'
//     // }
//   }
//   console.log(`{useMenuStyles} menuTheme:`, menuTheme)
//   return menuTheme;
// });

// TODO: classes???
const Menu = ({onMenuClick}) => { // logout
  const theme = useTheme();
  console.log(`{Menu} theme:`, theme);

  // const [state] = useState({  // setState
  //   menuCatalog: false,
  //   menuSales: false,
  //   menuCustomers: false,
  // });
  //console.log("{Menu} state:", state);
  const {permissions} = usePermissions();
  const translate = useTranslate();
  //const classes = useMenuStyles();
  //console.log("{Menu} classes:", classes);
  // const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const [open] = useSidebarState();

  // const open = useSelector(state => state.admin.ui.sidebarOpen);
  // useSelector(state => state.theme); // force rerender on theme change

  // const handleToggle = menu => {
  //   setState(state => ({ ...state, [menu]: !state[menu] }));
  // };

  if (!permissions) {
    //console.log(`{Menu} permissions not defined`);
    return null;
  }

  // TODO: Surround MenuItemLink's with Box?
  return (

    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: theme =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >

     <MenuItemLink
        //className={classes.menu}
        //classes={classes}
       sx={{color: theme.palette.secondary.main}}
        key={'/'}
        to={`/`}
        primaryText={translate(`ra.page.dashboard`, {
          smart_count: 2,
        })}
        leftIcon={<DashboardIcon/>}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />

      {isAuthorized(permissions, Activity.USER_READ)
        ? <MenuItemLink
          //className={classes.menu}
          //classes={classes}
          sx={{color: theme.palette.secondary.main}}
          key={'users'}
          to={`/${'users'}`}
          primaryText={translate(`resources.users.name`, {
            smart_count: 2,
          })}
          leftIcon={<users.ICON/>}
          onClick={onMenuClick}
          //sidebarIsOpen={open}
        />
        : null
      }

      {isAuthorized(permissions, Activity.SITE)
        ? <MenuItemLink
          //className={classes.menu}
          //classes={classes}
          sx={{color: theme.palette.secondary.main}}
          key={'sites'}
          to={`/${'sites'}`}
          primaryText={translate(`resources.sites.name`, {
            smart_count: 2,
          })}
          leftIcon={<sites.ICON/>}
          onClick={onMenuClick}
        />
        : null
      }

      {isAuthorized(permissions, Activity.APPLICATION_READ) // Allows to see nested applications
        ? <MenuItemLink
          //className={classes.menu}
          //classes={classes}
          sx={{color: theme.palette.secondary.main}}
          key={'representatives'}
          to={`/${'representatives'}`}
          primaryText={translate(`resources.representatives.name`, {
            smart_count: 2,
          })}
          leftIcon={<representatives.ICON/>}
          onClick={onMenuClick}
        />
        : null
      }

      {isAuthorized(permissions, Activity.EMPLOYER_READ)
        ? <MenuItemLink
          //className={classes.menu}
          //classes={classes}
          sx={{color: theme.palette.secondary.main}}
          key={'employers'}
          to={`/${'employers'}`}
          primaryText={translate(`resources.employers.name`, {
            smart_count: 2,
          })}
          leftIcon={<employers.ICON/>}
          onClick={onMenuClick}
        />
        : null
      }

      {isAuthorized(permissions, Activity.DICTIONARY_READ)
        ? <MenuItemLink
          //className={classes.menu}
          //classes={classes}
          sx={{color: theme.palette.secondary.main}}
          key={'dictionaries'}
          to={`/${'dictionaries'}`}
          primaryText={translate(`resources.dictionaries.name`, {
            smart_count: 2,
          })}
          leftIcon={<dictionaries.ICON/>}
          onClick={onMenuClick}
        />
        : null
      }

      {isAuthorized(permissions, Activity.DICTIONARY_READ)
        ? <MenuItemLink
          //className={classes.menu}
          //classes={classes}
          sx={{color: theme.palette.secondary.main}}
          key={'dictionaryaliases'}
          to={`/${'dictionaryaliases'}`}
          primaryText={translate(`resources.dictionaryaliases.name`, {
            smart_count: 2,
          })}
          leftIcon={<dictionaryaliases.ICON/>}
          onClick={onMenuClick}
        />
        : null
      }

      {isAuthorized(permissions, Activity.TEMPLATE_READ)
        ? <MenuItemLink
          //className={classes.menu}
          //classes={classes}
          sx={{color: theme.palette.secondary.main}}
          key={'templates'}
          to={`/${'templates'}`}
          primaryText={translate(`resources.templates.name`, {
            smart_count: 2,
          })}
          leftIcon={<templates.ICON/>}
          onClick={onMenuClick}
        />
        : null
      }

      {isAuthorized(permissions, Activity.UNTANGLER)
        ? <MenuItemLink
          //className={classes.menu}
          //classes={classes}
          sx={{color: theme.palette.secondary.main}}
          key={'untanglements'}
          to={`/${'untanglements'}`}
          primaryText={translate(`resources.untanglements.name`, {
            smart_count: 2,
          })}
          leftIcon={<untanglements.ICON/>}
          onClick={onMenuClick}
        />
        : null
      }

      {isAuthorized(permissions, Activity.ONBOARDING)
        ? <MenuItemLink
          sx={{color: theme.palette.secondary.main}}
          key={'setups'}
          to={`/${'setups/create'}`}
          primaryText={translate(`resources.setups.name`, {
            smart_count: 2,
          })}
          leftIcon={<setups.ICON/>}
          onClick={onMenuClick}
        />
        : null
      }

      {isAuthorized(permissions, Activity.BENEFIT)
        ? <MenuItemLink
          sx={{color: theme.palette.secondary.main}}
          key={'benefits'}
          to={`/${'benefits'}`}
          primaryText={translate(`resources.benefits.name`, {
            smart_count: 2,
          })}
          leftIcon={<templates.ICON/>}
          onClick={onMenuClick}
        />
        : null
      }

      {/*<Responsive*/}
      {/*  xsmall={*/}
      {/*    <MenuItemLink*/}
      {/*      to="/configuration"*/}
      {/*      primaryText={translate('pos.configuration')}*/}
      {/*      leftIcon={<SettingsIcon/>}*/}
      {/*      onClick={onMenuClick}*/}
      {/*    />*/}
      {/*  }*/}
      {/*  medium={null}*/}
      {/*/>*/}
      {/*<Responsive xsmall={logout} medium={null}/>*/}

  </Box>
  );
};

Menu.propTypes = {
  onMenuClick: PropTypes.func,
  logout: PropTypes.object,
};

export default Menu;

// https://github.com/acdlite/recompose/blob/master/docs/API.md
// const enhance = compose(
//   withRouter,
//   connect(
//     state => ({
//       theme: state.theme,
//       locale: state.i18n.locale,
//     }),
//     {}
//   ),
//   translate,
//   withStyles(styles)
// );
// export default enhance(Menu);

// export default enhance(withStyles(styles))(Menu);

//export default withRouter(connect(mapStateToProps)(withStyles(styles)(Menu)));

// export default compose(
//   connect(
//     mapStateToProps,
//     {
//       changeLocale,
//       changeTheme,
//     }
//   ),
//   translate,
//   withStyles(styles)
// )(Menu);