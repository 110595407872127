import React, {useState, useEffect} from 'react';
import {
  EditView,
  TabbedForm,
  FormTab,
  Toolbar,
  TextInput, SelectInput,
  //AutocompleteInput,
  RadioButtonGroupInput,
  SaveButton,
  TopToolbar, DeleteWithConfirmButton,
  useEditController,
  Loading,
  required,
  EditContextProvider, useResourceContext,
  useRecordContext
} from 'react-admin';
import log from 'loglevel';
import {Breadcrumbs} from "../breadcrumbs";
import {getTabName, queryParamsToFilterString, queryParamsToObject} from "../util/paramUtil";
import {DetailTitle} from "../components/InputFields";
import {UnShowButtonWithFilter} from "../button/UnShowButtonWithFilter";
import UnHelpButton from "../button/UnHelpButton";
import Aside from './TemplateFieldPanel';
import {sources, getSourceName, necessities} from './TemplateFieldConstants';
import {formats} from "./TemplateFieldUtil"
import {isValidTabName} from "../components/EditActions";
import {useLocation, useParams} from "react-router-dom";
import FormatInput from "./FormatInput";

// The input components are wrapped inside a div to ensure a good-looking form by default.
// You can pass a formClassName prop to the input components to customize the style of this div.
// For example, here is how to display two inputs on the same line:
//   <TextInput source="first_name" formClassName={classes.inlineBlock} />
// const useStyles = makeStyles({
//   inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
// });

const FieldEditActions = ({filter, tabName}) => {
  const adjTabName = isValidTabName(tabName) ? tabName : '';
  return (
    <TopToolbar>
      <UnShowButtonWithFilter filter={filter} tabName={adjTabName}/>
      <UnHelpButton helpPage={'/Configuration/field-formats'}/>
    </TopToolbar>
  );
};

const FieldEditToolbar = ({filter}) => {
  const record = useRecordContext();
  const {templateId, templateName} = filter;

  const redirectToFieldsList = () => {
    const url = `templates/${templateId}/show?${queryParamsToFilterString({name: templateName})}`;
    log.debug(`{redirectToFieldsList} url:`, url);
    return url;
  }

  return (
    <Toolbar>
      <SaveButton/>
      <DeleteWithConfirmButton confirmTitle={`Delete ${record.title}?`}
                               confirmContent={'Confirm to delete this template field'}
                               redirect={redirectToFieldsList}
      />
    </Toolbar>
  )
};

/**
 * args should contain templateId, templateName and name:
 * - templateId is the parent ID needed for the GET
 * - templateName is used in the 2nd breadcrumb
 * - name is the title of the current page and 4th breadcrumb
 * @returns {*}
 * @constructor
 */
const TemplateFieldEdit = () => {
  const location = useLocation();
  const resource = useResourceContext();
  const {id} = useParams();
  //const classes = useStyles();
  const [source, setSource] = useState('expr');

  const onSourceChange = (props) => {
    const {target} = props;
    setSource(target.value);
  }

  // Signature resource, id, data
  const redirectPath = () => {
    const locationTabName = getTabName(location);
    // log.debug(`{TemplateFieldEdit.redirectPath} locationTabName: ${locationTabName}`);
    return isValidTabName(locationTabName) ?
      `${resource}/${id}/show/${locationTabName}?${queryParamsToFilterString({templateId})}` :
      `${resource}/${id}/show?${queryParamsToFilterString({templateId})}`;
  }

  const args = queryParamsToObject(location);
  const {templateId, templateName} = args;
  const parent = 'templates';
  const editContext = useEditController({
    resource,
    id,
    queryOptions: {meta: {templateId}},
    mutationMode: "pessimistic",  // Subsequent redirected detail page is stale without this
    redirect: redirectPath
  });
  const {record, isLoading} = editContext;

  useEffect(() => {
    //console.log(`{useEffect} Setting source from ${source} to ${record.source}`);
    if (record) {
      setSource(record.source);
    }
  }, [record]);

  if (isLoading) return <Loading/>;
  const {title} = record;

  // No longer possible to return React Element for optionText
  // const optionRenderer = nec => <Fragment>{nec.name}&nbsp;<NecessityField necessity={nec.id} {...props}/></Fragment>;
  //const optionRenderer = nec => nec.name;
  const necessityRenderer = nec => {
    //console.log(`{necessityRenderer} nec:`, nec);
    //return <NecessityField necessity={nec.id} {...props}/>
    return nec.name;
  }
  // const onFormatsChange = (event, newValue) => {
  //   console.log(`{onMyChange} newValue:`, newValue);
  //   return newValue;
  // }

  const {name} = record;
  const breadcrumbs = [ // Note label (vs title) translates string with name_many in i18n
    {url: `/${parent}`, label: parent, resource: parent},
    {url: `/${parent}/${templateId}/show?${queryParamsToFilterString({name: templateName})}`,
      title: templateName, resource: parent},
    {url: `/${resource}?${queryParamsToFilterString({templateId, templateName})}`, label: resource, resource},
    {url: `/${resource}/${id}/show?${queryParamsToFilterString({templateId, templateName, name})}`, title, resource},
  ];

  // onChange={onFormatsChange}
  //log.debug(`{TemplateFieldEdit} record.source: ${record.source}, getSourceName: ${getSourceName(record.source)}` )
  log.info(`{TemplateFieldEdit} id: ${id}, name: ${name}, templateId: ${templateId}, templateName: ${templateName}, args:`, args);
  return (
    <EditContextProvider value={editContext}>
      <Breadcrumbs breadcrumbs={breadcrumbs}/>
      <EditView
        title={<DetailTitle prefix={"Field"} titleField={"title"}/>}
        actions={<FieldEditActions filter={{templateId, templateName}} tabName={getTabName(location)}/>}
        aside={<Aside/>}
      >
        <TabbedForm
          //redirect={redirect}
          toolbar={<FieldEditToolbar filter={args} record={record}/>}>
          <FormTab label="Field">
            <TextInput source="title" fullWidth />
            {/*<TextInput source="format" fullWidth />*/}

            <FormatInput source="format" name="format" label="Format" fullWidth
                         formats={formats}
            />
            {/*<AutocompleteInput source="format" choices={formats.map(f => ({id: f, name: f}))} />*/}
            {/*<BasicInput name="format" label="Format" validate={required()}/>*/}

              {source === "expr" ?
              <TextInput source={'value'} label={getSourceName(record.source)}
                         InputProps={{style: {fontFamily: 'courier'}}}
                         multiline={true}
                         rows={15}
                         fullWidth
              /> : null}
            {source === "constant" ?
              <TextInput source={'value'} label={`Constant value`} fullWidth /> :
              null}
            {source === "code" ?
              <TextInput source="name" label={'Dictionary Entry'} fullWidth
              /> : null}
            {source === "code" ?
              <TextInput source="value" label={'Default'} fullWidth
              /> : null}
            {source === "calc" ?
              <TextInput source="name" label={`Calculation name`} fullWidth
              /> : null}
            {source === "function" ?
              <TextInput source="name" label={`Function name`} fullWidth
              /> : null}

            <TextInput source={'notes'} multiline={true} rows={3} fullWidth />
          </FormTab>

          <FormTab label='Options' path="options">
            <SelectInput
              source="source"
              choices={sources}
              validate={[required()]}
              fullWidth onChange={onSourceChange}
            />
            {/*<SelectInput source="necessity" choices={necessities} initialValue={'expected'} fullWidth />*/}

            <RadioButtonGroupInput source="necessity"
                                   choices={necessities}
                                   optionText={necessityRenderer}
                                   //optionText={'id'}
                                   translateChoice={false}
                                   row={false}
            />

            <RadioButtonGroupInput source="state" label="Visibility" choices={[
              { id: 's', name: 'Visible' },
              { id: 'h', name: 'Hidden' },
            ]} />

          </FormTab>
        </TabbedForm>
      </EditView>
    </EditContextProvider>
  )
};

export default TemplateFieldEdit;
