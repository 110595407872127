/**
 * Docs: https://marmelab.com/react-admin/Tutorial.html
 * Demo: https://github.com/marmelab/admin-on-rest-demo
 * Local: http://192.168.56.102:3000/
 *
 * CHOKIDAR_USEPOLLING=true npm start
 * https://github.com/facebook/create-react-app/issues/1049#issuecomment-261731734
 *
 * Material UI icons: https://material.io/tools/icons/?style=baseline
 */

import React, {useState, useEffect} from 'react';
import {Route} from 'react-router';
import {
  Admin,
  Resource,
  CustomRoutes,
  //defaultTheme
} from 'react-admin';
import log from 'loglevel';
import './App.css';
import authProvider from './auth/authProvider';
import dataProviderFactory from './dataProvider/index.js';
import {
  lightTheme,
  //darkTheme, plainTheme
} from './layout/themes';
import {Login, Layout} from './layout';
import Menu from './layout/Menu';
import {Dashboard} from './dashboard';

import englishMessages from './i18n/en';
import frenchMessages from './i18n/fr';
import germanMessages from './i18n/de';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import users from './users';
import password from './password';
import sites from './sites';
import employers from './employers';
import employees from './employees';
import persons from './persons';
import contracts from './contracts';
import evaluations from './evaluations';
import benefits from './benefits';
import representatives from './representatives';
import applications from './representatives';
import untanglements from './untanglements';
import dictionaries from './dictionaries';
import templates from './templates';
import templatefields from './templatefields';
import templatefieldsorder from './templatefieldsorder';
import dictionaryentries from './dictionaryentries';
import dictionaryaliases from './dictionaryaliases';
import dictionaryredirect from './dictionaryredirect';
import permissions from './permissions';
import locales from './locales';
import entrytypes from './entrytypes';
import setups from './setups';
import {Activity, isAuthorized} from './auth/authorization';
import Configuration from "./configuration/Configuration";
import Profile from "./profile/Profile";

const messages = {
  en: englishMessages,
  fr: frenchMessages,
  de: germanMessages
};

const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'en', {allowMissing: true});

// console.error.bind()

const App = () => {
  //state = { dataProvider: null };
  const [dataProvider, setDataProvider] = useState(null);

  useEffect(() => {
    //let restoreFetch;

    const fetchDataProvider = async () => {
      // restoreFetch = await fakeServerFactory(
      //   process.env.REACT_APP_DATA_PROVIDER
      // );
      const dataProviderInstance = await dataProviderFactory(
        'rest' //process.env.REACT_APP_DATA_PROVIDER
      );

      setDataProvider(
        //await dataProviderFactory('rest' ) // process.env.REACT_APP_DATA_PROVIDER
        () => dataProviderInstance
      );
    };

    fetchDataProvider();
    return;
  }, []);

  if (!dataProvider) {
    return (
      <div className="loader-container">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  /** Note protocol ends with colon.
   *
   * @param protocol
   * @param hostname
   * @returns {string|*|string}
   */
  const envLogLevel = (protocol, hostname) => {
    if (protocol === 'https:') return 'info';  // Production (change to 'warn' later)
    const envLogLevelMapping = {
      'admin.untangl.uk': 'debug',  // QA  'debug'
      'admin.untangl.info': 'info', // Beta
    }
    return envLogLevelMapping[hostname] ? envLogLevelMapping[hostname] : 'debug';
  }

  const {protocol, hostname} = window.location;
  const logLevel = envLogLevel(protocol, hostname);
  log.setLevel(logLevel);
  // log.enableAll();
  log.info(`{App} NODE_ENV: ${process.env.NODE_ENV}, protocol: ${protocol}, ` +
    `hostname: ${hostname}, logLevel: ${logLevel}`);
  return (
    // <React.StrictMode>
    <Admin
      title="Admin"
      dataProvider={dataProvider}
      // customReducers={{
      //   theme: themeReducer,
      //   untangl: reduxReducers }}
      //customRoutes={customRoutes}
      authProvider={authProvider}
      dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      menu={Menu}
      i18nProvider={i18nProvider}
      //theme={defaultTheme}
      theme={lightTheme}  //lightTheme, plainTheme
    >
      <CustomRoutes>
        <Route path="/configuration" element={<Configuration />} />
        <Route path="/profile" element={<Profile />} />
      </CustomRoutes>
      {
        //console.log("{App.render} permissions:", permissions);
        // Note that enabling the menu is handled in layout/Menu.js
        myPermissions => [
          <Resource name="untanglements" {...untanglements} />,

          // (isAuthorized(myPermissions, Activity.UNTANGLER))
          //   ? <Resource name="untanglements" {...untanglements} />
          //   : null,
          // (isAuthorized(myPermissions, Activity.DICTIONARY))
          //   ? <Resource name="datadictionaries" {...datadictionaries} />
          //   : null,
          (isAuthorized(myPermissions, Activity.DICTIONARY_READ))
            ? <Resource name="dictionaries" {...dictionaries} />
            : null,
          (isAuthorized(myPermissions, Activity.DICTIONARY_READ))
            ? <Resource name="dictionaryentries" {...dictionaryentries} />
            : null,
          (isAuthorized(myPermissions, Activity.DICTIONARY_READ))
            ? <Resource name="dictionaryaliases" {...dictionaryaliases} />
            : null,
          (isAuthorized(myPermissions, Activity.DICTIONARY_READ))
            ? <Resource name="dictionaryredirect" {...dictionaryredirect} />
            : null,
          (isAuthorized(myPermissions, Activity.TEMPLATE_READ))
            ? <Resource name="templates" {...templates} />
            : null,
          (isAuthorized(myPermissions, Activity.TEMPLATE_READ))
            ? <Resource name="templatefields" {...templatefields} />
            : null,
          (isAuthorized(myPermissions, Activity.TEMPLATE))
            ? <Resource name="templatefieldsorder" {...templatefieldsorder} />
            : null,
          (isAuthorized(myPermissions, Activity.TEMPLATE_READ))
            ? <Resource name="locales" {...locales} />
            : null,
          (isAuthorized(myPermissions, Activity.TEMPLATE))
            ? <Resource name="setups" {...setups} />
            : null,
          (isAuthorized(myPermissions, Activity.DICTIONARY))
            ? <Resource name="entrytypes" {...entrytypes} />
            : null,
          (isAuthorized(myPermissions, Activity.UNTANGLER))
            ? <Resource name="permissions" {...permissions} />
            : null,
          (isAuthorized(myPermissions, Activity.UNTANGLER))
            ? <Resource name="password" {...password} />
            : null,
          (isAuthorized(myPermissions, Activity.USER_READ))
            ? <Resource name="users" {...users} />
            : null,
          (isAuthorized(myPermissions, Activity.SITE))
            ? <Resource name="sites" {...sites} />
            : null,
          (isAuthorized(myPermissions, Activity.APPLICATION_READ))
            ? <Resource name="representatives" {...representatives} />
            : null,
          (isAuthorized(myPermissions, Activity.APPLICATION_READ))  // applications same as representatives
            ? <Resource name="applications" {...applications} />
            : null,
          (isAuthorized(myPermissions, Activity.ORGANISATION))
            ? <Resource name="employers" {...employers} />
            : null,
          (isAuthorized(myPermissions, Activity.ORGANISATION))
            ? <Resource name="contracts" {...contracts} />
            : null,
          (isAuthorized(myPermissions, Activity.ORGANISATION))
            ? <Resource name="evaluations" {...evaluations} />
            : null,
          (isAuthorized(myPermissions, Activity.ORGANISATION))
            ? <Resource name="employees" {...employees} />
            : null,
          (isAuthorized(myPermissions, Activity.ORGANISATION))
            ? <Resource name="benefits" {...benefits} />
            : null,
          (isAuthorized(myPermissions, Activity.ORGANISATION))
            ? <Resource name="persons" {...persons} />
            : null
        ]}
    </Admin>
    /*</React.StrictMode>*/
  );
};

export default App;
