import React from 'react';
import {
  //translate,
  Create,
  SimpleForm,
  TextInput, DateInput, SelectInput,
  useTranslate
} from 'react-admin';
import log from 'loglevel';
import {getEmployerInfo} from "../employers/EmployerUtil";
import {queryParamString} from "../util/paramUtil";
import {Breadcrumbs} from "../breadcrumbs";

/**
 * /employers/{employer_id}/persons
 * [{dob: "1990-11-19"
 *   employment: {employer_id: "5a1d96c72d9bb76062bdb462"}
 *   first_name: "AAA"
 *   gender: "male"
 *   last_name: "BBB"
 * }]
 */

const PersonTitle = ({record, name}) => {
  log.debug("{PersonTitle} record:", record, ", name:", name);
  return <span>New Employee {name ? `for ${name}` : (record.employer_name ? `for ${record.employer_name}` : '')}</span>;
};

// const redirectX = (basePath, id, data) => {
//   const filterObj = {
//     employer_id: data.employer_id,
//     employer_name: data.employer_name
//   };
//   log.debug(`{PersonCreate.redirect} filterObj:`, filterObj);
//   return `/employees?filter=${JSON.stringify(filterObj)}`;
// };

/**
 * On submission, redirect to the list of employees.
 * Note that data is an array since it has already been converted (in create()) in the data provider.
 * redirect is called on submission and contains entered form contents in data including employerId.
 * Note redirect takes signature (basePath, id, data), not props!
 * queryParamString converts the filter object to a string for use in the URL.
 * @param basePath  '/dictionaryentries'
 * @param id        Dictionary entry ID. Needed for show but not list.
 * @param data      Using {dictId} from data
 * @returns {string}
 */

const redirect = (basePath, id, data) => {
  const {employer_id, employer_name} = data[0];
  log.debug("{redirect} data:", data[0]);
  return `/employees?${queryParamString({employer_id, employer_name})}`;
}

// TODO: pass employer ID and name to this form
const PersonCreate = (props) => {
  log.debug("{PersonCreate} props:", props);
  const translate = useTranslate();
  let {employerId, employerName} = getEmployerInfo(props);
  log.debug(`{PersonCreate} employerId: ${employerId}, employerName: ${employerName}`);
  // State disappears if you refresh page. This obtains it from URL query string if missing.
  const {resource, location} = props;
  if (!location.state && employerId) {
    log.debug("{PersonCreate} state not set, so no form prefill");
    location.state = {record:
      {employer_id: employerId,
       employer_name: employerName}
    };
    log.debug("{PersonCreate} location.state set:", location.state);
  }

  // TODO: state should contain record: employer_id to prefill field.
  // If state absent, fill here from filter?
  const parent = 'employers';
  const breadcrumbs = [
    {url: `/${parent}`, title: translate(`resources.${parent}.name_many`), resource: parent},
    {url: `/${parent}/${employerId}/show?${queryParamString({name: employerName})}`, title: employerName, resource: parent},
    {url: `/${resource}?${queryParamString({name: employerName})}`, title: translate(`resources.${resource}.name_many`), resource},
    {url: ``, title: translate(`navigation.create`), resource},
  ];

  return (
    <React.Fragment>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Create title={<PersonTitle name={employerName}/>} {...props}>
        <SimpleForm redirect={redirect}>
          <TextInput source="first_name"/>
          <TextInput source="last_name"/>
          <SelectInput source="gender" choices={[
            { id: 'male', name: 'Male' },
            { id: 'female', name: 'Female' }
          ]}/>
          <DateInput source="dob"/>
          <TextInput source="employer_id" disabled />
          {/*<FunctionField label="Name" render={record => `${employerId}`} />*/}
          {/*<FormDataConsumer>*/}
            {/*{({ formData, ...rest }) => formData.hasEmail &&*/}
              {/*<TextInput source="email" {...rest} />*/}
            {/*}*/}
          {/*</FormDataConsumer>*/}
        </SimpleForm>
      </Create>
    </React.Fragment>
  );
}

export default PersonCreate;