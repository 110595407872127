import React from 'react';
import BreadcrumbsList from './UnBreadcrumbsList';
import BreadcrumbsShow from './UnBreadcrumbsShow';
import {useParams} from "react-router-dom";
import {BreadcrumbElements} from "./UnBreadcrumbsUtil";


/**
 * Render a breadcrumb trail using the appropriate way for the page (list, show or edit)
 * Usage:
 *   import {Breadcrumbs} from '../breadcrumbs';
 *   <Breadcrumbs/>
 * Not yet supported: create. (useParams also returns {*: ''} for create)
 * @param props
 * @returns {*}
 * @constructor
 */
const UnBreadcrumbs = (props) => {
  const {id} = useParams();
  const {breadcrumbs} = props;
  const isShowOrEdit = !!id;
  if (breadcrumbs) {
    return BreadcrumbElements(breadcrumbs);
  }
  if (isShowOrEdit) return (<BreadcrumbsShow {...props}/>)
  else return (<BreadcrumbsList {...props}/>);
}

export default UnBreadcrumbs;