import frenchMessages from 'ra-language-french';

export default {
    ...frenchMessages,
    pos: {
        search: 'Rechercher',
        configuration: 'Configuration',
        language: 'Langue',
        theme: {
            name: 'Theme',
            light: 'Clair',
            dark: 'Obscur',
            plain: 'Plain'
        },
        dashboard: {
            monthly_revenue: 'CA à 30 jours',
            new_orders: 'Nouvelles commandes',
            pending_reviews: 'Commentaires à modérer',
            new_customers: 'Nouveaux clients',
            pending_orders: 'Commandes à traiter',
            order: {
                items:
                    'par %{customer_name}, un poster |||| par %{customer_name}, %{nb_items} posters',
            },
            profile: "Profile",
            welcome: {
                title: 'Bienvenue sur la démo de react-admin',
                subtitle:
                    "Ceci est le back-office d'un magasin de posters imaginaire. N'hésitez pas à explorer et à modifier les données. La démo s'exécute en local dans votre navigateur, et se remet à zéro chaque fois que vous rechargez la page.",
                aor_button: 'Site web de react-admin',
                demo_button: 'Code source de cette démo',
            },
        },
    },
    resources: {
        users: {
            name: 'Utilisateur |||| Utilisateurs',
            fields: {
                name: 'Nom',
                first_name: 'Prénom',
                last_name: 'Nom de famille',
                user_name:"Nom d'utilisateur",
                rep_uname: 'Nom unique de Rep',
                created: 'Créé',
                verifyDate: 'Vérifié'
            }
        },
        employers: {
            name: 'Employeur |||| Employeurs',
            fields: {
                id: 'ID'
            }
        },
        contracts: {
            name: 'Contract |||| Contracts',
            fields: {
                benefit_uname: 'Benefit Name',
                benefit_version: 'Benefit Version',
                rates_version: 'Rates Version',
                policy_number: 'Policy Number',
                start_date: 'Start Date',
                end_date: 'End Date',
                creation_date: 'Créé'
            }
        },
        untanglements: {
            name: 'Conversion |||| Conversions',
            name_one: 'Conversion',
            name_many: 'Conversions',
            fields: {
                elapsed_time: 'Durée (s)',
                data_size: 'Taille (KB)',
                in_range: 'Intervalle'
            }
        },
        representatives: {
            name: 'Représentant |||| Représentants',
            fields: {
                name: 'Nom',
                uname: 'Nom unique'
            }
        },
        sites: {
            name: 'Site |||| Sites'
        },
        datadictionaries: {
            name: 'Définition du dictionnaire |||| Définitions du dictionnaire',
            fields: {
                id: 'ID'
            }
        },
        customers: {
            name: 'Client |||| Clients',
            fields: {
                address: 'Rue',
                birthday: 'Anniversaire',
                city: 'Ville',
                commands: 'Commandes',
                first_name: 'Prénom',
                first_seen: 'Première visite',
                groups: 'Segments',
                has_newsletter: 'Abonné à la newsletter',
                has_ordered: 'A commandé',
                last_name: 'Nom',
                last_seen: 'Vu le',
                last_seen_gte: 'Vu depuis',
                latest_purchase: 'Dernier achat',
                name: 'Nom',
                total_spent: 'Dépenses',
                zipcode: 'Code postal',
            },
            tabs: {
                identity: 'Identité',
                address: 'Adresse',
                orders: 'Commandes',
                reviews: 'Commentaires',
                stats: 'Statistiques',
            },
            page: {
                delete: 'Supprimer le client',
            },
        },
        commands: {
            name: 'Commande |||| Commandes',
            fields: {
                basket: {
                    delivery: 'Frais de livraison',
                    reference: 'Référence',
                    quantity: 'Quantité',
                    sum: 'Sous-total',
                    tax_rate: 'TVA',
                    total: 'Total',
                    unit_price: 'P.U.',
                },
                customer_id: 'Client',
                date_gte: 'Passées depuis',
                date_lte: 'Passées avant',
                nb_items: 'Nb articles',
                reference: 'Référence',
                returned: 'Annulée',
                status: 'Etat',
                total_gte: 'Montant minimum',
            },
        },
        categories: {
            name: 'Catégorie |||| Catégories',
            fields: {
                name: 'Nom',
                products: 'Produits',
            },
        },
        dictionaries: {
            name: 'Dictionary |||| Dictionaries',
            name_one: 'Dictionary',
            name_many: 'Dictionaries',
            fields: {
                id: 'ID',
                _id: 'ID',
                description: 'Description'
            }
        },
        dictionaryentries: {
            name: 'Dictionary Entry |||| Dictionary Entries',
            name_one: 'Entry',
            name_many: 'Entries',
            fields: {
                id: 'ID',
                _id: 'ID',
                name: 'Name',
                value: 'Value',
                alternates: 'Alternates',
                alias: 'Alias',
                weight: 'Weight',
                dictName: 'Dictionary',
                dictId: 'Dictionary ID'
            }
        },
        dictionaryaliases: {
            name: 'Dictionary Alias |||| Dictionary Aliases',
            name_one: 'Dictionary Alias',
            name_many: 'Dictionary Aliases',
            fields: {
                id: 'ID',
                _id: 'ID',
                alias: 'Alias',
                name: 'Name',
                weight: 'Weight',
                dictName: 'Dictionary',
                dictId: 'Dictionary ID'
            }
        },
        templates: {
            name: 'Template |||| Templates',
            name_one: 'Template',
            name_many: 'Templates',
            fields: {
                id: 'ID',
                _id: 'ID',
                name: 'Name',
                value: 'Value'
            }
        },
        templatefields: {
            name: 'Template Field |||| Template Fields',
            name_one: 'Field',
            name_many: 'Fields',
            name_reorder: 'Template Fields Ordering',
            fields: {
                id: 'ID',
                _id: 'ID',
                title: 'Title',
                name: 'Name',
                source: 'Source',
                value: 'Value',
                expression: 'Formula',
                code: {name: 'Code'},
                constant: {value: 'Constant'},
                function: {name: 'Function'},
                format: 'Format',
                necessity: 'Necessity'
            }
        },
        templatefieldsorder: {
            name: 'Template Field |||| Template Fields (Ordering)',
            name_reorder: 'Reorder Template Fields',
            name_one: 'Field',
            name_many: 'Fields (Ordering)',
            fields: {
                id: 'ID',
                _id: 'ID',
                title: 'Title',
                name: 'Name',
                source: 'Source',
                value: 'Formula',
                code: {name: 'Code'},
                constant: {value: 'Constant'},
                function: {name: 'Function'},
                format: 'Format',
                necessity: 'Necessity'
            }
        },
        conversions: {
            name: 'Conversion |||| Conversions',
            fields: {
                elapsed_time: 'Duration (s)',
                data_size: 'Size (KB)',
                in_range: 'Range'
            }
        },
        setups: {
            name: 'Onboard Client |||| Onboard Client',
            name_one: 'Onboard Client',
            name_many: 'Onboard Client',
            fields: {
                application_name: 'Application Name',
                email: "Email Address"
            }
        },
        benefits: {
            name: 'Benefit |||| Benefits',
            name_one: 'Benefit',
            name_many: 'Benefits',
            benefitName: 'Benefit Name',
            fields: {
                field: 'Field',
                fieldName: 'Field Name',
                fieldValue: 'Field Value',
                name: 'Name',
                value: 'Value',
                formula: 'Formula',
                section: 'Section',
                sectionName: 'Section Name',
                optionName: 'Option Name',
                optionValue: 'Option Value',
                groupName: 'Group Name',
                groupValue: 'Group Value',
                group: "Group",
                constraint: "Constraint",
                constraints: "Constraints"
            }
        },

    },
};
