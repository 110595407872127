/**
 * DragTable
 * Uses react-sortable-hoc. See:
 *   https://github.com/clauderic/react-sortable-hoc
 *   https://codesandbox.io/s/l7v0qz869z
 * Normal react-admin data are stored in ids and data. Ids are an ordered list of IDs - one for each displayed
 * document's ObjectID. Data contains every document keyed on each ID.
 */
import React from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import get from 'lodash/get';
import 'react-base-table/styles.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DragHandleIcon from "@mui/icons-material/DragHandle";
import arrayMove from "array-move";
import {makeStyles} from "@mui/styles";
import {NecessityField} from "../templatefields/Necessity";

const useStyles = makeStyles(
  () => ({
    expression: {
      fontFamily: 'courier',
      // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
      '& svg': {color: 'orange'}
    },
    source: {
      fontStyle: 'italic'
      //color: theme.palette.error.main
    }
  }));

const DraggableContainer = SortableContainer(({ children }) => children)

const DragHandle = SortableHandle(() => (<DragHandleIcon />));

// Sortable row
// This should be changed so that this has a children property
// which allows TableCells to be specified for each column, including problems like code.name.

/**
 * Sortable row
 * This can be changed so that it has a children property which allows TableCells to be specified for each column.
 * sources is an array of column sources. E.g. value contains expressions and constants. name contains code and
 * calculation.
 * @type {React.ComponentClass<SortableElementProps>}
 */
const SortableRow = SortableElement((props) => {
  const classes = useStyles();
  const {sources, record} = props;
  return (
    <TableRow key={record.id}>
      {sources.map(colSource => {  // Column names
        //console.log(`{SortableRow} codeField[col]: ${codeField[col]}, record[col]: ${record[col]}`);
        const value = get(record, colSource);
        //console.log(`{SortableRow} colSource: ${colSource}, value: ${value}, record:`, record);
        if (colSource === 'name' || colSource === 'source')  // name has no separate column...goes in with value column
          return null;
        else if (colSource === 'code' || colSource === 'calc' || colSource === 'function') return (
          <TableCell key={colSource}>{'xxxxx'}</TableCell>
        )
        // Rending the 'Formula' column (i.e. colSource = 'value'), which may contain value or name from record
        else if (colSource === 'value' && value) {
            if (record.source === 'expr') return (
              <TableCell key={colSource}
                //style={{backgroundColor:'red', color: 'white'}} // This works too
                         className={classes.expression}
              >
                {value}
              </TableCell>
            )
            else return (// Constant
              <TableCell key={colSource}>{record.value} &nbsp;(Constant)</TableCell>
            )
          }
        else if (colSource === 'value' && record.name) {
          const t = {
            'code' : 'Dictionary',
            'function': 'Function',
            'calc': 'Calculation'
          }
          return (
            <TableCell key={colSource}>{record.name} &nbsp;({t[record.source]})</TableCell>
           )
        }
        else if (colSource === 'necessity') return (
          <TableCell key={colSource}>
            <NecessityField key={colSource} source="necessity" record={record}/>
          </TableCell>
        )
        else return (
          <TableCell key={colSource}>{value}</TableCell>
        )
      })}
      <TableCell key={`${record.title}`}>
          <DragHandle/>
      </TableCell>
      {/*<ListItemSecondaryAction>*/}
      {/*<DragHandle />*/}
      {/*</ListItemSecondaryAction>*/}
    </TableRow>
  )
});

const containerClass = '.MuiTable-root'; //'.MuiTable-root'; '.BaseTable__body' '.BaseTable'

/**
 * Helper function to convert an entry from ids and data to an item in items (used by this table).
 * @param id          ID for one data object (eg one template field)
 * @param dataRecord  Record in data object for id
 * @param sources     Columns, defined in client list component, such as 'title', 'code.name', 'constant.value'
 * @returns {*}
 */
// const getItem = (id, dataRecord, sources) => sources.reduce((accum, source) =>
//   ({...accum, [source.split('.')[0]]: dataRecord[source.split('.')[0]]}), {id});

// const doNothing = (props, arg) => {
//   console.log(`{doNothing} this.props.data:`, props.data);
//   console.log(`{doNothing} this.props.ids:`, props.ids);
//   return arg;
// }

/**
 * Material-UI table using drag and drop from react-sortable-hoc
 * Call with:
 *    <DragTable sources={['title', 'code.name']} headings={['Title', 'Code'} onDrop={onDrop}
        {...controllerProps} />
 * where onDrop is a callback function to run database/API update.
 * Note items is created based on sort order of ids.
 */
class DragTable extends React.Component {

  state = {
    data: this.props.records,
    // ids: this.props.ids,
    // items: this.props.ids.map(id => getItem(id, this.props.data[id], this.props.sources))
    items: this.props.records,
  };

  /**
   * Used by react-sortable-hoc to identify the container to which the dragged element is to be attached.
   * TODO: styling on this needs improving.
   * @returns {Element}
   */
  getContainer() {
    return document.querySelector(containerClass); // Was '.BaseTable__body'
  }

  getHelperContainer() {
    return document.querySelector(containerClass)
  }

  /**
   * handleSortEnd is invoked on drop.
   * It sorts the items and calls the caller's onDrop with an updated list of sorted IDs.
   * @param oldIndex
   * @param newIndex
   */
  handleSortEnd = ({oldIndex, newIndex}) => {
    console.log(`{DragTable.handleSortEnd} before sort - this.state.items:`, this.state.items);
    const items = arrayMove(this.state.items, oldIndex, newIndex);
    this.setState({items});
    const updatedIds = items.map(item => item.id);
    this.setState({ids: updatedIds});
    this.props.onDrop({...this.props, updatedIds}); // Call client's callback function to call REST API
  }

  render() {
    const {sources, headings} = this.props;
    console.log(`{DragTable.render} this.state.items:`, this.state.items); // Correct
    const filteredHeadings =  headings.filter(h => !['Source', 'Name'].includes(h)); // i18n!!!
    console.log(`{DragTable.render} filteredHeadings:`, filteredHeadings);
    return (
      <DraggableContainer
        useDragHandle
        getContainer={this.getContainer}
        helperContainer={this.getHelperContainer}
        onSortEnd={this.handleSortEnd}
      >
        <Table>
          <TableHead>
            <TableRow>
              {filteredHeadings.map(heading => (  // Hack using <b> instead of Ra or Mui styling
                <TableCell key={heading}><b>{heading}</b></TableCell>
              ))}

              <TableCell key={'drag'}>
                <b>Drag/Drop</b>
              </TableCell>

            </TableRow>
          </TableHead>

          <TableBody>
            {this.state.items.map((item, index) => {
                //console.log(`{render.TableBody} item ${index}:`, item);
                return (
                  <SortableRow key={item.id} index={index} sources={sources} record={item}
                               title={item.title}
                               style={{
                                 muiTableCell: {color: '#EEEEEE'}
                               }}
                  />
                )
              }
            )}
          </TableBody>
        </Table>
      </DraggableContainer>
    )
  }
}

export default DragTable;