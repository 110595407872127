
import isNil from 'lodash/isNil';
import toLower from 'lodash/toLower';
import startCase from'lodash/startCase';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import React from "react";

// See: https://github.com/mui-org/material-ui-x/tree/master/packages/grid/_modules_/gridimport log from 'loglevel';

const titleCase = str => startCase(toLower(str));

/**
 * Display cell in grid
 * @param fieldName
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const TableItemsCell = ({fieldName, item}) => {
  //log.debug(`{TableItemsCell} fieldName: ${fieldName}, item:`, item);
  return (!isNil(item) && !isNil(fieldName) && item[fieldName]) ? (
    <TableCell>{item[fieldName]}</TableCell>
  ) : <TableCell/>;
}

/**
 * Display a grid like DataGrid
 * Use this instead of a DataGrid in a tab if you get error:
 *   index.js:1 Warning: Failed prop type: The prop `currentSort.field`
 *   is marked as required in `Datagrid`, but its value is `undefined`.
 *  E.g. in a TabbedShowLayout:
 *  <Tab label="aliases">
 *    <TableItemsField items={record.aliases} fieldNames={['alias', 'weight']}/>
 *  </Tab>
 * @param items         Array of objects (e.g. from record/subrecord) containing properties whose names
 *                      match titles in fieldNames.
 * @param fieldNames    Array of titles to appear in header
 * @returns {JSX.Element}
 * @constructor
 */
const TableItemsField = ({items, fieldNames}) => {
  //console.log(`{TableItemsField} fieldNames:`, fieldNames);
  //console.log(`{TableItemsField} items:`, items);
  return (
    <Table aria-label="simple table" size="small">
      <TableHead>
        <TableRow key={0}>
          {fieldNames.map(fieldName => (
            <TableCell key={fieldName}><b>{titleCase(fieldName)}</b></TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {items? items.map((item, row) => (
          <TableRow key={row}>
            {fieldNames.map((fieldName, col) => (
              <TableItemsCell item={item} fieldName={fieldName} key={col.toString()}/>
            ))}
          </TableRow>
        )) : '' }
      </TableBody>
    </Table>
  );
}

export default TableItemsField;