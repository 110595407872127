import {useResourceContext, useTranslate} from "react-admin";
import {BreadcrumbElements} from './UnBreadcrumbsUtil';

// const breadcrumbsData = (resource) => {
//   const translate = useTranslate();
//   const breadcrumbsState = useSelector(state => state.untangl.breadcrumbTrail);
//   const breadcrumbs =  breadcrumbsState.breadcrumbs ? breadcrumbsState.breadcrumbs : [];
//   console.log(`{breadcrumbsNestedData} breadcrumbTrail state:`, breadcrumbsState);
//   const breadcrumb = {url: `/${resource}/create`, title: translate(`navigation.create`), resource};
//   return [...breadcrumbs, breadcrumb];
// }

// const breadcrumbsNestedData = (id, name, resource) => {
//   // const filterObj = {name};
//   // const filterStr = queryParamString(filterObj);
//   // console.log(`{breadcrumbsDetail} filterStr: ${filterStr}`);
//   const translate = useTranslate();
//   const breadcrumbsRedux = useSelector(state => state.untangl.breadcrumbTrail);
//   console.log(`{breadcrumbsNestedData} redux breadcrumbTrail:`, breadcrumbsRedux);
//   const createBreadcrumb = {url: `/${resource}`, title: translate(`resources.${resource}.name_many`)};
//   return [
//     {url: `/${resource}/create`, title: translate(`resources.navigation.create`)}
//   ];
// }

/**
 * The URL won't work for a nested resource (e.g. dictionaryentries) but
 * it's not needed since it is a leaf node that is never an active link.
 * @returns {*}
 * @constructor
 */

const UnBreadcrumbsCreate = () => {
  const resource = useResourceContext();
  const translate = useTranslate();
  console.log(`{UnBreadcrumbsCreate} resource:`, resource);

  const showBreadcrumbs = [
    {url: `/${resource}`, title: translate(`resources.${resource}.name_many`), resource},
    {url: ``, title: translate(`navigation.create`), resource}
  ];
  return BreadcrumbElements(showBreadcrumbs);
}

export default UnBreadcrumbsCreate;