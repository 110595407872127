import React from 'react';
import {useLocation, Link} from "react-router-dom";
import {
  ListView, Datagrid,
  TextField, FunctionField,
  TopToolbar,
  Pagination,
  useTranslate,
  useResourceContext,
  useListController,
  ListContextProvider,
  Loading
} from 'react-admin';
import log from 'loglevel';
import EntityName from '../components/EntityName';
import {Breadcrumbs} from "../breadcrumbs";
import {
  defaultPerPage,
  queryParamsToFilterString, queryParamsToObject,
} from "../util/paramUtil"; //queryParamObject not yet used
import {ListTitle} from "../components/InputFields";
import DeletePermissionButton from './DeletePermissionButton';

// Content-Range: posts 0-24/319
// E.g. <DeleteButton resource="comments" basePath="/comments" redirect={false} />

// To revoke, we previously called POST
// http://admin.untangl.info/api/v1/admin/users/5a1d9530a3baa9617ddc1c42/jurisdictions?event=unsetuntangler
//   &entity_id=5a33a86d4c61115f97cd9e95&security_code=xxxx
// which specified the event to unset the jurisdiction.
// We now calL DELETE /users/{user_id}/jurisdictions/{role_name}, which does not require a security code.
/**
 * A permission identifier takes the form: un_support!RP:5f840988218cda724c592eca.
 */

/**
 * CreateButton requires user_id to be specified for backend url:
 *   POST /users/{user_id}/jurisdictions
 *
 * See: https://marmelab.com/react-admin/Routing.html#linking-to-a-page
 * @param user_id
 * @param user_name
 * @returns {JSX.Element}
 * @constructor
 */
const PermissionsActions = ({user_id, user_name}) => {
  log.debug(`{PermissionsActions} user_id: ${user_id}, user_name: ${user_name}`);
  return <TopToolbar>
    {/*<UnCreateButton filterValues={{user_id, user_name}}*/}
    {/*                //icon={<Permissions.ICON/>}*/}
    {/*/>*/}
    {/*<CreateButton />*/}
    <Link to={`/permissions/create?user_id=${user_id}&user_name=${user_name}`}>
      Create Permission
    </Link>
  </TopToolbar>
};

const PermissionsPagination = () => <Pagination rowsPerPageOptions={[100]} />

export const PermissionsList = () => {
  const translate = useTranslate();
  const location = useLocation();
  const resource = useResourceContext();
  log.debug(`[PermissionsList] resource: ${resource}`);
  const args = queryParamsToObject(location);  // E.g. /permissions?user_id=63441784a4dcc64f4bfcbfd1
  log.debug("[PermissionsList] args from queryParamFilter:", args);
  const {user_id, user_name} = args;
  console.log(`{PermissionsList} user_id: ${user_id}, user_name: ${user_name}`);

  // const {user_id, user_name} = queryParamObject(props.location);
  // if (user_id && user_id.length > 12) { // TODO: minLength in swagger
  //   log.debug("[PermissionsList] user_id:", user_id, ", user_name:", user_name);

    const listContext = useListController({
      pagination: <PermissionsPagination />,
      perPage: defaultPerPage,
      //meta: {user_id},
      filter: {user_id},  // permanent filter, forced over the user filter https://marmelab.com/react-admin/useListController.html#usage
      //sort: {field: 'name', order: 'ASC'}
    });
    const {isLoading} = listContext;
    if (isLoading) return <Loading />;

    const parent = 'users';
    const breadcrumbs = [
      {url: `/${parent}`, title: translate(`resources.${parent}.name_many`), resource: parent},
      {url: `/${parent}/${user_id}/show?${queryParamsToFilterString({user_name, name: user_name})}`, title: user_name, resource: parent},
      {url: ``, title: 'Permissions', resource}
    ];
    return (
      <ListContextProvider value={listContext}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      <ListView
        title={<ListTitle nameValue={user_name} />}
        //title={`Permissions ${user_name ? `for ${user_name}` : ''} `}
        filter={{user_id: user_id}}
        pagination={<PermissionsPagination />}
        actions={<PermissionsActions user_id={user_id} user_name={user_name} />}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" label={"Identifier"}/>
          <TextField source="role"/>
          <FunctionField source="entity_code" label="Entity" render={r => translate(`entities.${r.entity_code}.name`)}/>
          {/*<TextField source="entity_id" label="Entity ID"/>*/}
          <EntityName source="entity_id" label="Entity Name" user_name={user_name}/>
          {/*<TextField source="user_id"/>*/}

          <DeletePermissionButton
            label="Revoke"
            user_id={user_id} user_name={user_name}
          />
          {/*<MyDeleteWithUndoButton label="Revoke" basePath={basePath} record={data} resource={resource}*/}
                                  {/*redirect={`permissions?user_id=${userId}&user_name=${userName}`} />*/}
          {/*<DeletePermissionButton />*/}
        </Datagrid>
      </ListView>
      </ListContextProvider>
    );
  // }
  // else {
  //   return (
  //   <span>No user specified</span>
  // );
  // }
};

export default PermissionsList;