import React, {useState} from 'react';
import {
  ShowView,
  ShowContextProvider,
  useShowController,
  TabbedShowLayout, Tab,
  TextField,
  TopToolbar,
  useRecordContext,
  useResourceContext, usePermissions
} from 'react-admin';
import log from 'loglevel';
import {DetailTitle} from "../components/InputFields";
import {Breadcrumbs} from "../breadcrumbs";
import UnEditButtonWithFilter from '../button/UnEditButtonWithFilter';
import {
  queryParamsToFilterString,
  getTabName,
  queryParamsToObject
} from "../util/paramUtil";
import {Activity, isAuthorized} from "../auth/authorization";
import TableItemsField from '../components/TableItemsField';
import {useLocation, useParams} from "react-router-dom";
import UnHelpButton from "../button/UnHelpButton";
//import {makeStyles} from "@mui/styles";

// const useStyles = makeStyles(theme => ({
//   label: {width: '10em', display: 'inline-block'},
//   root: {
//     width: 800,
//     height: 200,
//     marginTop: '2em',
//   },
//   table: {},
//   row: {
//     width: 700,
//     backgroundColor: 'grey'
//
//   },
//   displayName: {
//     fontSize: "12px",
//     width: 200,
//     minWidth: 1,
//     color: "primary.main",
//     backgroundColor: '#eeeeee'
//
//   },
//   displayValue: {
//     fontSize: "12px",
//     width: 500,
//     backgroundColor: 'white',
//     '& > *': {
//       margin: theme.spacing(0.5),
//     }
//   }
// }));


//const tabName = location => location.pathname.split('/').pop();

/**
 * Do not use UnEditButtonWithFilter as show/edit pages do not take a filter
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DictionaryEntryActions = (props) => {
  log.debug("{DictionaryEntryActions} props:", props);
  const record = useRecordContext();
  const {permissions} = usePermissions();
  const {tabName} = props;
  return (
    <TopToolbar>
      {isAuthorized(permissions, Activity.DICTIONARY) ?
        <UnEditButtonWithFilter
          filter={{
            id: record.id,
            //name: data.name,
            dictId: record.dictId,
            dictName: record.dictName}}
          record={record}
          tabName={tabName}
          {...props} /> :
        <></>
      }
      <UnHelpButton helpPage={'/Configuration/names-and-aliases'}/>
    </TopToolbar>
  );
}

/**
 * id and dictId (but not dictName) are required to display this page.
 * When this form is first invoked, the URL contains the query arguments such as dictId and dictName.
 * However, these disappear when clicking on tabs and are therefore stored in local state using useState().
 * Each tab has a name set by path.
 * No props for RA v4.
 * Note that dictId argument is passed to data provider (getOne) via queryOptions meta in useShowController.
 * dictName is obtained from the current database record.
 *
 * @returns {null|*}
 * @constructor
 */
export const DictionaryEntryShow = () => {
  const params = useParams();
  const {id} = params;
  const location = useLocation();
  const resource = useResourceContext();
  const [dictionary, setDictionary] = useState({});
  let args = queryParamsToObject(location);
  // log.debug(`{DictionaryEntryShow} location.pathname:`, location.pathname);
  // const tabName = location.pathname.split('/').pop();
  // log.debug(`{DictionaryEntryShow} tabName:`, tabName);
  log.debug(`{DictionaryEntryShow} args:`, args);
  log.debug(`{DictionaryEntryShow} dictionary:`, dictionary);
  if (args.dictId && !dictionary.dictId) {
    setDictionary(args);
    log.debug("{DictionaryEntryShow} setDictionary:", args);
  }
  if (!args.dictId && dictionary.dictId) {
    args = dictionary;
  }
  log.debug("{DictionaryEntryShow} args:", args);
  const showContext = useShowController({
    resource,
    id,
    queryOptions: {meta: args}
  });
  log.debug("{DictionaryEntryShow} showContext:", showContext);
  const {dictId} = args;
  const {record, isLoading} = showContext;
  log.info(`{DictionaryEntryShow} isLoading: ${isLoading}, record:`, record);
  if (!record || isLoading) return null;
  log.info(`{DictionaryEntryShow} id: ${record.id}, name: ${record.name}, isLoading: ${isLoading}`);
  const {dictName} = record;
  const parent = 'dictionaries';
  const breadcrumbs = [
    {url: `/${parent}`, label: parent, resource: parent},
    {url: `/${parent}/${dictId}/show?${queryParamsToFilterString({name: dictName})}`, title: dictName, resource: parent},
    {url: `/${resource}?${queryParamsToFilterString({dictId, dictName})}`, label: resource, resource},
    {url: `/${resource}/${record.id}/show?${queryParamsToFilterString({dictId, dictName, name: record.name})}`, title: record.name, resource},
  ];
  return (
    <ShowContextProvider value={showContext}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <ShowView title={<DetailTitle prefix={"Dictionary Entry"}/>}
                actions={<DictionaryEntryActions tabName={getTabName(location)}/>}
      >
        <TabbedShowLayout>
          <Tab label="entry">
            <TextField source='name' label='Entry name'/>
            <TextField source='code'/>
            <TextField source='type'/>
            <TextField source='notes' label="Description"/>
            {/*<NumberField source='weight'/>*/}
            {/*<TextField source='constraints'/>*/}
            <TextField source='dictName' label='Dictionary'/>
            <TextField source='_id'/>
          </Tab>
          <Tab label="aliases" path={"aliases"}>
            <TableItemsField items={record.aliases} fieldNames={['alias', 'weight']}/>
          </Tab>
          <Tab label="constraints" path="constraints">
            <TableItemsField items={record.constraints} fieldNames={['name', 'value']}/>
          </Tab>
          <Tab label="values" path={"values"}>
            <TableItemsField items={record.values} fieldNames={['value', 'alternates']}/>
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    </ShowContextProvider>
  )
};

export default DictionaryEntryShow;