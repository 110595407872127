import React from 'react';
import {
  Create, minLength, required,
  SimpleForm,
  TextInput
} from 'react-admin';
import {UnTextInput, CreateTitle} from "../components/InputFields";
import {BreadcrumbsCreate} from "../breadcrumbs";

const SiteCreate = () => {
  return (
    <React.Fragment>
      <BreadcrumbsCreate/>
      <Create title={<CreateTitle />}>
        <SimpleForm>
          <TextInput source="name" fullWidth validate={[required(), minLength(2)]}/>
          <UnTextInput source="logo" fullWidth label={"Logo (e.g. /assets/images/cclogo46@2xwhite.png)"}/>
          <UnTextInput source="tagline" fullWidth/>
          <TextInput source="strapline" fullWidth/>
          <TextInput source="type" />
        </SimpleForm>
      </Create>
    </React.Fragment>
  );
}

export default SiteCreate;