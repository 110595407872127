
import React from 'react';
//import { stringify } from 'query-string';
import { Link } from 'react-router-dom';
import Employers from "../employers";
import { withStyles } from '@mui/styles';
import { Button } from 'react-admin';
import {findEmployerId} from '../employers/EmployerUtil';
import {queryParamString} from "../util/paramUtil";

const styles = {
  button: {
    marginTop: '0em'
  }
};

/**
 *
 * @param classes     Styling
 * @param record      Employee record containing person, employment and employer fields
 * @param employerId  If the caller knows the employerId, it will be passed in
 * @returns {*}
 * @constructor
 */
const EmployerButton = ({classes, record, employerId: employerIdArg}) => {
  const employerId = employerIdArg ? employerIdArg : findEmployerId(record);
  console.log(`{EmployerButton} employerId: ${employerId}, record:`, record);

  //const filterObj = filter; //{name: record.name};
  // const queryParam = stringify({
  //   filter: record ? JSON.stringify(filterObj) : ''
  // });
  // TODO: returned hydrated employer to get name instead of just ID
  const filter = {
    name: employerId  // record.name,
  };
  const queryParam = queryParamString(filter);
  console.log(`{EmployerButton} queryParam:`, queryParam);

  return <Button
    className={classes.button}
    // variant="raised"
    component={Link}
    to={{
      pathname: employerId ? `/employers/${employerId}/show?${queryParam}` : `/employers/list`,
      // search: stringify({
      //   page: 1,
      //   perPage: 25,
      //   sort: 'name',
      //   order: 'ASC',
      //   filter: {}  //JSON.stringify({ employer_id: record._id }),
      // }),
    }}
    label="Employer"
    title="Employer"
  >
    <Employers.ICON />
  </Button>
};
export default withStyles(styles)(EmployerButton);



