//import UserIcon from '@mui/icons-material/Collections';
import UserIcon from '@mui/icons-material/People';
import UserList from './UserList';
import UserShow from './UserShow';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit';

export default {
  list: UserList,
  show: UserShow,
  create: UserCreate,
  edit: UserEdit,
  ICON: UserIcon
};