import React from 'react';
import {
  List, Datagrid,
  TextField,
  TopToolbar, CreateButton, Filter, SelectInput,
  Loading,
  useGetList, usePermissions, useRecordContext
} from 'react-admin';
import {defaultPerPage, UnPagination} from '../util/paramUtil';
import {Breadcrumbs} from "../breadcrumbs";
import {Activity, isAuthorized} from "../auth/authorization";
import UnListButton from "../button/UnListButton";
import TemplateFields from "../templatefields";
import UnIconButton from "../button/UnIconButton";
//import log from "loglevel";
import {ListTitle} from "../components/InputFields";

const TemplateActions = () => {
  const {permissions} = usePermissions();
  return (
    <TopToolbar>
      {isAuthorized(permissions, Activity.TEMPLATE) ?
        <CreateButton />
        : null}
    </TopToolbar>
  );
}

/**
 * Note that SelectArrayInput allows multiple selection and appears as Chips, which are higher.
 * applications are an array if id/names, e.g. [ {id: '6272b86b7f8e8f7c000d2a36', name: 'Application1'} ]
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TemplateFilter = (props) => {
  const {applications} = props;
  return (
    <Filter {...props}>
      {/*<TextInput label="Email" source="email" alwaysOn />*/}
      <SelectInput
        label="Application" source="appIds" choices={applications} alwaysOn
        //style={{minWidth: '200px', height: '32px'}}
      />
    </Filter>
  );
}

const UnFieldsButton = (props) => {
  const record = useRecordContext(props);
  return (
    <UnListButton
      label='' //'resources.templatefields.name_many'
      pathname='/templatefields'
      filter={{templateId: record.id, templateName: record.name}}
      record={record}
      size={'large'}
      icon={<TemplateFields.ICON style={{fontSize: 26}}/>}
      style={{minWidth: '40px', padding: '2px 2px 2px 1px'}}
    />
  );
}

export const TemplateSuperAdminList = (props) => {
  const {applications} = props;
  return (
    <>
      <Breadcrumbs/>
      <List
        perPage={defaultPerPage}
        pagination={<UnPagination/>}
        sort={{field: 'name', order: 'ASC'}}
        title={<ListTitle />}
        actions={<TemplateActions />}
        filters={<TemplateFilter applications={applications} />}
      >
        <Datagrid bulkActionButtons={true}
        //   sx={{
        //   // backgroundColor: "Lavender",
        //   "& .RaDatagrid-headerCell": {
        //     backgroundColor: "MistyRose",
        //   },
        // }}
        >
          <TextField source="name"/>
          <TextField source="description" sortable={false}/>
          <TextField source="strategy"/>
          <TextField source="locale"/>
          <UnIconButton label={'Detail'} />
          <UnFieldsButton label={'Fields'}/>
        </Datagrid>
      </List>
    </>
  );
}

export const TemplateUntanglerAdminList = (props) => {
  const {applications} = props;
  return ( // un_admin
    <>
      <Breadcrumbs/>
      <List
        perPage={defaultPerPage}
        pagination={<UnPagination/>}
        sort={{field: 'name', order: 'ASC'}}
        actions={<TemplateActions />}
        filters={<TemplateFilter applications={applications} />}
        {...props}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="name"/>
          <TextField source="description" sortable={false}/>
          <TextField source="locale"/>
          <UnIconButton label={'Detail'} />
          <UnFieldsButton label={'Fields'}/>
        </Datagrid>
      </List>
    </>
  );
}

/**
 * un_admin view does not show strategy
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const TemplateList = (props) => {
  const {permissions} = usePermissions();
  const {
    isLoading: isApplicationsLoading,
    data: applications
  } = useGetList(
    'representatives',
    {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}}
  );
  if (isApplicationsLoading) return <Loading />;
  if (isAuthorized(permissions, Activity.TEMPLATE_PERMISSIONS)) { // Only superadmin can write templates now
    return TemplateSuperAdminList({applications, ...props});
  }
  else return TemplateUntanglerAdminList({applications, ...props});  // un_admin
}

export default TemplateList;