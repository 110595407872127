import React from 'react';
import {
  Filter, ListView,
  Datagrid,
  TextField, DateField,
  TextInput, NumberField, DateInput, FunctionField,
  //useTranslate,
  useListController, ListContextProvider,
  useGetList,
  Loading,
  SelectInput, TopToolbar,
  CreateButton,
  //ExportButton,
  usePermissions
} from 'react-admin';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';
import log from 'loglevel';
import {Breadcrumbs} from "../breadcrumbs";
import exportUntanglements from './UntanglementExport';
import {defaultPerPage, UnPagination} from '../util/paramUtil';
import UnDownloadButton from '../export/UnDownloadButton';
import UnIconButton from "../button/UnIconButton";
import {TemplateChip} from "../components/Chips";
import {Activity, isAuthorized} from "../auth/authorization";
import {ListTitle} from '../components/InputFields';
//const UntanglementPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />

// const useStyles = makeStyles((/* theme */) => ({
//   button: {
//     //margin: theme.spacing(1),
//     //margin: 1
//     minWidth: 10
//   },
//   MuiButtonRoot: {
//     minWidth: 18
//   },
//   '.MuiButton-root': {
//     minWidth: 8
//   },
// }));

// const TooltipShowButton = (props) => {
//   log.debug(`{TooltipShowButton} props:`, props);
//   const {buttonLabel, basePath, resource, record} = props;
//   return (
//   // <Tooltip title={'Tooltip Detail'}>
//   //   <ShowButton icon={<PageviewIcon/>} size={'large'} label={label}
//   //               basePath={basePath} resource={resource} record={record}
//   //   />
//   // </Tooltip>
//   <ShowButton icon={<PageviewIcon fontSize="inherit"/>}
//               size={'large'}  // Makes button bigger so icon is bigger
//               label={buttonLabel}
//               basePath={basePath} resource={resource} record={record}
//   />
//   );
// }

const UntanglementActions = ({
                               filters, showFilter,
                               displayedFilters,
                               filterValues}) => {
  const {permissions} = usePermissions();
  return (
    <TopToolbar>
      {filters && React.cloneElement(filters, {
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      {/*<ExportButton/>*/}
      {isAuthorized(permissions, Activity.TEMPLATE) ?
        <CreateButton />
        : null}
    </TopToolbar>
  );
}

const storageModeChoices = [
  {id: 'D', name: 'Database'},
  {id: 'S', name: 'S3'}
];

const statusChoices = [
  {id: 'all', name: 'All'},
  {id: 'success', name: 'Successful'},
  {id: 'fail', name: 'Failed'}
];

const UntanglementFilter = (props) => {
  const {applications, templates, ...rest} = props;
  return (
    <Filter {...rest}>
      <TextInput label="File Name" source="name" alwaysOn />
      <DateInput label="From Date" source="createdatestart" placeholder="dd/mm/yyyy" alwaysOn/>
      <DateInput label="To Date" source="createdateend" />
      <SelectInput source="templateId" label="Templates" choices={templates} alwaysOn/>
      <SelectInput source="applicationId" label="Application" choices={applications} />
      <SelectInput source="storageMode" label="Storage Mode"
                   choices={storageModeChoices}
                   // emptyText={'Original'} emptyValue={'original'}
      />
      <SelectInput source="status" label="Status"
                   choices={statusChoices}
      />
      {/*<TextInput label="Template" source="template" />*/}
      {/*< ReferenceInput label="User" source="userId" reference="users" allowEmpty>*/}
      {/*<SelectInput optionText="name" />*/}
      {/*</ReferenceInput>*/}
    </Filter>
)};

const ErrorIcon = ({title}) => {
  return (<Tooltip title={title}>
    <ErrorOutlineIcon color={'primary'}/></Tooltip>)
}

//GET http://utangler.untangl.uk/api/v2/untanglment?repUname=uu&set_id=615dcf1dbfaab6405dea2113&analyses=raw&format=xlsx&tainted=template

// Note labels are in i18n/en.js
// perPage={25}
const UntanglementList = () => {
  //const classes = useStyles();
  //const {resource, permissions} = props;
  const {permissions} = usePermissions();
  //const translate = useTranslate();
  const pagination = <UnPagination/>;
  const listContext = useListController({
    perPage: defaultPerPage,
    pagination
    });
  log.debug("{UntanglementList} listContext:", listContext);

  const {
    //loaded: applicationsLoaded,
    isLoading: isApplicationsLoading,
    data: applications} = useGetList('representatives', {
        pagination: {page: 1},
        sort: {field: 'name', order: 'ASC'}
      }
  );
  const {
    isLoading: isTemplatesLoading,
    data: templates} = useGetList('templates', {
      pagination: {page: 1},
      sort: {field: 'name', order: 'ASC'}
    }
  );
  if (isApplicationsLoading || isTemplatesLoading) return <Loading />;
  log.debug(`{UntanglementList} applications:`, applications);
  log.debug(`{UntanglementList} templates:`, templates);

  const {data} = listContext;
  if (listContext.isLoading) return <Loading />;
  // empty property on List/ListView bypasses the empty page display and renders an empty list instead.
  return (
    <ListContextProvider value={listContext}>
      <Breadcrumbs/>
      <ListView
            title={<ListTitle/>}
            filters={<UntanglementFilter applications={applications} templates={templates}/>}
            //perPage={defaultPerPage}
            actions={<UntanglementActions permissions={permissions} />}
            pagination={<UnPagination/>}
            exporter={exportUntanglements}
            bulkActionButtons={false}
            empty={false}
      >
        <Datagrid>
          <TextField source="name"/>
          <FunctionField source="status" label=""
                         style={{minWidth: '10px'}}
                         render={record =>
                           record.errorCode ? <ErrorIcon title={`${record.errorMessage} (${record.errorCode})`}/> : ""
                         }/>
          <TextField source="user_name"/>
          <NumberField source="elapsed_time" options={{maximumFractionDigits: 2}}/>
          <FunctionField source="data_size" textAlign="right"
                         render={record => `${record.data_size ? (record.data_size / 1000).toFixed(2) : ''}`}/>
          <TextField source="in_range"/>
          {/*<ReferenceField label="User" source="user_id" reference="users" link="show">*/}
          {/*<TextField source="name" />*/}
          {/*</ReferenceField>*/}
          {/*<TextField source="template"/>*/}

          <FunctionField label={"Template"} render={
            record =>
              <TemplateChip templateId={record.templateId}
                            templateName={record.template}
              />
          }/>

          <FunctionField source="storageMode" label=""
                         style={{minWidth: '10px'}}
                         render={record =>
                           record.storageMode === 'S' ? <OfflineBoltOutlinedIcon color={'primary'}/> : ""
          }/>

          <DateField source="createdate" label="Created" showTime />
          {/*<TooltipShowButton buttonLabel={""} label={"Detail"}/>*/}
          <UnIconButton
            label={'Detail'}
            filter={{heritage: data.heritage}}
            />

          {/*<Tooltip title={'Show Detail'}>*/}
          {/*  <ShowButton icon={<PageviewIcon/>} size={'large'} label={""}/>*/}
          {/*</Tooltip>*/}
          {/*<ShowButton icon={<PageviewIcon/>} size={'large'}/>*/}

          <FunctionField label={'Download'} render={record =>
              record && !record.errorCode ? <UnDownloadButton showLabel={false} record={record} /> : ''
          } />
        </Datagrid>
      </ListView>
    </ListContextProvider>
  )
};

export default UntanglementList;
