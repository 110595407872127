import React from 'react';
import {
  Create,
  TabbedForm, FormTab,
  TextInput, DateInput, SelectInput,
  useTranslate, useResourceContext,
  useRedirect
} from 'react-admin';
import log from 'loglevel';
import {
  queryParamsToFilterString,
  queryParamsToObject,
  queryParamString
} from "../util/paramUtil";
import {Breadcrumbs} from "../breadcrumbs";
import {useLocation} from "react-router-dom";
import {CreateTitle} from "../components/InputFields";

/**
 * /employers/{employer_id}/persons
 * [{dob: "1990-11-19"
 *   employment: {employer_id: "5a1d96c72d9bb76062bdb462"}
 *   first_name: "AAA"
 *   gender: "male"
 *   last_name: "BBB"
 * }]
 */

// const EmployeeTitle = ({record, name}) => {
//   log.debug("{EmployeeTitle} record:", record, ", name:", name);
//   return <span>New Employee {name ? `for ${name}` : (record.employer_name ? `for ${record.employer_name}` : '')}</span>;
// };

// const redirectX = (basePath, id, data) => {
//   const filterObj = {
//     employer_id: data.employer_id,
//     employer_name: data.employer_name
//   };
//   log.debug(`{EmployeeCreate.redirect} filterObj:`, filterObj);
//   return `/employees?filter=${JSON.stringify(filterObj)}`;
// };

// TODO: pass employer ID and name to this form
const EmployeeCreate = () => {
  const location = useLocation();
  const resource = useResourceContext();
  const translate = useTranslate();
  const redirect = useRedirect();
  const {employer_id, employer_name} = queryParamsToObject(location);
  log.debug(`{EmployeeCreate} employer_id: ${employer_id}, employer_name: ${employer_name}`);

  const transform = data => {
    log.debug(`{EmployeeCreate.transform} employer_id: ${employer_id}, data:`, data);
    return {
      ...data,
      employer_id
    }
  };

  /**
   * On submission, redirect to the list of employees.
   * Note that data is an array since it has already been converted (in create()) in the data provider.
   * redirect is called on submission and contains entered form contents in data including employer_id.
   * Note redirect takes signature (basePath, id, data), not props!
   * queryParamString converts the filter object to a string for use in the URL.
   * @param basePath  '/employees'
   * @param id        Employee ID. Needed for show but not list.
   * @param data      Using {dictId} from data
   * @returns {string}
   */
  const onSuccess = (data) => {
    console.log("{onSuccess} data:", data);
    console.log(`{onSuccess} employer_id: ${employer_id}, employer_name: ${employer_name}`);
    redirect(`/employees?${queryParamsToFilterString({employer_id, employer_name})}`);
  };

  const parent = 'employers';
  const breadcrumbs = [
    {url: `/${parent}`, label: parent, resource: parent},
    {url: `/${parent}/${employer_id}/show?${queryParamsToFilterString({name: employer_name})}`, title: employer_name, resource: parent},
    {url: `/${resource}?${queryParamsToFilterString({employer_id, employer_name})}`, label: resource, resource},
    {url: ``, title: translate(`navigation.create`), resource},
  ];
  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Create
        title={<CreateTitle/>}
        transform={transform}
        mutationOptions={{onSuccess}}
      >
        <TabbedForm>
          <FormTab label="Personal">
            <TextInput source="first_name" fullWidth/>
            <TextInput source="last_name" fullWidth/>
            <SelectInput source="gender" choices={[
              { id: 'male', name: 'Male' },
              { id: 'female', name: 'Female' }
            ]}/>
            <DateInput source="dob"/>
            <TextInput source="street1" fullWidth />
            <TextInput source="street2" fullWidth />
            <TextInput source="city" fullWidth />
            <TextInput source="postcode" />
            <TextInput source="country_name" fullWidth />
            {/*<TextInput source="employer_id" disabled />*/}
          </FormTab>
          <FormTab label="Employment">
            <DateInput source="start_date" label={"Join Date"}/>
            <DateInput source="end_date" label={"Leave Date"}/>
            <TextInput source='notes' fullWidth multiline={true} rows={3} />
          </FormTab>
        </TabbedForm>
      </Create>
    </>
  );
}

export default EmployeeCreate;