import React from 'react';
import {
  Create,
  TextInput,
  FormTab,
  NumberInput,
  TabbedForm, ArrayInput, SimpleFormIterator,
  TopToolbar, useTranslate,
  SelectInput, Loading, useResourceContext, useGetList
} from 'react-admin';
import log from 'loglevel';
import {makeStyles} from '@mui/styles';
import {
  queryParamsToFilterString,
  queryParamsToObject
} from '../util/paramUtil';
import {Breadcrumbs} from "../breadcrumbs";
import {useLocation} from "react-router-dom";

const styles = {
  alias: {width: '18em'},
  weight: {width: '5em'},
  aliasFormGroup: {display: 'inline-block'},
  weightFormGroup: {display: 'inline-block', marginLeft: 24},
};
const useStyles = makeStyles(styles);

/**
 * On submission, redirect to the list of dictionary entries.
 * redirect is called on submission and contains entered form contents in data including dictId.
 * Note redirect takes signature (basePath, id, data), not props!
 * queryParamsToFilterString converts the filter object to a string for use in the URL.
 * @param basePath  '/dictionaryentries'
 * @param id        Dictionary entry ID. Needed for show but not list.
 * @param data      Using {dictId} from data
 * @returns {string}
 */

// const redirectToList = (resource, id, {dictId, dictName}) =>
//   `${resource}?${queryParamsToFilterString({dictId, dictName})}`;

/**
 * After Create, redirect to the detail (show) page.
 * @param resource
 * @param id
 * @param dictId from data record
 * @returns {`${string}/${string}/show?${string}`}
 */
const redirectToDetail = (resource, id, {dictId}) =>
  `${resource}/${id}/show?${queryParamsToFilterString({dictId})}`

// dictId
const DictionaryEntryActions = () => (
  <TopToolbar>
    {/*<UnListButton label={`navigation.back`}*/}
    {/*              pathname='/dictionaryentries'*/}
    {/*              filter={{dictId}}*/}
    {/*              icon={<ArrowBackIcon/>}/>*/}
    {/*<UnListButton label={`resources.dictionaryentries.name_many`}*/}
    {/*              pathname='/dictionaryentries'*/}
    {/*              filter={{dictId}}*/}
    {/*              icon={<DictionaryEntries.ICON/>}/>*/}
    {/*<ShowButton label={`resources.dictionaries.name_one`}*/}
    {/*            basePath={'/dictionaries'}*/}
    {/*            record={{id: dictId}}*/}
    {/*            icon={<Dictionaries.ICON/>}/>*/}
  </TopToolbar>
);

/**
 * Tabbed form to create a new dictionary entry.
 * The dictionary is specified in a dropdown list.
 * Aliases are added or removed in the Aliases tab.
 * @returns {*}
 * @constructor
 */
const DictionaryEntryCreate = () => {
  const resource = useResourceContext();
  const location = useLocation();
  const classes = useStyles();
  const translate = useTranslate();

  // transform the form data before calling dataProvider.create()
  const transform = data => {
    log.debug(`{DictionaryEntryCreate.transform} data:`, data);
    return {
      ...data,
      code: convertNameToCode(data.name)
    }
  };

  /**
   * Generate code from entry name
   * @param name
   * @returns {string}
   */
  const convertNameToCode = name => name.split(' ').join('_').toLowerCase();

  const {isLoading: isTypesLoading, data: types} = useGetList(
    'types',
    {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}
    });
  if (isTypesLoading) return <Loading/>;
  const args = queryParamsToObject(location);
  log.debug("{DictionaryEntryCreate} args:", args);
  const {dictId, dictName} = args;
  const parent = 'dictionaries';
  const breadcrumbs = [
    {url: `/${parent}`, label: parent, resource: parent},
    {url: `/${parent}/${dictId}/show?${queryParamsToFilterString({name: dictName})}`, title: dictName, resource: parent},
    {url: `/${resource}?${queryParamsToFilterString({dictId, dictName})}`, label: resource, resource},
    {url: ``, title: translate(`navigation.create`), resource},
  ];
  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Create
        actions={<DictionaryEntryActions dictId={dictId}/>}
        transform={transform}
        redirect={redirectToDetail}
      >
        <TabbedForm>
          <FormTab label="entry">
            <TextInput source='name' label='Entry name' fullWidth/>
            {/*<FunctionField label='Code' render={record => convertNameToCode(record.name)}/>*/}
            <SelectInput source='type' choices={types ? types: []}/>
            <NumberInput source='weight' defaultValue={100} />
            <TextInput source={'notes'} multiline={true} rows={7} fullWidth/>
            {/*<ReferenceInput label="Dictionary" source="dictId" reference="dictionaries" allowEmpty>*/}
            {/*  <SelectInput source="name"/>*/}
            {/*</ReferenceInput>*/}
            {/*<SelectInput label="Dictionary" source="dictId" choices={dictionaries} fullWidth/>*/}
            {/*<TextField source='dictName'/>*/}
            {/*<TextField source='dictId'/>*/}
          </FormTab>
          <FormTab label="aliases">
            <ArrayInput source="aliases" label=''>
              <SimpleFormIterator>
                <TextInput source='alias' className={classes.alias} formClassName={classes.aliasFormGroup} />
                <NumberInput source='weight' className={classes.weight} formClassName={classes.weightFormGroup} />
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
        </TabbedForm>
      </Create>
    </>
  );
};

export default DictionaryEntryCreate;