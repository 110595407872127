import React from "react";
import {
  ArrayInput, SelectInput,
  SimpleFormIterator,
  TextInput,
  useRecordContext
} from "react-admin";

const EmployeeOptionsForm = props => {
  const record = useRecordContext();
  //const {classes} = props;
  console.log(`{EmployeeOptionsForm} props:`, props);
  console.log(`{EmployeeOptionsForm} record:`, record);
  const i18n = 'resources.benefits.fields.';

  const optionNames = (record && record.optionGroups) ?
    record.optionGroups.map(g => ({id: g.value, name: g.name})) :
    [];
  return (
    <ArrayInput source="variants" label=''>
      <SimpleFormIterator inline>
        <SelectInput label={`${i18n}name`} source="name" choices={optionNames} sx={{width: 200}} />
        <TextInput source='label' label={`${i18n}label`} sx={{width: 400}}/>
        <TextInput source='value' label={`${i18n}value`} sx={{width: 200}}/>

        <ArrayInput source="constraints" label='Constraints'>
          <SimpleFormIterator>
            <TextInput label={`${i18n}constraint`} sx={{width: 600}}/>
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="fields" label='Fields'>
          <SimpleFormIterator inline>
            <TextInput source='name' label={`${i18n}name`} sx={{width: 300}} />
            <TextInput source='value' label={`${i18n}value`} sx={{width: 400}} />
          </SimpleFormIterator>
        </ArrayInput>

        {/*<TextField label="Dependant"/>*/}
        <ArrayInput source="variants" label=''>
          <SimpleFormIterator inline>
            <SelectInput label={`${i18n}name`} source="name" choices={optionNames} sx={{width: 200}} />
            <TextInput source='label' label={`${i18n}label`} sx={{width: 400}}/>
            <TextInput source='value' label={`${i18n}value`} sx={{width: 200}}/>

            <ArrayInput source="constraints" label='Constraints'>
              <SimpleFormIterator>
                <TextInput label={`${i18n}constraint`} sx={{width: 600}}/>
              </SimpleFormIterator>
            </ArrayInput>

            <ArrayInput source="fields" label='Fields'>
              <SimpleFormIterator inline>
                <TextInput source='name' label={`${i18n}name`} sx={{width: 300}} />
                <TextInput source='value' label={`${i18n}value`} sx={{width: 400}} />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>

      </SimpleFormIterator>
    </ArrayInput>
  )
}

export default EmployeeOptionsForm;