import * as React from 'react';
import {Button} from 'react-admin';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

//const baseHelpUrl = 'http://untangl.co.uk/book';
const mainDomain = 'untangl';

/**
 * If browser host is production, return unchanged as 'untangl.co.uk'
 * Otherewise return QA help 'untangl.uk'
 */
const getHelpDomain = () => {
  const domain = document.location.host;
 return domain.includes(`${mainDomain}.co.uk`) ?
   `https://${mainDomain}.co.uk` :
   `http://${mainDomain}.uk`
}

const UnHelpButton = (props) => {
  const {helpPage} = props;
  const label = props.label || 'Help'
  return (
    <Button label={label}
            color="primary"
            //size={'large'}
            //style={{padding: '2px 2px 2px 8px'}}
            href={getHelpDomain() + '/book' + helpPage}
            target="_blank">
      <HelpOutlineIcon/>
    </Button>
  )
}

export default UnHelpButton;