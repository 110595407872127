import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput, DateInput,
  TopToolbar, useTranslate, useResourceContext
} from 'react-admin';
import log from 'loglevel';
import {queryParamsToObject, queryParamString} from "../util/paramUtil";
import {Breadcrumbs} from "../breadcrumbs";
import {useLocation} from "react-router-dom";
import {DetailTitle} from "../components/InputFields";

// const ContractTitle = ({record}) => {
//   console.log("{ContractTitle} record:", record);
//   return <span>New Contract for employer {record ? `${record.employer_name}` : ''}</span>;
// };

const ContractActions = () => ( // basePath, data, employerId
  <TopToolbar>
    {/*<EmployerButton basePath={basePath} record={data} employerId={employerId}/>*/}
    {/*<UnListButton caption='Contracts' pathname='/contracts' upperResource='employer' basePath={basePath} record={data} />*/}
  </TopToolbar>
);

// Mapped to /employers/${employerId}/contracts in data provider resource mapper
//const redirect = (employerId) => `/contracts/list?employer_id=${employerId}`;
/**
 * Sets up employer_id to support nested URL to:
 * /employers/{employerId}/contracts/
 * @param employerId
 * @param employerName
 * @returns {string}
 */
// const redirect = (employerId, employerName) => { // basePath, id, data
//   log.debug("{ContractCreate.redirect} employerId:", employerId);
//   const filterObj = {
//     employer_id: employerId,
//     employer_name: employerName
//   };
//   //return `/employers/${employerId}/contracts?`; //filter=${JSON.stringify(filterObj)}`;
//   return `/contracts?filter=${JSON.stringify(filterObj)}`;
// };

// const redirect = (resource, id, {employer_id, employer_name}) =>
//   `${resource}?${queryParamString({employer_id, employer_name})}`;

// const redirect = (resource, id, data) => {
//   console.log(`{redirect} resource: ${resource}, id: ${id}`);
//   const {employer_id, employer_name} = data;
//   console.log(`{redirect} data:`, data);
//   const x =`${resource}?${queryParamString({employer_id, employer_name})}`;
//   console.log(`{redirect} returning:`, x);
//   return x;
// }

const ContractCreate = () => {
  const translate = useTranslate();
  const resource = useResourceContext();
  const location = useLocation();
  const args = queryParamsToObject(location);
  const {employer_id , employer_name} = args;
  log.debug(`{ContractCreate} employer_id: ${employer_id}, employer_name: ${employer_name}`);

  const redirect = (resource) =>  // Redirect to list after save (resource, id, data)
    `${resource}?${queryParamString({employer_id, employer_name})}`;

  const parent = 'employers';
  const breadcrumbs = [
    {url: `/${parent}`, label: parent, resource: parent},
    {url: `/${parent}/${employer_id}/show?${queryParamString({name: employer_name})}`, title: employer_name, resource: parent},
    {url: `/${resource}?${queryParamString({employer_id, employer_name})}`, label: resource, resource},
    {url: ``, title: translate(`navigation.create`), resource},
  ];
  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Create
        title={<DetailTitle titleField={'employer_name'}/>}
        actions={<ContractActions employerId={employer_id}/>}
        redirect={redirect}
      >
        <SimpleForm >
          <TextInput source="benefit_uname" fullWidth/>
          <TextInput source="benefit_version" fullWidth/>
          <TextInput source="rates_version" fullWidth/>
          {/*<TextInput source="rates_name"/>*/}
          <TextInput source="policy_number" fullWidth/>
          <DateInput source="start_date" />
          <DateInput source="end_date"/>
        </SimpleForm>
      </Create>
    </>
  );
};


export default ContractCreate;