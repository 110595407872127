import React from 'react';
import Icon from '@mui/icons-material/Person';
import {usePermissions} from 'react-admin';
import log from 'loglevel';
import {Activity, isAuthorized} from '../auth/authorization';
import UserListSuperadmin from './UserListSuperadmin';
import UserListAdmin from './UserListAdmin';

export const UserIcon = Icon;

export const UserList = () => {
  const {permissions} = usePermissions();
  // const permissions = usePermissions(); v4 and above
  // TODO: Needs to change when un_admin has USER_WRITE permissions
  if (isAuthorized(permissions, Activity.USER)) {
    return UserListSuperadmin()
  }
  else if (isAuthorized(permissions, Activity.USER_READ)) {  // un_admin
    log.debug("{UserList} isAuthorized un_admin");
    return UserListAdmin();
  }
  else {   // TODO: un_support role
    //log.debug("{UserList} Not authorized to access users");
    return <div/>;
  }
};

export default UserList;