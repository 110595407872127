import qs from "query-string";
import get from "lodash/get";
import log from 'loglevel';

export const getEmployerInfo = (props) => {
  log.debug("{getEmployerInfo} props:", props);
  let {state} = props.location;
  let employerId, employerName;
  if (state) {
    employerId = state.employer_id;
    employerName = state.employer_name;
    log.debug(`{getEmployerInfo} Found in state employerId: ${employerId}`);
  }
  else if (props.location && props.location.search) {
    //let searchStr = props.location.search.split('=')[1];
    //log.debug("{getEmployerInfo} searchStr:", searchStr);
    //let search = parse(props.location.search);
    const argObj = qs.parse(props.location.search);
    log.debug("{getEmployerInfo} argObj after search parse:", argObj);
    if (argObj.filter) {
      const filter = JSON.parse(argObj.filter);
      log.debug("{getEmployerInfo} argObj after filter parse:", filter);
      employerId = filter.employer_id;
      employerName = filter.employer_name;
      log.debug(`{getEmployerInfo} Found in location employerId: ${employerId}`);
    }
  }
  else {
    // employerId = "???";  // Debug
    // employerName = "???";
  }
  return {employerId, employerName}
};

/**
 * Same functionality as getEmployerInfo but looks in more places.
 * @param props
 * @returns {{employerId: *, employerName: *}}
 */
export const findEmployerInfo = (props) => {
  const {record} = props;
  let {state} = props.location;
  let employerId = findEmployerId(record, props);
  let employerName = findEmployerName(props);
  if (state) {
    employerId = employerId ? employerId : state.employer_id;
    employerName = employerName? employerName : state.employer_name;
  }
  log.debug(`{findEmployerInfo} returning employerId: ${employerId}, employerName: ${employerName}`);
  return {employerId, employerName}
};

/**
 * Attempt to find employer ID in a number of locations
 * @param record
 * @param props
 * @returns {*}
 */
export const findEmployerId = (record, props) => {
  let employerId = get(record, 'employer._id');
  if (employerId) {
    // log.debug("{findEmployerId} Found employerId in employer._id:", employerId);
    return employerId;
  }
  employerId = get(record, 'employer');
  if (employerId) {
    log.debug("{findEmployerId} Found employerId in employer:", employerId);
    return employerId;
  }
  employerId = get(record, 'employer_id');
  if (employerId) {
    log.debug("{findEmployerId} Found employerId in employer_id:", employerId);
    return employerId;
  }
  employerId = get(record, 'employments[0].employer');
  if (employerId) {
    log.debug("{findEmployerId} Found employerId in employments[0].employer:", employerId);
    return employerId;
  }
  const searchStr = get(props, 'location.search');
  if (searchStr) {
    const argObj = qs.parse(searchStr);
    log.debug("{findEmployerId} argObj after search parse:", argObj);
    if (argObj.filter) {
      const filter = JSON.parse(argObj.filter);
      log.debug("{findEmployerId} argObj after filter parse:", filter);
      employerId = filter.employer_id;
      //employerName = filter.employer_name;
      log.debug(`{findEmployerId} Found in location employerId: ${employerId}`);
      return employerId;
    }
  }
  log.debug("{findEmployerId} Unable to find employerId");
  return undefined;
};

/**
 * Attempt to find employer name in a number of locations
 * @param props
 * @returns {*}
 */
export const findEmployerName = (props) => {
  const {record} = props;
  let employerName = get(record, 'employer.name');
  if (employerName) {
    log.debug("{findEmployerName} Found employerName in employer.name:", employerName);
    return employerName;
  }
  employerName = get(record, 'employer_name');
  if (employerName) {
    log.debug("{findEmployerName} Found employerName in employer_name:", employerName);
    return employerName;
  }
  const searchStr = get(props, 'location.search');
  if (searchStr) {
    const argObj = qs.parse(searchStr);
    log.debug("{findEmployerName} argObj after search parse:", argObj);
    if (argObj.filter) {
      const filter = JSON.parse(argObj.filter);
      log.debug("{findEmployerName} argObj after filter parse:", filter);
      employerName = filter.employer_name;
      log.debug(`{findEmployerName} Found in location employerName: ${employerName}`);
      return employerName;
    }
  }
  log.debug("{findEmployerName} Unable to find employerName");
  return undefined;
};


