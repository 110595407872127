import React from 'react';
import {
  TopToolbar,
  ListView,
  useListController,
  ListContextProvider,
  useResourceContext, Loading,
} from 'react-admin';
import log from 'loglevel';
import UnTable from './UnTable';
import UnCreateButton from "../button/UnCreateButton";
import {queryParamsToObject, queryParamsToFilterString, UnPagination} from "../util/paramUtil";
import {Breadcrumbs} from "../breadcrumbs";
import {useLocation} from "react-router-dom";

/**
 * This uses the RA (react-admin) List component which is both a UI view and a connected component (fetches data).
 * Normally a Datagrid is next used to display a table and to iterate through each row and cell.
 * Instead the test TableRowsIterator is used, which calls internal test components TableHeader and TableRow.
 * The fields are represented by the normal RA field components.
 * @param props
 * @returns {*}
 * @constructor
 */

const formatMoney = n => (n && n !== 0) ? (n/100).toFixed(2): '0.00';

const formatDate = (s) => new Date(s).toLocaleDateString();

//const formatDateTime = (s) => new Date(s).toLocaleString()

/**
 * Remove duplicate elements from array
 * @param nonUniqueArray
 * @returns {unknown[]}
 */
const uniqueArray = (nonUniqueArray) => [...new Set(nonUniqueArray)];

/**
 * Get unique list of all benefit unames used in any employer evaluations
 * @param evaluations
 * @returns {*[]}
 */
const usedBenefitUnames = (evaluations) => {
  const allBenefitUnames = evaluations.flatMap(e => e.benefit_unames);
  //log.debug("{usedBenefitUnames} allBenefitUnames:", allBenefitUnames);
  return uniqueArray(allBenefitUnames);
}

/**
 * Get list of record field properties in API response for display on each row
 * @param initFields
 * @param evaluations
 * @returns {*}
 */
const getFields = (initFields, evaluations) => {
  log.debug("{getFields} evaluations:", evaluations);
  const benefitUnames = usedBenefitUnames(evaluations);
  return initFields.concat(benefitUnames);
};

/**
 * Return array of headings, starting with Date, Total, etc, followed by the benefit display names
 * @param headings
 * @param evaluations
 * @returns {*}
 */
const getHeadings = (headings, evaluations) => {
  log.debug("{getHeadings} evaluations:", evaluations);
  const benefitUnames = usedBenefitUnames(evaluations);
  return headings.concat(benefitUnames);
};

/**
 * Get rows of data to pass to UnTable
 * @param data
 * @param fields
 * @param formats
 * @returns {*}
 */
const getRows = (data, fields, formats) => {
  //if (!ids || ids.length < 1) return [];
  //const numColumns = getNumColumns(ids, data, fields);
  //log.debug("{getRows} ids:", ids, "fields length:", fields.length);
  let rows = data.map((r, i) => getRow(r._id, data[i], fields, formats));
  log.debug("{getRows} rows:", rows);
  return rows;
};

const getRow = (id, record, fields, formats) => {
  if (record.done) return record;
  //if (!ids || ids.length < 1) return ['empty'];
  record.key = id;
  // Format values
  fields.forEach((field, i) => {
    record[field] = formats[i](record[field]);
    //log.debug(`{getRow} field: ${field}, record[field]: ${record[field]}`);
  });
  record.done = true;
  return record;
};

const EvaluationActions = (props) => {
 log.debug("{EvaluationActions} props:", props);
  const {filter} = props;
  return <TopToolbar>
    {/*<ShowButton label="Employer" basePath={'employers'} record={{id: employerId}} icon={<Employers.ICON />} />*/}
    {/*<CreateEvaluationButton basePath={basePath} record={data} filterValues={filterValues}/>*/}
    {/*<UnCreateButton label="Create Evaluation" resource={resource} record={record}*/}
    {/*                filterValues={{employer_id, employer_name}}     //{filterValues}*/}
    {/*                icon={<Evaluations.ICON />} />*/}
    <UnCreateButton label="Create" filter={filter}/>
  </TopToolbar>
};

const EvaluationTitle = ({employerName}) => {
  //log.debug("{EvaluationTitle} employerName:", employerName);
  return <span>Evaluations {employerName ? ' for ' + employerName : ''}</span>;
};

export const EvaluationList = () => {
  const resource = useResourceContext();
  const location = useLocation();
  const {employer_id, employer_name} = queryParamsToObject(location); // employer_name is employer name
  log.debug(`{EvaluationList} employer_id: ${employer_id}, employer employer_name: ${employer_name}`);
  const listContext = useListController({
    pagination: <UnPagination/>,
    filter: {employer_id}
  });
  const parent = 'employers';
  const breadcrumbs = [
    {url: `/${parent}`, label: parent, resource: parent},
    {url: `/${parent}/${employer_id}/show?${queryParamsToFilterString({name: employer_name})}`,
      title: employer_name, resource: parent},
    {url: ``, label: resource, resource},
  ];

  const {isLoading, data} = listContext;
  if (isLoading) return <Loading/>;
  if (!employer_id) return (<span>No employer specified</span>);
  log.debug(`{EvaluationList} data:`, data);
  // These fields, headings, and formats need to be in the same order
  const initFields = ['evaluation_date',
    //'_id',
    'total'];
  const initHeadings = ['As Of Date',
    //'ID',
    'Total'];
  const initFormats = [
    s => formatDate(s), // Date
    //s => s,             // ID
    formatMoney         // Total
  ];
  const benFormats = Array.apply(null, {length: 10}).map(() => formatMoney);
  const formats = initFormats.concat(benFormats);
  log.debug("{EvaluationList} formats:", formats);
  const fields = getFields(initFields, data);
  log.debug("{EvaluationList} fields:", fields);

  return (
    <ListContextProvider value={listContext}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <ListView
        title={<EvaluationTitle employerName={employer_name} />}
        actions={<EvaluationActions filter={{employer_id, employer_name}} />}
      >
        <UnTable
          headings={getHeadings(initHeadings, data)}
          fields={fields}
          rows={getRows(data, fields, formats)}
        />
      </ListView>
    </ListContextProvider>
  )
};

// export const EvaluationList = (props) => {
//   log.debug("{EvaluationList} props:", props);
//
//   return <List title="Evaluations" filters={<EvaluationFilter/>} {...props}>
//     <Datagrid>
//       {log.debug("{EvaluationList.Datagrid} props:", props)}
//       <FunctionField render={ record => {
//         if (record) benefitUnames = record.benefit_unames;
//         log.debug("{Dummy} benefitUnames:", benefitUnames);
//         return '?';
//       }}
//       />
//       {log.debug("{EvaluationList.Datagrid} benefitUnames:", benefitUnames)}
//       <DateField source="evaluation_date"/>
//       <NumberField source="Total" />
//       <FunctionField label={benefitUnames[0]} render={record => `${record.benefit_unames[0]}`} />
//
//       {/*<ShowButton />*/}
//     </Datagrid>
//   </List>
// };



export default EvaluationList;