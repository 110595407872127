
import {useParams} from "react-router-dom";
import {Button,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import {Link} from "react-router-dom";
import React from "react";
import PageviewIcon from '@mui/icons-material/Pageview';
//import {makeStyles} from '@mui/styles';
import {queryParamString} from '../util/paramUtil';
//import log from 'loglevel';

//const stopPropagation = e => e.stopPropagation();

/**
 * Button to take you to show page for resource with extra filter query parameters.
 * This differs from standard ShowButton in that it accepts a filter object whose contents
 * are converted to query parameters added to the client URL.
 * Icon was ImageEye. caption did default to 'Detail'.
 *
 * Icon size changed via:
 * .RaButton-smallIcon-10 {
 *   font-size: 26px;
 * }
 *
 * If tab
 * /dictionaryentries/60c714d20a1fb2140fe524ad/show/aliases
 *
 * @param props Only prop is tabName.
 * @returns {*}
 * @constructor
 */
// const queryParamString = (filterObj) => stringify({filter: JSON.stringify(filterObj)});
const UnShowButtonWithFilter = (props) => {
  const resource = useResourceContext();
  //const record = useRecordContext();
  const {id} = useParams();
  //record._id
  const {filter, caption = 'Detail',
    icon = <PageviewIcon /> // style={{fontSize: 30}}
  } = props;
  //const filter = undefined;
  //const classes = useStyles();
  const tabName = (props.tabName !== 'edit') ? props.tabName : undefined;
  const filterObj = filter; //{name: record.name};
  // const queryParam = stringify({
  //   filter: record ? JSON.stringify(filterObj) : ''
  // });
  const queryParam = queryParamString(filterObj);
  const pathname = tabName ? `/${resource}/${id}/show/${tabName}` : `/${resource}/${id}/show`;
  //const baseEndPoint = `/${resource}/${record._id}/show`;
  console.log(`{UnShowButtonWithFilter} tabName: ${tabName}`);
  console.log(`{UnShowButtonWithFilter} Show path will be: ${pathname}?${queryParam}`);
  return (<Button
    //className={classes.button}
    //classes={classes}
    //labelStyle={{backgroundColor: 'red', fontSize: '30px', minSize: '30px', lineHeight: "100px" }
    // style={{backgroundColor: 'red'}}  // Works!
    // style={{fontSize: '30px'}}  // Only increases text and button size - not icon
    //style={{minWidth: '40px', padding: '2px 2px 2px 1px'}}
    //size={'large'}
    component={Link}
    to={{
      pathname,
      search: queryParam
    }}
    label={caption}
    title={caption}
    // onClick={stopPropagation} TODO: is this needed?
    //     {...rest}
  >
    {icon}
  </Button>)
}

/**
 * Display button with icon and no caption.
 * For use in list (DataGrid) pages. E.g. Detail at the end of each list item.
 * Note that passing a label attribute to this field in a DataGrid sets the column heading.
 * Default icon is <PageviewIcon/> which can be overridden with attribute, e.g icon={<ImageEye/>}
 * TODO For column header width see: https://marmelab.com/react-admin/List.html#datagrid-css-api
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const IconButton = (props) => {
  //eslint-disable-next-line
  const {caption, ...rest} = props;
  return <UnShowButtonWithFilter caption={''} {...rest}/>
}

/**
 * Extract name from record and pass as filter parameter to UnShowButtonWithFilter component.
 * E.g. On the dictionary edit page, a button link to the show page will include the name URL query parameter
 *    /dictionaries/<id>/edit?filter={name=mytitle}
 * For use at top of form.
 * Note: does not need resource or record in props (uses hooks)
 * @param props
 * @returns {*}
 * @constructor
 */
const UnShowButtonWithName = (props) => {
  //const {tabName} = props;
  const record = useRecordContext();
  if (!record) return null;
  // const tabName = (props.tabName !== 'show') ? props.tabName : undefined;
  // log.debug('{UnShowButtonWithName} props:', props);
  // log.debug('{UnShowButtonWithName} tabName:', tabName);
  // log.debug(`{UnShowButtonWithName} filter:`, filter);
  //console.log(`{UnShowButtonWithName} props:`, props);
  console.log(`{UnShowButtonWithName} record:`, record);
  return (
    <UnShowButtonWithFilter
        filter={{name: (record.name ? record.name : 'Name?')}}
        {...props}
    />);
}

export {
  UnShowButtonWithFilter,
  UnShowButtonWithName,
  IconButton
};
