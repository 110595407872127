import {BreadcrumbElements} from './UnBreadcrumbsUtil';
import {useResourceContext, useTranslate} from "react-admin";

/**
 *  Generates a breadcrumb trail for a list page.
 *  If parent is undefined, it generates for a top level (level = 1).
 *  If parent is defined, it generates for level 3, e.g for dictionaryentries or templatefields.
 *  The parent parameters are passed in via filter, e.g. filter={{dictId, dictName}}.
 *  The breadcrumb (nested or not) specified here for this page is added to the redux state
 *  in renderBreadcrumbs.
 *  The accumulated trail of breadcrumbs would look like this:
 *   {url: `/${parent}`, title: translate(`resources.${parent}.name_many`), resource: parent},
 *   {url: `/${parent}/${id}/show?${queryParamString({name})}`, title: name},
 *   {url: `/${current}?${queryParamString({[parentIdName]: id})}`,
 *   TODO: For handling parent ID and name, just pass filter through.
 * @returns {*}
 * @constructor
 */
const UnBreadcrumbsList = () => {
  const translate = useTranslate();
  const resource = useResourceContext();
  const breadcrumbs = [
    {url: ``, title: translate(`resources.${resource}.name_many`), resource}
  ];
  return BreadcrumbElements(breadcrumbs);
}

export default UnBreadcrumbsList;