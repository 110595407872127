import {useRecordContext, useResourceContext} from "react-admin";
import log from "loglevel";
import PageviewIcon from "@mui/icons-material/Pageview";
import {Link} from "react-router-dom";
import React from "react";
import {queryParamsToFilterString} from "../util/paramUtil";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => {
  console.log(`{UnEntriesButton.useStyles} theme:`, theme);
  return ({
    root: {
      //backgroundColor: 'red'
      color: theme.palette.primary.main
    }
  })
});

// /**
//  * Display entry button showing icon only.
//  * dictionaryaliases specifies its different resource via myresource.
//  * Use entryId if available.
//  *
//  * This gets the dictionary id from the current record.
//  *
//  * Usually use UnIconButton instead. Only used in dictionary alias list now.
//  *
//  * @param props
//  * @returns {JSX.Element}
//  * @constructor
//  */
const UnEntryIconButton = (props) => {
  const record = useRecordContext();
  //log.debug(`{UnEntryIconButton} record:`, record);
  const classes = useStyles();
  const {_id, entryId, dictId, dictionary: dictName} = record;
  const id = entryId ? entryId : _id;
  const {myresource} = props;
  const defResource = useResourceContext();
  const resource = myresource ? myresource : defResource;

  log.debug(`{UnEntryIconButton} dictName: ${dictName}, resource:`, resource);
  const {
    icon = <PageviewIcon style={{fontSize: 30}}/>
  } = props;
  //log.debug(`{UnEntryIconButton} dictId:`, dictId);
  const queryParam = queryParamsToFilterString({dictId});
  const pathname = `/${resource}/${id}/show`;
  log.debug(`{UnEntryIconButton} Show path will be: ${pathname}?${queryParam}`);
  return (
    <Link to={`${pathname}?${queryParam}`} className={classes.root}>
      {icon}
    </Link>
  )
}

export default UnEntryIconButton;