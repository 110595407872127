/**
 * Extract date from ObjectId
 * E.g. Usage in Show page:
 * <FunctionField label="Created" render={record => `${dateFromId(record._id)}`} />
 * @param id    Database ObjectId
 * @returns {Date}
 */

const dateFromId = (id, format = 'LONG') => {
  if (!id) return '';
  let d = new Date(parseInt(id.toString().slice(0, 8), 16) * 1000);
  return format === 'LONG' ? d : d.toLocaleDateString();
};


const isoDateString = (date) => {
  function pad(number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }
  // const pad = number => (number < 10) ? '0' + number : number;

  const dt = date ? date : new Date();
  return dt.getUTCFullYear() +
    '-' + pad(dt.getUTCMonth() + 1) +
    '-' + pad(dt.getUTCDate())

};

export {dateFromId, isoDateString};