import React from 'react';
import {
  ShowView,
  TabbedShowLayout, Tab,
  TextField, DateField,
  TopToolbar,
  Loading,
  ShowContextProvider,
  useShowController,
  useResourceContext
} from 'react-admin';
import {getTabName, queryParamFilter, queryParamsToFilterString} from "../util/paramUtil";
import {Breadcrumbs} from "../breadcrumbs";
import {DetailTitle} from "../components/InputFields";
import UnEditButtonWithFilter from "../button/UnEditButtonWithFilter";
import AttributeTable from "./AttributeTable";
import {useLocation, useParams} from "react-router-dom";
import {isValidTabName} from "../components/EditActions";

// const ContractTitle = ({ record, prefix }) => {
//   return <span>{prefix}: {record ? `${record.benefit_uname}` : ''}</span>;
// };

const ContractShowActions = ({filter, tabName}) => {
  const adjTabName = isValidTabName(tabName) ? tabName : '';
  //console.log(`{ContractShowActions} filter:`, filter);
  return (
    <TopToolbar>
      {/*<EditButton basePath={basePath} record={data} label={'Edit Contract'} />*/}
      <UnEditButtonWithFilter
        filter={filter}
        tabName={adjTabName}
      />
    </TopToolbar>
  );
}

export const ContractShow = () => {
  const resource = useResourceContext();
  const location = useLocation();
  const {id} = useParams();
  const args = queryParamFilter(location);
  const {employer_id, employer_name} = args;  // Passed to filter in the server API mapper via useUnShowController
  //console.log(`{ContractShow} employer_id: ${employer_id}, employer_name: ${employer_name} from queryParamObject`);
  const listContext = useShowController({
    id,
    queryOptions: {meta: {employer_id}}
  });
  const {isLoading, record} = listContext;
  if (isLoading) return <Loading/>;
  record.basis = record.basis || [];
  const parent = 'employers';
  const breadcrumbs = [
    {url: `/${parent}`, label: parent, resource: parent},
    {url: `/${parent}/${employer_id}/show?${queryParamsToFilterString({name: employer_name})}`, title: employer_name, resource: parent},
    {url: `/${resource}?${queryParamsToFilterString({employer_id, employer_name})}`, label: resource, resource},
    {url: ``, title: record.benefit_uname, resource},
  ];
  return (
    <ShowContextProvider value={listContext}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <ShowView
        title={<DetailTitle titleField={'benefit_uname'}/>}
        actions={<ContractShowActions filter={args} tabName={getTabName(location)}/>}
      >
        <TabbedShowLayout>
          <Tab label="contract">
            <TextField source="benefitName" />
            <TextField source="benefit_uname" />
            <TextField source="benefit_version" />
            <TextField source="rates_version" />
            <TextField source="rates_name" />
            <TextField source="policy_number" />
            <DateField source="start_date" />
            <DateField source="end_date" />
            <TextField source="creation_date" />
          </Tab>

          <Tab label="basis" path="basis">
            <AttributeTable attributes={record.attributesBasis || []} />
          </Tab>

          <Tab label="other" path="other">
            <AttributeTable attributes={record.attributesRate || []} />
          </Tab>

          <Tab label="Rate Table" path="rateTable">
          </Tab>

          <Tab label="legacy contract" path="legacy contract">
            <TextField source="benefit_uname" />
            <TextField source="benefit_version" />
            <TextField source="rates_version" />
            <TextField source="rates_name" />
            <TextField source="policy_number" />
            <DateField source="start_date" />
            <DateField source="end_date" />
            <TextField source="creation_date" />
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    </ShowContextProvider>
  );
}


export default ContractShow;