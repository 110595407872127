import React from 'react';
// import log from "loglevel";
// import {styled} from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
//import Tooltip from '@mui/material/Tooltip';
import TreeItem from '@mui/lab/TreeItem';
import {useTheme} from 'react-admin';

import {useRecordContext} from "react-admin";

/*
https://stackoverflow.com/questions/67028800/material-ui-treeview-how-to-get-the-nodeid-of-the-treeitem-that-was-clicked-on

https://stackoverflow.com/questions/64338612/react-material-ui-treeview-the-prp-type-nodeid-is-marked-as-required
*/

const getVariantName = (benefit, variant) => {
  const {optionGroups} = benefit;
  // log.debug("{getVariantName} benefit:", benefit);
  // log.debug("{getVariantName} variant:", variant);
  const name = optionGroups[variant.name] ? optionGroups[variant.name] : variant.name;
  return `${variant.label} (${name})`;
}

//Node Ids of TreeItems to be expanded at start - benefit "R" and top-level variants
const getOpenItemIds = (benefit) =>
  [
    'root',
    ...benefit.variants.map((v, i) => "R-" + i),
    ...benefit.variants.flatMap((v1, i) => {
      return Array.isArray(benefit.variants) ?
        v1.variants.map((v1, j) => `R-${i}-${j}`)
        : []
      })
  ];

export const VariantTree = () => {
  const theme = useTheme()[0];
  const benefit = useRecordContext();

  const renderTree = (node, i, level, prefix) => {
    const nodeId = `${prefix}-${i}`
    console.log(`{renderTree} i: ${i}, level: ${level}, prefix: ${prefix}, nodeId: ${nodeId}, node:`, node);
    //console.log(`{renderTree} theme.palette:`, theme.palette);
    level = ++level;
    return (
      <TreeItem key={nodeId} nodeId={nodeId} label={getVariantName(benefit, node)}>

        {Array.isArray(node.constraints) ?
          node.constraints.map((constraint, j) => (
            <TreeItem
              key={`C${level}${j}`}
              nodeId={`C${nodeId}${j}`}
              label={'If: ' + constraint}
              sx={{color: theme.palette.primary.light}}
            />
          ))
          : null}

        {Array.isArray(node.variants) ?
          node.variants.map((variant, j) => renderTree(variant, j, level, `${prefix}-${i}`))
          : null}

        {Array.isArray(node.fields) ?
          node.fields.map((field, j) => (
            <TreeItem key={`F${nodeId}${j}`}
                      nodeId={`F${level}${j}`}
                      label={<i>{field.name}: {field.value}</i>}
            />
          ))
          : null}
      </TreeItem>
    )
  }

  console.log(`{VariantsTree} benefit.variants:`, benefit.variants);
  //Node Ids of TreeItems to be expanded at start - benefit "root" and top-level variants
  const openItemIds = getOpenItemIds(benefit);
  console.log(`{VariantsTree} openItemIds:`, openItemIds);
  const level = 0;
  const prefix = 'R';
  return (
    <TreeView
      aria-label="file system navigator"
      defaultExpanded={openItemIds}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{height: 800, flexGrow: 1, maxWidth: 800, overflowY: 'auto'}}
    >
      <TreeItem nodeId="root" label={benefit.name}>
        {Array.isArray(benefit.fields) ?
          benefit.fields.map((field, j) => (
            <TreeItem key={`FR${j}`}
                      nodeId={`FR${j}`}
                      label={<i>{field.name}: {field.value}</i>}
            />
          ))
          : null}
      {Array.isArray(benefit.variants) ?
        benefit.variants.map((variant, v1) => (
          renderTree(variant, v1, level, prefix)
      ))
      : null
      }
      </TreeItem>
    </TreeView>
  )
}

