import qs from 'query-string';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import log from 'loglevel';
import React from 'react';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import {useRecordContext} from 'react-admin';
//import {useLocation} from 'react-router-dom';

/**
 * Strip entity code (e.g. 'RP:') and return list of entity codes.
 * Look out for entity handles that do NOT have an entity code, e.g. for superadmin.
 * @param entityHandles
 */
const getEntityIds = entityHandles => entityHandles ? entityHandles.map(eh => {
  const parts = eh.split(':');
  if (parts.length < 2) return parts[0];  // If no entity type/code
  const [code, handle] = parts;
  return !isUndefined(handle) ? handle : code;
}) : [];

// Components

/**
 * Display an array of chips for one role, each chip showing role and application.
 * @param role            Role from permissions of user being displayed , e.g. un_admin, un_support
 * @param applicationIds  Visibility as application IDs
 * @param applications    List of applications containing application names
 * @returns {*}
 * @constructor
 */
const RoleChipArray = ({role, entityIds, applications, employers}) => {
  //log.debug(`{RoleChipArray} role: ${role}, applicationIds: ${applicationIds}`);
  // If role is "er_admin" applicationIds are employerIDs
  const untanglerRoles = ['un_admin', 'un_support', 'untangler', 'superadmin'];
  return entityIds.map((entityId) => {
    if (untanglerRoles.includes(role)) {
      const application = find(applications, a => entityId === a._id);
      //log.debug(`{RoleChipArray} role: ${role}, entityId: ${entityId}, application from find:`, application);
      const applicationName = application ? application.name : '';
      log.debug(`{RoleChipArray} role: ${role}, entityId: ${entityId}, application name:`, applicationName);
      return (
        <Tooltip title={`${applicationName} ${roleToLongName[role] ? roleToLongName[role] : ''}`}
                 key={`${entityId}-${role}`}>
          <Chip label={`${applicationName} ${roleToShortName[role]}`}
                key={`${entityId}-${role}`}
                avatar={<Avatar>{role === 'superadmin' ? 'S' : 'U'}</Avatar>}
                clickable={false}/>
        </Tooltip>
      );
    }

    const employerRoles = ['er_admin', 'superadmin'];
    if (employerRoles.includes(role)) {
      log.debug(`{RoleChipArray} employers:`, employers);
      const employer = find(employers, a => entityId === a._id);
      //log.debug(`{RoleChipArray} role: ${role}, entityId: ${entityId}, application from find:`, application);
      const employerName = employer ? employer.name : '';
      log.debug(`{RoleChipArray} role: ${role}, entityId: ${entityId}, employer name:`, employerName);
      return (
        <Tooltip title={`${employerName} ${roleToLongName[role] ? roleToLongName[role] : ''}`}
                 key={`${entityId}-${role}`}>
          <Chip label={`${employerName} ${roleToShortName[role]}`}
                key={`${entityId}-${role}`}
                avatar={<Avatar>E</Avatar>}
                //color="primary"
                clickable={false}/>
        </Tooltip>
      );
    }

  });
};

/**
 * Show all chips for a user's jurisdictions (permissions), except for authentication (verified, etc).
 * @param classes
 * @param record
 * @param applications
 * @param employers
 * @returns {JSX.Element|null}
 * @constructor
 */
const RoleChips = ({classes, applications, employers}) => {
  const record = useRecordContext();
  log.debug(`{RoleChips} record:`, record);
  if (!record) return null;
  log.debug(`{RoleChips} applications:`, applications);
  log.debug(`{RoleChips} Name: ${record.first_name} ${record.last_name}, roles: ${record.roles}, jurisdictions:`, record.jurisdictions);
  if (!record.roles || record.roles.length < 1) return null;
  return (
    <div className={classes.root}>
      {record.roles.map((role) => {
        if (!roleToShortName[role]) {
          log.debug(`{RoleChips} roleToShortName failed for role ${role}`);
          return null;
        }
        const entityIds = getEntityIds(record.jurisdictions[role]);
        log.debug(`{RoleChips} entityIds:`, entityIds);
          return (
            <RoleChipArray role={role}
                           entityIds={entityIds}
                           applications={applications}
                           employers={employers}
                           key={role}/>
          )
      })}
    </div>
  );
};

// Functions

/**
 * TODO: This can be merged with getEmployerInfo
 */
const getUserInfo = (props) => {
  console.log("{getUserInfo} props:", props);
  console.log("{getUserInfo} location:", location);
 const location = null; // !!!
  let {state} = location;
  let userId, userName;
  if (state && state.user_id) {
    userId = state.user_id;
    userName = state.user_name;
    log.debug(`{getUserInfo} Found in state userId: ${userId}`);
  }
  else if (state && state.record && state.record.user_id) {
    userId = state.record.user_id;
    userName = state.record.user_name;
    log.debug(`{getUserInfo} Found in state.record userId: ${userId}`);
  }
  else if (props.location && props.location.search) {
    //let searchStr = props.location.search.split('=')[1];
    //log.debug("{getUserInfo} searchStr:", searchStr);
    //let search = parse(props.location.search);
    const argObj = qs.parse(props.location.search);
    log.debug("{getUserInfo} argObj after search parse:", argObj);
    if (argObj.filter) {
      const filter = JSON.parse(argObj.filter);
      log.debug("{getUserInfo} argObj after filter parse:", filter);
      userId = filter.user_id;
      userName = filter.user_name;
      log.debug(`{getUserInfo} Found in location userId: ${userId}`);
    }
    else {
      userId = argObj.user_id;
      userName = argObj.user_name;
    }
  }
  else {
    userId = "???";  // Debug
    userName = "???";
  }
  return {userId, userName}
};



/**
 * Object version of roleChoices with id (e.g. 'un_admin') as key
 * @type {{}}
 */
const roleToName = (roleChoices) => {
  return roleChoices.reduce((accum, c) => {
    accum[c.id] = c.name;
    return accum;
  }, {});
}

// const roleToNameObject = () => {
//   return roleChoices.reduce((accum, c) => {
//     accum[c.id] = c.name;
//     return accum;
//   }, {});
// }

const roleToShortName = {
  untangler: 'User',
  un_admin: 'Admin',
  un_support: 'Support',
  er_admin: 'Employer',
  rp_admin: 'Rep Admin',
  superadmin: 'Superadmin'
}

const roleToLongName = {
  registered: 'Registered user',
  verified: 'Verified user',
  untangler: 'Standard user',
  un_admin: 'Untangler administrator',
  un_support: 'Support user',
  er_admin: 'Employer administrator',
  rp_admin: 'Rep administrator',
  superadmin: 'Super administrator'
}

/**
 * Used for query filter dropdown list.
 * @type {({name: string, id: string}|{name: string, id: string}|{name: string, id: string}|{name: string, id:
 *   string}|{name: string, id: string})[]}
 */
// const roleChoicesX = [
//   {name: 'Registered user', id: 'registered'},
//   {name: 'Verified user', id: 'verified'},
//   {name: 'Standard user', id: 'untangler'},
//   {name: 'Support user', id: 'un_support'},
//   {name: 'Admin user', id: 'un_admin'},
//   {name: 'Employer admin', id: 'er_admin'}  // TODO: Only display with er_admin or superadmin permissions
// ];

const roleChoices = Object.keys(roleToLongName).map(key => ({id: key, name: roleToLongName[key]}));

// const roleAllChoices = [
//   {name: 'Registered user', id: 'registered'},
//   {name: 'Verified user', id: 'verified'},
//   {name: 'Standard user', id: 'untangler'},
//   {name: 'Support user', id: 'un_support'},
//   {name: 'Admin user', id: 'un_admin'},
//   {name: 'Employer admin', id: 'er_admin'},
//   {name: 'Rep admin', id: 'rp_admin'},
//   {name: 'Superadmin', id: 'superadmin'}
// ];

const roleAllChoices = roleChoices; // TODO: Is there a list that should not display superadmin?

/**
 * Short version of admin names for display in role chips
 */
const getRoleToShortName = (role) => roleToShortName[role] ? roleToShortName[role] : '';

export {
  RoleChips,
  getUserInfo,
  roleChoices,
  roleAllChoices,
  roleToName,
  roleToShortName,
  getRoleToShortName,
  roleToLongName
}
