import React from 'react';
import {
  List, Datagrid,
  TextField, DateField,
  Filter,
  TextInput,
  FunctionField,
  useGetList,
  TopToolbar,
  Loading, Error, useResourceContext,
} from 'react-admin';
import {makeStyles} from '@mui/styles';
import Icon from '@mui/icons-material/Person';
import log from 'loglevel';
import {Breadcrumbs} from '../breadcrumbs';
import {RoleChips, roleChoices, roleToName} from './UserUtil';
import UnIconButton from "../button/UnIconButton";
import {ListTitle} from "../components/InputFields";

export const UserIcon = Icon;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const UserFilterReadOnly = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" alwaysOn />
  </Filter>
);

const UserListActions = () => {
  return (
    <TopToolbar>
    </TopToolbar>
  );
};

const UserListAdmin = () => {
  const resource = useResourceContext();
  const classes = useStyles();
  //const dataProvider = useDataProvider();
  //const [employers, setEmployers] = useState();

  // Doesn't work with useQueryWithStore (which is not supported in v4 anyway)
  // const {data: employers, loaded: employersLoaded, error: employersError} = useQuery({
  //   type: 'getList',
  //   resource: 'employers',
  //   payload: {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}, filter: {view: 'summary'}}
  // });
  const employers = [];
  const isEmployersLoading = false;
  const employersError = false;

  // Original
  const {
    data: applications,
    isLoading: isApplicationsLoading,
    error: applicationsError} = useGetList(
      'representatives',
      {
        pagination: {page: 1},
        sort: {field: 'name', order: 'ASC'}
      }
  );

  // useEffect(() => {
  //   (async () => {
  //     const data = await dataProvider.getList('employers', {})
  //       .catch(error => {
  //         log.debug(`{UserListAdmin} [useEffect] error:`, error);
  //       });
  //     log.debug(`{UserListAdmin} [useEffect] employers returned:`, data);
  //     if (data && data.data && !employers) {
  //       setEmployers(data.data.length > 0 ? data.data[0] : undefined);
  //       log.debug(`{UserListAdmin} [useEffect] employers set:`, employers);
  //     }
  //   })();
  // }, [dataProvider.getList, employers]);

  if (isApplicationsLoading || isEmployersLoading) return <Loading />;
  if (applicationsError || employersError) return <Error />;
  // log.debug(`{UserListAdmin} applicationsLoaded: ${applicationsLoaded}, applications:`, applications);
  // log.debug(`{UserListAdmin} employersLoaded: ${employersLoaded}, employersError: ${employersError}, employers:`, employers);

  const roleToNameMap = roleToName(roleChoices);
  log.debug("{UserListSuperadmin} roleToNameMap:", roleToNameMap);
  return (
    <>
      <Breadcrumbs resource={resource} />
      <List title={<ListTitle/>}
            filters={<UserFilterReadOnly/>}
        //filter={{ representative_id }}  TODO: get from un_admin jurisdiction
        //filter={{ role: 'untangler' }}
            sort={{ field: 'last_name', order: 'ASC' }}
            actions={<UserListActions />}
            bulkActionButtons={false}
            >
        <Datagrid>
          {/*<TextField source="id" />*/}
          <TextField source="first_name"/>
          <TextField source="last_name"/>
          <TextField source="email"/>
          {/*<FunctionField label="Roles" render={record =>*/}
          {/*  <RoleChips record={record} classes={classes} roleToName={roleToNameMap} roleToShortName={getRoleToShortName}/>*/}
          {/*}/>*/}
          <FunctionField label="Roles" render={() =>
            <RoleChips classes={classes} roleToName={roleToNameMap}
                       applications={applications} employers={employers}/>
          }/>
          <DateField source="verifyDate"/>
          <UnIconButton label={'Detail'} />
          {/*<EditButton basePath="/users" />*/}
        </Datagrid>
      </List>
    </>
  );
}

export default UserListAdmin;