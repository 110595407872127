import germanMessages from 'ra-language-german';

export default {
  ...germanMessages,
  main: {
    title: 'Untangl Administration'
  },
  pos: {
    search: 'Search',
    configuration: 'Configuration',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
      untangl: 'Untangl'
    },
    dashboard: {
      new_users: 'New Users',
      welcome: {
        title: 'Welcome to Untangl Admin',
        subtitle:
          "This is Untangl Admin.",
      }
    }
  },
  notification: {
    permission: {
      created: 'Permission created'
    },
    updated: 'Element updated |||| %{smart_count} elements updated',
    created: 'Element created',
    deleted: 'Element deleted |||| %{smart_count} elements deleted',
    bad_item: 'Incorrect element',
    item_doesnt_exist: 'Element does not exist',
    http_error: 'Server communication error',
    data_provider_error:
      'dataProvider error. Check the console for details.',
    i18n_error:
      'Cannot load the translations for the specified language',
    canceled: 'Action cancelled',
    logged_out: 'Your session has ended, please sign in again.',
  },
  navigation: {
    back: 'Back',
    create: 'Create',
    edit: 'Edit',
    delete: 'Delete'
  },
  resources: {
    users: {
      name: 'Benutzer |||| Benutzer',
      name_many: 'Benutzer',
      fields: {
        name: 'Vollständiger Name',
        rep_uname: 'Anwendung',
        user_name: 'Nutzername',
        first_name: 'Vorname',
        last_name: 'Nachname',
        email: 'E-Mail-Addresse',
        role: 'Rolle',
        verifyDate: 'Verifizieirt',
        jurisdictions: 'Zuständigkeiten',
        id: 'ID'
      }
    },
    permissions: {
      name: 'Berechtigung |||| Berechtigungen',
      name_one: 'Berechtigung',
      name_many: 'Berechtigungen'
    },
    employers: {
      name: 'Arbeitgeber |||| Arbeitgeber',
      fields: {
        id: 'ID'
      }
    },
    contracts: {
      name: 'Contract |||| Contracts',
      fields: {
        benefit_uname: 'Benefit Name',
        benefit_version: 'Benefit Version',
        rates_version: 'Rates Version',
        policy_number: 'Policy Number',
        start_date: 'Start Date',
        end_date: 'End Date',
        creation_date: 'Creation Date'
      }
    },
    employees: {
      name: 'Angestellter |||| Angestellte',
      fields: {
        'employee.first_name': 'Vorname',
        'employee.last_name': 'Nachname',
        'employee.gender': 'Gender',
        'employee.street1': 'Straße 1',
        'employee.street2': 'Straße 2',
        'employee.city': 'Stadt',
        'employee.postcode': 'Postleitzahl',
        'employee.dob': 'Geburtsdatum',
        start_date: 'Anfangsdatum',
        end_date: 'Datum verlassen',
        'employer.name': 'Angestellter'
      }
    },
    persons: {
      name: 'Person |||| Personen',
      fields: {
        first_name: 'Vorname',
        last_name: 'Nachname',
        dob: 'Geburtsdatum',
        street1: 'Straße 1',
        street2: 'Straße 2',
        'address.country_name': 'Land'
      }
    },
    representatives: {
      name: 'Anwendung |||| Anwendungen',
      fields: {
        uname: 'Unique Name',
        tel: 'Telephone'
      }
    },
    sites: {
      name: 'Site |||| Sites'
    },
    datadictionaries: {
      name: 'Wörterbucheintrag |||| Wörterbucheinträge',
      fields: {
        id: 'ID'
      }
    },
    dictionaries: {
      name: 'Wörterbuch |||| Wörterbücher',
      name_one: 'Wörterbuch',
      name_many: 'Wörterbücher',
      fields: {
        id: 'ID',
        _id: 'ID',
      }
    },
    dictionaryentries: {
      name: 'Wörterbucheintrag |||| Wörterbucheinträge',
      name_one: 'Wörterbucheintrag',
      name_many: 'Wörterbucheinträge',
      fields: {
        id: 'ID',
        _id: 'ID',
        alias: 'Alias',
        weight: 'Gewicht',
        dictName: 'Wörterbuch',
        dictId: 'Wörterbuch ID'
      }
    },
    dictionaryaliases: {
      name: 'Wörterbucheintrag Alias |||| Wörterbucheinträge Aliasese',
      name_one: 'Wörterbucheintrag Alias',
      name_many: 'Wörterbucheintrag Aliasese',
      fields: {
        id: 'ID',
        _id: 'ID',
        alias: 'Alias',
        weight: 'Gewicht',
        dictName: 'Wörterbucheintrag',
        dictId: 'Wörterbucheintrag ID'
      }
    },
    templates: {
      name: 'Vorlage |||| Vorlagen',
      name_one: 'Vorlage',
      name_many: 'Vorlagen',
      fields: {
        id: 'ID',
        _id: 'ID'
      }
    },
    templatefields: {
      name: 'Vorlagenfeld |||| Vorlagenfelder',
      name_one: 'Feld',
      name_many: 'Felder',
      name_reorder: 'Vorlagenfeld Anordnung',
      fields: {
        id: 'ID',
        _id: 'ID',
        title: 'Titel',
        name: 'Name',
        source: 'Quelle',
        value: 'Zahlenwert',
        code: {name: 'Code'},
        constant: {value: 'Constant'},
        function: {name: 'Function'},
        format: 'Format',
        necessity: 'Necessity'
      }
    },
    templatefieldsorder: {
      name: 'Vorlagenfeld |||| Vorlagenfelder (Anordnung)',
      name_reorder: 'Vorlagenfeld Anordnung',
      name_one: 'Feld',
      name_many: 'Felder (Anordnung)',
      fields: {
        id: 'ID',
        _id: 'ID',
        title: 'Titel',
        name: 'Name',
        source: 'Quelle',
        value: 'Zahlenwert',
        code: {name: 'Code'},
        constant: {value: 'Constant'},
        function: {name: 'Function'},
        format: 'Format',
        necessity: 'Necessity'
      }
    },
    untanglements: {
      name: 'Umwandlung |||| Umwandlungen',
      name_one: 'Umwandlung',
      name_many: 'Umwandlungen',
      fields: {
        elapsed_time: ' Länge (s)',
        data_size: 'Größe (KB)',
        in_range: 'Range'
      }
    },
    conversions: {
      name: 'Conversion |||| Conversions',
      fields: {
        elapsed_time: 'Duration (s)',
        data_size: 'Size (KB)',
        in_range: 'Reichweite'
      }
    }
  }
};
