import BenefitIcon from '@mui/icons-material/Web';
import BenefitList from './BenefitList';
import BenefitShow from './BenefitShow';
import BenefitCreate from './BenefitCreate';
import BenefitEdit from './BenefitEdit';

export default {
  list: BenefitList,
  show: BenefitShow,
  create: BenefitCreate,
  edit: BenefitEdit,
  ICON: BenefitIcon
};