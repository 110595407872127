import * as React from "react";
import {
  Button,
  useUpdateMany,
  //useRefresh,
  useNotify,
  useUnselectAll,
  useListContext
} from 'react-admin';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import log from "loglevel";

const resourceName = 'templatefields';

const ShowFieldsButton = ({mutationOptions}) => ShowHideFieldsButton({mutationOptions, state: 's'});

const HideFieldsButton = ({mutationOptions}) => ShowHideFieldsButton({mutationOptions, state: 'h'});

/**
 *
 * @param mutationOptions
 * @param state   's' or 'h' for show or hide
 * @returns {JSX.Element}
 * @constructor
 */
const ShowHideFieldsButton = ({mutationOptions, state}) => {
  //const refresh = useRefresh();
  const notify = useNotify();
  const {selectedIds} = useListContext();
  const unselectAll = useUnselectAll(resourceName);
  const [updateMany, {isLoading, error}] = useUpdateMany();

  // log.debug(`{ShowHideFieldsButton} selectedIds:`, selectedIds);
  // log.debug(`{ShowHideFieldsButton} mutationOptions:`, mutationOptions);
  const {meta} = mutationOptions;

  const handleClick = () => {
    updateMany(
      resourceName,
      {
        ids: selectedIds,
        data: {state, templateId: meta.templateId}, // h - hide
      },
      {
        onSuccess: () => {
          //log.debug(`{ShowHideFieldsButton.onSuccess}`);
          //refresh();
          notify('notification.fields_updated', 'info');
          unselectAll();
        },
        onFailure: error => {
          log.debug(`{ShowHideFieldsButton} error:`, error);
          notify('notification.fields_not_updated', 'warning');
        },
      }
    );
  }
  if (error) {
    log.error(`{ShowHideFieldsButton} error:`, error);
  }

  return (
    <Button label={state === 's' ? 'Show' : 'Hide'} disabled={isLoading} onClick={handleClick}>
      {state === 's' ? <Visibility/> : <VisibilityOff/>}
    </Button>
  );
};

export {
  ShowFieldsButton,
  HideFieldsButton
};