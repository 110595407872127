import {useLocation, useParams} from "react-router-dom";
import {queryParamsToFilterString, queryParamsToObject} from "../util/paramUtil";
import log from "loglevel";
import {
  //EditContextProvider,
  Edit,
  PasswordInput,
  SimpleForm,
  TextField,
  // useEditController,
  // useGetList,
  useNotify, useRefresh, useRedirect, useTranslate
} from "react-admin";
import {Breadcrumbs} from "../breadcrumbs";
import {DetailTitle, UnEditToolbar} from "../components/InputFields";
import {UnEditActions} from "../components/EditActions";
import {validatePassword} from "../users/UserValidate";
import React from "react";

const PasswordEdit = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  //const redirect = useRedirect();
  const translate = useTranslate();
  const location = useLocation();
  const {id: userId} = useParams();
  const {name} = queryParamsToObject(location);
  const userName = name;
  log.debug(`{PasswordEdit} userId: ${userId}, name: ${name}`);

  // const onSuccess = () => {
  //   notify('Password changed', {
  //     messageArgs: { smart_count: 1 },
  //     undoable: mutationMode === 'undoable'
  //   });
  //   // notify(`Password changed`);
  //   log.debug(`{PasswordEdit.onSuccess} userId: ${userId}, name: ${name}`);
  //   // redirect(`/users/${userId}/show`);
  //   // refresh();
  // };

  const redirect = (resource, id, data) => `users/${userId}/show`;
  const parent = 'users';
  const breadcrumbs = [
    {url: `/${parent}`, title: translate(`resources.${parent}.name_many`), resource: parent},
    {url: `/${parent}/${userId}/show?${queryParamsToFilterString({userName, name: userName})}`, title: userName, resource: parent},
    {url: ``, title: 'Password', resource: parent}
  ];
  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs}/>
      <Edit
        resource={'password'}
        title={<DetailTitle />}
        redirect={redirect}
        // actions={<UnEditActions />}
        // mutationOptions={{onSuccess}}
        undoable={false}  // Needed for onSuccess to receive data
      >
        <SimpleForm toolbar={<UnEditToolbar/>}>
          <TextField source="userName" />
          <PasswordInput source="password" label="New password" validate={validatePassword} fullWidth />
        </SimpleForm>
      </Edit>
    </>
  );
}

export default PasswordEdit;