import React, {useEffect, useState} from 'react';
import {
  useDataProvider,
  useTranslate
} from 'react-admin';
//import {version} from '../../package.json';
import {makeStyles} from '@mui/styles';
import Chip from "@mui/material/Chip";
import get from 'lodash/get';
import log from "loglevel";
import {getUserFromCookie} from '../auth/authProvider';
import {Role, getRoles} from "../auth/authorization";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles(theme => ({
  label: {width: '10em', display: 'inline-block'},
  root: {
    width: 800,
    height: 160,
    marginTop: '2em',
  },
  table: {},
  row: {
    width: 700,
    backgroundColor: 'grey'

  },
  displayName: {
    fontSize: "12px",
    width: 200,
    minWidth: 1,
    color: "primary.main",
    backgroundColor: '#eeeeee'

  },
  displayValue: {
    fontSize: "12px",
    width: 500,
    backgroundColor: 'white',
    '& > *': {
      margin: theme.spacing(0.5),
    }
  }
}));

const DisplaySuperAdminPanel = (props) => {
  const {first_name, last_name, rep_uname, profile} = props; // jurisdictions
  const translate = useTranslate();
  const classes = useStyles();
  log.debug(`{Profile.DisplaySuperAdminPanel} profile:`, profile);
  return (
    <Paper className={classes.root}>
      <Table size="small">
        <TableBody>
          <TableRow key={0}>
              <TableCell className={classes.displayName}>{translate('resources.users.fields.user_name')}</TableCell>
              <TableCell className={classes.displayValue}>{`${first_name} ${last_name}`}</TableCell>
          </TableRow>
          <TableRow key={1}>
            <TableCell className={classes.displayName}>Application Name</TableCell>
            <TableCell className={classes.displayValue}>{`${profile.name}`}</TableCell>
          </TableRow>
          <TableRow key={2}>
            <TableCell className={classes.displayName}>Role</TableCell>
            <TableCell className={classes.displayValue}>
              <Chip label='superadmin' key={'superadmin'} clickable={false}/>
            </TableCell>
          </TableRow>
          <TableRow key={3}>
            <TableCell className={classes.displayName}>Application (rep_uname) - deprecated</TableCell>
            <TableCell className={classes.displayValue}>{`${rep_uname}`}</TableCell>
          </TableRow>
          {/*<TableRow key={4}>*/}
          {/*  <TableCell key={200} className={classes.displayName}>Version</TableCell>*/}
          {/*  <TableCell key={201} className={classes.displayValue}>{version}</TableCell>*/}
          {/*</TableRow>*/}
        </TableBody>
      </Table>
    </Paper>
  )
}

const DisplayAdminPanel = (props) => {
  const translate = useTranslate();
  const {first_name, last_name, profile, roles} = props;
  let profileChildren = [];
  if (profile && profile.children && profile.children.length > 0) {
    profileChildren = profile.children;
  }
  const profileChildrenStr = profileChildren.map(c => c.name).join(', ');

  let profileTemplates = [];
  if (profile && profile.allTemplates && profile.allTemplates.length > 0) {
    profileTemplates = profile.allTemplates;
  }
  const profileTemplatesStr = listTemplates(profile.allTemplates);
  let i = 0;
  log.debug(`profileTemplates: `, profileTemplates)
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Table size="small">
        <TableBody>
          <TableRow key={i++}>
            <TableCell className={classes.displayName}>{translate('resources.users.fields.user_name')}</TableCell>
            <TableCell className={classes.displayValue}>{`${first_name} ${last_name}`}</TableCell>
          </TableRow>
          <TableRow key={i++}>
            <TableCell className={classes.displayName}>Role</TableCell>
            <TableCell className={classes.displayValue}>
              {roles.map((role, j) => (
                <Chip key={j+500} label={role} clickable={false}/>
              ))}
            </TableCell>
          </TableRow>
          <TableRow key={i++}>
             <TableCell key={300} className={classes.displayName}>Child Applications</TableCell>
             <TableCell key={301} className={classes.displayValue}>{`${profileChildrenStr}`}</TableCell>
          </TableRow>
          <TableRow key={i++}>
            <TableCell key={400} className={classes.displayName}>Templates</TableCell>
            <TableCell key={401} className={classes.displayValue}>{`${profileTemplatesStr}`}</TableCell>
          </TableRow>
          {/*<TableRow key={i++}>*/}
          {/*  <TableCell key={200} className={classes.displayName}>Application (rep_uname) - deprecated</TableCell>*/}
          {/*  <TableCell key={201} className={classes.displayValue}>{`${rep_uname}`}</TableCell>*/}
          {/*</TableRow>*/}
          {/*<TableRow key={i++}>*/}
          {/*  <TableCell key={200} className={classes.displayName}>Version</TableCell>*/}
          {/*  <TableCell key={201} className={classes.displayValue}>{version}</TableCell>*/}
          {/*</TableRow>*/}
        </TableBody>
      </Table>
    </Paper>
  )
}

const DisplayOtherPanel = (props) => {
  const translate = useTranslate();
  const {first_name, last_name, roles} = props;
  // let profileChildren = [];
  // if (profile && profile.children && profile.children.length > 0) {
  //   profileChildren = profile.children;
  // }
  // const profileChildrenStr = profileChildren.map(c => c.name).join(', ');
  //
  // let profileTemplates = [];
  // if (profile && profile.allTemplates && profile.allTemplates.length > 0) {
  //   profileTemplates = profile.allTemplates;
  // }
  // const profileTemplatesStr = listTemplates(profile.allTemplates);
  let i = 0;
  console.log(`{DisplayOtherPanel} first_name: ${first_name}, last_name: ${last_name}`);
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Table size="small">
        <TableBody>
          <TableRow key={i++}>
            <TableCell className={classes.displayName}>{translate('resources.users.fields.user_name')}</TableCell>
            <TableCell className={classes.displayValue}>{`${first_name} ${last_name}`}</TableCell>
          </TableRow>
          <TableRow key={i++}>
            <TableCell className={classes.displayName}>Role</TableCell>
            <TableCell className={classes.displayValue}>
              {roles.map((role, j) => (
                <Chip key={j+500} label={role} clickable={false}/>
              ))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}

const listTemplates = allTemplates => allTemplates.map(t => ` ${t.templateName} (${t.applicationName})`);

const Profile = () => {
  const [profile, setProfile] = useState();
  const dataProvider = useDataProvider();
  const userProps = getUserFromCookie();
  const {jurisdictions} = userProps;
  const {getOne} = dataProvider;
  const roles = getRoles(jurisdictions);
  log.debug(`{Profile} roles:`, roles);

  // TODO: Retrieve employer name using ER entity ID from JWT
  useEffect(() => {
    (async () => {
      const data = await getOne('profiles', {id: '0'})
        .catch(error => {
          log.debug(`{Profile} [useEffect] error:`, error);
        });
      log.debug(`{Profile} [useEffect] profile data returned:`, data);
      if (data && data.data && !profile) {
        //setProfile(data.data.length > 0 ? data.data[0] : undefined);
        setProfile(data.data);
        log.debug(`{Profile} [useEffect] profile set:`, profile);
      }
    })();
  }, [getOne, profile]);
  if (!profile) return null;
  log.debug(`{Profile} profile:`, profile);
  const props = {roles, profile, ...userProps};
  if (get(jurisdictions, Role.SUPERADMIN))
    return (<DisplaySuperAdminPanel {...props}/>)
  else if (get(jurisdictions, Role.UN_ADMIN))
    return (<DisplayAdminPanel {...props}/>)
  else if (get(jurisdictions, Role.UN_SUPPORT))
    return (<DisplayAdminPanel {...props}/>)
  else {
    //log.info(`{Profile} No supported role for profile`);
    //return <></>
      return (<DisplayOtherPanel {...props}/>)
  }
};

export default Profile;
