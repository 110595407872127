import React from 'react';
import log from 'loglevel';
import {Link, useParams} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {Button, useRecordContext, useResourceContext} from 'react-admin';
import qs from "query-string";
import ContentCreate from '@mui/icons-material/Create';  // Edit Icon
import {
  queryParamsToFilterString
} from "../util/paramUtil";

const styles = {
  button: {
    marginTop: '0em',
    marginLeft: '1em'
  }
};

/**
 * Button to take you to edit page for resource with extra filter query parameters.
 * This differs from standard EditButton in that it accepts a filter object whose contents
 * are converted to query parameters added to the client URL.
 * tabName cannot be 'show'.
 * If resource specified as property use it, otherwise use context (e.g. password vs context in passwordEdit)
 * @param props filter & tabName
 * @returns {*}
 * @constructor
 */

const UnEditButtonWithFilter = (props) => {
  const ctxResource = useResourceContext();
  const params = useParams();
  const {id} = params;
  const {filter, resource, caption = 'Edit', icon = <ContentCreate/>} = props;
  const selResource = resource || ctxResource;
  const tabName = (props.tabName !== 'show') ? props.tabName : undefined;
  log.debug('{UnEditButtonWithFilter} props:', props); // Only contains tabName (e.g. consstraints)
  log.debug(`{UnEditButtonWithFilter} selResource: ${selResource}, tabName:`, tabName);
  log.debug(`{UnEditButtonWithFilter} filter:`, filter);
  const queryParam = queryParamsToFilterString(filter);
  log.debug("{UnEditButtonWithFilter} queryParam:", queryParam);
  const pathname = tabName ? `/${selResource}/${id}/${tabName}` : `/${selResource}/${id}`;
  log.debug(`{UnEditButtonWithFilter} Edit path will be: ${pathname}?${queryParam}`);
  return (<Button
    //className={classes.button}
    component={Link}
    to={{
      pathname,
      search: queryParam
    }}
    label={caption}
    title={caption}
  >
    {icon}
  </Button>)
}

/**
 * Extract name from record and pass as filter parameter to UnEditButtonWithFilter component.
 * E.g. On the dictionary show page, a button link to the edit page will include the name URL query parameter
 *    /dictionaries/<id>/edit?filter={name=mytitle}
 * @param props
 * @returns {*}
 * @constructor
 */
export const UnEditButtonWithName = (props) => {
  const record = useRecordContext();
  log.debug(`{UnEditButtonWithName} record: `, record);
  log.debug(`{UnEditButtonWithName} name: ${record ? record.name : 'No name!'}`);
  return (<UnEditButtonWithFilter filter={{name: record.name}} {...props} />);
}


/**
 * TODO: Not currently used
 * TODO: is basePath prop needed?
 * TODO: caption -> label
 * @param props
 * @returns {*}
 * @constructor
 */
const UnEditButtonUnused = (props) => {
  //log.debug("{UnEditButton} props:", props);
  let {upperResource = undefined, upperResourceVals = undefined,
    pathname, classes, record = {}, caption = 'Edit', icon = <ContentCreate />} = props;
  //log.debug(`{UnEditButton} caption: ${caption}, pathname: ${pathname}, record:`, record);
  if (!record) {
    log.debug(`{UnEditButton} Error initialising button: record not defined. `);
    record = {};
  }
  //if (!upperResource) log.debug(`{UnEditButton} upperResource not specified!`);
  const filterObj = {};
  if (upperResource === 'employer') {
    let employerId, employerName;
    if (record.employer) {
      employerId = record.employer._id;
      employerName = record.employer.name;
    }
    else if (upperResourceVals) {
      employerId = upperResourceVals.id;
      employerName = upperResourceVals.name;
    }
    else if (record.employer_id) {
      employerId = record.employer_id;
      employerName = record.employer_name ? record.employer_name : ''; // Not available for contract
    }
    else {
      employerId = record._id;
      employerName = record.name;
    }
    filterObj[`${upperResource}_id`] = employerId;
    filterObj[`${upperResource}_name`] = employerName;

  }
  // if (!_.isEmpty(filterObj)) {
  //   log.debug("{UnEditButton} filterObj:", filterObj);
  // }
  if (!record._id) {
    log.debug(`{UnEditButton} Error initialising page: no _id found. `);
    return null;
  }
  const queryParam = qs.stringify({
    filter: record ? JSON.stringify(filterObj) : ''
  });
  log.debug(`{UnEditButton} pathname will be: ${pathname}/${record._id}/edit?${queryParam}`);
  return <Button
    className={classes.button}
    component={Link}
    to={{
      pathname: `${pathname}/${record._id}/edit`,
      search: queryParam
    }}
    label={caption}
    title={caption}
  >
    {icon}
  </Button>
};

export {
  UnEditButtonUnused
}

export default withStyles(styles)(UnEditButtonWithFilter);
