import React from 'react';
import {
  ShowView,
  SimpleShowLayout,
  TextField, TopToolbar,
  useShowController,
  Loading,
  // useResourceContext,
  // useRecordContext,
  ShowContextProvider
} from 'react-admin';
import {DetailTitle} from "../components/InputFields";
import {Breadcrumbs} from "../breadcrumbs";
import {UnEditButtonWithName} from "../button/UnEditButtonWithFilter";
import log from 'loglevel';

const SiteActions = () => {
  return (
    <TopToolbar>
      <UnEditButtonWithName />
    </TopToolbar>
  );
};

/**
 * The breadcrumb for this page does not need name to be passed as a URL name argument as
 * it uses the name from the record.
 * @returns {JSX.Element|null}
 * @constructor
 */
export const SiteShow = () => {
  const listContext = useShowController();
  const {isLoading, record} = listContext;
  if (isLoading) {return <Loading />;}
  log.debug(`{SiteShow} record:`, record);
  return (
    <ShowContextProvider value={listContext}>
      <Breadcrumbs/>
      <ShowView
        title={<DetailTitle />}
        actions={<SiteActions/>}
      >
        <SimpleShowLayout>
          <TextField source="uname" />
          <TextField source="name" />
          {/*<TextField source="tel" />*/}
          <TextField source="logo" />
          <TextField source="tagline" />
          <TextField source="strapline" />
          <TextField source="type" />
        </SimpleShowLayout>
      </ShowView>
    </ShowContextProvider>
  );
}

export default SiteShow;