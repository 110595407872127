import {makeStyles} from '@mui/styles';
import {
  useRecordContext, useResourceContext
} from "react-admin";
import {Link} from "react-router-dom";
import React from "react";
import PageviewIcon from '@mui/icons-material/Pageview';
import {queryParamsToFilterString} from '../util/paramUtil';
//import {Box} from "@mui/material";
//import log from "loglevel";

/**
 * https://mui.com/material-ui/customization/palette/#main-content
 * @type {(props?: any) => ClassNameMap<string>}
 */
const useStyles = makeStyles(theme => {
  console.log(`{UnIconButton.useStyles} theme:`, theme);
  return ({
    root: {
      //backgroundColor: 'red'
      color: theme.palette.primary.main
    },
    button: {
      // marginTop: '0em',
      // marginLeft: '1em',
      // //fontSize: '30px',
      // largeIcon: {
      //   fontSize: '30px'
      //}
    }})
})

/**
 * Button to take you to detail (show) page for resource with extra filter query parameters.
 * This differs from standard ShowButton in that it accepts a filter object whose contents
 * are converted to query parameters added to the client URL.
 * Icon was ImageEye. caption did default to 'Detail'.
 *
 * For use in a DataSet row.
 *
 * Icon size changed via:
 * .RaButton-smallIcon-10 {
 *   font-size: 26px;
 * }
 *
 * If tab
 * /dictionaryentries/60c714d20a1fb2140fe524ad/show/aliases
 * @param props
 * @returns {*}
 * @constructor
 */
const UnIconButton = (props) => {
  const record = useRecordContext();
  const defResource = useResourceContext();
  const classes = useStyles();
  const {filter: filterArg,
    myresource, // Needed to specify different resource as in dictionaryaliases -> entries,
    //idName,
    icon = <PageviewIcon style={{fontSize: 30}}/>
  } = props;
  const resource = myresource ? myresource : defResource;
  //log.debug(`{UnIconButton} filterArg:`, filterArg);
  const id = record._id;
  // const id = idName ? record[idName] :
  //   (idArg ? idArg : record._id);  // Use id if supplied separately
  const filter = filterArg ? filterArg : {name: (record.name ? record.name : 'Name?')}
  const tabName = (props.tabName !== 'edit') ? props.tabName : undefined;

  const queryParam = queryParamsToFilterString(filter);
  const pathname = tabName ? `/${resource}/${id}/show/${tabName}` : `/${resource}/${id}/show`;
  //console.log(`{UnIconButton} Show path will be: ${pathname}?${queryParam}`);
  return (
    // <Button
    //   label=""
    //   size="small"
    //   component={Link}
    //     to={{
    //       pathname,
    //       search: queryParam
    //     }}
    //   onClick={() => {
    //     console.log(`IconButtonWithTooltip click`);
    //   }}
    //   disabled={false}
    //   color="primary"
    // >
    //   {icon}
    // </Button>
    <Link to={`${pathname}?${queryParam}`} className={classes.root}
      sx={{
        color: "primary"
      }}
    >
      {icon}
    </Link>

  )
}

/**
 * Display button with icon and no caption.
 * For use in list (DataGrid) pages. E.g. Detail at the end of each list item.
 * Note that passing a label attribute to this field in a DataGrid sets the column heading.
 * Default icon is <PageviewIcon/> which can be overridden with attribute, e.g icon={<ImageEye/>}
 * TODO For column header width see: https://marmelab.com/react-admin/List.html#datagrid-css-api
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
// const IconButton = (props) => {
//   //eslint-disable-next-line
//   const {caption, ...rest} = props;
//   return <UnIconButton caption={''} {...rest}/>
// }

/**
 * Extract name from record and pass as filter parameter to UnShowButtonWithFilter component.
 * E.g. On the dictionary edit page, a button link to the show page will include the name URL query parameter
 *    /dictionaries/<id>/edit?filter={name=mytitle}
 * @param props
 * @returns {*}
 * @constructor
 */
const UnShowButtonWithName = (props) => {
  const {record} = props;
  if (!record) return null;
  //log.debug(`{UnShowButtonWithName} name: ${record ? record.name : 'No name!'}`);
  return (<UnIconButton
    caption = '' // If icons only in list buttons
    filter={{name: (record.name ? record.name : 'Name?')}}
    {...props} />);
}

export {
  UnShowButtonWithName,
  //IconButton
};

export default UnIconButton;