import React from 'react';
import {useTheme} from '@mui/styles';
import {
  useParams
} from "react-router-dom";
import {
  Datagrid,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  ArrayField,
  NumberField,
  FunctionField,
  useTranslate,
  useShowController,
  ShowView,
  ShowContextProvider,
  TopToolbar,
  Loading,
  useResourceContext,
  useRecordContext
} from 'react-admin';
import {DetailTitle} from '../components/InputFields';
import log from 'loglevel';
import {Breadcrumbs} from "../breadcrumbs";
import UnDownloadButton from "../export/UnDownloadButton";
import ElapsedTimes from './ElapsedTimes';

/*
TextField is composed of smaller components ( FormControl, Input, FilledInput, InputLabel, OutlinedInput,
and FormHelperText ) that you can leverage directly to significantly customize your form inputs.
https://material-ui.com/api/text-field/
 */

const UntanglementActions = () => {
  //const {permissions} = usePermissions();
  const record = useRecordContext();
  return (
    <TopToolbar>
      {record && !record.errorCode ?
      <UnDownloadButton
        label={'Download'}
        showLabel={true}
      /> : ''}
    </TopToolbar>
  );
}

const UntanglementSuccessTab = props => {
  const {className: notLast} = props;
  return (
    <Tab label="summary" {...props}>
      <TextField source="name" />
      <DateField source="createdate" label="Created" showTime className={notLast}/>
      <TextField source="id" />
      <TextField source="user_name" className={notLast}/>
      <TextField source="template" />

      <NumberField
        label="Duration (s)"
        source="elapsed_time"
        options={{maximumFractionDigits: 2}}
        className={notLast}
      />
      <FunctionField label="Size (KB)" source="data_size" textAlign="right"
                     render={record =>
                       record.data_size ? `${(record.data_size/1000).toFixed(2)}` : ""
                     }
      />
      <FunctionField label="Range" render={record =>
        record.in_range + (record.numCells ? ` (${record.numCells} cells)` : '')
      }/>
      <FunctionField source="storageMode" render={
          record => record.storageMode === 'S' ? 'S3' : "Database"
        }
      />
    </Tab>
      )
}

const UntanglementFailTab = props => {
  const {className} = props;
  return (
    <Tab label="summary" {...props}>
      <TextField source="name" />
      <DateField source="createdate" label="Created" showTime className={className}/>
      <TextField source="id" />
      <TextField source="user_name" className={className}/>
      <TextField source="template" />

      <TextField label="Error Code" source="errorCode" className={className} />
      <TextField label="Error" source="errorMessage" />
    </Tab>
  )
}

/**
 * Standalone Datagrid usage: https://marmelab.com/react-admin/Datagrid.html#standalone-usage
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
// const ResultsTab = props => {
//   const {results, className: notLast} = props;
//   log.debug(`{ResultsTab} results:`, results);
//   return (
//     <Tab label="results" {...props}>
//       <ArrayField source="results" label={''}>
//       <Datagrid
//         data={data}
//         bulkActionButtons={false}
//       >
//         {/*<TextField source='column' />*/}
//       </Datagrid>
//       </ArrayField>
//     </Tab>
//   )
// }

const ResultTextField = ({source}) => {
  const theme = useTheme();
  const row = useRecordContext();
  log.debug(`{ResultTextField} row:`, row);
  // ? '#eeeeee' : theme.palette.secondary.main;
  if (row.column === '') {
    return (<TextField source={source} sx={{color: theme.palette.secondary.main}}/>)
  }
  else return (<TextField source={source}/>)
}

const ResultsGrid = () => {
  const record = useRecordContext();
  const {results} = record;
  if (!results) return null;
  // log.debug(`{ResultsGrid} results:`, results);
  return (
    <ArrayField source="results" label={''}>
      {/*<TableItemsField items={record.analyses} fieldNames={['message', 'oref', 'tref', 'scope', 'level', 'detail']}/>*/}
      {/*filters={<AnalysesFilter />}*/}
      <Datagrid data={record.results} bulkActionButtons={false}>
        <TextField source="column"/>
        <ResultTextField source="status"/>
        <NumberField source="weight"/>
        <NumberField source="confidence"/>
        <ResultTextField source="code"/>
        <ResultTextField source="composite" label="Composite Term"/>
        <ResultTextField source="simple" label="Simple Term"/>
      </Datagrid>
    </ArrayField>
  )
}

const UntanglementShow = () => {
  const resource = useResourceContext();
  const {id} = useParams();
  const translate = useTranslate();
  const showContext = useShowController();
  const {record, isLoading} = showContext;
  if (isLoading) return <Loading />;
  log.debug(`{UntanglementShow} record:`, record);
  const name = record ? record.name : undefined;
  //log.info(`{UntanglementShow} id: ${id}, name: ${name}`);
  const breadcrumbs = [
    {url: `/${resource}`, title: translate(`resources.${resource}.name_many`), resource},
    {url: `/${resource}/${id}/show`, title: name, resource}
  ];
  return (
    <ShowContextProvider value={showContext}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <ShowView
        title={<DetailTitle />}
        actions={<UntanglementActions  />}
      >
        <TabbedShowLayout>
          {!record.errorCode ?
            <UntanglementSuccessTab  /> :
            <UntanglementFailTab />
          }

          <Tab label="analyses">
            {/*<TextField source="name" />*/}
            <ArrayField source="analyses" label={''}>
              {/*<TableItemsField items={record.analyses} fieldNames={['message', 'oref', 'tref', 'scope', 'level', 'detail']}/>*/}
              {/*filters={<AnalysesFilter />}*/}
              <Datagrid data={record.analyses} bulkActionButtons={false}>
                <TextField source="detail" label={'Description'} />
                <TextField source="message" />
                <TextField source="oref" />
                <TextField source="tref" />
                <TextField source="scope" />
                <TextField source="level" />
              </Datagrid>
            </ArrayField>
          </Tab>
          <Tab label="owner">
            <TextField label="Entity code" source="owner.entitycode" />
            <TextField label="Entity ID" source="owner.entityid" textAlign="right"/>
          </Tab>
          {/*<ResultsTab results={record.results} className={classes.notLast} />*/}
          <Tab label="results">
            <ResultsGrid />
          </Tab>

          <Tab label="Profiling">
            <ElapsedTimes />
          </Tab>
        </TabbedShowLayout>
      </ShowView>
      </ShowContextProvider>
  );
};

export default UntanglementShow;
