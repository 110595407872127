import React from 'react';
import {
  //useLocation,
  //useParams
} from "react-router-dom";
import {
  ShowView, TopToolbar,
  TabbedShowLayout, Tab,
  TextField, FunctionField, ReferenceField,
  useShowController,
  ShowContextProvider,
  Loading,
  //useResourceContext,
  usePermissions, useRecordContext,
} from 'react-admin';
import Employees from '../employees';
import Contracts from '../contracts';
import Evaluations from '../evaluations';
import {dateFromId} from "../util/dateUtil";
import UnListButton from '../button/UnListButton';
import {Breadcrumbs} from "../breadcrumbs";
import {UnEditButtonWithName} from "../button/UnEditButtonWithFilter";
import {Activity, isAuthorized} from "../auth/authorization";
import {DetailTitle} from "../components/InputFields";


const EmployerActions = () => {
  const {permissions} = usePermissions();
  //const resource = useResourceContext();
  const data = useRecordContext();
  //if (!data) return null;
  //console.log(`{EmployerActions} permissions:`, permissions)
  const filter = {employer_id: data.id, employer_name: data.name};
  return (
    <TopToolbar>
      {/*<EditButton basePath={basePath} record={data} icon={<Employers.ICON />} />*/}
      {isAuthorized(permissions, Activity.EMPLOYER) ?
        <UnEditButtonWithName />
        : null}

      <UnListButton
        label='Employees'  //'resources.dictionaryentries.name_many'
        pathname='/employees'
        filter={filter}
        record={data}
        icon={<Employees.ICON/>}
      />
      {isAuthorized(permissions, Activity.EMPLOYER) ?
        <UnListButton
          label='Evaluations'  //'resources.evaluations.name_many'
          pathname='/evaluations'
          filter={filter}
          record={data}
          icon={<Evaluations.ICON/>}
        />
        : null}

      {isAuthorized(permissions, Activity.EMPLOYER) ?
        <UnListButton
          label='Contracts'  //'resources.contracts.name_many'
          pathname='/contracts'
          filter={filter}
          record={data}
          icon={<Contracts.ICON/>}
        />
        : null}

    </TopToolbar>
  );
}

export const EmployerShow = () => {
  const {permissions} = usePermissions();
  const listContext = useShowController();
  const {isLoading} = listContext;
  if (isLoading) return <Loading/>;
  return (
    <ShowContextProvider value={listContext}>
      <Breadcrumbs/>
      <ShowView
        title={<DetailTitle />}
        actions={<EmployerActions />}
        //toolbar={<EmployerToolbar/>}
        >
        <TabbedShowLayout>
          <Tab label="details">
            <TextField source="name"/>
            <TextField source="street"/>
            <TextField source="city"/>
            <TextField source="postcode"/>
            <TextField source="industry"/>
            {isAuthorized(permissions, Activity.APPLICATION) ?
            <ReferenceField label="Representative" source="representative_id" reference="representatives">
              <TextField source="name"/>
            </ReferenceField>
              : null}

            <FunctionField label={"Created"} render={record => `${dateFromId(record._id, 'LONG')}`}/>

            {isAuthorized(permissions, Activity.APPLICATION) ?
              <FunctionField label="Status" render={
                record =>
                  `${(record.status === 'test') ? 'Test' : 'Live'}`
              }/>
              : null}

            {isAuthorized(permissions, Activity.APPLICATION) ?
              <TextField source="id"/>
            : null}
          </Tab>
          {isAuthorized(permissions, Activity.APPLICATION) ?
          <Tab label="metrics">
            <FunctionField label={"# Employees"}
                           render={record => `${record.employments ? record.employments.length : 0}`}/>
            <FunctionField label={"# Contracts"}
                           render={record => `${record.contracts ? record.contracts.length : 0}`}/>
            <FunctionField label={"# Evaluations"}
                           render={record => `${record.evaluations ? record.evaluations.length : 0}`}/>
          </Tab>
            : null}
        </TabbedShowLayout>
      </ShowView>
    </ShowContextProvider>
  );
}


export default EmployerShow;