/*
 * Template data functions
 */
import log from 'loglevel';
import {useDataProvider} from "react-admin";
import {useEffect} from "react";

const dictionaryIdsFromRecord = (props) => {
  if (!props.record) return undefined;
  const {record} = props;
  log.debug(`{dictionaryIdsFromRecord} record:`, record);
  return record.dictionaries;
}

/**
 * Get owner applications from document
 * TODO: Add view=summary argument and support on endpoint
 * @param dataProvider
 * @param applicationIds
 * @returns {Promise<*>}
 */
const fetchApplications = async ({dataProvider, applicationIds}) => {
  log.debug(`{fetchApplications} applicationIds:`, applicationIds);
  if (applicationIds === undefined) {
    log.debug(`{fetchApplications} applicationIds undefined`);
    return undefined;
  }
  const response = applicationIds ?
    await dataProvider.getList('representatives', {filter: {ids: applicationIds}})
      .catch(err => log.error(`{fetchApplications} err:`, err)) :
    await dataProvider.getList('representatives', {})
      .catch(err => log.error(`{fetchApplications} err:`, err));
  log.debug(`{fetchApplications} after getList - response:`, response);
  // return response ? response.data : [];
  return response ? response.data : undefined;
  //.catch(error => {log.debug(`{TemplateShow} useEffect - applications error:`, error)});
}

const fetchAllApplications = async ({dataProvider}) => {
  const response = await dataProvider.getList('representatives', {})
    .catch(err => log.error(`{fetchAllApplications} err:`, err));
  log.debug(`{fetchAllApplications} after getList - response:`, response);
  return response ? response.data : undefined;
}

//const fetchAllApplications = async props => await fetchApplications(props);

const fetchProfile = async (dataProvider) => {
  log.debug(`{fetchProfile} dataProvider:`, dataProvider);
  // const response =
  //   await dataProvider.getList('profiles', {}) // {filter: {ids: applicationIds}}
  //     .catch(err => log.error(`{fetchProfile} err:`, err));
  //
  // log.debug(`{fetchProfile} after getList - response:`, response);
  // return response ? response.data : undefined;
}


/**
 * Call GET /dictionaries to return name, locale and section for display in dropdown list.
 * TODO: Should this IDs filter be done on the admin server based on dictionary owner permissions?
 * @param dataProvider
 * @param dictionaryIds
 * @returns {Promise<*>}
 */
const fetchDictionaries = async ({dataProvider, dictionaryIds}) => {
  log.debug(`{fetchDictionaries} dictionaryIds:`, dictionaryIds);
  // Convert ids if contain objects containing _id instead of IDs
  const ids = dictionaryIds ? dictionaryIds.map(id => id._id ? id._id : id) : [];
  const response = await dataProvider.getList('dictionaries', {
    pagination: {page: 1, perPage: 500},
    sort: {field: 'name', order: 'ASC'},
    filter: dictionaryIds ? {ids, view: 'summary'} : {view: 'summary'}
  })
  if (!response) {
    log.info(`{fetchDictionaries} No response`);
    return undefined;
  }
  log.debug(`{fetchDictionaries} getList - dictionaries response.data:`, response.data);
  const displayDictionaries = response.data.map(d => {
    const section = d.section ? `${d.section}/` : '';
    const locale = d.locale ? `${d.locale}/` : '';
    return {id: `${d._id}`, name: `${section}${locale}${d.name}`};
  });
  console.log(`{TemplateShow} displayDictionaries:`, displayDictionaries);
  return displayDictionaries;
}

const fetchDictionariesFromTemplate = async ({dataProvider, templateId}) => {
  log.debug(`{fetchDictionariesFromTemplate} templateId:`, templateId);
  const response = await dataProvider.getOne('templates', {id: templateId, args: {view: 'summary'}})
    .catch(err => log.error(`{fetchDictionariesFromTemplate} err:`, err))

  log.debug(`{fetchDictionariesFromTemplate} after getList - response:`, response);
  const data = response ? response.data : undefined;
  return data ? data.dictionaries : undefined;
}

/**
 * Custom hook to look up applications for IDs in applicationIds via fetchApplications().
 * Updates applications state (using setApplications).
 * Dependencies: applicationIds comes from main record. Whenever this changes, we need to look up
 * new list of applications (reps).
 * @param props
*/
const useGetOwnerApplications = (props) => {
  const dataProvider = useDataProvider();
  const {applicationIds, applications, setApplications} = props;
  useEffect(() => {
    (async() => {
      log.debug(`{useGetOwnerApplications} applications before fetchApplications:`, applications);
      const isFetch = (applicationIds && applicationIds.length > 0 && !applications);
      log.debug(`{useGetOwnerApplications} fetch?: ${isFetch}, applicationIds:`, applicationIds);
      let fetchedApplications = isFetch ?
        await fetchApplications({dataProvider, applicationIds})
          .catch(err => {
            log.error(`{useGetOwnerApplications} fetchApplications returned err:`, err);
            fetchedApplications = [];
          })
        : undefined; // undefined
      log.info(`{useGetOwnerApplications} fetchedApplications:`, fetchedApplications);
      if (fetchedApplications !== undefined) {
        setApplications(fetchedApplications);
      }
    })();
  }, [applicationIds, dataProvider, applications, setApplications]);
}

/**
 * Custom hook to look up dictionaries for IDs in dictionaryIds via fetchDictionaries().
 * Updates dictionaries state (using setDictionaries).
 * Called by (e.g.) TemplateShow using record.dictionaries (array of dictionary IDs)
 * @param props
 */
const useGetDictionaries = (props) => {
  const dataProvider = useDataProvider();
  const {dictionaryIds, setDictionaries} = props;

  useEffect( () => {
    (async () => {
      log.debug(`{useGetDictionaries} dictionaryIds:`, dictionaryIds);
      let fetchedDictionaries = (dictionaryIds && dictionaryIds.length > 0) ?
        await fetchDictionaries({dataProvider, dictionaryIds})
          .catch(err => {
            log.error(`{useGetDictionaries} fetchedDictionaries returned err:`, err);
            fetchedDictionaries = [];
          })
        : []; // undefined if API call not finished
      if (fetchedDictionaries !== undefined) {
        setDictionaries(fetchedDictionaries);
      }
    })();
  }, [dictionaryIds, dataProvider, setDictionaries]);
}


export {
  dictionaryIdsFromRecord,
  fetchApplications,
  fetchAllApplications,
  fetchProfile,
  fetchDictionaries,
  fetchDictionariesFromTemplate,
  useGetOwnerApplications,
  useGetDictionaries
}
