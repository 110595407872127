import {Datagrid, DateField, FunctionField, TextField} from "react-admin";
import UnIconButton from "../button/UnIconButton";
import React from "react";
import {employerMonthlyCost, unadjustedMonthlyCost} from "./EmployeeUtil";

const NormalGrid = ({filter}) => {
  return (
    <Datagrid bulkActionButtons={false}>
      <TextField source="employee.first_name"/>
      <TextField source="employee.last_name"/>
      <TextField source="employee.gender"/>
      <TextField source="employee.city"/>
      <DateField source="employee.dob"/>
      <DateField source="start_date"/>
      <DateField source="end_date"/>
      <UnIconButton label={'Detail'} filter={filter} />
    </Datagrid>
  );
}

const CanadaLifeGrid = ({filter, benefit_uname}) => {
  return (
    <Datagrid bulkActionButtons={false}>
      <TextField source='id' label='Employee ID'/>
      <TextField source='employee.last_name' label='Surname'/>
      <TextField source='employee.first_name' label='First Name'/>
      <TextField source='employee.gender' label='Gender'/>
      <DateField source="employee.dob" label='Date of Birth'/>
      <FunctionField label='Selected Fixed Benefit' render={() => `£100,000`} />
      <TextField source="employer.postcode" label='Business / Workplace Postcode'/>
      <DateField source="start_date" label='Join Date'/>
      <DateField source="end_date" label='Leave Date'/>
      <FunctionField label='Employer Monthly Cost' render={e => employerMonthlyCost(e, benefit_uname)} />
      <FunctionField label='Unadjusted Monthly Cost' render = {e => unadjustedMonthlyCost(e, benefit_uname)} />
      <UnIconButton label={'Detail'} filter={filter} />
    </Datagrid>
  );
}

const WestfieldGrid = ({filter}) => {
  return (
  <Datagrid bulkActionButtons={false}>
    <TextField source='employee.first_name' label='Forename' />
    <TextField source='employee.last_name' label='Surname' />
    <FunctionField label='Gender' render={ee =>
      ee.employee.gender && ee.employee.gender.substring(0,1).toUpperCase()} />
    <DateField source='employee.dob' />
    <TextField source='employee.street1' label='Home address 1' />
    <TextField source='employee.street2' label='Home address 2' />
    <TextField source='employee.city' label='Home address 3' />
    <TextField source='employee.postcode' label='Home postcode' />
    <FunctionField label='Level of Cover' render={() => `F2+DL`} />
    <DateField source='start_date'/>
    <DateField source='end_date'/>
    <UnIconButton label={'Detail'} filter={filter} />
  </Datagrid>
  );
}

/**
 * Return a datagrid corresponding to the selected benefit view
 * @param view
 * @param filter
 * @returns {*}
 * @constructor
 */
const CustomGrid = ({view, filter}) => {
  return {
    normal: <NormalGrid filter={filter} />,
    leavers: <CanadaLifeGrid filter={filter} benefit_uname={'canada_life'} />,
    westfield: <WestfieldGrid filter={filter} benefit_uname={'westfield'} />
  }[view];
}

export {
  NormalGrid,
  CanadaLifeGrid,
  WestfieldGrid,
  CustomGrid
}