import React from 'react';
import {
  List, Datagrid,
  TextField, FunctionField,
  SelectInput,
  //CreateButton,
  Filter,
  TopToolbar,
  usePermissions,
} from 'react-admin';
import {dateFromId} from '../util/dateUtil';
import {Breadcrumbs} from '../breadcrumbs';
import {defaultPerPage, UnPagination} from '../util/paramUtil';
import UnIconButton from "../button/UnIconButton";
import {ApplicationChip} from "../components/Chips";
import {Activity, isAuthorized} from "../auth/authorization";
import {ListTitle} from "../components/InputFields";

/**
 * This uses the RA (react-admin) List component which is both a UI view and a connected component (fetches data).
 * Normally a Datagrid is next used to display a table and to iterate through each row and cell.
 * Instead the test TableRowsIterator is used, which calls internal test components TableHeader and TableRow.
 * The fields are represented by the normal RA field components.
 * TODO Enable CreateButton when Create Employer page implemented.
 * @returns {*}
 * @constructor
 */

const EmployerActions = () => {
  //const {permissions} = usePermissions();
  return (
    <TopToolbar>
      {/*{isAuthorized(permissions, Activity.EMPLOYER) ? <CreateButton/> : null}*/}
    </TopToolbar>
  );
}

const EmployerFilter = (props) => (
  <Filter {...props}>
    {/*<TextInput label='Search' source='q' alwaysOn />*/}
    <SelectInput source='status' choices={[
      { id: ' ', name: 'Live' },
      { id: 'test', name: 'Test' }
    ]} />
  </Filter>
);

export const EmployerList = () => {
  const {permissions} = usePermissions();
  return (
    <>
      <Breadcrumbs/>
      <List
        title={<ListTitle />}
        filters={<EmployerFilter />}
        sort={{ field: 'name', order: 'ASC' }}
        pagination={<UnPagination/>}
        perPage={defaultPerPage}
        actions={<EmployerActions/>}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" />
          <TextField source="city" />
          <TextField source="postcode" />

          {isAuthorized(permissions, Activity.APPLICATION) ?
            <FunctionField label={"Application"} render={
              record => record && record.representative_id ?
                <ApplicationChip application={record && record.representative_id}/> :
                ''
            }/>
            : null}

          {isAuthorized(permissions, Activity.APPLICATION) ?
          <FunctionField label={"# Ee/Con/Ev"} render={
            record => `${record.employments ? record.employments.length : 0}
                      / ${record.contracts ? record.contracts.length : 0}
                      / ${record.evaluations ? record.evaluations.length : 0}`
          } />
            : null}

          {/*<FunctionField label={"# Employees"} render={record => `${record.employments ? record.employments.length : 0}`} />*/}
          {/*<FunctionField label={"# Contracts"} render={record => `${record.contracts ? record.contracts.length : 0}`} />*/}
          {/*<FunctionField label={"# Evaluations"} render={record => `${record.evaluations ? record.evaluations.length : 0}`} />*/}
          <FunctionField label="Created" render={record => `${dateFromId(record._id, 'SHORT')}`} />
          {/*<UnShowButtonWithName record={record} resource={resource}/>*/}
          <UnIconButton label={'Detail'} />
        </Datagrid>
        </List>
      </>
  )
};

export default EmployerList;