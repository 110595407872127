import React from 'react';
import {
  Create,
  TextInput, SelectInput,
  useGetList,
  Loading,
  FormTab, TabbedForm, TopToolbar
} from 'react-admin';
import {BreadcrumbsCreate} from "../breadcrumbs";
import {queryParamString} from "../util/paramUtil";
import log from "loglevel";
import {CreateTitle} from "../components/InputFields";
import UnHelpButton from "../button/UnHelpButton";

// const redirect = (basePath, id, {name}) => `${basePath}/${id}/show?${queryParamString({name})}`;

const redirect = (basePath, id, data) => {
  const {name} = data;
  log.debug(`{redirect} data:`, data);
  return`${basePath}/${id}/show?${queryParamString({name})}`;
}

const RepresentativeActions = () => (
  <TopToolbar>
    <UnHelpButton helpPage={'/Configuration/application'}/>
  </TopToolbar>
);

const RepresentativeCreate = (props) => {
  const {resource} = props;
  const {
    isLoading: isSitesLoading,
    data: sites
  } = useGetList(
    'sites',
    {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}}
  );
  if (isSitesLoading) return <Loading/>;
  return (
    <>
      <BreadcrumbsCreate resource={resource}/>
      <Create
        title={<CreateTitle/>}
        actions={<RepresentativeActions/>}
      >
        <TabbedForm redirect={redirect}>
          <FormTab label="main">
            <TextInput source="name" fullWidth/>
            <TextInput source="uname" fullWidth/>
            <TextInput source="email" fullWidth/>
            <TextInput source="tel"/>
            <SelectInput label="Site" source="site" choices={sites} fullWidth/>
          </FormTab>
          <FormTab label="social media">
            <TextInput source="linkedin" fullWidth/>
            <TextInput source="facebook" fullWidth/>
            <TextInput source="twitter" fullWidth/>
            {/*Stored as array. Viewed as comma-separated string.*/}
            <TextInput source="benefits"
                       format={bens => bens ? bens.join() : ''}
                       parse={benStr => benStr.split(',')}
                       fullWidth
            />
          </FormTab>
        </TabbedForm>
      </Create>
    </>
  );
};


export default RepresentativeCreate;