
import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { Button } from 'react-admin';
import qs from "query-string";
import log from 'loglevel';

// Edit Icon
import ContentCreate from '@mui/icons-material/Create';

const styles = {
  button: {
    marginTop: '0em',
    marginLeft: '1em'
  }
};

/**
 * TODO: is basePath prop needed?
 * TODO: caption -> label
 * @param props
 * @returns {*}
 * @constructor
 */
const UnEditButton = (props) => {
  log.debug("{UnEditButton} props:", props);
  let {upperResource = undefined, upperResourceVals = undefined,
    pathname, classes, record = {}, caption = 'Edit', icon = <ContentCreate />} = props;
  log.debug(`{UnEditButton} caption: ${caption}, pathname: ${pathname}, record:`, record);
  if (!record) {
    log.debug(`{UnEditButton} Error initialising button: record not defined. `);
    record = {};
  }
  //if (!upperResource) log.debug(`{UnEditButton} upperResource not specified!`);
  const filterObj = {};
  if (upperResource === 'employer') {
    let employerId, employerName;
    log.debug(`{UnEditButton} upperResource is employer`);
    if (record.employer) {
      employerId = record.employer._id;
      employerName = record.employer.name;
      log.debug(`{UnEditButton} employer found - employerName: ${employerName}`);
    }
    else if (upperResourceVals) {
      employerId = upperResourceVals.id;
      employerName = upperResourceVals.name;
    }
    else if (record.employer_id) {
      employerId = record.employer_id;
      employerName = record.employer_name ? record.employer_name : ''; // Not available for contract
      log.debug(`{UnEditButton} record - employerName: ${employerName}`);
    }
    else {
      employerId = record._id;
      employerName = record.name;
    }
    filterObj[`${upperResource}_id`] = employerId;
    filterObj[`${upperResource}_name`] = employerName;
    log.debug(`{UnEditButton} filter - employerId: ${employerId}, employerName: ${employerName}`);
  }
  // if (!_.isEmpty(filterObj)) {
  //   log.debug("{UnEditButton} filterObj:", filterObj);
  // }
  if (!record._id) {
    log.debug(`{UnEditButton} Error initialising page: no _id found. `);
    return null;
  }
  const strFilter = qs.stringify({
    filter: record ? JSON.stringify(filterObj) : ''
  });
  log.debug(`{UnEditButton} pathname will be: ${pathname}/${record._id}/edit with query ${strFilter}`);
  return <Button
    className={classes.button}
    component={Link}
    to={{
      pathname: `${pathname}/${record._id}/edit`,
      search: strFilter
    }}
    label={caption}
    title={caption}
  >
    {icon}
  </Button>
};

export default withStyles(styles)(UnEditButton);
