/**
 * Display the full name of an entity.
 * It first checks whether it is an employer (ER), representative (RP) or user (US),
 * then looks up the appropriate list.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  ReferenceField,
  useRecordContext
} from 'react-admin';
import log from 'loglevel';

/**
 * With ReferenceField you can use a custom link function to get a custom path for the children.
 * This function must accept record and reference as arguments.
 * @param props E.g. {label: "Entity Name", source: "entity_id", user_name: "Edgardo Batz"}
 * @returns {JSX.Element}
 * @constructor
 */
const EntityName = (props) => {
  const record = useRecordContext();
  const {user_name} = props;
  // log.debug("{EntityName} props:", props);
  // log.debug("{EntityName} record:", record);
  // Hack
  record._id = record._id ? record._id : record.event;
  record.key = record._id;

  // const redirect = (record, reference) => {
  //   log.debug("{redirect} reference:", reference);
  //   return `${basePath}/${record.id}/show?${queryParamString({name: 'test'})}`;
  // }

  // TODO: pass name for breadcrumbs
  if (record.entity_code === 'RP') { // Previously 'Rep'
    return (<span>
    <ReferenceField label="Name" source="entity_id" reference="representatives"
          //link={(record, reference) => `/my/path/to/${reference}/${record.id}`}
          //link={redirect}
          link={'show'}
          {...props}>
      <TextField source="name"/>
    </ReferenceField>
  </span>);
  }
  else if (record.entity_code === 'ER') {
    return (<span>
    <ReferenceField label="Name" source="entity_id" reference="employers" link={'show'} {...props}>
      <TextField source="name"/>
    </ReferenceField>
   {/*<Link*/}
   {/*  key={record.entity_id}*/}
   {/*  to={`/employers/${record.entity_id}/show?${queryParamString({name: record.name})}`}*/}
   {/*  //onClick={stopPropagation}*/}
   {/*  >*/}
   {/*   {record.name}*/}
   {/*</Link>*/}
  </span>);
  }
  else if (record.entity_code === 'US') {
    log.debug(`{EntityName} entity_code: US (User). user_name:`, user_name);
    return (<span>
        {user_name}
    {/*<ReferenceField label="Name" source="entity_id" reference="users" link={'show'} {...props}>*/}
    {/*  <TextField source="user_name"/>*/}
    {/*</ReferenceField>*/}
    {/*<FunctionField label="Name" render={() => user_name} />*/}
  </span>);
    }
  else {
    return(<span></span>);
  }
};

EntityName.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default EntityName;