import {
  downloadCSV
} from 'react-admin';
import {unparse as convertToCSV} from 'papaparse/papaparse.min';
import log from 'loglevel';

// See: https://marmelab.com/react-admin/List.html#exporter
// The <ExportButton> limits the main request to the dataProvider to 1,000 records.
// If you want to increase or decrease this limit, pass a maxResults prop to the <ExportButton>
// in a custom <ListActions> component,

const exportFileName = ees => {
  let employerName = (ees.length > 0) ? ees[0].employer.name.replace(/\s/g, '_') : '';
  let fileName = (ees.length > 0) ? 'Employees_for_' + employerName : 'Employees';
  return fileName + '_' + new Date().toLocaleDateString();
};

const exportEmployeesNormal = ees => {
  log.debug("{exportEmployeesNormal} v2 ees:", ees);
  const fileName = exportFileName(ees);
  log.debug("{exportEmployeesNormal} fileName:", fileName);
  const eesForExport = ees.map(ee => {
    let eeForExport = {};
    log.debug("{exportEmployeesNormal} ee:", ee);
    eeForExport['First Name'] = ee.employee.first_name; // add a field
    eeForExport['Last Name'] = ee.employee.last_name;
    eeForExport['Gender'] = ee.employee.gender;
    eeForExport['City'] = ee.employee.city;
    eeForExport['Date of Birth'] = ee.employee.dob ? new Date(ee.employee.dob).toLocaleDateString() : '';
    eeForExport['Join Date'] = ee.start_date ? new Date(ee.start_date).toLocaleDateString() : '';
    eeForExport['Leave Date'] = ee.end_date ? new Date(ee.end_date).toLocaleDateString() : '';
    //eeForExport['Created'] = dateFromId(ee._id, 'SHORT');
    return eeForExport;
  });
  const csv = convertToCSV({
    data: eesForExport,
    // Order fields in the export
    fields: ['First Name', 'Last Name', 'Gender', 'City', 'Date of Birth', 'Join Date', 'Leave Date', 'Created']
  });
  downloadCSV(csv, fileName); // download as 'ees.csv` file
};

export default exportEmployeesNormal;