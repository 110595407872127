import React  from 'react';
import Typography from '@mui/material/Typography';
import {
  EditContextProvider, EditView,
  TabbedForm, FormTab,
  TextInput, required, minLength, useEditController,
  Loading
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import { UnTextInput, UnEditToolbar, DetailTitle } from "../components/InputFields";
import {UnEditActions} from "../components/EditActions";
import {Breadcrumbs} from "../breadcrumbs";
// import {queryParamObject} from "../util/paramUtil";
// import {useLocation, useParams} from "react-router-dom";
// import log from "loglevel";


const useStyles = makeStyles(theme => ({
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20,
  },
  titleLink: { textDecoration: 'none', color: 'inherit' },
  // card: {
  //   padding: '0px 0',
  //   overflow: 'inherit',
  //   textAlign: 'right',
  // },
  title: {
    padding: '0 0px',
  },
  value: {
    padding: '0 16px',
    minHeight: 48,
  },
  avatar: {
    background: theme.palette.background.avatar,
  },
  listItemText: {
    overflowY: 'hidden',
    height: '4em',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  }
}));

const Aside = ({classes}) => (
  <div style={{ width: 200, margin: '1em' }}>
    <Typography className={classes.title} color="textSecondary">
      Site details
    </Typography>
    <Typography variant="caption" color="inherit" align="right">
      A site should be assigned to an application.
    </Typography>
  </div>
);

export const SiteEdit = () => {
  const classes = useStyles();
  const editContext = useEditController({redirect: 'show'});
  const {isLoading} = editContext;
  if (isLoading) return <Loading/>
  return (
    <EditContextProvider value={editContext}>
      <Breadcrumbs/>
      <EditView
        title={<DetailTitle />}
        actions={<UnEditActions/>}
        aside={<Aside classes={classes} />}
        >
        <TabbedForm toolbar={<UnEditToolbar/>}>
          <FormTab label="site">
            {/*<TextInput source="uname" />*/}
            <UnTextInput source="uname" fullWidth validate={[required(), minLength(2)]}/>
            <UnTextInput source="name" fullWidth/>
            <UnTextInput source="logo" label={"Logo (e.g. /assets/images/cclogo46@2xwhite.png)"} fullWidth/>
            {/*<div>Logo example: https://s3-eu-west-1.amazonaws.com/docs-untangl-io/logos/faircare.png</div>*/}
            {/*fullWidth="true"*/}
            <UnTextInput source="tagline" fullWidth/>
            <TextInput source="type"/>
            {/*<TextInput label="Id" source="id" disabled/>*/}
          </FormTab>
          <FormTab label="strapline">
            <TextInput source="strapline" fullWidth/>
          </FormTab>
        </TabbedForm>
      </EditView>
    </EditContextProvider>
  );
};

export default SiteEdit;