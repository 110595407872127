import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
//import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import React from "react";

const sources = [
  { id: 'expr', name: 'Formula' },
  { id: 'code', name: 'Dictionary' },
  { id: 'constant', name: 'Constant' },
  { id: 'function', name: 'Function' },
  { id: 'calc', name: 'Calculation' }
];
const getSourceName = (abbr) => sources.filter(s => s.id === abbr)[0].name;

const necessities = [
  { id: 'mandatory', name: 'Mandatory ' },
  { id: 'expected', name: 'Expected' },
  { id: 'optional', name: 'Optional' }
];

// const getNecessityIndex = (necessity) => necessities.filter(nec => nec.id === necessity)[0].name;

const getNecessityIndex = (necessity) => {
  const index = necessities.map(nec => nec.id).indexOf(necessity);
  //const x = necessities.filter(nec => nec.id === necessity)[0].name;
  //console.log(`{getNecessityIndex} necessity: ${necessity}, index:`, index)
  return index;
}

const NecessityField = ({record}) => {
  let starsArray = [];
  //console.log(`{NecessityField} record:`, record);
  const numStars = record ? getNecessityIndex(record.necessity) : '';
  //console.log(`{NecessityField} necessity: ${record ? record.necessity : '???'}, numStars:`, numStars);
  const colors = ['#0072ea', 'orange', 'red'];
  for (let i = 0; i <= numStars; i++) {
    starsArray.push(<FiberManualRecordIcon
      key={i}
      fontSize={'inherit'}
      style={{fill: colors[numStars]}}
    />)
  }
  return starsArray;
}

// const options = [ // formats
//   'string',
//   'number',
//   'integer',
//   'date',
//   'date_iso',
//   'money',
//   'money_with_symbol',
//   'money_currency',
//   'genderMF',
//   '#0',
//   '#0.0',
//   '000'
// ]

export {
  sources,
  getSourceName,
  necessities,
  getNecessityIndex,
  NecessityField,
  //options
}