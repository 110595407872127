export default (yaml, filename) => {
  console.log(`{downloadYaml} yaml:`, yaml);
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  const blob = new Blob([yaml], {type: 'text/plain'});   // MIME type csv for default
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Manage IE11+ & Edge
    window.navigator.msSaveOrOpenBlob(blob, `${filename}.yaml`);    // Was csv
  } else {
    fakeLink.setAttribute('href', URL.createObjectURL(blob));
    fakeLink.setAttribute('download', `${filename}.yaml`);  // Was csv
    fakeLink.click();
  }
};
