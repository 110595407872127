import DictionaryAliasIcon from '@mui/icons-material/MenuBook';
import DictionaryAliasList from './DictionaryAliasList';


export default {
  list: DictionaryAliasList,
  // show: DictionaryEntryShow,
  // edit: DictionaryEntryEdit,
  // create: DictionaryEntryCreate,
  ICON: DictionaryAliasIcon
};