import React from 'react';
import {
  Create, SelectInput,
  SimpleForm,
  FileInput,
  FileField,
  useGetList,
  Toolbar, SaveButton,
  required
} from 'react-admin';
import {BreadcrumbsCreate} from "../breadcrumbs";
import {CreateTitle} from "../components/InputFields";
//import {queryParamString} from "../util/paramUtil";

//const Box = styled('div')(compose(spacing, palette));

// const useStyles = makeStyles(() => ({
//   button: {
//     marginTop: '0em',
//     marginRight: '1em',
//     width: '1em'
//     //height: '10em'
//     //color: 'red'
//   }
// }));

// const UnSaveButton = ({className}) => <Button label="" className={className}></Button>
//
// const ConvertOpenButton = props => {
//   console.log(`{ConvertOpenButton} props:`, props);
//   const dataProvider = useDataProvider();
//   const notify = useNotify();
//   const resource = 'untanglements';
//   // const id = record ? record.id : 0;
//   const id = 0;
//   //console.log(`{ConvertOpenButton} 2nd button - props:`, props);
//
//   const afterSave = props => {
//     console.log(`{ConvertOpenButton.afterSave} props:`, props);
//     // dataProvider.create(resource, // e.g. 'untanglements'
//     //   {args: {format: 'xlsx'}}
//     // )
//     //   .then(
//     //     ({data}) => {
//     //       console.log(`{ConvertOpenButton.afterSave} data:`, data);
//     //       return downloadXlsx(data.data, resource);  // !!!
//     //     }
//     //   )
//     //   .catch(error => {
//     //     console.error(error);
//     //     notify('ra.notification.http_error', 'warning');
//     //   });
//   }
//   return (
//     <SaveButton label="Convert and Open"
//                        redirect={false}
//                        submitOnEnter={false}
//                        handleSubmitWithRedirect={afterSave}
//                        //onClick={beforeSave}
//                        onSuccess={afterSave}
//     />
//   );
// }

const UntanglementCreateToolbar = () => {
  return (
    <Toolbar>
      <SaveButton label="Convert"/>
      {/*<UnSaveButton label="" className={classes.button} />*/}
      {/*<ConvertOpenButton label="Convert and Open" />*/}
    </Toolbar>
  );
};

const UntanglementCreate = () => {
  const {isLoading: isTemplatesLoading, data: templates} = useGetList(
    'templates', {
      pagination: {page: 1},
      sort: {field: 'name', order: 'ASC'}
  });
  if (isTemplatesLoading) return null;
  return (
    <>
      <BreadcrumbsCreate />
      <Create
        title={<CreateTitle/>}
        redirect="show"
      >
        <SimpleForm
          toolbar={<UntanglementCreateToolbar />}
        >
          <SelectInput
            label="Template"
            source="templateId"
            choices={templates}
            validate={required()}
            translateChoice={false}
          />
          <FileInput source="fileData" label="Input file" accept=".xlsx,.csv" translateChoice={false}
                     placeholder={<p>Drag & drop file to be converted</p>}>
            <FileField source="src" title="title" translateChoice={false}/>
          </FileInput>

          {/*<UntanglementDropPanel*/}
          {/*  // templateId={templateId} templateName={templateName}*/}
          {/*  title={'Drag & drop file to convert file with Untangler'}*/}
          {/*  onload={(props) => {*/}
          {/*    const d = loadFile(props, templateId);*/}
          {/*    setData(d);*/}
          {/*
          {/*  }}*/}
          {/*  // dictionaryIds={dictionaryIds}*/}
          {/*  resource={resource}/>*/}
        </SimpleForm>
      </Create>
    </>
  );
}

export default UntanglementCreate;