import EmployerIcon from '@mui/icons-material/Work';
import EmployerList from './EmployerList';
import EmployerShow from './EmployerShow';
import EmployerEdit from './EmployerEdit';

export default {
  list: EmployerList,
  show: EmployerShow,
  edit: EmployerEdit,
  ICON: EmployerIcon
};