import React, {useMemo, useState}  from "react";
import {useDropzone} from 'react-dropzone';
import log from 'loglevel';
import {makeStyles} from "@mui/styles";
import {useResourceContext} from "react-admin";

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'  // Colour of dotted box when a valid file is dropped
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',  // Extends box vertically to bottom
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1.0),
    },
  }
}));

function _arrayBufferToBase64(buffer) {
  let binary = '';
  let bytes = new Uint8Array( buffer );
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode( bytes[ i ] );
  }
  return window.btoa( binary );
}

function StyledDropzone(props) {
  const resource = useResourceContext();
  const {setDataFiles} = props;
  log.debug(`{StyledDropzone} resource: ${resource}`);
  const [, setFiles] = useState([]);
  const classes = useStyles();
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
      //accept: 'application/*',
      onDrop: acceptedFiles => {
        acceptedFiles.map(file => {
          log.debug(`{StyledDropzone} Drop - file name:`, file.name);
          //log.debug(`Drop - file:`, file);
          const reader = new FileReader();
          reader.onloadstart = () => console.log('File reader started');
          reader.onloadend = () => console.log('File reader ended');
          reader.onabort = () => console.log('File reader aborted');
          reader.onerror = () => console.log('File reader failed');
          reader.onload = () => {
            // Process file contents
            const contentsStr = reader.result;
            log.debug(`{StyledDropzone} Drop - contentsStr:`, contentsStr);
            const encodedStr = _arrayBufferToBase64(contentsStr);
            setDataFiles([encodedStr])
          }
          reader.readAsArrayBuffer(file);

          setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
          })));

          return null;  //To prevent warning array-callback-return
        });

        // setDataFiles(acceptedFiles.map(file => Object.assign(file, {
        //   preview: URL.createObjectURL(file)
        // })));
        // setFiles(acceptedFiles.map(file => Object.assign(file, {
        //   preview: URL.createObjectURL(file)
        // })));
      }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  return (
    <div className={classes.root}>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        {files.length < 1 ? "Drag & drop file for template fields" : ""}
          {files}
      </div>
    </div>
  );
}

const SetupDropPanel = (props) => {
  return <StyledDropzone {...props}/>
}

export default SetupDropPanel;