import React from 'react';
import {
  Datagrid, List,
  TopToolbar,
  TextField, DateField,
  DateInput,
  Filter,
  Pagination,
  useTranslate, useResourceContext
} from 'react-admin';
import UnCreateButton from "../button/UnCreateButton";
import {isoDateString} from "../util/dateUtil";
import {queryParamFilter, queryParamString} from "../util/paramUtil";
import {Breadcrumbs} from "../breadcrumbs";
import UnIconButton from "../button/UnIconButton";
import {useLocation} from "react-router-dom";

/**
 * Instead of using the List component, this uses the 2 constituents, the useListController hook (which
 * gets the data) and ListView which renders the UI. This allows us to access the data before it is displayed
 * on the page (in order to remove a spurious row).
 */

/*
http://192.168.56.102:3000/#/contracts?filter=%7B%22employer_id%22%3A%225db7128c02aa9327364b8db7%22%2C%22employer_name%22%3A%22Batz%2C%20Kshlerin%20and%20Stamm%22%7D&page=1&perPage=25
 */

const ContractListTitle = ({employerName}) => {
  return <span>Contracts {`for ${employerName ? employerName : ''}`}</span>;
};

const ContractFilter = (props) => (
  <Filter {...props}>
    {/*<TextInput label="Search" source="q" alwaysOn />*/}
    <DateInput source="as_of_date" alwaysOn/>
  </Filter>
);

/**
 * This allows the Add Filter button to be included in the custom actions.
 * @param props
 * @returns {*}
 * @constructor
 */
const ContractListActions = props => {
  const {filter} = props;
  return <TopToolbar>
    {/*{filters && React.cloneElement(filters, {*/}
      {/*resource,*/}
      {/*showFilter,*/}
      {/*displayedFilters,*/}
      {/*filterValues,*/}
      {/*context: 'button',*/}
    {/*})}*/}
    {/*<CreateEvaluationButton basePath={basePath} record={data} filterValues={filterValues}/>*/}
    {/*<ShowButton label="Employer" basePath={'employers'} record={{id: employerId}} ICON={<Employers.ICON />}/>*/}

     {/* <Icons.create/> hardcoded in UnCreateButton */}
    <UnCreateButton label="Create" filter={filter}/>
  </TopToolbar>
};

// Remove pagination
const ContractPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;  // [100, 200]

/**
 * const MyList = props => {
 * const controllerProps = useListController(props);
 * return <ListView {...controllerProps} {...props} />;
 *
 * @returns {*}
 * @constructor
 */

export const ContractList = () => {
  const translate = useTranslate();
  const resource = useResourceContext();
  const location = useLocation();
  const {employer_id, employer_name} = queryParamFilter(location);
  console.log(`{ContractList} employer_id: ${employer_id}, employer name: ${employer_name}`);
  if (!employer_id) {
    return (<span>No employer specified in contacts page</span>);
  }
  const as_of_date = isoDateString();
  //console.log("{ContractList} as_of_date:", as_of_date);
  const parent = 'employers';
  const breadcrumbs = [
    {url: `/${parent}`, title: translate(`resources.${parent}.name_many`), resource: parent},
    {url: `/${parent}/${employer_id}/show?${queryParamString({name: employer_name})}`, title: employer_name, resource: parent},
    {url: ``, title: translate(`resources.${resource}.name_many`), resource},
  ];
  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <List
        title={<ContractListTitle employerName={employer_name} />}
        filter={{employer_id}}
        filters={<ContractFilter/>}
        actions={<ContractListActions filter={{employer_id, employer_name}}/>}
        pagination={<ContractPagination/>}
        bulkActionButtons={false}
        filterDefaultValues={{as_of_date}}  // TODO: Why doesn't this set the as-of date filter control at top left?
      >
      <Datagrid>
        <TextField source="benefit_uname"/>
        <TextField source="benefitName"/>
        <TextField source="benefit_version"/>
        {/*<TextField source="rates_version"/>*/}
        {/*<TextField source="rates_name"/>*/}
        {/*<TextField source="policy_number"/>*/}
        <DateField source="start_date"/>
        <DateField source="end_date"/>
        <DateField source="creation_date"/>
        {/*<TextField source="id"/>*/}
        {/*<UnEditButton pathname='/contracts' basePath={basePath} record={data} caption={'Edit'}*/}
        {/*              upperResource='employer' upperResourceVals={upperResourceVals} />*/}

        {/*<UnShowButtonWithFilter filter={{employer_id, employer_name: employer_name}} {...props} />*/}
        <UnIconButton label={'Detail'} filter={{employer_id, employer_name: employer_name}} />

        {/*<MyDeleteContractButton label="Delete" basePath={basePath} record={data} resource={resource}*/}
                                {/*redirect={`list?employer_id=${employerId}&`}/>*/}
        {/*<DeleteContractButton basePath={basePath} record={data} resource={resource} />*/}
      </Datagrid>
    {/*</ListView>*/}
    </List>
  </>
  )
};

export default ContractList;