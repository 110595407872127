import React from 'react';
import {
  Create, FormTab, SelectInput,
  TabbedForm,
  required, minLength,
  TextInput, TopToolbar,
  useGetList,
  useResourceContext
} from 'react-admin';
import {Breadcrumbs} from "../breadcrumbs";
import {CreateTitle} from "../components/InputFields";
import {useParams} from "react-router-dom";
import UnHelpButton from "../button/UnHelpButton";

const DictionaryActions = () => (
  <TopToolbar>
    <UnHelpButton helpPage={'/Configuration/dictionaries'}/>
  </TopToolbar>
);

const DictionaryCreate = () => {
  const resource = useResourceContext();
  const params = useParams();
  console.log(`{DictionaryCreate} params:`, params);  // {*: ''}

  const breadcrumbs = [
    {url: `/${resource}`, label: resource, resource},
    {url: ``, title: 'Create', resource}
  ];
  const {isLoading: isLocalesLoading, data: localesAll} = useGetList(
   'locales',
    {pagination: {page: 1}, sort: {field: 'name', order: 'ASC'}
  });
  if (isLocalesLoading) return null;
  const locales = localesAll.map(r => ({id: r._id, name: r.name}));
  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Create
        title={<CreateTitle/>}
        actions={<DictionaryActions/>}
      >
        <TabbedForm>
          <FormTab label="Dictionary">
            <TextInput source="name" validate={[required(), minLength(2)]} fullWidth/>
            <TextInput source="section" fullWidth/>
            <SelectInput label="Locale" source="locale" choices={locales} fullWidth />
            <TextInput source={'description'} multiline={true} rows={7} fullWidth/>
          </FormTab>
          <FormTab label="YAML">
            <TextInput source="yaml" multiline={true} rows={16} fullWidth/>
          </FormTab>
        </TabbedForm>
      </Create>
    </>
  );
}

export default DictionaryCreate;