import React, {useState} from 'react';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import {useLocation} from "react-router-dom";
import {
  FilterForm,
  //TextField, DateField,
  DateInput,
  SelectInput,
  FilterButton,
  ExportButton,
  //TextInput,
  Title,
  //Pagination,
  useListController,
  ListContextProvider,
  usePermissions,
  Loading, useResourceContext
} from 'react-admin';
import {
  //defaultPerPage,
  queryParamsToObject,
  queryParamsToFilterString,
  UnPagination
  } from "../util/paramUtil";
import {Breadcrumbs} from "../breadcrumbs";
import log from 'loglevel';
import {Activity, isAuthorized} from "../auth/authorization";
import {ListTitle} from "../components/InputFields";
import {CustomGrid} from './EmployeeGrid';
import customExport from "./export/index";
import UnCreateButton from "../button/UnCreateButton";
//export const EmployeeIcon = Icon;

/**
 * This uses the RA (react-admin) List component which is both a UI view and a connected component (fetches data).
 * Normally a Datagrid is next used to display a table and to iterate through each row and cell.
 * Instead the test TableRowsIterator is used, which calls internal test components TableHeader and TableRow.
 * The fields are represented by the normal RA field components.
 *
 * Changing the view dropdown updates the redux employer view. Changed to setEmployerView state.
 * Only display view dropdown with sufficient permissions, e.g. EMPLOYER.
 */

const EmployeesToolbar = ({onChange, filter}) => {
  const employeeFilters = [
    <SelectInput
      source="view"
      choices={[
        {id: 'normal', name: 'Normal'},
        {id: 'leavers', name: 'Canada Life'},
        {id: 'westfield', name: 'Westfield'},
      ]}
      initialValue = 'normal'
      onChange={onChange}
      alwaysOn
    />,
    <DateInput source="as_of_date" />
  ];
  return (
    <Stack direction="row" justifyContent="space-between">
      <FilterForm filters={employeeFilters} />
      <FilterButton filters={employeeFilters} />
      <ExportButton />
      <UnCreateButton label="Create" filter={filter}/>
      {/*<CreateButton />*/}
    </Stack>
  )
}

//const EmployeeTitle = ({entityName}) => <span>Employees {entityName ? ' for ' + entityName: ''}</span>;


// TODO: Improve
const isValidObjectId = id => id && id.length >= 12;

/**
 * No pagination on backend API
 * Note this obtains the employer name, displayed in a breadcrumb, from the current query (via listContext),
 * rather than from a query argument in the URL.
 * @returns {*}
 * @constructor
 */
const ConfigurableEmployeesList = () => {
  const {permissions} = usePermissions();
  const location = useLocation();
  const [employerView, setEmployerView] = useState()
  const resource = useResourceContext();
  const {employer_id, employer_name, view: viewSel} = queryParamsToObject(location);
  const view = viewSel ? viewSel : 'normal';
  const sort = {field: 'employee.last_name', order: 'ASC'};
  const listContext = useListController({
    filter: {employer_id},
    sort,
    perPage: 100, // defaultPerPage???
    exporter: customExport(view)
  });
  if (!permissions) return null;

  if (!isValidObjectId(employer_id)) {
    return <span>No employer specified</span>
  }
  log.debug(`{EmployeeList} employer_id: ${employer_id}, employer_name: ${employer_name}, view: ${view}`);
  const {data, isLoading} = listContext;
  log.debug(`{ConfigurableEmployeesList} employerView: ${employerView}, data:`, data);

  const onChange = (event) => {
    if (setEmployerView) {
      console.log(`{ConfigurableEmployeesList.onChange} setEmployerView with value:`, event.target.value);
      setEmployerView(event.target.value);
    }
    else console.log(`{ConfigurableEmployeesList.onChange} Warning - no setEmployerView set!`);
  };

  const parent = 'employers';
  const breadcrumbs = [
    {url: `/${parent}`, label: parent, resource: parent},
    {url: `/${parent}/${employer_id}/show?${queryParamsToFilterString({name: employer_name})}`, title: employer_name, resource: parent},
    {url: ``, label: resource, resource},
  ];
  if (isLoading) return <Loading />;
  return (
    <ListContextProvider value={listContext}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div>
        <Title title={<ListTitle nameValue={employer_name}/>}/>
        <EmployeesToolbar onChange={onChange} filter={{employer_id, employer_name}}/>
        <Card>
          <CustomGrid view={view} filter={{employer_id, employer_name}} />
        </Card>
        <UnPagination />
      </div>
    </ListContextProvider>
  );
};

export const EmployeeList = () => {
  const {permissions} = usePermissions();
  if (isAuthorized(permissions, Activity.EMPLOYER)) {
    return <ConfigurableEmployeesList />
  }
  else return null;
};

export default EmployeeList;

