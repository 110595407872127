import SiteIcon from '@mui/icons-material/Web';
import SiteList from './SiteList';
import SiteShow from './SiteShow';
import SiteCreate from './SiteCreate';
import SiteEdit from './SiteEdit';

export default {
  list: SiteList,
  show: SiteShow,
  create: SiteCreate,
  edit: SiteEdit,
  ICON: SiteIcon
};