import RepresentativeIcon from '@mui/icons-material/Business';
import RepresentativeList from './RepresentativeList';
import RepresentativeShow from './RepresentativeShow';
import RepresentativeCreate from './RepresentativeCreate';
import RepresentativeEdit from './RepresentativeEdit';

export default {
  list: RepresentativeList,
  show: RepresentativeShow,
  create: RepresentativeCreate,
  edit: RepresentativeEdit,
  ICON: RepresentativeIcon
};