import React from 'react';
import {
  List, Datagrid, Filter,
  TextField, FunctionField,
  TopToolbar,
  useResourceContext,
  usePermissions,
  BulkDeleteWithConfirmButton
} from 'react-admin';
import log from 'loglevel';
import UnCreateButton from '../button/UnCreateButton';
import {constraintsStr} from './DictionaryEntryUtil';
import exportDictionaryEntries from './DictionaryEntryExport';
import {BreadcrumbsAll} from "../breadcrumbs";
import {
  defaultPerPage,
  queryParamString,
  queryParamFilter,
  UnPagination
} from "../util/paramUtil";
import {Activity, isAuthorized} from "../auth/authorization";
import {useLocation, useParams} from "react-router-dom";
import UnIconButton from "../button/UnIconButton";
import {ListTitle} from "../components/InputFields";

const DictionaryFilter = (props) =>
  <Filter {...props}>
    {/*<TextInput label='Search for entry' source='alias' alwaysOn />*/}
    {/*<TextInput label='Code' source='code' />*/}
    {/*<SelectArrayInput label="Dictionary" source="dicts" choices={dictChoices} />*/}
  </Filter>;

//const DictionaryEntryPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />;

/**
 * dictId (for parent entity) is put in meta object, which is passed in mutationOptions object:
 *   mutationOptions={{meta: {dictId}}}
 * meta object becomes available in data provider deleteMany in argument params.
 * See BulkDeleteButton at https://marmelab.com/react-admin/Buttons.html
 * @returns {JSX.Element}
 * @constructor
 */
const EntryBulkActionButtons = ({mutationOptions}) => {
  //log.debug(`{EntryBulkActionButtons} mutationOptions:`, mutationOptions);
  return(
  <>
    {/*<BulkExportButton/>*/}
    <BulkDeleteWithConfirmButton mutationOptions={mutationOptions}/>
  </>
)}


/**
 * Buttons in top toolbar at top right.
 * Note that we can use regular CreateButton component since we do not need to specify extra
 * dictionary ID as it is specified in the form (in the dropdown list).
 * BUT - default???
 *
 * @param resource
 * @param filter
 * @returns {*}
 * @constructor
 */
const DictionaryEntryActions = ({resource, permissions, filter}) => {
  const {dictId, dictName} = filter;
  log.debug(`{DictionaryEntryActions} dictId: ${dictId}, dictName: ${dictName}`);
  return (
    <TopToolbar>
      {/*<ShowButton label={`navigation.back`}*/}
      {/*            basePath={'/dictionaries'}*/}
      {/*            record={{id: dictId}}*/}
      {/*            icon={<Icons.back/>}/>*/}
      {/*{filters && React.cloneElement(filters, {*/}
      {/*  resource,*/}
      {/*  showFilter,*/}
      {/*  displayedFilters,*/}
      {/*  filterValues,*/}
      {/*  context: 'button',*/}
      {/*})}*/}
      {/*<ShowButton label={`resources.dictionaries.name_one`}*/}
      {/*            basePath={'/dictionaries'}*/}
      {/*            record={{id: dictId}}*/}
      {/*            icon={<Dictionaries.ICON/>}/>*/}
      {isAuthorized(permissions, Activity.DICTIONARY) ?
      <UnCreateButton label="Create"
                      resource={resource}
                      filter={filter}
                      idProp={'dictId'}
      />
      : null}
    </TopToolbar>
  );
}

/**
 * List of dictionary entries.
 * The bulk delete is carried out using Datagrid bulkActionButtons using the mutationOptions property
 * to pass the parent dictId. See also deleteMany in dataProviderRest.js.
 * @returns {JSX.Element}
 * @constructor
 */
export const DictionaryEntryList = () => {
  const location = useLocation();
  const resource = useResourceContext();
  const {permissions} = usePermissions();
  const params = useParams();
  log.debug(`{DictionaryEntryList} params:`, params);
  const {dictId, dictName} = queryParamFilter(location);
  log.debug(`{DictionaryEntryList} dictId: ${dictId}, dictName: ${dictName}`);
  const parent = 'dictionaries';  // NB breadcrumbs label translates via i18n name_many
  const breadcrumbs = [
    {url: `/${parent}`, label: parent, resource: parent},
    {url: `/${parent}/${dictId}/show?${queryParamString({dictName, name: dictName})}`, title: dictName, resource: parent},
    {url: `/${resource}`, label: resource, resource}
  ];
  return (
    <React.Fragment>
      <BreadcrumbsAll breadcrumbs={breadcrumbs} />
      <List
          sort={{field: 'name', order: 'ASC'}}
          perPage={defaultPerPage}
          pagination={<UnPagination/>}
          actions={<DictionaryEntryActions permissions={permissions} filter={{dictId, dictName}}/>}
          filters={<DictionaryFilter/>}
          exporter={exportDictionaryEntries}
          title={<ListTitle />}
      >
        <Datagrid
          //bulkActionButtons={<EntryBulkActionButtons mutationOptions={{meta: {dictId}}}/>}
          bulkActionButtons={isAuthorized(permissions, Activity.TEMPLATE_PERMISSIONS) ?
            <EntryBulkActionButtons mutationOptions={{meta: {dictId}}}/> :
            false
          }
        >
          <TextField source='name' label='Entry name'/>
          <TextField source='code'/>
          <TextField source='type'/>
          <TextField source='notes' label={'Description'}/>
          {/*<NumberField source='weight' sortable={true} />*/}
          {/*<TextField source='dictName' label='Dictionary' sortable={false}/>*/}
          <FunctionField source='constraints' label='Constraints' sortable={false} render={r => constraintsStr(r)}/>
          {/*<UnEntryIconButton label={'Detail'} dictId={dictId} {...props}/>*/}
          <UnIconButton label={'Detail'} filter={{dictId}}/>
        </Datagrid>
      </List>
    </React.Fragment>
  );
}

export default DictionaryEntryList;
