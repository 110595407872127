import PersonIcon from '@mui/icons-material/Web';
// import PersonList from './PersonList';
import PersonShow from './PersonShow';
import PersonCreate from './PersonCreate';
import PersonEdit from './PersonEdit';

export default {
  // list: PersonList,
  show: PersonShow,
  create: PersonCreate,
  edit: PersonEdit,
  ICON: PersonIcon
};